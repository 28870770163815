import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL, config } from '../../../config/config';
import { useSelector } from 'react-redux';
import Loader from '../../common/Loader';
import { Link } from 'react-router-dom';
import TableComponent from '../../common/TableComponent';
import { toast } from 'react-hot-toast';
import SuccessConstant from '../../../constants/SuccessConstant';
import Pagination2 from '../../common/Pagination2';
import commonService from '../../../services/common/commonService';
import VendorDashboardMobileCard from '../../common/VendorDashboardMobileCard';
const Table = () => {


  const productsPerPage = 20;

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paymentCompletedOrders, setPaymentCompletedOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const vendor = useSelector((state) => state.vendor);
  const loggedInVendorId = vendor && vendor.vendor && vendor.vendor._id;
  const PRODUCT_API_URL = `${API_URL}/api/products/getProduct`;
  const noOrdersMessage = (
    <div className="text-center text-gray-500 mt-10">
      No new orders available.
    </div>
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await commonService.fetchAllOrders();      
        const ordersWithProductDetails = await Promise.all(
          response.map(async (order) => {
            // Filter productDetails based on vendorId
            const filteredProductDetails = order.productsDetails.filter(
              (product) => product.vendorId === loggedInVendorId
            );

            // If there are productDetails for the logged-in vendor, fetch additional info
            if (filteredProductDetails.length > 0) {
              const productDetailsWithAdditionalInfo = await Promise.all(
                filteredProductDetails.map(async (product) => {
                  try {
                    const productResponse = await axios.get(
                      `${PRODUCT_API_URL}/${product.productId}`
                    );

                    return { ...product, additionalInfo: productResponse.data };
                  } catch (error) {
                    console.error('Error fetching product details:', error);
                    return { ...product, additionalInfo: null };
                  }
                })
              );

              // If there is at least one product detail, include the order
              if (productDetailsWithAdditionalInfo.length > 0) {
                return { ...order, productsDetails: productDetailsWithAdditionalInfo };
              }
            }

            return null; // Filter out orders with no matching productDetails
          })
        );

        // Remove null values and set orders
        const filteredOrders = ordersWithProductDetails.filter((order) => order !== null);
        const paymentCompletedOrders = filteredOrders.filter((order) => order.paymentStatus === 'completed');

        setOrders(paymentCompletedOrders);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [loggedInVendorId]);

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      // Make a request to your backend to update the order status
      await axios.put(`${API_URL}/api/order/updateOrderStatus/${orderId}`, {
        orderStatus: newStatus,
      }, config);

      // Update the local state with the new order status
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order._id === orderId ? { ...order, orderStatus: newStatus } : order
        )
      );
      toast.success(SuccessConstant.Success.Inprocess)
    } catch (error) {
      console.error('Error updating order status:', error);
    }
    
  };

  const getCityFromAddress = (fullAddress) => {
    const addressParts = fullAddress?.split(',');
    const cityIndex = 1; // Index of the city part
    const city = addressParts[cityIndex]?.trim() || '';
    return city;
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-IN', options);
    return formattedDate;
  };

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
    scrollToActivePage(pageNumber);
  };

  const scrollToActivePage = (pageNumber) => {
    const paginationButton = document.getElementById(`page-${pageNumber}`);
    if (paginationButton) {
      paginationButton.scrollIntoView({ behavior: "smooth", inline: "center" });
    }
  };
  const newOrders = orders.filter((order) => order.orderStatus === 'NewOrders');

  const scrollTo = () => {
    window.scrollTo(0, 0);
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(newOrders.length / productsPerPage);

  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = Math.min(startIndex + productsPerPage, newOrders.length);

  return (
    <>
      <div className='hidden sm:block p-5 overflow-x-auto'>
        {loading ? (
          <div className='flex justify-center'><Loader /></div>
        ) : newOrders.length === 0 ? (
          noOrdersMessage
        ) : (
          <div>
          <TableComponent
            heads={['Customer', 'Order Details', 'Order Status', 'Date/Time', 'Action']}
            data={newOrders.slice(startIndex, endIndex).map((item, index) => {
              const uniqueProductIds = new Set(
                item.productsDetails.map((product) => product.productId)
              );
              return Array.from(uniqueProductIds).map((productId, productIndex) => {
                const productDetail = item.productsDetails.find((product) => product.productId === productId);
                if (productDetail) {
                  const image = productDetail.additionalInfo?.product?.images?.[0];
                  return (
                    <React.Fragment key={`${index}-${productIndex}`}>
                      <td>{item?.shippingAddress?.fullname}</td>
                      <td>
                        <div className='flex flex-col lg:flex-row gap-2 items-center'>
                          <div key={productIndex}>
                            <Link
                              to={{
                                pathname: `/SpecificProductsPage/${productDetail.additionalInfo?.product?._id}`,
                                state: { productId: productDetail.additionalInfo?.product?._id }
                              }}
                              target='_blank'
                              onClick={scrollTo}
                            >
                              {image && (
                                <img src={image} alt="Product" className='w-16 h-16 md:w-24 md:h-24 object-fill' />
                              )}
                            </Link>
                            <div className='flex flex-col items-start'>
                              <div>
                                Quantity: {productDetail.quantity}
                              </div>
                              <div>
                                Price: {productDetail.price * productDetail.quantity}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <button className='bg-orange-100 w-28 h-10 rounded-2xl text-orange-600'>{item.orderStatus}</button>
                        </div>
                      </td>
                      <td>{formatDate(item.createdAt)}</td>
                      <td>
                        <div className='flex flex-row gap-2'>
                          <button onClick={() => handleStatusChange(item._id, 'InProcess')} className='bg-orange-400 text-white w-24 h-10 rounded-[6px]'>
                            In Process
                          </button>
                        </div>
                      </td>
                    </React.Fragment>
                  );
                }
                return null;
              });
            })}
          />
          {/* Custom pagination */}
      <div>
          <Pagination2
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
          </div>
        ) }
      </div>

      

      {/* mobile view */}

      <div className='block sm:hidden p-5 mb-[100px]'>
        {loading ? (
          <div className='flex justify-center'><Loader /></div>
        ) : newOrders.length === 0 ? (
          noOrdersMessage
        ) : (
          <>
            {newOrders?.map((item, index) => {
              const uniqueProductIds = new Set(
                item.productsDetails
                  .filter((product) => product.vendorId === loggedInVendorId)
                  .map((product) => product.productId)
              );

              return Array.from(uniqueProductIds).map((productId, productIndex) => {
                // Find the product details for the current product ID
                const productDetail = item.productsDetails.find((product) => product.productId === productId);

                if (productDetail) {

                  // Access the first image for the current productDetail
                  const image = productDetail.additionalInfo?.product?.images?.[0];

                  return (

                    <div key={`${index}-${productIndex}`} className='w-full mb-4'>
                      <VendorDashboardMobileCard
                      order={item}
                      formatDate={formatDate}
                      scrollTo={scrollTo}
                      productDetail={productDetail}
                      showProcessButton={true}
                      handleStatusChange={handleStatusChange}
                      changeStatus = "InProcess"
                      />
                    </div>
                  )
                }
                return null;
              })

            })}
          </>
        )}

      </div>



    </>
  );
};

export default Table;