import React from 'react';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

const Pagination2 = ({ currentPage, totalPages, handlePageChange }) => {
  return (
    <div className='flex justify-center items-center'>
    <div className={`flex justify-center items-center mt-4 mb-4 ${totalPages > 5 ? 'w-[500px]' : ''}`}>
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="pagination-button hover:bg-[#b788ca] rounded-3xl"
      >
        <IoChevronBack />
      </button>
      <div className="flex overflow-x-auto" style={{ overflowX: "scroll", scrollbarWidth: "none", WebkitOverflowScrolling: "touch" }}>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            id={`page-${index + 1}`}
            onClick={() => handlePageChange(index + 1)}
            className={`pagination-button ${currentPage === index + 1 ? "active" : ""
              }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="pagination-button hover:bg-[#b788ca] rounded-3xl"
      >
        <IoChevronForward />
      </button>
    </div>
  <style>
    {`
  .pagination-button {
    margin: 0 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    cursor: pointer;
  }

  .pagination-button.active {
    background-color: #ccc;
  }

  .flex > .pagination-button {
    flex: 1;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`}
  </style>
</div>

  );
};

export default Pagination2;
