// paymentHandler.js

import axios from 'axios';
import toast from 'react-hot-toast';
import {API_URL , config } from '../../config/config';
import ErrorConstants from '../../constants/ErrorConstants';
import SuccessConstant from '../../constants/SuccessConstant';



const handleOrderAndPayment = async (productsDetails, addressFormData, userDetails, setLoading , event) => {
  if (event && event.preventDefault) {
    event.preventDefault();
  }
    try {
      
      const keyResponse = await axios.get(`${API_URL}/api/getkey`);
      const { data: { key } } = keyResponse;
       
       // Make an API call to your server to initiate payment
       const response  = await axios.post(`${API_URL}/api/payment/checkout`, {
        cartItems: productsDetails,
        shippingAddress: addressFormData,
        userDetails: userDetails,
      }, config);

      if (response.data.error) {
        toast.error(response.data.error);
        throw new Error(response.data.error);
      }

      const { orderId, amount, currency, receipt } = response.data;  

        const options = {
          key,
          amount,
          currency,
          name: "Perceptions Technologies",
          description: "Inspiring Creativity, Connecting Creators",
          image: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2Floader%2FLogo.png?alt=media&token=4b94a5aa-fd59-4993-a032-f45a0f6469d0",
          order_id: orderId,

          handler: async function (response) {
            const data = {
              orderId,
              paymentId: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              signature: response.razorpay_signature,
              receipt
            };
           
            
            try {
              // Make an API call to complete payment after successful payment
              const captureResponse = await axios.post(`${API_URL}/api/payment/checkstatus`, data, config);
              
              if (captureResponse.data.error) {
                window.location.href = `/cancel`;
                toast.error(ErrorConstants.Error.PAYMENT_ERROR);
              } else {
                window.location.href = `/success`;
                toast.success(SuccessConstant.Success.PAYMENT_SUCCESS)
              }
            }
            catch (error) {
              console.error(ErrorConstants.Error.PAYMENT_ERROR, error);
              toast.error(ErrorConstants.Error.PAYMENT_ERROR);
            }
          },
            prefill: {
              name: userDetails.fullname,
              email: userDetails.email,
              contact: userDetails.phone
            },
            notes: {
              message: `Payment successfully paid by ${userDetails.fullname}`
            },
            theme: {
              "color": "#121212"
            }
        };

        const rzp1 = new window.Razorpay(options);

        rzp1.on('payment.failed', function (response) {
            toast.error(ErrorConstants.Error.PAYMENT_ERROR);
        });

        rzp1.open();
        setLoading(false);
    } catch (error) {
        setLoading(false);
        console.error(ErrorConstants.Error.PAYMENT_ERROR, error);
        if (error.response) {
            toast.error(` ${(error.response && error.response.data && error.response.data.message) || error.message || error.toString()}`);
        }
    }
};

export { handleOrderAndPayment };