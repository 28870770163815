import React from 'react'
import VendorVerify from '../component/SuperAdmin/Verify/VendorVerify';

const VendorVerifyPage = () => {
    return (
        <>
            <VendorVerify />
        </>
    )
}

export default VendorVerifyPage;
