import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com'
import { toast } from 'react-hot-toast';
import commonService from '../../services/common/commonService';
import ErrorConstants from "../../constants/ErrorConstants"
import SuccessConstants from "../../constants/SuccessConstant"
import Button from '../common/Button';
import InputField from '../common/InputField';


const BusinessConsultants = () => {

  const formconsultant = useRef();

  const initialFormData = {
    name: '',
    email: '',
    phone: '',
    consultationType: '',
    businessType: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  // Handle form submission
  const sendEmail = async (e) => {
    e.preventDefault();

    // Validation logic (phone and email format checks, etc.)
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmpty = Object.values(formData).some(value => value === '');


    if (isEmpty) {
      toast.error(ErrorConstants.Error.ALL_FIELD_ERROR);
      return;
    }
    if (!phoneRegex.test(formData.phone)) {
      toast.error(ErrorConstants.Error.PHONE_ERROR_MESSAGE);
      return;
    }
    if (!emailRegex.test(formData.email)) {
      toast.error(ErrorConstants.Error.EMAIL_ERROR_MESSAGE);
      return;
    }

    // Call the sendEmail function from commonService
    try {
      await commonService.sendBusinessConsultants(formData);
      toast.success(SuccessConstants.Success.FORM_SUCCESS);

      // Reset the form
      setFormData({
        name: '',
        email: '',
        phone: '',
        consultationType: '',
        businessType: '',
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(ErrorConstants.Error.FORM_ERROR_MESSAGE);
    }

    // Disable button for a certain period of time
    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 5000);
  };





  //handling form changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'name' && value.length > 50) {
      return; // Do not update state if the name exceeds 50 characters
    }

    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));


  };


  const handlePhoneInputChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 10); // Limit to 10 characters

    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: inputValue,
    }));
  };


  // array of form fields 
  const formFields = [
    { name: 'name', label: 'Name', type: 'text', placeholder: 'Name', required: true },
    { name: 'email', label: 'Email', type: 'email', placeholder: 'Email', required: true },
    { name: 'phone', label: 'Phone', type: 'tel', placeholder: 'Phone', required: true },
    { name: 'consultationType', label: 'Consultation Type', type: 'text', placeholder: 'Consultation Message', required: true },
    { name: 'businessType', label: 'Business Type', type: 'text', placeholder: 'Business Type', required: true },
  ];

  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className=' bg-gray-100 p-2 sm:p-10'>
        <div className=' w-full flex justify-center items-center'>
          <h2 className="text-xl min-[510px]:text-2xl font-bold mb-4 text-gray-800">Business Consultation</h2>
        </div>

        <div className=' grid md:grid-cols-2 grid-cols-1 gap-2 p-2'>
          <div className="flex flex-col justify-center items-center">
            <div className='p-5 w-[100%] sm:w-[70%]'>
              <form ref={formconsultant} onSubmit={sendEmail} className="mt-4 flex flex-col">
                <div className="flex flex-wrap -mx-4">
                  {formFields.map((field, index) => (
                    <div key={index} className={`mb-4 px-4 ${field.name === 'email' || field.name === 'phone' ? 'w-full lg:w-1/2' : 'w-full'}`}>
                      <InputField
                        type={field.type}
                        id={field.name}
                        label={field.label}
                        name={field.name}
                        value={formData[field.name] || ''}
                        onChange={field.name === 'phone' ? handlePhoneInputChange : handleInputChange}
                        placeholder={field.placeholder}
                      />
                    </div>
                  ))}
                </div>
                <Button
                  type="submit"
                  style="primary"
                  disabled={buttonDisabled}
                >
                  Submit
                </Button>
              </form>
            </div>
            {/* Add any other UI elements and functionality here*/}
          </div>

          <div className=' flex flex-col justify-center items-center rounded-lg  order-1 md:order-2 py-20 md:py-0 px-2 sm:px-10 lg:px-20'>
            <h1 className=' text-3xl text-violet-500 font-bold '>Get In Touch</h1>
            <p className=' rounded-lg p-2 text-sm text-justify'>
              Engage with experienced business professionals who offer personalized consultations. Whether you're seeking insights for business growth, strategic planning, or contemplating a new venture, our consultants are here to provide valuable perspectives and actionable guidance based on their extensive industry experience.
            </p>

          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessConsultants;
