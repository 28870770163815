import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineSwapRight } from 'react-icons/ai';
import { getUser, login } from '../Redux/features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { resetMessage } from '../Redux/features/auth/authSlice';
import { toast, Toaster } from 'react-hot-toast'
import constant from "../constants/ImageConstants";
import ErrorConstants from '../constants/ErrorConstants';
import Button from '../component/common/Button';
import InputField from '../component/common/InputField';
import Loader from '../component/common/Loader';

const images = constant.IMAGES_IMAGES.HOME.LOGIN

const LoginForm = () => {

  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [timer, setTimer] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const togglePasswordVisibility = () => {
    setVisible(!visible);
  };


  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;

    if (newPassword.length <= 16) {
      setPassword(newPassword);
    }
  };

  const handleCreateAccountLinkClick = () => {
    window.scrollTo(0, 0);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevents the default form submission
      handleLogin(e); // Pass the event object to handleLogin function
    }
  };

  const [clicked, setClicked] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(emailOrPhone).toLowerCase().trim())) {
      dispatch(login({ emailOrPhone, password })).then((response) => {
        if (response.error) {
          setSuccessMessage('')
          setErrorMessage(response.payload)
          toast.error(response.payload)
          dispatch(resetMessage())
        } else {
          dispatch(getUser());
          toast.success(response.payload.message)
          setErrorMessage('')
          setSuccessMessage(response.payload.message)
          dispatch(resetMessage())
          window.location.href = '/'
        }
      });
      return;
    }
    if (emailOrPhone.match(/^\d+$/)) {
      dispatch(login({ emailOrPhone, password })).then((response) => {
        if (response.error) {
          setSuccessMessage('')
          setErrorMessage(response.payload)
          toast.error(response.payload)
          dispatch(resetMessage())
        } else {
          setErrorMessage('')
          dispatch(getUser());
          toast.success(response.payload.message)
          setSuccessMessage(response.payload.message)
          window.location.href = '/'
          dispatch(resetMessage())
        }
      })
    } else {
      toast.error(ErrorConstants.Error.EMAIL_ERROR)
    }

    if (!clicked) {

      setClicked(true);

      const timeout = setTimeout(() => {
        setClicked(false);
        clearTimeout(timeout);
      }, 2000);

      setTimer(timeout);
    }

  }

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const links = document.querySelectorAll(".scroll-link");
    links.forEach((link) => {
      link.addEventListener("click", scrollToTop);
    });

    return () => {
      // Remove event listeners when the component unmounts
      links.forEach((link) => {
        link.removeEventListener("click", scrollToTop);
      });
    };
  }, []);

  const inputFields = [
    {
      id: 'email',
      label: 'Phone or Email',
      type: 'email',
      placeholder: 'example@gmail.com',
      value: emailOrPhone,
      onChange: (e) => setEmailOrPhone(e.target.value.toLowerCase())
    },
    {
      id: 'password',
      label: 'Password',
      type: visible ? 'text' : 'password',
      placeholder: 'Password',
      value: password,
      onChange: handlePasswordChange,
      onKeyDown: handleEnterKey
    }
  ];

  return (
    <div className='h-full w-auto bg-white' >
      <div className="flex items-center justify-center flex-col w-full p-5 pb-10 max-sm:p-0">
        <div className="bg-white rounded-lg p-8 shadow-lg max-w-4xl w-full">
          <div className="flex flex-col md:flex-row gap-5">
            <div className="md:w-1/2">
              <img
                src={images}
                loading='lazy-loading'
                alt="Image"
                className="h-auto w-full mx-auto md:ml-0 md:mr-8 mb-4 md:mb-0"
                onLoad={() => setImageLoaded(true)}
              />
              {!imageLoaded && (
                <div className="w-full  mx-auto h-[200px] flex items-center justify-center">
                  <Loader />
                </div>
              )}
            </div>
            <div className="md:w-1/2">
              <h2 className="text-md mt-4 font-lighter text-[#222] text-opacity-50">Welcome back!</h2>
              <h2 className="text-3xl font-bold mb-4">Start Shopping</h2>
              <form className="space-y-4">
                {/* InputFields */}
                {inputFields.map((field, index) => (
                  <div key={index} className='mt-10'>
                    <InputField {...field} />
                  </div>
                ))}

                <div className="relative">

                  {/* eye open/close */}
                  <span
                    className="absolute right-2 transform bottom-7 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {visible ? (
                      <AiOutlineEye className="h-6 w-6 text-gray-500 hover:text-[#8A58DC] transition-colors duration-300" />
                    ) : (
                      <AiOutlineEyeInvisible className="h-6 w-6 text-gray-500 hover:text-[#8A58DC] transition-colors duration-300" />
                    )}
                  </span>
                </div>

                <div className="text-[#222] text-opacity-50 text-sm hover:underline">
                  <Link to="/forgot-password" className=' scroll-link'>Forgot Password?</Link>
                </div>


                <div className="flex items-center justify-center">
                  <Button style="tertiary" type="button" onClick={(e) => handleLogin(e)} disabled={clicked} >LOGIN </Button>
                </div>
                <p className="mt-5 text-center text-[#222] text-opacity-80 text-sm">
                  New to The Ekaiv?{' '}
                  <Link to="/signup" className="text-[#8A58DC]" onClick={handleCreateAccountLinkClick}>
                    Create an account
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
