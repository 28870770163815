import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { API_URL, config } from '../../config/config';
import { toast } from 'react-hot-toast';
import Loader from '../common/Loader';
import SuccessConstant from '../../constants/SuccessConstant';
import ErrorConstants from '../../constants/ErrorConstants';
import Button from '../common/Button';
import InputField from '../common/InputField';
import commonService from '../../services/common/commonService';



const DesignsNPhotography = () => {

  const [ IMAGE1, setIMAGE1 ] = useState('')
  const [ IMAGE2, setIMAGE2 ] = useState('')
  const [ IMAGE3, setIMAGE3] = useState('')
  const [ IMAGE4, setIMAGE4 ] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/assistance/getPhotographyImages`)
        setIMAGE1(response.data.PhotographyImage.firstImage.url)
        setIMAGE2(response.data.PhotographyImage.secondImage.url)
        setIMAGE3(response.data.PhotographyImage.thirdImage.url)
        setIMAGE4(response.data.PhotographyImage.fourthImage.url)

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const formdp = useRef();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    requirement: '',
    businessType: '',
    location: '',
  });
  const sendEmail = async (e) => {
    e.preventDefault();

    // Validation checks
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if any form field is empty
    const isEmpty = Object.values(formData).some(value => value === '');
    if (isEmpty) {
      toast.error(ErrorConstants.Error.ALL_FIELD_ERROR);
      return;
    }

    // Check if phone number is valid
    if (!phoneRegex.test(formData.phone)) {
      toast.error(ErrorConstants.Error.PHONE_ERROR_MESSAGE);
      return;
    } else if (!emailRegex.test(formData.email)) {
      toast.error(ErrorConstants.Error.EMAIL_ERROR_MESSAGE);
      return;
    }

    // Check if email address is valid
    if (!emailRegex.test(formData.email)) {
      toast.error(ErrorConstants.Error.EMAIL_ERROR_MESSAGE);
      return;
    }

    // Call the commonService.sendDesignNPhotographyEmail function
    try {
      const response = await commonService.sendDesignNPhotographyEmail(formData);

      // If the response is successful
      if (response.success) {
        // Display success toast message
        toast.success(SuccessConstant.Success.FORM_SUCCESS);

        // Reset the form data
        setFormData({
          name: '',
          email: '',
          phone: '',
          requirement: '',
          businessType: '',
          location: '',
        });
      } else {
        // Display error toast message
        toast.error(ErrorConstants.Error.FORM_ERROR_MESSAGE);
      }
    } catch (error) {
      // Handle any error during the request
      console.error('Error submitting form:', error);
      toast.error(ErrorConstants.Error.FORM_ERROR_MESSAGE);
    }
  };

  const previousWorks = [
    {
      imageUrl: IMAGE1,
    },
    {
      imageUrl: IMAGE2,
    },
    {
      imageUrl: IMAGE3,
    },
    {
      imageUrl: IMAGE4,
    },
  ];
  const [loadingImages, setLoadingImages] = useState(true);

  const handleImageLoad = () => {
    setLoadingImages(false);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if ((name === 'name' || name === 'location' || name === 'businessType') && value.length > 50) {
      // Do not update state if the name, location, or businessType exceeds 50 characters
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handlePhoneInputChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 10); // Limit to 10 characters

    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: inputValue,
    }));


  };

  // Define an array of form fields for rendering
  const formFields = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'Name',
    },
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      placeholder: 'Email',
      pattern: '^[^\s@]+@[^\s@]+\.[^\s@]+$',

    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'tel',
      placeholder: 'Phone',
    },
    {
      name: 'requirement',
      label: 'Requirement',
      type: 'text',
      placeholder: 'Your Requirements',
    },
    {
      name: 'businessType',
      label: 'Business Type',
      type: 'text',
      placeholder: 'Business Type',
    },
    {
      name: 'location',
      label: 'Location',
      type: 'text',
      placeholder: 'Location',
    },
    // Add more form fields as needed
  ];

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Implement logic to handle form submission with the formData state.
    // Example: Log form data to the console
  };


  return (
    <div className="bg-gray-100 p-4">
      <h2 className="text-xl min-[510px]:text-2xl text-center font-bold mb-4 text-gray-800">Photography</h2>
      <p className="text-gray-600 text-center">
        Explore our previous works in designs and photography. We take pride in our creative projects.
      </p>
      <div className="mt-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {previousWorks.map((work, index) => (
            <div key={index} className="p-4 border rounded-lg shadow-md hover:shadow-lg transition-shadow relative">
              <div className="aspect-w-1 aspect-h-1">
                {loadingImages && <div className="absolute inset-0 flex items-center justify-center"><Loader /></div>}
                <img
                  src={work.imageUrl}
                  alt="photography"
                  className={`w-96 h-96 object-cover opacity-${loadingImages ? '0' : '100'} hover:opacity-100 transition-opacity`}
                  onLoad={handleImageLoad}
                />
              </div>
            </div>
          ))}
        </div>
      </div>


      <div className=' grid md:grid-cols-2 grid-cols-1 gap-2 p-2'>


        <div className=' flex flex-col justify-center items-center'>
          <div className='rounded-2xl p-5 w-[100%] md:w-[70%]'>
            <form ref={formdp} onSubmit={sendEmail} className="mt-4 flex flex-col">
              <div className="flex flex-wrap -mx-4">
                {formFields.map((field, index) => (
                  <div key={index} className={`mb-4 px-4 ${field.name === 'email' || field.name === 'phone' ? 'w-full md:w-1/2' : 'w-full'}`}>
                    <InputField
                      type={field.type}
                      id={field.name}
                      label={field.label}
                      name={field.name}
                      value={formData[field.name] || ''}
                      onChange={field.name === 'phone' ? handlePhoneInputChange : handleInputChange}
                      placeholder={field.placeholder}
                    />
                  </div>
                ))}
              </div>
              <Button
                type="submit"
                style="primary"
                disabled={buttonDisabled}
              >
                Submit
              </Button>
            </form>
          </div>
          {/* Add any other UI elements and functionality here*/}
        </div>

        <div className=' flex flex-col justify-center  rounded-lg  order-1 md:order-2 py-20 md:py-5 px-2 sm:px-10 lg:px-20'>
          <h1 className=' font-bold text-2xl text-violet-500 indent-2'>Reach Out To Us ...</h1>
          <p className=' text-sm rounded-lg p-2 text-justify'>
            Elevate your product presentation with our community of skilled photographers. They specialize in enhancing your product imagery, ensuring that your offerings are visually captivating to potential customers. From professional photoshoots to creative styling, our photographers are dedicated to making your products stand out in the crowded marketplace.
          </p>

        </div>

      </div>

    </div >

  );
};

export default DesignsNPhotography;
