import React, { useEffect, useState } from 'react';
import VendorMenuBar from '../component/VendorProfile/Dashboard/VendorMenuBar';
import VendorNavbar from '../component/VendorProfile/Dashboard/VendorNavbar';
import VendorSales from '../component/VendorProfile/Dashboard/VendorSales';
import VendorStore from '../component/VendorProfile/YourStore/Store'
import NewOrders from '../component/VendorProfile/Dashboard/NewOrders'
import DeliveredOrders from '../component/VendorProfile/Dashboard/DeliveredOrders'
import InProcessOrders from '../component/VendorProfile/Dashboard/InProcessOrders'
import SalesStore from '../component/VendorProfile/YourStore/SalesStore'
import ManageProduct from '../component/VendorProfile/AddProduct/ManageProduct';
import Help from '../component/VendorProfile/NeedHelp/help';
import Report from '../component/VendorProfile/Report/Report';
import AccountDetailsEdit from '../component/VendorProfile/AccounSettings/AccountDetailsEdit'
import TransitOrders from '../component/VendorProfile/Dashboard/TransitOrders'
import { useDispatch, useSelector } from 'react-redux'
import { getVendor } from '../Redux/features/vendor/vendorSlices';
import { Helmet } from 'react-helmet';
import NotVerifiedMessage from '../pages/NotVerifiedMessage';
import Button from '../component/common/Button';
import Loader from '../component/common/Loader';
import Blog from '../component/VendorProfile/Our Blog/Blog';

function Vendor() {

  const users = useSelector((state) => state.auth);
  const vendor = useSelector((state) => state.vendor);
  const vendorStatus = vendor && vendor.vendor && vendor.vendor.status;

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('dashboard');
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const [activeOrders, setActiveOrders] = useState('newOrders');

  const handleOrders = (tabName) => {
    setActiveOrders(tabName);
  }


  useEffect(() => {
    if (users && users.user) {
      dispatch(getVendor())
        .then((response) => {
          (function () {
            if (response.error) {
              console.error("Error fetching vendor:", response.error);
              window.location.reload(); // Reload the page if there's an error
            }
          })();
          setLoading(false); // Set loading to false after fetching data
        })
        .catch((error) => {
          console.error("Error fetching vendor:", error);
          setLoading(false); // Set loading to false if there's an error
        });
    }
  }, [users, dispatch]);

  if (loading) {
    // Show a loading indicator while data is being fetched
    return <div className='flex justify-center items-center h-[800px]'><Loader /></div>;
  }

  return (
    <div>
      <div className="flex flex-col sm:flex-row bg-white">
        <Helmet>
          <title>Vendor</title>
        </Helmet>

        <VendorMenuBar activeTab={activeTab} handleTabClick={handleTabClick} />

        <div className='flex flex-col w-full'>

          <div>
            <VendorNavbar setActiveTab={setActiveTab} />
          </div>
          <div className='p-2 bg-white' >
            {activeTab === 'dashboard' && (
              <>
                {vendorStatus === 'Pending' || vendorStatus === 'Rejected' ? (
                  <NotVerifiedMessage />
                ) : (
                  <>
                    <div className='p-1 bg-white' >
                      <VendorSales />
                    </div>
                    <div className="flex text-xs sm:text-base w-full gap-2 min[450px]:space-x-4 ">
                      <Button
                        onClick={() => handleOrders('newOrders')}
                        style='primary'
                        className={`md:w-full ${activeOrders === 'newOrders' ? 'bg-[#8A58DC] text-white' : 'bg-gray-200 text-gray-800'}`}
                      >
                        New Orders
                      </Button>
                      <Button
                        onClick={() => handleOrders('inProcessOrders')}
                        style='primary'
                        className={`md:w-full ${activeOrders === 'inProcessOrders' ? 'bg-[#8A58DC] text-white' : 'bg-gray-200 text-gray-800'}`}
                      >
                        In Process
                      </Button>
                      <Button
                        onClick={() => handleOrders('transitOrders')}
                        style='primary'
                        className={` py-3 min-[320px]:py-1 md:w-full ${activeOrders === 'transitOrders' ? 'bg-[#8A58DC] text-white' : 'bg-gray-200 text-gray-800'}`}
                      >
                        Transit
                      </Button>
                      <Button
                        onClick={() => handleOrders('deliveredOrders')}
                        style='primary'
                        className={`md:w-full ${activeOrders === 'deliveredOrders' ? 'bg-[#8A58DC] text-white' : 'bg-gray-200 text-gray-800'}`}
                      >
                        Delivered Orders
                      </Button>

                    </div>

                    {activeOrders === 'newOrders' && <NewOrders />}
                    {activeOrders === 'inProcessOrders' && <InProcessOrders />}
                    {activeOrders === 'transitOrders' && <TransitOrders />}
                    {activeOrders === 'deliveredOrders' && <DeliveredOrders />}
                  </>
                )}
              </>
            )}
          </div>
          <div className='p-2 bg-white'>
            {activeTab === 'store' && (
              <>
                {vendorStatus === 'Pending' || vendorStatus === 'Rejected' ? (
                  <NotVerifiedMessage />
                ) : (
                  <>
                    <div className='bg-white'>
                      <SalesStore />
                    </div>
                    <VendorStore />
                  </>
                )}
              </>
            )}
          </div>

          <div className='p-2 bg-white'>
            {activeTab === 'product' && (
              <>
                {vendorStatus === 'Pending' || vendorStatus === 'Rejected' ? (
                  <NotVerifiedMessage />
                ) : (
                  <ManageProduct />
                )}
              </>
            )}
          </div>

          <div className='p-2 bg-white ' >
            {activeTab === 'account' && (
              <>
                <AccountDetailsEdit />
              </>
            )}
          </div>
          <div className='p-2 bg-white ' >
            {activeTab === 'blog' && (
              <>
                <Blog />
              </>
            )}
          </div>

          <div className=' bg-white' >
            {activeTab === 'report' && (
              <>
                <Report />
              </>
            )}
          </div>
          <div className=' bg-white' >
            {activeTab === 'help' && (
              <>
                <Help />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vendor;
