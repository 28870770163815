import React, { useState, useRef } from 'react';
import { toast } from 'react-hot-toast';
import commonService from '../../services/common/commonService';
import ErrorConstants from '../../constants/ErrorConstants';
import SuccessConstant from '../../constants/SuccessConstant';
import Button from '../common/Button';
import InputField from '../common/InputField';

const CaAssistance = () => {
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    query: '',
    info: '',
  });



  const formca = useRef();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();

    // Check if all fields are empty
    if (Object.values(formData).every(value => !value)) {
      toast.error(ErrorConstants.Error.ALL_FIELD_ERROR);
      return;
    }

    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check phone and email validity
    if (!phoneRegex.test(formData.phone)) {
      toast.error(ErrorConstants.Error.PHONE_ERROR_MESSAGE);
      return;
    } else if (!emailRegex.test(formData.email)) {
      toast.error(ErrorConstants.Error.EMAIL_ERROR_MESSAGE);
      return;
    } else {
      setPhoneError(ErrorConstants.Error.PHONE_ERROR_MESSAGE);
      setEmailError(ErrorConstants.Error.EMAIL_ERROR_MESSAGE);

    }
    if (!emailRegex.test(formData.email)) {
      toast.error(ErrorConstants.Error.EMAIL_ERROR_MESSAGE);
      return;
    }

    try {
      // Call the sendCAAssistanceEmail function from commonService
      const response = await commonService.sendCAAssistanceEmail(formData);

      if (response.success) {
        toast.success(SuccessConstant.Success.FORM_SUCCESS);
        setFormData({
          name: '',
          email: '',
          phone: '',
          query: '',
          info: '',
        });
      } else {
        toast.error(response.message || ErrorConstants.Error.FORM_ERROR_MESSAGE);
      }
    } catch (error) {
      console.error(ErrorConstants.Error.FORM_ERROR_MESSAGE, error);
      toast.error(ErrorConstants.Error.ALL_FIELD_ERROR);
    }

    // Disable the button and enable it back after a delay
    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 5000);
  };

  const handleInputChange = (id, value) => {
    // Allow only numeric characters in the phone field
    if (id === 'phone' && !/^\d*$/.test(value)) {
      return; // Do not update state if input contains non-numeric characters
    }
    setFormData({ ...formData, [id]: value });
  };

  // Define an array of input fields
  const inputFields = [
    { id: 'name', label: 'Name', type: 'text', value: formData.name, maxLength: 50 },
    { id: 'email', label: 'Email', type: 'email', value: formData.email },
    { id: 'phone', label: 'Phone', type: 'tel', value: formData.phone, pattern: '[0-9]*', maxLength: 10 },
    { id: 'query', label: 'Query and Concern', type: 'textarea', value: formData.query },
    { id: 'info', label: 'Any information before connecting', type: 'textarea', value: formData.info },
  ];




  return (
    <>
      <div className=' bg-gray-100 p-2 sm:p-10'>

        <div className=' w-full flex justify-center items-center'>
          <h2 className="text-xl min-[510px]:text-2xl font-bold mb-4 text-gray-800">CA Assistance</h2>
        </div>

        <div className=' grid md:grid-cols-2 grid-cols-1 gap-5 p-2'>

          <div className=' flex flex-col justify-center items-center' >
            <form ref={formca} onSubmit={sendEmail} className="mt-4 flex flex-col w-full">
              {inputFields.map(field => (
                <div key={field.id} className="mb-4">
                  <InputField
                    {...field}
                    onChange={(e) => handleInputChange(field.id, e.target.value)}
                  />
                </div>
              ))}
              <Button
                type="submit"
                style="primary"
                disabled={buttonDisabled}
              >
                Book an Appointment
              </Button>
            </form>
          </div>
          <div className=' rounded-2xl pt-5 '>
            <div className=' flex flex-col'>
              {/* <div className='flex'>

              <p className=' pt-14 px-5 text-2xl font-bold'>Ranjana Erankar</p>
              </div>
              <div className=' flex flex-col p-5'>
              <p className='text-sm text-justify'>Ranjana Erankar, with an extensive 18-year history in Business Registration, exemplifies a company that has stood the test of time. Demonstrating unwavering commitment, our company continuously endeavors to enhance and purify its services. We specialize in facilitating various types of loans, including Personal, Home, Business, Car, Mortgage, OD, BT, and TOP UP. Through partnerships with 50 banks and financial institutions, we streamline documentation processes and also offer comprehensive Insurance policies. For all your loan requirements, Ranjana Erankar is your trusted point of contact, ensuring seamless and efficient service.</p>
              </div> */}
              <h1 className='font-bold text-2xl text-violet-500 sm:indent-2 pt-12'>Contact Us to Get Started ...</h1>
              <p className="text-gray-600 ml-2">
                At The Ekaiv, we believe in empowering our vendors not just as sellers but as thriving entrepreneurs. Our Assistance Page is designed to provide a helping hand, fostering growth and success for our valued vendors. Whether you're a seasoned business owner or just starting, our range of support services ensures you have the guidance and expertise needed to navigate various aspects of your business journey.
              </p>

            </div>

          </div>

        </div>
      </div>

    </>
  );
};

export default CaAssistance;
