//NOTE - Local Environment
// const API_URL = 'http://localhost:4000';


//NOTE - Deployed Environment
const API_URL = 'https://www.theekaiv.in/api';


//NOTE - Test Environment
// const API_URL = 'https://www.perceptionstechnologies.com';


//NOTE - Test Environment
// const API_URL = 'https://www.balajicateringandevents.com';

//NOTE - Test Environment
// const API_URL = 'https://customizebychoice.com';


const token = JSON.parse(localStorage.getItem('token'));

const config = {
  headers: {
    "Content-type": "application/json",
    "Authorization": token,
  },
};

module.exports={
    config,
    API_URL
};