import React from 'react';
// import Header from '../component/career/Header';
import ComingSoon from './ComingSoon';
const CareerPage = () => {
  
  return (
  <>
    <div className="min-h-screen ">
   
{/* 
    <Header/> */}
    
    <ComingSoon/>
     
    </div> 
  </>
  );
};

export default CareerPage;