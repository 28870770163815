import React from 'react'
import StartSelling from '../component/SellingPage/StartSelling'
import SellerCards from '../component/SellingPage/SellerCards'
import SellingCharges from '../component/SellingPage/SellingCharges'
import StartingSelling from '../component/SellingPage/StartingSelling'


const SellingPage = () => {
  return (
    <div>
      <StartSelling />
      <SellerCards />
      <SellingCharges />
      <StartingSelling />
    </div>
  )
}

export default SellingPage
