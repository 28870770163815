import React from 'react';

const Button = ({ children, style, onClick, disabled, variant, className }) => {
    const primaryStyles = "text-gray-100 bg-[#8A58DC] p-1 md:p-2 rounded-md font-light hover:bg-[#F16080] cursor-pointer";
    let secondaryStyles = "flex gap-2 font-semibold py-3 rounded-full text-sm text-center justify-center items-center";
    let tertiaryStyles = "flex gap-2 text-white bg-[#8A58DC] hover:bg-[#F16080] font-medium rounded-full text-sm px-5 py-2.5 text-center justify-center items-center mb-2";
    let fourthStyles = "bg-white text-purple-900 border border-purple-900 p-1 pl-2 pr-1  text-lg rounded-md transition-colors duration-300 ease-in-out hover:text-pink-600 hover:bg-white";


    // Applying additional styles based on the variant
    if (style === "secondary") {
        if (variant === "addToCart") {
            secondaryStyles += " bg-[#F16080] hover:opacity-80 text-white w-full";
        } else if (variant === "buyNow") {
            secondaryStyles += " bg-[#9D5BB7] hover:opacity-80 text-white w-full";
        } else if (variant === "customizedPrices") {
            secondaryStyles += " bg-green-500 hover:opacity-80 text-white font-semibold py-3 rounded-full w-[250px] h-full";
        }
    } else if (style === "tertiary") {
        // Apply tertiary styles
        tertiaryStyles += " hover:text-white";
    } else if (style === "fourth") {
        return (
            <button
                className={`${fourthStyles} h-full ${className}`}
                onClick={onClick}
                disabled={disabled}
            >
                {children}
            </button>
        );
    }

    return (
        <button
            className={`${style === "primary" ? primaryStyles : (style === "secondary" ? secondaryStyles : tertiaryStyles)} h-full ${className}`}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default Button;
