import React, { useState, useRef } from 'react'
import emailjs from 'emailjs-com'
import { toast } from 'react-hot-toast';
import commonService from '../../services/common/commonService';
import ErrorConstants from '../../constants/ErrorConstants';
import SuccessConstant from '../../constants/SuccessConstant'; import constant from '../../constants/ImageConstants';
import Button from '../common/Button';
import InputField from '../common/InputField';


const ZAIP = constant.IMAGES_IMAGES.ASSISTANCE.COMMUNITY_WELFARE.ZAIP;
const CAIT = constant.IMAGES_IMAGES.ASSISTANCE.COMMUNITY_WELFARE.CAIT;

const inputFields = [
  { id: 'name', label: 'Name', type: 'text', placeholder: 'Name' },
  { id: 'email', label: 'Email', type: 'email', placeholder: 'Email' },
  { id: 'phone', label: 'Phone', type: 'tel', placeholder: 'Phone' },
  { id: 'location', label: 'Location', type: 'text', placeholder: 'Location' },
  { id: 'businessType', label: 'Business Type & Details', type: 'text', placeholder: 'Business Type & Details' }
];

const CommunityEngagement = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    location: '',
    businessType: '',
  });


  const formcommunity = useRef();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmpty = Object.values(formData).some(value => value === '');

    if (isEmpty) {
      toast.error(ErrorConstants.Error.ALL_FIELD_ERROR);
      return;
    }

    if (!phoneRegex.test(formData.phone)) {
      toast.error(ErrorConstants.Error.PHONE_ERROR_MESSAGE);
      return;
    } else if (!emailRegex.test(formData.email)) {
      toast.error(ErrorConstants.Error.EMAIL_ERROR_MESSAGE);
      return;

    }

    try {
      // Call the sendCommunityEngagementEmail function from commonService
      const response = await commonService.sendCommunityEngagementEmail(formData);

      if (response.success) {
        toast.success(SuccessConstant.Success.FORM_SUCCESS);
        setFormData({
          name: '',
          email: '',
          phone: '',
          location: '',
          businessType: '',
        });
      } else {
        toast.error(ErrorConstants.Error.FORM_ERROR_MESSAGE);
      }
    } catch (error) {
      console.error('Error during API call:', error);
      toast.error(ErrorConstants.Error.FORM_ERROR_MESSAGE);
    }

    // Disable the button and enable it back after a delay
    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 5000);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if ((name === 'name' || name === 'location' || name === 'businessType') && value.length > 50) {
      // Do not update state if the name, location, or businessType exceeds 50 characters
      return;
    }
    // Ensure only digits are entered into the phone number field
    if (name === 'phone') {
      const numericValue = value.replace(/\D/g, '').slice(0, 10); // Remove non-digit characters
      setFormData({
        ...formData,
        [name]: numericValue,
      });


    } else {
      // Update formData with the new input value
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };


  return (
    <>
      <div className=' bg-gray-100 p-2 md:p-10'>

        <div className=' w-full flex justify-center items-center'>
          <h2 className="text-xl min-[510px]:text-2xl font-bold mb-4 text-gray-800">Community & Welfare</h2>
        </div>
        <div className=' grid md:grid-cols-2 grid-cols-1 gap-2 p-2'>

          <div className=' flex flex-col justify-center items-center'>
            <div className='p-5 w-[100%] md:w-[70%]'>
              <form ref={formcommunity} onSubmit={sendEmail} className="mt-4 flex flex-col gap-4">
                {inputFields.map((field) => (
                  <InputField
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    value={formData[field.id]}
                    onChange={handleInputChange}
                    placeholder={field.placeholder}
                    className="form-input"
                  />
                ))}

                <Button
                  type="submit"
                  style="primary"
                  disabled={buttonDisabled}
                >
                  Join Us
                </Button>
              </form>
            </div>
            {/* Add any other UI elements and functionality here */}
          </div>
          <div className='  md:order-2 py-20 md:py-0 px-2 md:px-10 lg:px-16'>
            <div>
              <p className=' p-2 text-justify '>
                Join our vibrant community and become part of a network that propels your business forward. Stay updated on local events, collaborate with fellow vendors, and tap into a supportive ecosystem that fosters growth. Together, we build a community that goes beyond transactions, creating lasting connections and opportunities.
              </p>
            </div>
            <div className=' flex flex-col sm:flex-row justify-center items-center w-full pt-10 '>
              <img src={ZAIP} loading='lazy-loading' alt="zaip" className=' h-32 w-32 p-2' />
              <img src={CAIT} loading='lazy-loading' alt="CAIT" className=' h-32 w-32 p-2' />
            </div>


          </div>


        </div>

      </div>

    </>
  )
}

export default CommunityEngagement
