import React, { useState, useEffect } from "react";
import Loader from '../common/Loader';
import constant from "../../constants/ImageConstants";

const Hero = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  const images = [
    constant.IMAGES_IMAGES.HOME.HOMEPAGE1,
    constant.IMAGES_IMAGES.HOME.HOMEPAGE2,
    constant.IMAGES_IMAGES.HOME.HOMEPAGE_GREEN,
    constant.IMAGES_IMAGES.HOME.HOMEPAGE_KIDS,
  ];

  useEffect(() => {
    let loadedImages = 0;

    const loadHandler = () => {
      loadedImages++;
      if (loadedImages === images.length) {
        setAllImagesLoaded(true);
      }
    };

    images.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = loadHandler;
    });

    return () => {
      images.forEach((src) => {
        const img = new Image();
        img.src = src;
        img.onload = null;
      });
    };
  }, [images]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000);

    return () => clearTimeout(timer);
  }, [currentImage, images.length]);

  const heroHeight = 'h-[20vh] min-[200px]:h-[30vh] min-[500px]:h-[50vh] sm:h-[65vh] min-[900px]:h-[60vh] lg:h-[81vh]';

  return (
    <div className={`w-full ${heroHeight} bg-white relative cursor-pointer overflow-hidden`}>
      <img
        src={images[currentImage]}
        alt={`Image ${currentImage}`}
        loading="eager"
        className={`absolute w-full h-full object-cover transition-opacity duration-500 ${allImagesLoaded ? 'opacity-100' : 'opacity-0'
          }`}
      />

      {!allImagesLoaded && (
        <div className='flex items-center justify-center w-full h-full bg-white'>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Hero;
