import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../Redux/features/auth/authSlice';
import Loader from '../component/common/Loader';

const useAuth = () => {
  const { auth, user, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  return { user, loading };
};

export const ProtectedUserRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const location=useLocation();

  if (loading) {
    return <div className="fixed inset-0 overflow-hidden w-full h-full flex justify-center items-center z-50">
    <Loader />
  </div> 
  }

  if(location.pathname=='/seller-registration' &&  user?.role!=='user'){
    return <Navigate to="/" />;
  }
  if (!user) {
    return <Navigate to="/" />;
  }

  return children;
};

export const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div className="fixed inset-0 overflow-hidden w-full h-full flex justify-center items-center z-50">
    <Loader />
  </div> 
  }

  if (!user) {
    return <Navigate to="/" />;
  }

  return children;
};

export const ProtectedVendorRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div className="fixed inset-0 overflow-hidden w-full h-full flex justify-center items-center z-50">
    <Loader />
  </div> 
  }

  if (!user || !(user.role === 'vendor')) {
    return <Navigate to="/" />;
  }

  return children;
};

export const ProtectedAdminRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div className="fixed inset-0 overflow-hidden w-full h-full flex justify-center items-center z-50">
    <Loader />
  </div> 
  }

  if (!user || !(user.role === 'admin')) {
    return <Navigate to="/" />;
  }

  return children;
};
