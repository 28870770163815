import React, { useEffect, useState, useRef } from "react";
import { IoMdHeart, IoMdCart, IoMdPerson } from "react-icons/io";
import { Search } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CartCard from "../CartCard";
import { toast } from "react-hot-toast";
import { API_URL, config } from "../../config/config";
import userService from "../../services/user/userService";
import constant from "../../constants/ImageConstants";
import ErrorConstants from "../../constants/ErrorConstants";
import Button from "../common/Button";

const SearchBar = () => {
  const users = useSelector((state) => state.auth);
  const location = useLocation();
  const [cartItemCount, setCartItemCount] = useState(0);
  const [wishlistItemCount, setWishlistItemCount] = useState(0);
  const [showCartSideBar, setShowCartSideBar] = useState(false);
  const [cartNotificationCount, setCartNotificationCount] = useState(0);
  const [wishlistNotificationCount, setWishlistNotificationCount] = useState(0);
  const token = JSON.parse(localStorage.getItem("token"));
  const Logo = constant.IMAGES_LOGO.LOGO;
  const [wishlistItems, setWishlistItems] = useState([]);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const suggestionsRef = useRef(null);

  useEffect(() => {
    if (users && users.user && users.user.cartsItems) {
      setCartItemCount(users.user.cartsItems.length);
    }
  }, [users]);

  useEffect(() => {
    if (users && users.user && users.user.wishlistItems) {
      setWishlistItemCount(users.user.wishlistItems.length);
    }
  }, [users]);

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // const users = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleLogin = () => {
    // Implement your login logic here, set isLoggedIn to true after successful login
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    // Implement your logout logic here, set isLoggedIn to false after successful logout
    setIsLoggedIn(false);
  };

  const setCartItems = () => {
    // Define your logic for setting cart items here
  };
  // const searchProductsAPI = async (term) => {
  //   try {
  //     const response = await fetch(`${API_URL}/api/products/searchproducts?searchTerm=${searchTerm}`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },

  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     const data = await response.json();
  //     return data;
  //   } catch (error) {
  //     throw new Error(`Error fetching data: ${error.message}`);
  //   }
  // };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    navigate(`/products?searchTerm=${searchTerm}`);
    setSearchSuggestions([]); // Clear search suggestions
  };

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newTimeout = setTimeout(async () => {
      if (term.length > 0) {
        try {
          const response = await axios.get(
            `${API_URL}/api/products/searchSuggestions?searchTerm=${term}`
          );
          const shuffledSuggestions = shuffleArray(
            response.data.suggestions
          ).slice(0, 5);
          setSearchSuggestions(shuffledSuggestions);
        } catch (error) {
          console.error("Error fetching search suggestions:", error);
        }
      } else {
        setSearchSuggestions([]);
      }
    }, 5);

    setDebounceTimeout(newTimeout);
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) =>
        Math.min(prevIndex + 1, searchSuggestions.length - 1)
      );
    } else if (event.key === "Enter") {
      event.preventDefault();
      if (selectedSuggestionIndex >= 0 && selectedSuggestionIndex < searchSuggestions.length) {
        const selectedSuggestion = searchSuggestions[selectedSuggestionIndex];
        handleSuggestionClick(selectedSuggestion);
      } else {
        handleSearchSubmit(event);
      }
    }
  };
  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion);
    setSearchSuggestions([]);
    setSelectedSuggestionIndex(-1); // Reset selected suggestion index
    navigate(`/products?searchTerm=${suggestion}`);
  };

  useEffect(() => {
    // Extract search term from query parameter if available
    const searchParams = new URLSearchParams(location.search);
    const searchTermFromQuery = searchParams.get("searchTerm");
    if (searchTermFromQuery) {
      setSearchTerm(searchTermFromQuery);
    }
  }, [location.search]);

  useEffect(() => {
    // Clear searchTerm only if not on /products or if /products doesn't have searchTerm
    if (
      location.pathname !== "/products" ||
      !location.search.includes("searchTerm")
    ) {
      setSearchTerm("");
    }
  }, [location.pathname, location.search]);

  const handleCartClick = () => {
    if (!users.user) {
      toast.error(ErrorConstants.Error.VIEW_CART_ERROR);
    } else {
      setShowCartSideBar(!showCartSideBar);
    }
  };
  const handleWishlistClick = () => {
    if (!users.user) {
      toast.error(ErrorConstants.Error.WISHLIST_CART_ERROR);
    } else {
      navigate("/wishlist");
    }
  };

  // Fetch cart items using the service
  useEffect(() => {
    const fetchCartItemsFromService = async () => {
      try {
        const cartItemsData = await userService.fetchCartItems(users, config);
        setCartItems(cartItemsData);
        // Update the cart notification count based on the number of items in the cart
        setCartNotificationCount(cartItemsData.length);
      } catch (error) {
        console.error(error);
        toast(ErrorConstants.Error.SOMETHING);
      }
    };

    fetchCartItemsFromService();
  }, [users]);

  // Fetch wishlist items using the service
  useEffect(() => {
    const fetchWishlistItemsFromService = async () => {
      try {
        const wishlistItemsData = await userService.fetchWishlistItems(
          users,
          config
        );
        setWishlistItems(wishlistItemsData);
        // Update the wishlist notification count based on the number of items in the wishlist
        setWishlistNotificationCount(wishlistItemsData.length);
      } catch (error) {
        console.error(error);
        toast(ErrorConstants.Error.SOMETHING);
      }
    };

    fetchWishlistItemsFromService();
  }, [users]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        setSearchSuggestions([]);
      }
    };

    const handleLocationChange = () => {
      setSearchSuggestions([]);
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("popstate", handleLocationChange);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, []);

  return (
    <>
      <div className="w-full flex flex-row justify-between md:p-3 sm:p-3 md:pl-6 md:pr-6 sm:pl:3 sm:pr-3 max-sm:p-3">
        {/* <div className=" flex-1 flex items-center justify-center"> */}
        {/* {LOGO} */}
        <Link to="/">
          <img
            src={Logo}
            alt="Logo"
            loading="lazy-loading"
            className="md:w-[90px] md:h-[40px] sm:w-[90px] sm:h-[40px] max-sm:w-[90px] max-sm:h-[40px]"
          />
        </Link>

        {/* {searchBar} */}
        <form
          onSubmit={handleSearchSubmit}
          className="hidden md:flex items-center justify-start md:w-[-webkit-fill-available] px-4 md:px-8 sm:md:w-[350px] xs:md:w-[300px]"
        >
          <div className="flex justify-center items-center border w-[100%] rounded-md border-none relative">
            <div className="flex items-center justify-center p-2 bg-[#F7FAFC] rounded-l-md border border-r-0 border-[#F16080]">
              <Search className="h-6 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown} // Add this line for keyboard navigation
              placeholder="Search for Products..."
              className="md:flex px-4 py-2 w-[-webkit-fill-available] bg-[#F7FAFC] rounded-l-none rounded-r-1 border border-l-0 border-[#F16080] outline-none"
            />
            <button
              type="submit"
              className="px-4 py-2 w-[max-content] border border-[#F16080] bg-[#F16080] text-white rounded-r-md hover:bg-[#8A58DC] hover:border-[#8A58DC] cursor-pointer"
            >
              Search
            </button>

            {searchSuggestions.length > 0 && (
              <div
                ref={suggestionsRef}
                className="absolute z-30 top-full left-0 bg-slate-100 border border-green-200 w-[93%] shadow-md opacity-90 rounded-b-lg"
              >
                {searchSuggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className={`p-2 hover:bg-gray-100 cursor-pointer ${
                      index === selectedSuggestionIndex ? "bg-gray-200" : ""
                    }`} // Highlight the selected suggestion
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </div>
                ))}
              </div>
            )}
          </div>
        </form>

        {/* {icons} */}
        <div className=" flex flex-row items-center justify-end md:gap-3 sm:gap-2 max-sm:gap-1 relative  w-[fit-content]">
          {users.user !== null ? (
            // Show the Person logo and a logout button if the user is logged in
            <>
              <Link to={"/user-profile"}>
                <IoMdPerson
                  className="text-[#8A58DC] hover:text-[#F16080] cursor-pointer"
                  size={27}
                  onClick={handleLogout}
                />
              </Link>
            </>
          ) : (
            // Show the Login button if the user is not logged in
            <Link to={"/signin"}>
              <Button style="primary">Login</Button>
            </Link>
          )}

          {/* Add icons for wishlist, cart, and profile */}

          <div className="relative inline-block">
            <IoMdHeart
              className="text-[#8A58DC] hover:text-[#F16080] cursor-pointer"
              onClick={handleWishlistClick}
              size={27}
            />
            {wishlistItemCount > 0 && (
              <span className="absolute top-0 right-0 w-4 h-4 flex items-center justify-center text-sm text-white bg-red-500 rounded-full">
                {wishlistNotificationCount}
              </span>
            )}
          </div>

          <div className="relative inline-block" onClick={handleCartClick}>
            <IoMdCart
              className="text-[#8A58DC] hover:text-[#F16080] cursor-pointer"
              size={27}
            />
            {cartItemCount > 0 && (
              <span className="absolute top-0 right-0  w-4 h-4 flex items-center justify-center text-sm text-white bg-red-500 rounded-full">
                {cartNotificationCount}
              </span>
            )}
          </div>
        </div>
      </div>
      {/* after 655px */}
      <div className="p-2 relative">
        <form
          onSubmit={handleSearchSubmit}
          className="md:hidden flex items-center justify-start w-full"
        >
          <div className="flex justify-center items-center border w-full rounded-md border-none relative">
            <div className="flex items-center justify-center p-2 bg-[#F7FAFC] rounded-l-md border border-r-0 border-[#F16080]">
              <Search className="h-6 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}// Add this line for keyboard navigation
              placeholder="Search for Products..."
              className="md:flex px-4 py-2 w-full bg-[#F7FAFC] rounded-l-none rounded-r-1 border border-l-0 border-[#F16080] outline-none"
            />
            <button
              type="submit"
              className="px-2 py-2 border border-[#F16080] bg-[#F16080] text-white rounded-r-md hover:bg-[#8A58DC] hover:border-[#8A58DC] cursor-pointer"
            >
              Search
            </button>
          </div>
          {searchSuggestions.length > 0 && (
            <div ref={suggestionsRef} className="absolute z-30 top-full left-0 w-full md:w-auto bg-slate-100 border border-green-200 shadow-md opacity-90 rounded-b-lg">
              {searchSuggestions.map((suggestion, index) => (
                <div
                key={index}
                className={`p-2 hover:bg-gray-100 cursor-pointer ${
                  index === selectedSuggestionIndex ? "bg-gray-200" : ""
                }`} // Highlight the selected suggestion
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </div>
              ))}
            </div>
          )}
        </form>
      </div>


      {showCartSideBar && (
        <CartCard setShowCartSideBar={setShowCartSideBar} showCartSideBar />
      )}
    </>
  );
};

export default SearchBar;
