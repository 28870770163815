import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineClose, AiOutlineSwapRight } from 'react-icons/ai';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import Loader from '../component/common/Loader';


const LoginForm = () => {

  const navigate = useNavigate();
  const [value, setValue] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [visible, setVisible] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);


  const togglePasswordVisibility = () => {
    setVisible(!visible);
  };
  const handleClose = () => {
    navigate('/');
  };

  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasDigits = /\d/.test(password);
  const hasSymbols = /[-+_!@#$%^&*.,?]/.test(password);
  const hasMinimumLength = password.length >= 8;

  const passwordStrength = {
    weak: hasMinimumLength,
    moderate: hasMinimumLength && (hasLowerCase || hasUpperCase || hasDigits || hasSymbols),
    strong: hasMinimumLength && hasLowerCase && hasUpperCase && hasDigits && hasSymbols,
  };


  const strengthColors = {
    weak: 'bg-red-300',
    moderate: 'bg-yellow-300',
    strong: 'bg-green-300',
  };

  useEffect(() => {
    const isFormComplete = (
      value !== '' &&
      password !== '' &&
      confirmPassword !== '' &&
      passwordStrength.strong &&
      password === confirmPassword
    );

    setFormComplete(isFormComplete);
  }, [value, password, confirmPassword, passwordStrength.strong]);

  return (
    <>
      <div className='h-full w-auto bg-[#f7fafc]'>
        <div className="inset-0 flex items-center justify-center z-50 p-20">
          <div className="bg-white rounded-lg p-8 shadow-lg max-w-4xl w-full">
            <div className="flex justify-end">
              {/* close button */}
              <button className="focus:outline-none" onClick={handleClose}>
                <AiOutlineClose className="h-4 w-auto text-[#222] text-opacity-50 hover:text-[#8A58DC]" />
              </button>
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="md:w-1/2">
                <img
                  src="https://img.freepik.com/free-vector/social-media-stalking-invasion-online-privacy-cyberstalking-following-geotagging-guy-with-binoculars-looking-girls-social-profile-vector-isolated-concept-metaphor-illustration_335657-1283.jpg?w=740&t=st=1692788801~exp=1692789401~hmac=ebf0c382438f9ae7a61417872bcc6b859cce6e288d67579a756f98aaf6974de4"
                  alt="Image"
                  loading='lazy-loading'
                  className="h-auto w-full mx-auto md:ml-0 md:mr-8 mb-4 md:mb-0"
                  onLoad={() => setImageLoaded(true)}
                />
                {!imageLoaded && (
              <div className="w-full mx-auto h-[200px] flex items-center justify-center">
                <Loader/>
              </div>
            )}
              </div>
              <div className="md:w-1/2">
                {/* <h2 className="text-md mt-4 font-lighter text-[#222] text-opacity-50">Welcome back!!!</h2> */}
                <h2 className="text-3xl font-bold mb-4">Continue Signup</h2>
                <form className="space-y-4">
                  <div className='mt-10'>
                    <div className='flex flex-row items-center gap-2'>
                      <div className='grid'>
                        <label htmlFor="FirstName" className="flex text-gray-700 mb-2">
                          First Name
                        </label>
                        <input
                          type="text"
                          id="firstname"
                          name="firstname"
                          className="w-full px-4 py-2 border-none outline-none rounded-md bg-[#FFEDF1] mb-4"
                          placeholder="First Name"
                        />
                      </div>

                      <div className='grid'>
                        <label htmlFor="FirstName" className="flex text-gray-700 mb-2">
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="lastname"
                          name="lastname"
                          className="w-full px-4 py-2 border-none outline-none rounded-md bg-[#FFEDF1] mb-4"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>



                    <label htmlFor="email" className="block text-gray-700 mb-2">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="w-full px-4 py-2 border-none outline-none rounded-md bg-[#FFEDF1] mb-4"
                      placeholder="test1@gmail.com"
                    />


                    <label htmlFor="phonenumber" className="block text-gray-700 mb-2">
                      Phone Number
                    </label>
                    <PhoneInput
                      defaultCountry='IN'
                      value={value}
                      onChange={setValue}
                      placeholder='Phone Number'
                      className='mb-4'
                    />
                  </div>

                  {/* Password field */}
                  <div>
                    <label htmlFor="password" className="block text-gray-700 mb-2">
                      Password
                    </label>
                    <div className="relative">
                      <input
                        className={`w-full px-4 py-2 pr-10 border-none outline-none rounded-md bg-[#FFEDF1] ${password ? '' : ''
                          }`}
                        type={visible ? 'text' : 'password'}
                        value={password}
                        placeholder='Password'
                        onChange={(e) => setPassword(e.target.value)}
                        minLength={8}
                        maxLength={16}
                      />

                      {/* eye open/close */}
                      <span
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                        onClick={togglePasswordVisibility}
                      >
                        {visible ? (
                          <AiOutlineEye className="h-6 w-6 text-gray-500 hover:text-[#8A58DC] transition-colors duration-300" />
                        ) : (
                          <AiOutlineEyeInvisible className="h-6 w-6 text-gray-500 hover:text-[#8A58DC] transition-colors duration-300" />
                        )}
                      </span>

                    </div>
                    <label className="block text-sm text-black opacity-50 mt-2">
                      Must be at least 8 characters.
                    </label>
                    {/* Password strength indicator */}
                    {password && (
                      <div className="mt-2">
                        <div className="flex">
                          <div className={`w-1/3 h-1 ${passwordStrength.weak ? strengthColors.weak : ''} transition-colors duration-300`}></div>
                          <div className={`w-1/3 h-1 ${passwordStrength.moderate ? strengthColors.moderate : ''} transition-colors duration-300`}></div>
                          <div className={`w-1/3 h-1 ${passwordStrength.strong ? strengthColors.strong : ''} transition-colors duration-300`}></div>
                        </div>

                        <div className="grid justify-between mt-1 text-xs">
                          <div className="text-gray-500">
                            <span className={`mr-2 ${hasMinimumLength ? 'text-green-500' : 'text-red-500'}`}>{hasMinimumLength ? '✓' : '✗'}</span> 8-16 characters
                          </div>
                          <div className="text-gray-500">
                            <span className={`mr-2 ${hasLowerCase ? 'text-green-500' : 'text-red-500'}`}>{hasLowerCase ? '✓' : '✗'}</span> At least 1 lowercase letter
                          </div>
                          <div className="text-gray-500">
                            <span className={`mr-2 ${hasUpperCase ? 'text-green-500' : 'text-red-500'}`}>{hasUpperCase ? '✓' : '✗'}</span> At least 1 uppercase letter
                          </div>
                          <div className="text-gray-500">
                            <span className={`mr-2 ${hasDigits ? 'text-green-500' : 'text-red-500'}`}>{hasDigits ? '✓' : '✗'}</span> At least 1 digit
                          </div>
                          <div className="text-gray-500">
                            <span className={`mr-2 ${hasSymbols ? 'text-green-500' : 'text-red-500'}`}>{hasSymbols ? '✓' : '✗'}</span> Contains special symbol -+_!@#$%^&*.,?
                          </div>
                        </div>



                      </div>
                    )}
                  </div>
                  {/* confirm password */}
                  <div>
                    <label htmlFor="password" className="block text-gray-700 mb-2">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="w-full px-4 py-2 border-none outline-none rounded-md bg-[#FFEDF1]"
                      placeholder="********"
                      maxLength={16}
                      minLength={8}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {/* Warning message */}
                    {confirmPassword && password !== confirmPassword && (
                      <label className="block text-sm text-red-500 mt-1">
                        Passwords do not match.
                      </label>
                    )}
                    {confirmPassword && password === confirmPassword && (
                      <label className="block text-sm text-green-500 mt-1">
                        Passwords match.
                      </label>
                    )}
                  </div>


                  <p className="mt-5 text-center text-[#222] text-opacity-80 text-sm">
                    By selecting Agree and Continue below, I agree too Business<br /> <Link to="/signup" className="text-[#8A58DC]">Terms & Condition</Link> and <Link to="/signup" className="text-[#8A58DC]">Privacy Policy</Link>
                  </p>
                  <div className="flex items-center justify-center">
                    <div className="relative overflow-hidden">
                      <button
                        type="button"
                        className={`flex row gap-2 text-white bg-[#8A58DC] font-medium rounded-full text-sm px-5 py-2.5 text-center justify-center mb-2 hover:bg-[#ac7ff4] w-100 ${!formComplete ? 'opacity-50 cursor-not-allowed' : ''
                          }`}
                        disabled={!formComplete}
                      >
                        Agree and Continue<AiOutlineSwapRight className="text-xl justify-center items-center" />
                      </button>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
