import React, { useEffect, useState, useMemo } from 'react';
import { Search } from 'react-feather';
import {searchVendorsByTypeOfBusiness,searchVendorsByNameOrBusinessName,getAllVendors} from '../../services/vendor/vendorService'
const tabs = ['All Vendors', 'Clothing', 'Homemade-Food', 'Accessories', 'Home-Decor', 'Other'];

function Searchbar({ setVendors }) {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState('');

  const clearSearch = () => {
    setSearchText('');
    setNoResultsMessage('');
  }; 

  const handleSearch = async () => {
    try {
        setLoading(true);

        if (searchText.length > 0) {
            const vendors = await searchVendorsByNameOrBusinessName(searchText);
            setVendors(vendors);
            
            if (vendors.length === 0) {
                setNoResultsMessage('No vendor found with the provided name or business name.');
            } else {
                setNoResultsMessage('');
            }
        } else {
            let vendors;
            
            if (activeTab === 'All Vendors') {
                vendors = await getAllVendors();
                vendors=vendors.data;
            } else {
                vendors = await searchVendorsByTypeOfBusiness(activeTab.toLowerCase());
            }

            setVendors(vendors);
            
            if (vendors.length === 0) {
                setNoResultsMessage(`No vendors found in the category ${activeTab}.`);
            } else {
                setNoResultsMessage('');
            }
        }
    } catch (error) {
        console.error('Error while fetching data:', error);
    } finally {
        setLoading(false);
    }
};

  const switchTab = (tab) => {
    clearSearch();
    setActiveTab(tab);
  };

  useEffect(() => {
    handleSearch();
  }, [searchText, activeTab]);

  const memoizedTabs = useMemo(() => tabs, []);
  const memoizedSwitchTab = useMemo(() => switchTab, []);

  return (
    <div className='flex flex-col items-center justify-center pb-10'>
      <div className="relative rounded-full bg-gray-100 p-1 w-full sm:w-[90%] md:w-[80%] lg:w-[70%] xl:w-[60%] flex justify-center items-center">
        <div className='absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none'>
          <Search className='h-5 w-5 text-gray-400' />
        </div>
        <input
          className='flex justify-center items-center pl-12 pr-1 py-1.5 rounded-full shadow-lg w-full focus:ring-2 focus:ring-[#8A58DC] outline-none'
          type='text'
          placeholder='Search for the vendor'
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>

      <div className='pt-8 flex flex-row lg:justify-between md:justify-between items-center flex-wrap w-full sm:justify-center max-sm:justify-center'>
        <div className='mb-2 md:mb-0 font-bold text-xl text-center text-[#880081]'>
          {activeTab.replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}
        </div>
        <div className='flex justify-center flex-wrap'>
          {memoizedTabs.map((tab) => (
            <button
              key={tab}
              className={`cursor-pointer py-2 px-4 relative transition-all duration-300 ease-in-out ${activeTab === tab
                ? 'text-[#8a58dc] font-bold'
                : 'text-gray-800'
              }`}
              onClick={() => memoizedSwitchTab(tab)}
            >
              {tab.replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}
              {activeTab === tab && (
                <span
                  className="cursor-pointer absolute bottom-0 left-0 w-full h-1 bg-[#8a58dc] transition-all duration-300 ease-in-out"
                  style={{ animationDelay: '0.50s' }}
                />
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Searchbar;
