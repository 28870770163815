import React from 'react'
import ComingSoon from '../../../pages/ComingSoon'

function Report() {
  return (
	<div className='h-full'>
	  <ComingSoon/>
	</div>
  )
}

export default Report
