import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL, config } from "../../../config/config";
import EditBlog from "./EditBlog";
import Button from "../../common/Button";
import AddBlog from "./AddBlog";
import { toast } from 'react-hot-toast';
import Loader from "../../common/Loader";

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0"); // Get day and pad with zero if necessary
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month (zero-indexed) and pad with zero if necessary
  const year = date.getFullYear(); // Get full year

  return `${day}/${month}/${year}`;
}

function Blog() {
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [activeComponent, setActiveComponent] = useState("OurBlogs");
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [isAddBlogOpen, setIsAddBlogOpen] = useState(false);
  const [filterStatus, setFilterStatus] = useState("All");

  const getBlogs = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/api/admin-profile/vendorblog`,
        config
      );
      setBlogs(response.data.vendorBlogs);
    } catch (error) {
      toast.error("Error fetching blogs");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);

  const handleEditBlog = (blog) => {
    try {
      setActiveComponent("EditBlog");
      setSelectedBlog(blog);
    } catch (error) {
      console.error("Error navigating to edit blog page:", error);
    }
  };

  const handleAddBlogOpen = () => {
    setIsAddBlogOpen(true);
  };

  const handleAddBlogClose = () => {
    setIsAddBlogOpen(false);
    getBlogs();
  };

  const handleBlogAdded = (newBlog) => {
    setBlogs([...blogs, newBlog]);
    getBlogs();
  };

  const handleBackToBlogs = () => {
    setActiveComponent("OurBlogs");
    getBlogs();
  };

  const filteredBlogs = blogs.filter((blog) => {
    if (filterStatus === "All") return true;
    return blog.status === filterStatus.toLowerCase();
  });

  return (
    <>
      <div className="w-full h-full">
        <div>
          <div className="p-2 w-full flex justify-end">
            <Button style="primary" onClick={handleAddBlogOpen}>
              ADD BLOGS
            </Button>
          </div>
          <div className="p-2 w-full flex justify-start space-x-2">
            <Button
              style="primary"
              className={`w-full ${filterStatus === "All" ? "bg-[#F16080] text-white" : ""
                }`}
              onClick={() => setFilterStatus("All")}
            >
              All
            </Button>
            <Button
              style="primary"
              className={`w-full ${filterStatus === "Verified" ? "bg-[#F16080] text-white" : ""
                }`}
              onClick={() => setFilterStatus("Verified")}
            >
              Published
            </Button>
            <Button
              style="primary"
              className={`w-full ${filterStatus === "Pending" ? "bg-[#F16080] text-white" : ""
                }`}
              onClick={() => setFilterStatus("Pending")}
            >
              Pending
            </Button>
            <Button
              style="primary"
              className={`w-full ${filterStatus === "Rejected" ? "bg-[#F16080] text-white" : ""
                }`}
              onClick={() => setFilterStatus("Rejected")}
            >
              Rejected
            </Button>
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center items-center h-[500px]">
            <Loader />
          </div>
        ) : (
          <>
            {activeComponent === "OurBlogs" && (
              <>
                <div className="hidden sm:block w-full">
                  <table className=" min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Image
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white min-w-full divide-y divide-gray-200">
                      {filteredBlogs.map((blog, index) =>
                        blog?.title ? (
                          <tr key={index}>
                            <td className="px-6 py-4 w-1/2">
                              <span className="hidden md:block text-md font-semibold text-gray-900">
                                {blog.title.length > 10 ? `${blog.title.slice(0, 20)}...` : blog.title}
                              </span>
                            </td>
                            <td className="px-6 py-4">
                              <img
                                src={blog.blogImage.url}
                                alt={blog.title}
                                className="h-12 w-12 rounded-full"
                              />
                            </td>
                            <td className="px-6 py-4">
                              <span className="px-2 p-2 inline-flex leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                {formatDate(blog.createdAt)}
                              </span>
                            </td>
                            <td className="px-6 py-4">
                              <span
                                className={`px-2 p-2 inline-flex leading-5 font-semibold rounded-full ${blog.status === "verified"
                                  ? "bg-green-100 text-green-800"
                                  : blog.status === "pending"
                                    ? "bg-yellow-100 text-yellow-500"
                                    : blog.status === "rejected"
                                      ? "bg-red-100 text-red-800"
                                      : ""
                                  }`}
                              >
                                {blog.status}
                              </span>
                            </td>
                            <td className="px-6 py-4">
                              <button
                                onClick={() => handleEditBlog(blog)}
                                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                              >
                                Edit
                              </button>
                            </td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="sm:hidden flex flex-col justify-center items-center w-full">
                  {filteredBlogs.map((blog, index) =>
                    blog?.title ? (
                      <div className="flex shadow-lg p-5 gap-x-2 w-[300px] min-[500px]:w-[430px]  h-[180px]">
                        <div key={index}>
                          <img
                            src={blog.blogImage.url}
                            alt={blog.title}
                            className=" h-36 w-36 rounded-md"
                          />
                        </div>

                        <div className=" flex flex-col gap-y-3">
                          <span className="px-2 text-lg font-semibold text-gray-900">
                            {blog.title.length > 10 ? `${blog.title.slice(0, 5)}...` : blog.title}
                          </span>
                          <span className="px-2  inline-flex leading-3 font-semibold rounded-full">
                            {formatDate(blog.createdAt)}
                          </span>
                          <span
                            className={`px-2  inline-flex leading-5 font-semibold ${blog.status === "verified"
                              ? "text-green-800"
                              : blog.status === "pending"
                                ? " text-yellow-500"
                                : blog.status === "rejected"
                                  ? " text-red-800"
                                  : ""
                              }`}
                          >
                            {blog.status}
                          </span>
                          <button
                            onClick={() => handleEditBlog(blog)}
                            className="inline-flex items-center w-14 px-3 py-2 border border-transparent rounded-md shadow-sm text-md font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              </>
            )}

            {activeComponent === "EditBlog" && (
              <EditBlog
                blog={selectedBlog}
                onClose={handleBackToBlogs}
                getBlogs={getBlogs}
              />
            )}

            {isAddBlogOpen && (
              <AddBlog
                onClose={handleAddBlogClose}
                onBlogAdded={handleBlogAdded}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Blog;
