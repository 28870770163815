import React, { useState } from 'react';
import constant from '../constants/ImageConstants';
import Loader from '../component/common/Loader';

const { BECOME_A_SELLER: { BADGES } } = constant.IMAGES_IMAGES;

const badgesData = [
    {
        image: BADGES.BULE,
        title: 'Efficiency Expert (Warm Pink Badge)',
        howToEarn: [
            'Process orders promptly.',
            'Ensure timely delivery.',
            'Maintain high-quality products that meet or exceed customer expectations.',
        ],
        benefits: [
            'Increased visibility and prominence on our platform.',
            'Build trust with customers, leading to repeat business.',
            'Priority consideration for promotional features.',
        ],
    },
    {
        image: BADGES.ORANGE,
        title: 'Artisan Extraordinaire (Purple Flower Badge)',
        howToEarn: [
            'Showcase artistic and unique creations that reflect your distinct style.',
            'Demonstrate creativity and innovation in your product offerings.',
            'Consistently introduce new and captivating designs.',
        ],
        benefits: [
            'Stand out with unique products that capture the attention of art-loving customers.',
            'Featured placement in our exclusive artisan spotlight promotions.',
            'Access to special events and collaborations.',
        ],
    },
    {
        image: BADGES.PURPLE,
        title: 'Customer Champion (Clear Blue Badge)',
        howToEarn: [
            'Provide excellent customer service, responding promptly to inquiries and concerns.',
            'Garner positive feedback from satisfied customers.',
            'Ensure overall customer satisfaction through personalized interactions.',
        ],
        benefits: [
            'Build a loyal customer base with positive reviews and recommendations.',
            'Enhanced visibility through the "Customer Favorites" section.',
            'Participation in customer appreciation campaigns.',
        ],
    },
];


const Badge = ({ badge }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    return (
        <div className="py-10">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                <div className="flex justify-center items-center">
                    <img src={badge.image} onLoad={() => setImageLoaded(true)} loading='lazy-loading' alt={badge.title} className="w-[60%]" />
                    {!imageLoaded && (
                        <div className="w-full mx-auto h-[200px] flex items-center justify-center">
                            <Loader />
                        </div>
                    )}
                </div>
                <div className="flex justify-center items-center  ">
                    <div className=' w-[70%]'>
                        <div>
                            <h1 className="font-bold text-xl text-center sm:text-left py-5">{badge.title}</h1>
                        </div>
                        <div>
                            <h1 className="font-bold text-md">How to Earn:</h1>
                            <ul className="pl-2 text-sm">
                                {badge.howToEarn.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <h1 className="font-bold text-md">Benefits:</h1>
                            <ul className="pl-2 text-sm">
                                {badge.benefits.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

function Badges() {
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <div>
            <div className="grid grid-cols-1 pt-20 pb-14">
                <div className="flex justify-center flex-wrap items-center gap-x-3 px-5">
                    <h1 className="text-3xl md:text-5xl font-bold text-center py-5">The Ekaiv Badges</h1>
                    <img src={BADGES.TICK} loading='lazy-loading' alt="tick" className="h-10 w-10" />

                </div>
                <div>
                    <p className="pt-3 text-justify sm:text-center px-16 md:px-52 text-sm">
                        Unlock recognition for your efforts! The Ekaiv celebrates vendor excellence through our exclusive badge program. Showcase your achievements proudly on your catalogue page by earning these badges.
                    </p>
                </div>
            </div>

            <div className="flex flex-col justify-center items-center py-20 gap-y-10">
                {badgesData.map((badge, index) => (
                    <Badge key={index} badge={badge} />
                ))}
            </div>

            <div className="flex flex-col justify-center items-center">
                <p className="text-center py-5 px-5">
                    Enjoy the benefits of increased visibility, customer trust, and additional promotional opportunities.
                </p>
                <div className="grid grid-cols-1 sm:grid-cols-2">
                    <div className="text-justify flex flex-col justify-center items-center">
                        <h1 className="font-bold text-xl px-4 py-5 text-center">How It Works:</h1>
                        <p className="text-justify px-5 lg:px-20 text-sm w-[70%]">
                            • Fulfill the specific criteria for each badge as outlined above.<br />
                            • Ekaiv reviews and awards badges based on your performance.<br />
                            • Display your badges proudly on your catalogue page to attract more customers.<br />
                        </p>
                    </div>
                    <div>
                        <img src={BADGES.QUESTION} onLoad={() => setImageLoaded(true)} loading='lazy-loading' alt="question" className="w-[80%]" />
                        {!imageLoaded && (
                            <div className="w-full mx-auto h-[200px] flex items-center justify-center">
                                <Loader />
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center">
                    <p className="text-center py-16 px-10">
                        Join the league of outstanding vendors, and let your badges tell your success story! <br /> Earning badges is a rewarding journey, and we're here to celebrate every milestone with you.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Badges;
