import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./multiRangeSlider.css";

const MultiRangeSlider = ({ min, max, onChange }) => {
  const [minVal, setMinVal] = useState(() => {
    const storedMin = localStorage.getItem('minVal');
    return storedMin ? Math.min(Number(storedMin), max) : min;
  });

  const [maxVal, setMaxVal] = useState(() => {
    const storedMax = localStorage.getItem('maxVal');
    return storedMax ? Math.max(Number(storedMax), min) : max;
  });

  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  const getPercent = useCallback((value) => ((value - min) / (max - min)) * 100, [min, max]);
useEffect(() => {
    localStorage.setItem('minVal', minVal);
    localStorage.setItem('maxVal', maxVal);
  }, [minVal, maxVal]);

  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('minVal');
      localStorage.removeItem('maxVal');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  const handleMinChange = (event) => {
    const value = Math.min(Number(event.target.value), maxVal - 1);
    setMinVal(value);
    minValRef.current = value;
  };

  const handleMaxChange = (event) => {
    const value = Math.max(Number(event.target.value), minVal + 1);
    setMaxVal(value);
    maxValRef.current = value;
  };

  const handleSliderRelease = () => {
    onChange({ min: minVal, max: maxVal });
  };

  return (
    <div className="flex justify-start w-full">
      <div className="containerRange">
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          onInput={handleMinChange}
          onMouseUp={handleSliderRelease}
          onTouchEnd={handleSliderRelease}
          className="thumb thumb--left"
          style={{ zIndex: minVal > max - 100 && "5" }}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          onInput={handleMaxChange}
          onMouseUp={handleSliderRelease}
          onTouchEnd={handleSliderRelease}
          className="thumb thumb--right"
        />

        <div className="slider">
          <div className="slider__track" />
          <div ref={range} className="slider__range" />
          <div className="slider__left-value">{minVal}</div>
          <div className="slider__right-value">{maxVal}</div>
        </div>
      </div>
    </div>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default React.memo(MultiRangeSlider);
