import React from 'react'
import Checkout from '../component/CheckoutPage/Checkout'

function CheckoutPage() {
  return (
    <>
    <Checkout/>
    </>
  )
}

export default CheckoutPage