import React, { useState, useEffect } from 'react';
import ProductCard from '../ProductCard/ProductCard';

const RecentlyViewedProduct = () => {
  const [recentlyViewed, setRecentlyViewed] = useState([]);

  useEffect(() => {
    const storedRecentlyViewed = localStorage.getItem('recentlyViewed');
    if (storedRecentlyViewed) {
      const parsedRecentlyViewed = JSON.parse(storedRecentlyViewed);
  
      // Use a Set to keep track of unique product IDs
      const uniqueProductIds = new Set();
      const uniqueRecentlyViewed = [];
  
      // Iterate through recentlyViewed in reverse order to keep the latest views
      for (let i = parsedRecentlyViewed.length - 1; i >= 0; i--) {
        const product = parsedRecentlyViewed[i];
        if (!uniqueProductIds.has(product._id)) {
          uniqueProductIds.add(product._id);
          uniqueRecentlyViewed.unshift(product); // Add to the beginning of the array
        }
      }
  
      setRecentlyViewed(uniqueRecentlyViewed);
    }
  }, []);
  

  return (
    <div className='w-full p-8'>

      <h2 className='text-3xl font-semibold text-[#343246] mb-4'>Recently Viewed Products</h2>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6'>
        {recentlyViewed.map((product) => (
          <ProductCard key={product._id} data={product} showWishlistAndCartButtons={true} />
        ))}
      </div>
    </div>
  );
};


export default RecentlyViewedProduct;
