import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import Button from '../common/Button';

const Logout = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/'
  }

  const handleClose = () => {
    setIsOpen(false);
  }

  if (!isOpen) {
    return null;
  }

  return (
    <Box sx={{ width: "100%", zIndex: 30, height: '100vh', top: 0, left: 0, position: 'fixed', backgroundColor: '#05033191', display: 'flex', justifyContent: 'center', alignItems: "center" }}>
      <Box sx={{ display: "flex", width: '100%', height: '100%', alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ padding: '40px', borderRadius: "15px", position: 'relative', backgroundColor: "#ffffff", display: "flex", flexDirection: "column", gap: "10px", alignItems: "center", justifyContent: "center" }}>
          <CloseIcon onClick={handleClose} sx={{ position: "absolute", cursor: 'pointer', right: '10px', top: '10px', color: "#222" }} fontSize="medium" />
          <Typography variant='h4' color={'#222'}>
            Do you wanna logout?
          </Typography>
          <Button
            onClick={handleLogout}
            style="primary" className='w-20 mt-5'
          >Yes</Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Logout