
import React, { useEffect, useState } from 'react';
import HeaderAccountSettings from './HeaderAccountSettings';
import ButtonsAccountSettings from './ButtonsAccountSettings';
import PersonalDetailsSettings from './PersonalDetailsSettings';
import BusinessDetailsSettings from './BusinessDetailsSettings';
import AccountDetailsSettings from './AccountDetailsSettings';
import Loader from '../../common/Loader';

const AccountDetailsEdit = () => {
  const [activeTab, setActiveTab] = useState("general");
  const [loading, setLoading] = useState("");

  const handleTabChange = (tab) => {
    setLoading(true);
    setActiveTab(tab);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <>

      <div className="top break-words mb-32">
        {/* header */}
        <HeaderAccountSettings />

        <div className="tab-switching mt-4 md:mt-12 ml-2 sm:ml-4 md:ml-12">
          {/* buttons started */}
          <ButtonsAccountSettings activeTab={activeTab} handleTabChange={handleTabChange} />
          {loading ? (
            <div className='flex justify-center items-center h-[350px]'>
              <Loader />
            </div>
          ) : (
            <>
              {activeTab === "general" && <PersonalDetailsSettings />}
              {activeTab === "business" && <BusinessDetailsSettings />}
              {activeTab === "account" && <AccountDetailsSettings />}
            </>
          )}
        </div>
      </div >
    </>
  );
};

export default AccountDetailsEdit;