import React, { useState, useEffect } from 'react';
import axios from 'axios'; // For HTTP requests
import { FaStar } from 'react-icons/fa'; // For star-based rating
import Button from '../common/Button'; // Custom Button component
import {API_URL, config} from '../../../src/config/config';
import { toast, ToastContainer } from "react-toastify"; 
import ErrorConstants from "../../../src/constants/ErrorConstants";
import SuccessConstant from "../../../src/constants/SuccessConstant";
const Modal = ({ comment, onClose }) => {
  // Add event listener to prevent scrolling when modal is open
  useEffect(() => {
    document.body.style.overflow = "hidden";

    // Remove the event listener when the modal is unmounted
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black bg-opacity-60 p-4">
      <div className="bg-white p-4 rounded-lg text-center relative sm:flex">
        <div className="flex-shrink-0 w-1/3 mx-auto">
          <img
            className="max-w-full h-auto"
            src={comment.images[0]}
            loading="lazy-loading"
            alt={` for ${comment.customerName}`}
          />
        </div>
        <div className="flex-grow p-4 text-left ">
          <button
            className="absolute top-0 right-0 p-2 text-gray-600 hover:text-red-600"
            onClick={onClose}
          >
            &#x2716;{/* Close button (cross symbol) */}
          </button>
          <h1 className="text-2xl font-bold mb-2">{comment.customerName}</h1>
          <p className="text-gray-600 mb-2">{comment.PurchaseDate}</p>
          <h1
            className={`text-white rounded-md p-1 w-fit ${
              comment.rating >= 4
                ? "bg-green-500"
                : comment.rating === 3 || comment.rating === 2
                ? "bg-yellow-500"
                : "bg-red-500"
            }`}
          >
            {comment.rating}{" "}
            <span role="img" aria-label="star">
              &#9733;
            </span>
          </h1>
          <p className="py-4">{comment.commentText}</p>
        </div>
      </div>
    </div>
  );
};

const ProductsComments = ({ productId, user }) => {
  const [comments, setComments] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);

  // Fetch comments when the component is first rendered
  useEffect(() => {
    const fetchComments = async () => {
      try {
         
        const response = await axios.get(`${API_URL}/api/products/${productId}/comments`);
    
        if (response.data.success) {
          setComments(response.data.comments); // Store the comments
          setAverageRating(response.data.averageRating); // Store the average rating
        }
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    fetchComments(); // Call the function to fetch comments
  }, [productId]); // Run when productId changes

  const addNewComment = async (newComment) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/products/${productId}/comments`,
        newComment,
        config
      );
  
  
      if (response.data.success) {
        // Fetch updated list of comments
        const updatedComments = await axios.get(`${API_URL}/api/products/${productId}/comments`);
        setComments(updatedComments.data.comments);
        setAverageRating(updatedComments.data.averageRating); // Update average rating
        toast.success(SuccessConstant.Success.COMMENT_SUCCESS); // Show success toast
      } else if (response.data.message === "You can add a maximum of 5 comments per product") {
        toast.warning(ErrorConstants.Error.COMMENT_5_WARNING); // Limit reached
      }
     
    } catch (error) {
      if (error.response?.data.message === "Comment contains offensive language. Please use appropriate language.") {
        toast.error(ErrorConstants.Error.ABSUIVE_COMMENT); // Handle abusive language
      } else if (error.response?.data.message === "You must purchase the product to leave a comment") {
        toast.error(ErrorConstants.Error.COMMENT_PRODUCT_PURCHASE); // Handle not purchased product
      } else {
        console.error('Error adding comment:', error);
        toast.error("Error adding comment"); // General error handling
      }
    }
  };
  

  // Open and close modal functions
  const openModal = (comment) => {
    setSelectedComment(comment);
    setModalVisible(true);
  };

  const closeModal = () => {
    setSelectedComment(null);
    setModalVisible(false);
  };

  return (
    <div className="product-comments">
      {/* Display the average rating */}
      <h3 className=" text-purple">Average Rating: {averageRating}</h3>

      {/* List of comments */}
      <div>
        {comments.map((comment) => (
          <div key={comment._id}>
            <h4>
              {comment.rating} <FaStar className="inline-block text-yellow-500" />
            </h4>
            <p>{comment.commentText}</p>
            

            {/* Open modal for detailed comment */}
            {comment.image && (
              <img
                src={comment.image}
                alt="Comment image"
                onClick={() => openModal(comment)}
              />
            )}
          </div>
        ))}
      </div>

      {modalVisible && <Modal comment={selectedComment} onClose={closeModal} />}

      {/* Form to add new comments */}
      <NewCommentForm onAddComment={addNewComment} />
    </div>
  );
};

// Form to add new comments
const NewCommentForm = ({ onAddComment }) => {
  const [rating, setRating] = useState(0);
  const [commentText, setCommentText] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
   
    if (rating === 0) {
      // If no rating is selected, show a toast message
      toast.error(ErrorConstants.Error.COMMENT_CHECK
      );
      return; // Prevent the form from submitting without a rating
    }
    if (rating > 0 && !commentText) { // Check if comment text is empty when rating is present
      // If no comment text is provided, show a toast error
      toast.error(ErrorConstants.Error.COMMENT_SUBMIT);
      return; // Prevent the form from submitting without a comment
    }
    if (rating > 0 && commentText) {
      const newComment = {
        commentText,
        rating,
      };

      onAddComment(newComment); // Add the new comment
      setRating(0); // Reset rating
      setCommentText(""); // Reset comment text
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Star rating system */}
      <div className="flex gap-2">
        {Array.from({ length: 5 }, (_, index) => (
          <FaStar
            key={index}
            size={24}
            className={`cursor-pointer ${
              index < rating ? "text-yellow-500" : "text-gray-300"
            }`}
            onClick={() => setRating(index + 1)}
          />
        ))}
      </div>

      {/* Comment text area */}
      <textarea className='h-[200px] w-full mt-4 outline-[#8A58DC]'
        placeholder="Write your comment"
        value={commentText}
        onChange={(e) => setCommentText(e.target.value)}
      />
      
      <Button type="submit">Submit Comment</Button> {/* Submit button */}
    </form>
  );
};

export default ProductsComments;
