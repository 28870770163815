import React, { useMemo } from 'react';

function SellerCards() {
const cardsData = useMemo(
  () => [
   {
    heading:'Weight Handling Fee',
    field:`Shipping charges vary based on the weight of the product`,
  },
  {
    heading:'Assistance Services Fee',
    field:`Fees are contingent upon the selected services.`,
  },
  {
    heading:'Catalogue Fee',
    field:`Customized and designed catalogues are subject to additional charges.`,
  },
 
  ],
  []
);

function SellCards({heading,field}) {
  return(
    <div className="bg-[#EAF0F1] p-4 rounded-lg shadow-md text-black py-10 mb-10">
      <h2 className="text-xl font-semibold text-center mb-3">{heading}</h2>
      <p className="text-gray-700 mt-2 px-5 py-4">{field}</p>
    </div>
  )
}


  return (
    <div className=' sm:mb-10 mb-5 '>
      <div className=' sm:px-14 px-5'>
      <p className=' sm:text-4xl text-2xl font-bold mb-4 sm:px-12 px-8'>
      Other Service Charges
      </p>
      <p className=' sm:text-lg text-sm text-justify sm:px-12 px-8'>
      This service charges will be applied as per the services you opt for as per your requirement and necessity. These charges won't be deducted or charged from the website, they'll be charged directly providing you the invoice by our partners for various Assistance based service. There is no such commission or referral fee charged for suggestions.
      </p>
      </div>
      <div className=' sm:px-12 px-5'>

    <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-5 p-4 md:p-14 min-w-[250px] ">

      
    {cardsData.map((cards, index) => (
        <SellCards key={index} {...cards} />
        ))}

    </div>
    </div>
    </div>
  )
}

export default SellerCards
