import React, { useState } from 'react';
import axios from 'axios';
import { API_URL, config } from '../../../config/config';
import Button from '../../common/Button';
import { toast } from 'react-hot-toast';

const compressImage = async (file, maxSizeInBytes) => {
  const options = {
    maxSizeMB: maxSizeInBytes / (1024 * 1024),
    maxWidthOrHeight: 1024,
    useWebWorker: true,
  };

  // Check if the provided image is under the max size; if so, return the original file
  if (file.size <= maxSizeInBytes) {
    return file;
  }

  try {
    const compressedFile = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          let width = img.width;
          let height = img.height;
          const maxDim = Math.max(width, height);
          if (maxDim > options.maxWidthOrHeight) {
            const scale = options.maxWidthOrHeight / maxDim;
            width *= scale;
            height *= scale;
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob) {
                resolve(new File([blob], file.name, { type: file.type }));
              } else {
                reject(new Error('Image compression failed'));
              }
            },
            file.type,
            options.maxSizeMB
          );
        };
      };
      reader.onerror = (error) => reject(error);
    });
    return compressedFile;
  } catch (error) {
    throw error; // Rethrow the error to be caught by the caller
  }
};

function AddBlogModal({ onClose, onBlogAdded }) {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [blogContent, setBlogContent] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!image) {
      toast.error('Please upload a valid image file.');
      return;
    }

    try {
      const compressedFile = await compressImage(image, 1024 * 1024); // 1 MB
      const reader = new FileReader();

      reader.onloadend = async () => {
        const imageDataUrl = reader.result;
        try {
          const response = await axios.post(`${API_URL}/api/admin-profile/createBlog`, { title, blogContent, blogImage: imageDataUrl }, config);
          onBlogAdded();
          toast.success('Blog added successfully!');
          onClose();
        } catch (error) {
          console.error('Error adding blog:', error);
          toast.error(error.response?.data?.error || 'An error occurred while adding the blog.');
        }
      };

      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.error('Image compression error:', error);
      toast.error('Error compressing image.');
    }
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <form onSubmit={handleSubmit}>
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Add New Blog</h3>
                  <div className="mt-2">
                    <input
                      type="text"
                      placeholder="Title"
                      value={title}
                      maxLength={100}
                      onChange={(e) => setTitle(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      required
                    />
                    <input
                      type="file"
                      onChange={(e) => setImage(e.target.files[0])}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md mt-2"
                      required
                    />
                    <textarea
                      placeholder="Content"
                      value={blogContent}
                      onChange={(e) => setBlogContent(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md mt-2"
                      required
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 flex gap-4 sm:px-6 sm:flex sm:flex-row-reverse">
              <Button type="button" onClick={onClose} style="primary">Cancel</Button>
              <Button type="submit" style="primary">Save</Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddBlogModal;
