import React, { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { API_URL, config } from '../../../config/config';
import ErrorConstants from '../../../constants/ErrorConstants';

function FestiveBanner() {
  const [mainBanner, setMainBanner] = useState('');
  const [bannerCard1, setBannerCard1] = useState('');
  const [bannerCard2, setBannerCard2] = useState(null);
  const [bannerCard3, setBannerCard3] = useState(null);
  const [bannerCard4, setBannerCard4] = useState(null);
  const [title1, setTitle1] = useState('');
  const [title2, setTitle2] = useState('');
  const [title3, setTitle3] = useState('');
  const [title4, setTitle4] = useState('');

  const TransformImage = (mainBanner) => {

    const reader = new FileReader();
    reader.readAsDataURL(mainBanner);
    reader.onload = () => {
      const mainBannerData = reader.result;
      setMainBanner(mainBannerData);
      setMainBanner(reader.result);
    };
  };

  const TransformCard_1 = (bannerCard1) => {

    const reader = new FileReader();
    reader.readAsDataURL(bannerCard1);
    reader.onload = () => {
      const bannerCard_1Data = reader.result;
      setBannerCard1(bannerCard_1Data);
      setBannerCard1(reader.result);
    };
  };

  const TransformCard_2 = (bannerCard2) => {

    const reader = new FileReader();
    reader.readAsDataURL(bannerCard2);
    reader.onload = () => {
      const bannerCard_2Data = reader.result;
      setBannerCard2(bannerCard_2Data);
      setBannerCard2(reader.result);
    };
  };

  const TransformCard_3 = (bannerCard3) => {

    const reader = new FileReader();
    reader.readAsDataURL(bannerCard3);
    reader.onload = () => {
      const bannerCard_3Data = reader.result;
      setBannerCard3(bannerCard_3Data);
      setBannerCard3(reader.result);
    };
  };

  const TransformCard_4 = (bannerCard4) => {

    const reader = new FileReader();
    reader.readAsDataURL(bannerCard4);
    reader.onload = () => {
      const bannerCard_4Data = reader.result;
      setBannerCard4(bannerCard_4Data);
      setBannerCard4(reader.result);
    };
  };




  const handleBannerImageChange = (event) => {

    const file = event.target.files[0];
    if (!file) {
      toast.error(ErrorConstants.Error.IMG_SELECT_ERROR);
      return;
    }

    // Function to check if a file is an image
    const isImage = (file) => {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      return allowedTypes.includes(file.type);
    };
    TransformImage(file)
  };

  const handleCard_1ImageChange = (event) => {

    const file = event.target.files[0];
    TransformCard_1(file)
  };

  const handleCard_2ImageChange = (event) => {

    const file = event.target.files[0];
    TransformCard_2(file)
  };

  const handleCard_3ImageChange = (event) => {

    const file = event.target.files[0];
    TransformCard_3(file)
  };

  const handleCard_4ImageChange = (event) => {

    const file = event.target.files[0];
    TransformCard_4(file)
  };

  const handleclick = async () => {
    if (mainBanner == '') {
      return toast.error(ErrorConstants.Error.MAIN_BANNER_ERROR)
    }

    const banners = [bannerCard1, bannerCard2, bannerCard3, bannerCard4];
    for (let i = 0; i < banners.length; i++) {
      if (banners[i] === '') {
        return toast.error(`Banner ${i + 1} field empty`);
      }
    }

    const title = [title1, title2, title3, title4];
    for (let i = 0; i < title.length; i++) {
      if (title[i] === '') {
        return toast.error(`Title ${i + 1} field empty`);
      }
    }


    const submitData = {
      mainBanner,
      bannerCards: {
        bannerCard1,
        bannerCard2,
        bannerCard3,
        bannerCard4
      },
      titles: {
        title1,
        title2,
        title3,
        title4
      }
    };


    try {
      const response = await
        axios.post(`${API_URL}/api/admin-profile/uploadBannerImg`, submitData, config);
      toast.success('Upload Sucessfully');
    } catch (error) {

    }

  };

  const handleMainBannerDelete = () => {
    setMainBanner('');
    const fileInputSize = document.getElementById('mainBanner');
    if (fileInputSize) {
      fileInputSize.value = '';

    }
    toast.error(ErrorConstants.Error.MAIN_BANNER_ERROR);
  };

  const handleCard_1Delete = () => {
    setBannerCard1('');
    setTitle1('');
    const fileInputSize = document.getElementById('card_1');
    if (fileInputSize) {
      fileInputSize.value = '';
    }
  };

  const handleCard_2Delete = () => {
    setBannerCard2('');
    setTitle2('');
    const fileInputSize = document.getElementById('card_2');
    if (fileInputSize) {
      fileInputSize.value = '';
    }
  };

  const handleCard_3Delete = () => {
    setBannerCard3('');
    setTitle3('');
    const fileInputSize = document.getElementById('card_3');
    if (fileInputSize) {
      fileInputSize.value = '';
    }
  };

  const handleCard_4Delete = () => {
    setBannerCard4('');
    setTitle4('');
    const fileInputSize = document.getElementById('card_4');
    if (fileInputSize) {
      fileInputSize.value = '';
    }
  };




  const mainBannerImagePreview = (image) => {
    if (image) {
      return (
        <div className="relative mb-4">
          {mainBanner ? <>
            <img src={mainBanner} alt='Size chart Image' className='max-w-xs max-h-xs'></img>
          </> : <p>Image not found</p>}
          <button onClick={() => handleMainBannerDelete()} className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  const card_1ImagePreview = (image) => {
    if (image) {
      return (
        <div className="relative mb-4">
          {bannerCard1 ? <>
            <img src={bannerCard1} alt='Size chart Image' className='max-w-xs max-h-xs'></img>
          </> : <p>Image not found</p>}
          <button onClick={() => handleCard_1Delete()} className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  const card_2ImagePreview = (image) => {
    if (image) {
      return (
        <div className="relative mb-4">
          {bannerCard2 ? <>
            <img src={bannerCard2} alt='Size chart Image' className='max-w-xs max-h-xs'></img>
          </> : <p>Image not found</p>}
          <button onClick={() => handleCard_2Delete()} className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  const card_3ImagePreview = (image) => {
    if (image) {
      return (
        <div className="relative mb-4">
          {bannerCard3 ? <>
            <img src={bannerCard3} alt='Size chart Image' className='max-w-xs max-h-xs'></img>
          </> : <p>Image not found</p>}
          <button onClick={() => handleCard_3Delete()} className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  const card_4ImagePreview = (image) => {
    if (image) {
      return (
        <div className="relative mb-4">
          {bannerCard4 ? <>
            <img src={bannerCard4} alt='Size chart Image' className='max-w-xs max-h-xs'></img>
          </> : <p>Image not found</p>}
          <button onClick={() => handleCard_4Delete()} className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className='bg-white rounded-lg mt-10 w-[650px] p-5'>
      <div>
        <p className='mb-2 font-bold'>Choose Festival Main Banner</p>
        <input
          onChange={(e) => {
            handleBannerImageChange(e)
          }}
          type='file'
          accept="image/*"
          className='mb-6'
          id='mainBanner'
        />
        {mainBannerImagePreview(mainBanner, 'mainBanner')}
      </div>

      <div className=' flex flex-col'>
        <label className=' mt-5 font-bold'>Choose Festival Banner Cards</label>

        {/* Card-1  */}
        <label>Card-1</label>
        <input
          onChange={(e) => {
            handleCard_1ImageChange(e)
          }}
          type='file'
          accept="image/*"
          className='mb-6'
          id='card_1'
        />
        {card_1ImagePreview(bannerCard1, 'bannerCard1')}
        <input
          type='text'
          value={title1}
          onChange={(e) => setTitle1(e.target.value)}
          placeholder='Title'
          className=' mb-1 px-4 py-1 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:border-[#8A58DC]'
        />

        {/* Card-2  */}
        <label>Card-2</label>
        <input
          onChange={(e) => {
            handleCard_2ImageChange(e)
          }}
          type='file'
          accept="image/*"
          className='mb-6'
          id='card_2'
        />
        {card_2ImagePreview(bannerCard2, 'bannerCard2')}
        <input
          type='text'
          value={title2}
          onChange={(e) => setTitle2(e.target.value)}
          placeholder='Title'
          className=' mb-1 px-4 py-1 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:border-[#8A58DC]'
        />

        {/* Card-3  */}
        <label>Card-3</label>
        <input
          onChange={(e) => {
            handleCard_3ImageChange(e)
          }}
          type='file'
          accept="image/*"
          className='mb-6'
          id='card_3'
        />
        {card_3ImagePreview(bannerCard3, 'bannerCard3')}
        <input
          type='text'
          value={title3}
          onChange={(e) => setTitle3(e.target.value)}
          placeholder='Title'
          className=' mb-1 px-4 py-1 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:border-[#8A58DC]'
        />

        {/* Card-4  */}
        <label>Card-4</label>
        <input
          onChange={(e) => {
            handleCard_4ImageChange(e)
          }}
          type='file'
          accept="image/*"
          className='mb-6'
          id='card_4'
        />
        {card_4ImagePreview(bannerCard4, 'bannerCard4')}
        <input
          type='text'
          value={title4}
          onChange={(e) => setTitle4(e.target.value)}
          placeholder='Title'
          className=' mb-6 px-4 py-1 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:border-[#8A58DC]'
        />

      </div>

      <button
        onClick={handleclick}
        className=' focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-1 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900'>
        Add Banner
      </button>
    </div>
  );
}

export default FestiveBanner;
