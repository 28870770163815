// NotVerifiedMessage.jsx

import React from 'react';

const NotVerifiedMessage = () => {
  return (
    <div className="not-verified-message-container bg-gradient-to-r from-red-500 to-pink-500 p-8 rounded-lg shadow-lg text-white">
      <div className="flex items-center">
        <svg
          className="w-8 h-8 mr-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          ></path>
        </svg>
        <p className="text-lg font-semibold">
          Oops! Your vendor account is not verified yet.
        </p>
      </div>
      <p className="mt-2">
        Don't worry, we are processing your request. Please be patient.
      </p>
      {/* You can customize this message as needed */}
    </div>
  );
};

export default NotVerifiedMessage;
