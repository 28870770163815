import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL, config } from "../../../config/config"
import { toast } from 'react-hot-toast';

const AboutUs = () => {
  const [aboutUsData, setAboutUsData] = useState({
    heading1: '',
    heading2: '',
    heading3: '',
    heading4: '',
    mainContent: '',
    image1: '',
    image2: '',
    image3: '',
    contentforImage1: '',
    contentforImage2: ''
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`${API_URL}/api/admin-profile/aboutUs`, config);
        setAboutUsData(result.data.aboutUsData);
        setIsLoading(false);
      } catch (error) {
        setError('Error fetching data');
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAboutUsData({ ...aboutUsData, [name]: value });
  };

  const handleSave = async () => {
    try {
      await axios.put(`${API_URL}/api/admin-profile/aboutUs`, aboutUsData, config);
      setIsEditing(false);
    } catch (error) {
      setError('Error saving data');
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto py-8">
        <div className="max-w-2xl mx-auto bg-white shadow-md p-8 rounded-lg">
          {isEditing ? (
            <div>
              <div className="mb-4">
                <label className="block mb-2">Heading 1:
                  <input type="text" name="heading1" value={aboutUsData.heading1} onChange={handleChange} className="form-input mt-1 block w-full" />
                </label>
              </div>
              <div className="mb-4">
                <label className="block mb-2">Heading 2:
                  <input type="text" name="heading2" value={aboutUsData.heading2} onChange={handleChange} className="form-input mt-1 block w-full" />
                </label>
              </div>
              <div className="mb-4">
                <label className="block mb-2">Heading 3:
                  <input type="text" name="heading3" value={aboutUsData.heading3} onChange={handleChange} className="form-input mt-1 block w-full" />
                </label>
              </div>
              <div className="mb-4">
                <label className="block mb-2">Heading 4:
                  <input type="text" name="heading4" value={aboutUsData.heading4} onChange={handleChange} className="form-input mt-1 block w-full" />
                </label>
              </div>
              <div className="mb-4">
                <label className="block mb-2">Main Content:
                  <textarea name="mainContent" value={aboutUsData.mainContent} onChange={handleChange} className="form-textarea mt-1 block w-full" />
                </label>
              </div>
              <div className="mb-4">
                <label className="block mb-2">Image 1 URL:
                  <input type="file" name="image1"  onChange={handleChange} className="form-input mt-1 block w-full" />
                </label>
                
              </div>
              <div className="mb-4">
                <label className="block mb-2">Image 2 URL:
                  <input type="file" name="image2"  onChange={handleChange} className="form-input mt-1 block w-full" />
                </label>
                
              </div>
              <div className="mb-4">
                <label className="block mb-2">Image 3 URL:
                  <input type="file" name="image3"  onChange={handleChange} className="form-input mt-1 block w-full" />
                </label>
                
              </div>
              <div className="mb-4">
                <label className="block mb-2">Content for Image 1:
                  <input type="text" name="contentforImage1" value={aboutUsData.contentforImage1} onChange={handleChange} className="form-input mt-1 block w-full" />
                </label>
              </div>
              <div className="mb-4">
                <label className="block mb-2">Content for Image 2:
                  <input type="text" name="contentforImage2" value={aboutUsData.contentforImage2} onChange={handleChange} className="form-input mt-1 block w-full" />
                </label>
              </div>
              <button onClick={handleSave} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Save</button>
            </div>
          ) : (
            <div>
              <h1 className="text-3xl font-bold mb-2">{aboutUsData.heading1}</h1>
              <h2 className="text-2xl font-bold mb-2">{aboutUsData.heading2}</h2>
              <h3 className="text-xl font-bold mb-2">{aboutUsData.heading3}</h3>
              <h4 className="text-lg font-bold mb-2">{aboutUsData.heading4}</h4>
              <p className="mb-4">{aboutUsData.mainContent}</p>
              {aboutUsData.image1 && <img src={aboutUsData.image1} alt="Image 1" className="mb-2" />}
              {aboutUsData.image2 && <img src={aboutUsData.image2} alt="Image 2" className="mb-2" />}
              {aboutUsData.image3 && <img src={aboutUsData.image3} alt="Image 3" className="mb-2" />}
              <p className="mb-2">{aboutUsData.contentforImage1}</p>
              <p className="mb-2">{aboutUsData.contentforImage2}</p>
              <button onClick={() => setIsEditing(true)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Edit</button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AboutUs;
