import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import productsService from "./productsService";
import axios from "axios";
import { API_URL } from "../../../config/config";
// Initial state
const initialState = {
    products: null,
    popularProducts: null, // Add a new state property for popular products
    featuredProducts: null,
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
    priceRange: null,
    selectedRating: '',
};


// Define your async thunks for fetching products and setting the price range
export const getAllProducts = createAsyncThunk('products/getAllProducts', async (data, thunkAPI) => {
    try {
        const resp = await productsService.getAllProducts(data);
        return resp;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const setPriceRange = createAsyncThunk(
    'products/setPriceRange',
    async (priceRange, thunkAPI) => {
        try {
            const lte = priceRange[10000]; // Maximum price

            // Make a GET request to the API endpoint with the price range
            const response = await axios.get(`${API_URL}/api/products?originalPrice[lte]=${lte}`);

            // Return the response data (adjust this based on your API response)
            return response.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            // Reject with an error message
            return thunkAPI.rejectWithValue(message);
        }
    }
);


// Add extra reducers for getProductsByRating
export const getProductsByRating = createAsyncThunk('products/getProductsByRating', async (rating, thunkAPI) => {
    try {
        // Your API call or logic to fetch products by rating
        const response = await axios.get(`${API_URL}/api/products?ratings=${rating}`);
        return response.data; // You may need to adjust this based on your API response
    } catch (error) {
        // Handle any errors
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const getPopularProducts = createAsyncThunk('products/getPopularProducts', async (_, thunkAPI) => {
    try {
        const response = await axios.get(`${API_URL}/api/products/sortedProducts?sortBy=ratings`);
        return response.data;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getFeaturedProducts = createAsyncThunk('products/getFeaturedProducts', async (_, thunkAPI) => {
    try {
        const response = await axios.get(`${API_URL}/api/products/sortedProducts?sortBy=clickCount`);
        return response.data;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

const productsSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.message = "";
        },
        // Set the selected rating in the state
        setRating: (state, action) => {
            state.selectedRating = action.payload;
        },
        setPriceRange: (state, action) => {
            state.priceRange = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllProducts.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
            })
            .addCase(getAllProducts.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.products = action.payload.products;
                state.message = action.payload.message;
                state.isError = false;
            })
            .addCase(getAllProducts.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
            });
        // Add extra reducers for getProductsByRating
        builder
            .addCase(getProductsByRating.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
            })
            .addCase(getProductsByRating.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.products = action.payload.products;
                state.message = action.payload.message;
                state.isError = false;
            })
            .addCase(getProductsByRating.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
            });
        builder
            .addCase(setPriceRange.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
            })
            .addCase(setPriceRange.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.priceRange = action.payload; // Update the price range in the state
                state.isError = false;
            })
            .addCase(setPriceRange.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
            });
        builder
            .addCase(getPopularProducts.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
            })
            .addCase(getPopularProducts.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.products = action.payload.products;
                state.message = action.payload.message;
                state.isError = false;
            })
            .addCase(getPopularProducts.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
            });
        builder
            .addCase(getFeaturedProducts.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
            })
            .addCase(getFeaturedProducts.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.products = action.payload.products;
                state.message = action.payload.message;
                state.isError = false;
            })
            .addCase(getFeaturedProducts.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
            });
    },
});



export const { reset, setRating } = productsSlice.actions;

export default productsSlice.reducer;