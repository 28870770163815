import axios from 'axios';
import { API_URL } from '../../config/config'; // Adjust the import path as necessary

// Function to fetch cart items for the user
const fetchCartItems = async (users, config) => {
    if (users && users.user && users.user.cartsItems && users.user.cartsItems.length > 0) {
        try {
            const response = await axios.get(`${API_URL}/api/user/getCart`, config);
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('Failed to fetch cart items');
            }
        } catch (error) {
            console.error('Error fetching cart items:', error);
            throw error; // Re-throw error so the calling code can handle it
        }
    } else {
        return [];
    }
};

// Function to fetch wishlist items for the user
const fetchWishlistItems = async (users, config) => {
    if (users && users.user && users.user.wishlistItems && users.user.wishlistItems.length > 0) {
        try {
            const response = await axios.get(`${API_URL}/api/user/getWishlist`, config);
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('Failed to fetch wishlist items');
            }
        } catch (error) {
            console.error('Error fetching wishlist items:', error);
            throw error; // Re-throw error so the calling code can handle it
        }
    } else {
        return [];
    }
};




export default {
    fetchCartItems,
    fetchWishlistItems,

};
