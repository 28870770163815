import React from 'react'
import SpecificBlog from '../component/Blog/SpecificBlog'

function SpecificBlogPage() {
    return (
        <>
            <SpecificBlog />
        </>
    )
}

export default SpecificBlogPage