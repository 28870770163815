import React, { useState, useEffect } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import { toast, Toaster } from 'react-hot-toast'
import { API_URL } from '../config/config';
import Loader from '../component/common/Loader';
import axios from 'axios';
import ErrorConstants from '../constants/ErrorConstants';
import Button from '../component/common/Button';
import SuccessConstant from '../constants/SuccessConstant';
import InputField from '../component/common/InputField';

const ContactUs = () => {

  const subjects = ["General Inquiry", "Technical Support", "Billing Issue", "Other"];
  const [imageLoaded, setImageLoaded] = useState(false);
  const [clicked, setClicked] = useState(false);


  const sendEmail = async (e) => {

    e.preventDefault();
    if (!clicked) {
      setClicked(true);
      const timeout = setTimeout(() => {
        setClicked(false);
        clearTimeout(timeout);
      }, 3000);
      setTimer(timeout);
    }

    // Check if all fields are filled
    const formFields = ['name', 'email', 'phone', 'subject', 'message'];
    const allFieldsFilled = formFields.every(field => {
      const fieldValue = e.target[field] && e.target[field].value; // Check if e.target[field] is defined
      return fieldValue && fieldValue.trim() !== '';
    });

    if (!allFieldsFilled) {
      toast.error(ErrorConstants.Error.ALL_FIELD_ERROR);
      return;
    }

    const phone = e.target['phone'].value.trim();
    if (phone.length !== 10 || !/^\d+$/.test(phone)) {
      toast.error(ErrorConstants.Error.PHONE_ERROR_MESSAGE);
      return;
    }

    const email = e.target['email'].value.trim();
    if (!validateEmail(email)) {
      toast.error(ErrorConstants.Error.EMAIL_ERROR_MESSAGE);
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/api/ContactUs/mail`, {
        name: e.target['name'].value.trim(),
        email: e.target['email'].value.trim(),
        phone: e.target['phone'].value.trim(),
        subject: e.target['subject'].value.trim(),
        message: e.target['message'].value.trim(),
      });

      if (response.status === 200) {
        toast.success(SuccessConstant.Success.MAIL_SENT);
        e.target.reset();
      } else {
        toast.error(ErrorConstants.Error.MAIL_ERROR);
      }
    } catch (error) {
      console.error('Error sending email:', error);
      toast.error(ErrorConstants.Error.MAIL_ERROR);
    }
  };
 

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const [timer, setTimer] = useState(null);

  const handleClick = () => {
    setClicked(true);
  };

  const fields = [
    { label: 'Name', id: 'name', name: 'name', type: 'text', placeholder: 'Your Name' },
    { label: 'Email', id: 'email', name: 'email', type: 'email', placeholder: 'Your Email' },
    { label: 'Phone Number', id: 'phone', name: 'phone', type: 'tel', placeholder: 'Your Phone Number', pattern: '[0-9]*', maxLength: 10 },
  ];

  return (

    <div className="min-h-screen mx-auto max-w-7xl flex items-center justify-center pb-10 mt-10">

      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-6xl mx-auto md:flex flex-row items-center">


        <div className="md:w-[50%] sm:p-8 p-4">
          <h2 className="text-3xl font-semibold mb-6">Contact Us</h2>
          <div className="mb-6">
            <p className="text-gray-700">
              If you have any questions or feedback, please feel free to contact us using the form below.
            </p>
          </div>

          <form className="w-full max-w-5xl mx-auto flex flex-col gap-4" onSubmit={sendEmail} id='form' >
            {/* Form fields */}
            {fields.map(field => (
              <InputField
                key={field.id}
                label={field.label}
                id={field.id}
                name={field.name}
                className='shadow-sm'
                type={field.type}
                placeholder={field.placeholder}
                pattern={field.pattern}
                maxLength={field.maxLength}
                onKeyDown={field.onKeyDown}
              />
            ))}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="subject">
                Subject
              </label>
              <select
                className="shadow appearance-none border rounded-lg w-full h-12 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-[#8A58DC]"
                id="subject"
                name='subject'
              >
                <option value="" disabled selected>Select a subject</option>
                {subjects.map((subject, index) => (
                  <option key={index} value={subject}>{subject}</option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                Message
              </label>
              <textarea
                className="w-full h-20 px-3 border border-y-300 shadow-sm rounded-lg focus:outline-none focus:border-[#8A58DC]"
                id="message"
                name='message'
                rows="5"
                placeholder="Your Message"
              ></textarea>
            </div>
            {/* Button */}
            <div className="flex items-center justify-center">
              <Button type="submit" disabled={clicked} style="primary">Submit</Button>
            </div>
          </form>

          <div className="mt-10">

            <h1 className="text-lg font-semibold text-[#8A58DC] mb-4">The Ekaiv Team is here to help. Feel free to reach out with any questions or concerns.</h1>
            <div className="mt-10 flex flex-col space-y-2 ">
              <div className="flex items-center">
                <FaEnvelope className="h-6 w-6 text-[#F16080] mr-2" />
                <p className="text-gray-700">Email: <a href="mailto:support@percerptionstechnologies.com" className="text-[#8A58DC] hover:underline">support@theekaiv.in</a></p>
              </div>

            </div>
          </div>
        </div>

        <div className="sm:w-[50%] mx-auto relative overflow-hidden">
          <img
            src="https://img.freepik.com/free-vector/contact-us-concept-illustration_114360-3147.jpg?size=626&ext=jpg&ga=GA1.1.584503204.1684751112&semt=ais"
            alt="Contact Us"
            className="w-full border-slate-950 h-auto rounded-lg transform hover:scale-105 transition-transform duration-300"
            loading='lazy-loading'
            onLoad={() => setImageLoaded(true)}
          />
          {!imageLoaded && (
            <div className="w-full mx-auto h-[200px] flex items-center justify-center">
              <Loader />
            </div>
          )}

        </div>

      </div>
      <Toaster />


    </div>
  );
};

export default ContactUs;