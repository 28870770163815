import React from 'react';

const InputField = ({ id, label, type, value, onChange, placeholder, onKeyDown, maxLength, minLength, error, className, options }) => {

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        onChange({ target: { name: id, value: selectedValue } });
    };

    if (type === 'select') {
        return (
            <div>
                <label htmlFor={id} className="block text-gray-700 text-sm font-bold mb-2">
                    {label}
                </label>
                <select
                    id={id}
                    value={value}
                    onChange={handleSelectChange}
                    className={`w-full h-12 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-purple-500 ${className}`}
                >
                    <option value="" disabled>Select State</option>
                    {options.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
        );
    }
    return (
        <div>
            <label htmlFor={id} className="block font-bold text-gray-700 mb-2 text-start" >
                {label}
            </label>
            <input
                type={type}
                id={id}
                value={value}
                name={id}
                onChange={onChange}
                maxLength={maxLength}
                minLength={minLength}
                placeholder={placeholder}
                onKeyDown={onKeyDown}
                className={`w-full h-12 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#8A58DC] ${className}`}
            />
            {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
        </div>
    );
};

export default InputField;
