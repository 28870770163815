import React from 'react'
import { FaArrowRight } from "react-icons/fa";

import constant from '../../constants/ImageConstants';

const TICKMARK=constant.IMAGES_ERRORCRVES.TICKMARK;
const POLYGON=constant.IMAGES_ERRORCRVES.POLYGON;

function OrderConfirmed() {
  const goBack = () => {
    window.history.back(); 
  };
  return (
    <>
    <div className='bg-[#F3F3F3] py-10 px-5 sm:px-20 '>
      <div className=' flex justify-center items-center rounded-md bg-white'>
        <div className=' grid grid-cols-1'>
          <div className='flex justify-center items-center  px-10 pb-10 pt-20  '>
           <div className='relative text-2xl text-center sm:text-5xl md:text-8xl   text-100 text-[#E9E9E9] '>Thank You!</div> 
            <img src={TICKMARK} loading='lazy-loading' alt="tick" className=' absolute top-[55%] text-center h-20 md:h-28' />
          </div>
          <div className=' pt-14'>
          <img src={POLYGON} loading='lazy-loading' alt="polygon" />
          </div>
          <div>
            <h1 className=' text-center font-bold text-lg pt-2  '>Your Order is Confirmed!</h1>
            <p className=' text-center text-sm pt-2'>Thank you for shopping with us. You can check your mail or order section for more detail on shipping.</p>
            <span className=' flex justify-center items-center pt-5 pb-28'>
              <button onClick={goBack} className="flex items-center space-x-2 text-[5px] sm:text-xs bg-[#DF5F8C] text-white mt-2 py-1 md:py-2 px-3 md:px-8 rounded-md">
                <span>Shop More</span>
                <FaArrowRight />
              </button>
            </span>
          </div>
        </div>       
      </div>
    </div>
    </>
  )
}

export default OrderConfirmed
