import React, { useState, useRef, useMemo, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import SuccessConstant from '../../constants/SuccessConstant';
import ErrorConstants from '../../constants/ErrorConstants';
import Button from '../common/Button';
import InputField from '../common/InputField';
import commonService from '../../services/common/commonService';
import { API_URL } from '../../config/config';


const Registrations = () => {

  const formregister = useRef();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    details: '',
    messageOrFAQ: '',
    businessType: '',
    message: '', // Added for message/FAQ textarea
  });

  const [advisorName, setAdvisorName] = useState('')
  const [advisorImage, setAdvisorImage] = useState('')
  const [description, setDescription] = useState('')


  const sendEmail = async (e) => {
    e.preventDefault();
    if (
      !formData.name ||
      !formData.email ||
      !formData.phone ||
      !formData.businessType ||
      !formData.details ||
      !formData.messageOrFAQ ||
      !formData.message
    ) {
      toast.error(ErrorConstants.Error.ALL_FELID_ERROR);
      return;
    }
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check for empty fields
    if (
      !formData.name ||
      !formData.email ||
      !formData.phone ||
      !formData.businessType ||
      !formData.details ||
      !formData.messageOrFAQ
    ) {
      toast.error(ErrorConstants.Error.ALL_FIELD_ERROR);
      return;
    }

    // Validate phone number and email
    if (!phoneRegex.test(formData.phone)) {
      toast.error(ErrorConstants.Error.PHONE_ERROR_MESSAGE);
      return;
    }
    if (!emailRegex.test(formData.email)) {
      toast.error(ErrorConstants.Error.EMAIL_ERROR_MESSAGE);
      return;
    }

    try {
      // Call the function to send the email and await the response
      const response = await commonService.sendBusinessRegistrationsEmail(formData);

      // Handle success response
      toast.success(SuccessConstant.Success.FORM_SUCCESS);

      // Clear the form data after successful submission
      setFormData({
        name: '',
        email: '',
        phone: '',
        businessType: '',
        messageOrFAQ: '',
        details: '',
      });
    } catch (error) {
      // Handle error
      toast.error(ErrorConstants.Error.FORM_ERROR_MESSAGE);
    }

    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 2000);
  };

  // Define initial form data with fields

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Validate name field length
    if (name === 'name' && value.length > 50) {
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneInputChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 10); // Limit to 10 characters

    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: inputValue,
    }));
  };

  const handleMessageOrFAQChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      message: '', // Reset message when toggling between message/FAQ
    });
  };


  // Define an array of form fields for rendering
  const formFields = useMemo(() => [
    { name: 'name', label: 'Name', type: 'text', placeholder: 'Name' },
    { name: 'email', label: 'Email', type: 'email', placeholder: 'Email' },
    { name: 'phone', label: 'Phone', type: 'tel', placeholder: 'Phone' },
    { name: 'businessType', label: 'Business Type', type: 'text', placeholder: 'Business Type' },
    { name: 'details', label: 'Business Details & Licenses', type: 'text', placeholder: 'Business Details & Licenses' },
    {
      name: 'messageOrFAQ',
      label: 'Choose Message or FAQ',
      type: 'radio',
      options: [
        { value: 'message', label: 'Message' },
        { value: 'faq', label: 'FAQ' },
      ],
    },
  ], []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Implement logic to handle form submission with the formData state.
    // Example: Log form data to the console
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/assistance/getBuisnessAdvisor`);
        setAdvisorName(response.data.BuisnessAdvisor.name)
        setAdvisorImage(response.data.BuisnessAdvisor.imageUrl.url)
        setDescription(response.data.BuisnessAdvisor.description)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className='bg-gray-100 p-1  sm:p-10'>

        <div className=' w-full flex flex-col justify-center items-center'>
          <h2 className="text-xl min-[510px]:text-2xl font-bold py-4 mb-4  text-gray-800">Bussiness Registrations</h2>
          <p className='text-sm rounded-lg sm:py-5 px-2 sm:px-7  text-justify'>
            Simplify the often-complex process of business registration with our assistance services. We provide guidance and support to help you obtain the necessary licenses tailored to your specific product categories. Our aim is to streamline the registration process, ensuring that you can focus on what you do best – growing your business.
          </p>
        </div>
        <div className=' grid md:grid-cols-2 grid-cols-1 gap-2 p-2 items-center'>

          <div className=' flex flex-col justify-center items-center' >

            <div className='p-5 w-[100%] md:w-[70%]'>
              <form ref={formregister} onSubmit={sendEmail} className="mt-4 flex flex-col">
                <div className="flex flex-wrap -mx-4">
                  {formFields.map((field, index) => (
                    <div key={index} className={`mb-4 px-4 ${field.name === 'email' || field.name === 'phone' ? ' w-full sm:w-1/2' : 'w-full'}`}>

                      {field.type !== 'radio' ? (
                        <InputField
                          type={field.type}
                          id={field.name}
                          label={field.label}
                          name={field.name}
                          value={formData[field.name]}
                          onChange={field.name === 'phone' ? handlePhoneInputChange : handleInputChange}
                          placeholder={field.placeholder}
                        />
                      ) : (
                        <div>
                          {field.options.map((option, optionIndex) => (
                            <label className="inline-flex items-center px-2" key={optionIndex}>
                              <input
                                type="radio"
                                className="form-radio h-5 w-5 px-2 text-blue-600"
                                name={field.name}
                                value={option.value}
                                checked={formData[field.name] === option.value}
                                onChange={(e) => {
                                  handleMessageOrFAQChange(e);
                                  handleInputChange(e);
                                }}
                              />
                              <span className="ml-2">{option.label}</span>
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                  {formData.messageOrFAQ === 'message' && (
                    <div className="mb-4 w-full px-4 ">

                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        placeholder="Your message..."
                        className="w-full h-20 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#8A58DC]"
                      ></textarea>
                    </div>
                  )}
                  {formData.messageOrFAQ === 'faq' && (
                    <div className="mb-4 w-full px-4">

                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        placeholder="Your FAQ..."
                        className="w-full h-20 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#8A58DC]"
                      ></textarea>
                    </div>
                  )}
                </div>
                <Button
                  type="submit"
                  style="primary"
                  disabled={buttonDisabled}
                >
                  Submit Registration
                </Button>
              </form>
              {/* Add any other UI elements and functionality here */}
            </div>

          </div>
          <div className=' '>
            {/* <h1 className=' text-lg font-bold'>Reach Out To Us!</h1> */}
            <div className=' bg-white rounded-2xl px-5 pt-5 '>

              <div className=' flex flex-col'>
                <div className='flex flex-col sm:flex-row'>
                  <img src={advisorImage} loading='lazy-loading' alt="rachnaErankar" className='mx-5 h-28 w-28 rounded-full' />
                  <p className=' pt-5 sm:pt-14 px-2 sm:px-5 text-2xl font-bold'>{advisorName}</p>
                </div>
                <div className=' flex flex-col p-2 sm:p-5'>
                  <p className='text-sm text-justify'>{description}</p>
                </div>

              </div>

            </div>
          </div>

        </div>

      </div>
    </>
  );
};

export default Registrations;
