import React from 'react'
import ComingSoon from './ComingSoon'

const Reviews = () => {
    return (
        <div>
            <ComingSoon />
        </div>
    )
}

export default Reviews
