import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AccountDetails from "./AccountDetails";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import axios from "axios";
import { API_URL } from "../../config/config";
import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorConstants from "../../constants/ErrorConstants";


const PersonalDetails = ({ onNextStep, setAllData, allData }) => {

  const users = useSelector((state) => state.auth)

  const [isCreateAccountOpen, setIsCreateAccountOpen] = useState(false);
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [alternateNumber, setAlternateNumber] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSendOTPButtonDisabled, setIsSendOTPButtonDisabled] = useState(true);
  const [phone, setPhone] = useState("");
  const [isEmptyFieldsError, setIsEmptyFieldsError] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [isNextButtonClicked, setIsNextButtonClicked] = useState(false);
  const [resendTimer, setResendTimer] = useState(90);

  let timerInterval; // Declare timerInterval here

  const handleAlternateMobileInputChange = (e) => {
    const inputValue = e.target.value;
    const onlyDigits = inputValue.replace(/[^0-9]/g, ""); // Remove non-digit characters
    setAlternateNumber(onlyDigits);
    setIsSendOTPButtonDisabled(onlyDigits.length !== 10);
    checkFormValidity();
  };

  useEffect(() => {
    if (users.user) {
      setPhone(users.user.phone)
      setFullName(users.user.fullname)
      if (users.user.email) setEmail(users.user.email)
    }
  }, [users])


  // Function to handle input change and allow only digits
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const onlyDigits = inputValue.replace(/[^0-9]/g, "");
    setPhone(onlyDigits);
    checkFormValidity();
  };


  const handleDateOfBirthChange = (e) => {
    const inputDate = e.target.value;

    // Check if the input date is valid
    if (isNaN(new Date(inputDate))) {
      setIsEmptyFieldsError(true);
      toast.error(ErrorConstants.Error.INVALID_DATA_ERROR);
      return;
    }

    const selectedDate = new Date(inputDate);
    const currentDate = new Date();
    const userAge = currentDate.getFullYear() - selectedDate.getFullYear();

    if (userAge < 13) {
      setIsEmptyFieldsError(true);
      toast.error(ErrorConstants.Error.AGE_ERROR);
    } else {
      setIsEmptyFieldsError(false);
      setDateOfBirth(selectedDate.toISOString().split('T')[0]);
    }
  };

  const handleGenderChange = (e) => {
    const selectedGender = e.target.value;
    setGender(selectedGender);
  };


  const checkFormValidity = () => {
    if (
      phone.trim() !== "" &&
      alternateNumber.trim() !== "" &&
      otpValue.trim() !== "" &&
      dateOfBirth.trim() !== "" &&
      gender.trim() !== ""
    ) {
      setIsFormValid(true);
      setIsEmptyFieldsError(false);
    } else {
      setIsFormValid(false);
      if (isNextButtonClicked) {
        setIsEmptyFieldsError(true);
      }
    }
  };

  const sendOTP = async (e) => {

    e.preventDefault();

    if (alternateNumber.length !== 10) {
      return;
    }

    await axios.post(`${API_URL}/api/otp-verfication/send-number-otp`, { alternatePhone: alternateNumber }).then(response => {
      setShowOTP(true);
      toast.success(response.data)

    })
      .catch(error => {
        setShowOTP(false);
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        toast.error(message)
      })
  }

  const resendOTP = () => {
    sendOTP()
  };

  const verifyOTP = async (e) => {
    e.preventDefault();

    await axios.post(`${API_URL}/api/otp-verfication/verify-number-otp`, { phone: alternateNumber, otp: otpValue }).then(response => {
      setShowOTP(false);
      toast.success(response.data)
      setIsOtpVerified(true);
    })
      .catch(error => {
        setShowOTP(true);
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        toast.error(message)
      })
  }

  const handleNextClick = () => {
    setIsNextButtonClicked(true);


    if (phone.trim() !== "" && alternateNumber.trim() !== "" && dateOfBirth.trim() !== "" && gender.trim() !== "") {
      if (isOtpVerified) {
        // All fields are filled, and OTP is verified, proceed to the next step
        setAllData({ ...allData, alternateNumber: alternateNumber, dateOfBirth, gender });
        onNextStep();
      } else if (otpValue.trim() !== "") {
        // OTP is provided but not verified yet
        setIsEmptyFieldsError(true);
        toast.error(ErrorConstants.Error.OTP_VERIFICATION_ERROR);
      } else {
        // OTP is required
        setIsEmptyFieldsError(true);
        toast.error(ErrorConstants.Error.OTP_ENTER_ERROR);
      }
    } else {
      // Some or all fields are empty
      setIsEmptyFieldsError(true);
      toast.error(ErrorConstants.Error.ALL_FIELD_ERROR_MESSAGE);
    }
  };

  const startResendTimer = () => {
    // Start a 1-minute 30-second timer
    timerInterval = setInterval(() => {
      setResendTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          // When the timer reaches zero, clear the interval
          clearInterval(timerInterval);
          return 0;
        }
      });
    }, 1000);
  };

  const resetTimer = () => {
    // Clear the existing timer before resetting
    clearInterval(timerInterval);

    // Reset the timer to 1 minute and 30 seconds
    setResendTimer(90);

    // Start the timer again
    startResendTimer();
  };

  useEffect(() => {
    if (showOTP) {
      startResendTimer();
    }

    // Clear the timer when the component unmounts
    return () => {
      clearInterval(resendTimer);
    };
  }, [showOTP]);

  useEffect(() => {
    let timerInterval;

    if (showOTP) {
      timerInterval = setInterval(() => {
        setResendTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          } else {
            clearInterval(timerInterval);
            return 0;
          }
        });
      }, 1000);
    }

    // Clear the timer when the component unmounts
    return () => {
      clearInterval(timerInterval);
    };
  }, [showOTP, resetTimer]);

  useEffect(() => {
    setAllData((prevData) => ({
      ...prevData,
      alternateNumber,
      dateOfBirth,
      gender,
    }));
  }, [alternateNumber, dateOfBirth, gender, isOtpVerified]);
  return (
    <>

      <div className='flex md:flex-row items-start justify-evenly gap-5 xl:gap-10 p-10 flex-col'>
        {/* left div */}
        <div className="font-bold w-[100%]    md:w-[25%] text-center md:sticky top-10">
          <h1>Personal Details</h1>
        </div>
        {isCreateAccountOpen ? (
          <AccountDetails />
        ) : (
          <form action="" className=" w-full md:w-[41%]">
            <div className="flex flex-col gap-7 mb-4 ">
              <div className="flex flex-col w-full ">
                <input
                  type="text"
                  placeholder="Enter Your Name *"
                  value={fullname}
                  disabled={true}
                  onChange={(e) => setFullName(e.target.value)}
                  className="w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                  required
                />
                {isNextButtonClicked && setIsEmptyFieldsError && !fullname.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Enter Full Name.
                  </p>
                )}
              </div>

              <div className="flex flex-col">
                <input
                  type="text"
                  placeholder="Enter Mobile Number *"
                  value={phone}
                  disabled={true}
                  maxLength={10}
                  onChange={handleInputChange}
                  className={`w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC] 
                    }`}
                  required
                />
                {isNextButtonClicked && setIsEmptyFieldsError && !phone.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Enter mobile number.
                  </p>
                )}
              </div>

              {/* Your Phone Number input field */}
              <div className="flex flex-col relative justify-center">
                <input
                  type="text"
                  placeholder="Enter Alternate Mobile Number *"
                  value={alternateNumber}
                  maxLength={10}
                  onChange={handleAlternateMobileInputChange}
                  className="w-full p-2 h-12 pl-11 px-3  border rounded-xl pr-[90px] focus:outline-none focus:border-[#8A58DC]"
                  required
                /><div className='absolute left-3 top-3'>+91</div>
                {isNextButtonClicked && setIsEmptyFieldsError && !alternateNumber.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Enter alternate mobile number.
                  </p>
                )}
                {/* Send OTP button or Verified label */}
                {isOtpVerified ? (
                  <span className="absolute top-0 p-2 h-12 bg-none text-green-500 right-2">
                    <VerifiedIcon />
                  </span>
                ) : (
                  <button
                    className={`absolute top-0 p-2 h-12 bg-none text-[#8A58DC] right-2 ${isSendOTPButtonDisabled
                      ? "opacity-50 cursor-not-allowed"
                      : " text-[#8A58DC]"
                      }`}
                    onClick={isOtpVerified ? undefined : sendOTP}
                    disabled={isSendOTPButtonDisabled}
                  >
                    {isOtpVerified ? <VerifiedIcon /> : showOTP ? 'Resend OTP' : 'Send OTP'}
                  </button>
                )}

              </div>

              {/* Your OTP-related elements should go here */}
              {showOTP && (
                <div className="flex flex-col relative">
                  <input
                    type="text"
                    value={otpValue}
                    maxLength={6}
                    onChange={(e) => setOtpValue(e.target.value)}
                    placeholder="Enter OTP *"
                    className="w-full p-2 h-12 border rounded-xl pr-[90px] focus:outline-none focus:border-[#8A58DC]"
                    required
                  />
                  {/* Send OTP button */}
                  <button
                    className={`absolute top-0 p-2 h-12 bg-none text-[#8A58DC] right-2 ${false
                      ? "opacity-50 cursor-not-allowed"
                      : " text-[#8A58DC]"
                      }`}
                    onClick={verifyOTP}
                  // disabled={isSendOTPButtonDisabled}
                  >

                    Verify OTP
                  </button>
                  <button
                    className={`absolute top-0 p-2 h-12 bg-none text-[#8A58DC] right-2 ${isSendOTPButtonDisabled
                      ? "opacity-50 cursor-not-allowed"
                      : " text-[#8A58DC]"
                      }`}
                    onClick={verifyOTP}
                  // disabled={isSendOTPButtonDisabled}
                  ></button>

                  {isNextButtonClicked && setIsEmptyFieldsError && !otpValue.trim() && (
                    <p className="flex text-red-500 ml-2">
                      OTP is required.
                    </p>
                  )}
                </div>
              )}

              <div className="flex flex-col">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email Id"
                  disabled={true}
                  className="flex p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC] w-full"
                  required
                />
                {/* {isNextButtonClicked && setIsEmptyFieldsError && !email.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Enter email id.
                  </p>
                )} */}
              </div>

              <div className="flex flex-col">
                <input
                  type="date"
                  placeholder="Date of Birth *"
                  value={dateOfBirth instanceof Date ? dateOfBirth.toISOString().split('T')[0] : dateOfBirth}
                  onChange={handleDateOfBirthChange}
                  className="w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                  required
                  max={`${new Date().getFullYear()}-12-31`}
                />
                {isNextButtonClicked && setIsEmptyFieldsError && !dateOfBirth.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Enter date of birth.
                  </p>
                )}
              </div>

              <div className="flex flex-col">
                <select
                  value={gender}
                  onChange={handleGenderChange}
                  className="w-full p-2 h-12 border rounded-xl focus:outline-none cursor-pointer focus:border-[#8A58DC]"
                  required
                >
                  <option value="" disabled>
                    Select Gender *
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                {isNextButtonClicked && setIsEmptyFieldsError && !gender.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Please select the gender.
                  </p>
                )}
              </div>

            </div>
            <div class="flex justify-start p-5">
              {/* "Next Section" button is always displayed */}
              <Link
                onClick={handleNextClick}
                class="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-[#8A58DC] rounded-full shadow-md group"
              >
                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full text-4xl bg-[#8A58DC] group-hover:translate-x-0 ease">
                  <IoIosArrowRoundForward />
                </span>
                <span class="absolute flex items-center justify-center w-full h-full text-[#8A58DC] font-bold transition-all duration-300 transform group-hover:translate-x-full ease">
                  Next Section
                </span>
                <span class="relative invisible">Next Section</span>
              </Link>

            </div>
          </form>

        )}

        {/* right part */}
        <div className='flex  items-center justify-center w-[100%]  md:w-[33%]  md:sticky top-32'>
          <img src="https://img.freepik.com/premium-vector/onlin-ebusiness-partnership-two-male-cartoon-characters-businessmen-shaking-hands-making-business-deal-business-management-remotely-flat-vector-illustration_241107-1243.jpg?size=626&ext=jpg&ga=GA1.1.584503204.1684751112&semt=ais" alt="seller registration"
            loading='lazy-loading'
            className="w-[200px] sm:w-[400px]" />
        </div>
      </div>

    </>
  );
}
export default PersonalDetails;