import React, { useEffect, useState } from "react";
import axios from "axios";
import imageCompression from 'browser-image-compression';
import { useSelector, useDispatch } from "react-redux";
import ErrorConstants from "../../../constants/ErrorConstants";
import SuccessConstant from "../../../constants/SuccessConstant";
import { API_URL, config } from "../../../config/config";
import { FaImage } from "react-icons/fa";
import { toast } from "react-hot-toast";
import { Tooltip } from "@mui/material";
import Loader from '../../common/Loader';

function HeaderAccountSettings() {
  const vendor = useSelector((state) => state.vendor);
  const ShopAvatar = vendor?.vendor?.ShopAvatar;
  const [ShopAvatarImage, setShopAvatarImage] = useState(null);
  const [loading, setLoading] = useState('');
  const [businessName, setBusinessName] = useState(
    vendor &&
    vendor.vendor &&
    vendor.vendor.BussinessDetails &&
    vendor.vendor.BussinessDetails.Businessname
  );
  const [typeOfBusiness, setselectedTypeOfBusiness] = useState(
    (vendor &&
      vendor.vendor &&
      vendor.vendor.BussinessDetails &&
      vendor.vendor.BussinessDetails.typeOfBusiness) ||
    []
  );
  const status = vendor && vendor.vendor && vendor.vendor.status;

  useEffect(() => {
    // Retrieve the uploaded image URL from localStorage on component mount
    const storedImageUrl = localStorage.getItem("uploadedImageUrl");
    if (storedImageUrl) {
      setLoading(true);
      setShopAvatarImage(storedImageUrl);
    }
    setLoading(false);
  }, []);

  const handleImageUpload = async (event) => {
    const imageFile = event.target.files[0];

    if (imageFile) {
      if (!imageFile.type.startsWith('image/')) {
        toast.error(ErrorConstants.Error.IMG_ERROR);
        return;
      }

      try {
        setLoading(true);
        // Compress the image
        const compressedImage = await imageCompression(imageFile, {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
        });

        // Convert the compressed image to base64 data URL
        const reader = new FileReader();

        reader.onloadend = async () => {
          const compressedImageDataUrl = reader.result; // Extracting the base64 data
          try {
            // Send the compressed image data to the backend
            const response = await axios.post(
              `${API_URL}/api/vendor/uploadAvatar`,
              { imageDataUrl: compressedImageDataUrl },
              config
            );

            const { url } = response.data;

            toast.success(SuccessConstant.Success.AVTAR_SUCCESS);
            setShopAvatarImage(url);
            setLoading(false);
            event.target.form.reset();
            localStorage.setItem('ShopAvatarImage', url);
          } catch (error) {
            console.error('Error uploading image:', error);
            setLoading(false);
            event.target.form.reset();
            toast.error(error.response.data.error);
          }
        };

        reader.readAsDataURL(compressedImage); // Read the compressed image data
      } catch (error) {
        console.error('Error compressing image:', error);
        toast.error('Failed to compress image.');
      }
    }
  };

  return (
    <div>
      <div className="upperbox flex flex-wrap sm:gap-6 border-b-2 sm:visible max-sm:hidden ">
        {loading ? (
          <div className="flex justify-center items-center w-[500px] h-[200px]">
            <Loader />
          </div>
        ) : (
          <div className=" flex-col md:flex md:md:items-center md:space-x-4 bg-cover relative ml-16">
            {ShopAvatarImage ? (
              <img
                src={ShopAvatarImage}
                alt="Uploaded Image"
                className="w-full object-fill h-[200px] shadow-md mb-2"
              />
            ) : ShopAvatar ? (
              <img
                src={ShopAvatar.url}
                alt="Shop Avatar"
                className="w-full object-fill h-[200px] shadow-md mb-2"
              />
            ) : (
              <div className="flex justify-center items-center">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2Fdefault%20Shop%20Avatar%2FDefault%20Shop%20Avatar.jpg?alt=media&token=1f94748a-8ed6-4fd5-ba08-22a0240e7f95"
                  alt={` logo`}
                  className="w-full object-fill h-[200px] shadow-md mb-2"
                />
              </div>
            )}
            <div className="md:ml-4  bottom-2 w-full absolute rounded-full flex justify-end items-end ">
              <form
                className="flex flex-wrap break-word px-1 py-2 font-semibold md:w-48    rounded-full justify-center md:items-center"
                action="/upload"
                method="POST"
                enctype="multipart/form-data"
              >
                <Tooltip title="UploadShop Logo">
                  <label className="hover:scale-110 transition-all duration-200 flex justify-center md:items-center">
                    <input
                      type="file"
                      name="vendorAvatar"
                      accept="image/*"
                      className="hidden"
                      onChange={handleImageUpload}
                    />

                    <div className="p-3 rounded-full bg-black/20 m-auto">
                      <FaImage size={"20px"} color="white" />
                    </div>
                  </label>
                </Tooltip>
              </form>
            </div>
          </div>
        )}
        <div className="flex flex-col justify-center">
          <h1 className="font-bold text-4xl pl-5">{businessName}</h1>
          <p className="text-gray-500 pl-5 font-semibold md:w-48 text-lg w-full">
            {typeOfBusiness.join(", ").toUpperCase()}
          </p>
          <p className=" pl-5">
            Status :{" "}
            <span
              className={
                status === "Pending"
                  ? "text-red-500 font-bold"
                  : status === "Verified"
                    ? "text-green-500"
                    : ""
              }
            >
              {status}
            </span>
          </p>
        </div>
      </div>

      {/* mobile view for image header */}

      <div className="  sm:hidden  flex flex-wrap gap-6  border-b-2 p-2">
        {loading ? (
          <div className="flex justify-center items-center h-40 md:w-48">
            <Loader />
          </div>
        ) : (
          <div className=" flex-col md:flex  bg-cover relative  rounded-lg  ">
            {ShopAvatarImage ? (
              <img
                src={ShopAvatarImage}
                alt="Uploaded Image"
                className="h-40 md:w-48"
              />
            ) : ShopAvatar ? (
              <img
                src={ShopAvatar.url}
                alt="Shop Avatar"
                className="h-40 md:w-48"
              />
            ) : (
              <img
                src="https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2Fdefault%20Shop%20Avatar%2FDefault%20Shop%20Avatar.jpg?alt=media&token=1f94748a-8ed6-4fd5-ba08-22a0240e7f95"
                alt="Default Image"
                className="h-40 md:w-48"
              />
            )}
            <div className="ml-4 rounded-full w-[90%]  absolute h-10 bottom-1 flex justify-end items-end ">
              <form
                className="flex flex-wrap break-word   font-semibold  absolute  rounded-full justify-end items-center"
                action="/upload"
                method="POST"
                encType="multipart/form-data"
              >
                <Tooltip title="UploadShop Logo">
                  <label className="hover:scale-110 transition-all duration-200 flex justify-end items-end">
                    <input
                      type="file"
                      name="vendorAvatar"
                      accept="image/*"
                      className="hidden h-20 w-20"
                      onChange={handleImageUpload}
                    />

                    <div className="p-1  rounded-full bg-black/20 ">
                      <FaImage size={"13px"} color="white" />
                    </div>
                  </label>
                </Tooltip>
              </form>
            </div>
          </div>
        )}
        <div className=" md:ml-12 mt-4 gap-4">
          <h1 className="font-bold text-xl mt-10">{businessName}</h1>
          <p className="text-gray-500 font-semibold md:w-48 text-lg">
            {" "}
            {typeOfBusiness.join(", ").toUpperCase()}
          </p>
          <p>
            Status :{" "}
            <span
              className={
                status === "Pending"
                  ? "text-red-500 font-bold"
                  : status === "Verified"
                    ? "text-green-500"
                    : ""
              }
            >
              {status}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default HeaderAccountSettings;
