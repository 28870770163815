import axios from 'axios';
import { API_URL } from '../../config/config';


// Function to search for vendors by name or business name
export const searchVendorsByNameOrBusinessName = async (searchText) => {
    try {
        const response = await axios.get(`${API_URL}/api/vendor/vendorsearch?name=${searchText}&businessname=${searchText}`);
        return response.data.vendors;
    } catch (error) {
        console.error('Error while fetching data:', error);
        throw error;
    }
};

// Function to get all vendors
export const getAllVendors = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/vendor/`);
        return response;
    } catch (error) {
        console.error('Error while fetching data:', error);
        throw error;
    }
};

// Function to search vendors by type of business
export const searchVendorsByTypeOfBusiness = async (typeOfBusiness) => {
    try {
        const response = await axios.get(`${API_URL}/api/vendor/search-by-type?typeOfBusiness=${typeOfBusiness.toLowerCase()}`);
        return response.data.vendors;
    } catch (error) {
        console.error('Error while fetching data:', error);
        throw error;
    }
};
