import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Rating } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, addToWishlist, deleteFromWishlist, getUser, updateToCart } from "../../Redux/features/auth/authSlice";
import ProductsComments from "./ProductsComments";
import { Link } from "react-router-dom";
import { API_URL, config } from '../../config/config.js'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import Loader from '../common/Loader.jsx'
import { RxCross2 } from "react-icons/rx";
import { RiDeleteBinLine } from "react-icons/ri";
import axios from "axios";
import ErrorConstants from "../../constants/ErrorConstants.js";
import SuccessConstant from "../../constants/SuccessConstant.js";
import Button from "../common/Button.jsx";
import InputField from "../common/InputField.jsx";


const token = JSON.parse(localStorage.getItem('token'));


function ExpandableDiv({ title, content }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = (e) => {
    // Check if the click target is either the title or the button
    const isTitleOrButton = e.target.tagName === 'H2' || e.target.tagName === 'BUTTON';

    if (isTitleOrButton) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div
      className={`bg-[#FCFCFC] p-3 border  rounded-sm w-full mb-4 relative cursor-pointer ${isExpanded ? "expanded" : ""}`}
      onClick={toggleExpand}
    >
      <button
        className={`absolute text-3xl items-center right-2 mr-2 text-[#5156E9] transform transition-transform ${isExpanded ? "rotate-180" : "rotate-0"}`}
        onClick={(e) => {
          e.stopPropagation();
          toggleExpand(e);
        }}
      >
        {isExpanded ? "-" : "+"}
      </button>
      <div className="mb-2">
        <h2 className="text-lg  items-center">{title}</h2>
      </div>
      <div className={isExpanded ? "block" : "hidden"}>{content}</div>
    </div>
  );
}

export default function ProductDesc({ data }) {

  const [wordCount, setWordCount] = useState(0);
  const { productId } = useParams();
  const [isWishlist, setIsWishlist] = useState(false);
  const [localWishlist, setLocalWishlist] = useState([]);
  const [isloading, setIsLoading] = useState(true)
  const [customizationInput, setCustomizationInput] = useState("");
  const [detailsSubmitted, setDetailsSubmitted] = useState(false);
  const [imageData, setImageData] = useState('');
  const [image, setImage] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();


  const users = useSelector((state) => state.auth);
  const handleAddToCart = () => {
    const loggedInVendorId = users?.user?.vendorDetails;

    if (loggedInVendorId === vendor) {
      toast.error(ErrorConstants.Error.OWN_PRODUCT_ERROR);
      return;
    }
    if (!users || !users.user || !users.user._id) {
      toast.error(ErrorConstants.Error.LOGIN_FIRST_ERROR);
      return;
    }
    if (
      (selectedCategory === "clothing" ||
        selectedCategory === "Men" ||
        selectedCategory === "kids" ||
        selectedCategory === "Boy" ||
        selectedCategory === "Girl" ||
        selectedCategory === "Women") &&
      !selectedSize
    ) {
      toast.error(ErrorConstants.Error.SIZE_ERROR);
      return;
    }

    if (isCustomizing && !detailsSubmitted) {
      toast.error(ErrorConstants.Error.CUSTOMIZATION_ERROR);
      return;
    }


    const newData = {
      id: users?.user?._id,
      pId: productId,
      quantity: amount,
      size: selectedSize || null,
      customization: {
        isCustomized: isCustomizing,
        customizationDetails: customizationInput,
      },
    };

    dispatch(addToCart(newData))
      .then((result) => {
        if (addToCart.fulfilled.match(result)) {
          // Product added to the cart successfully, handle the success case
          toast.success(SuccessConstant.Success.ITEM_ADDED_TO_CART);
          setCustomizationInput("");
          setIsCustomizing(false)
          setDetailsSubmitted(false)

        } else {
          // Handle the error case
          toast.error(result.payload)

        }
      });
  };


  const customProductIds = users.user?.customization?.map((customProduct) => customProduct.productDetails.productId);
  const customizationMessage = users.user?.customization?.map((customProduct) => customProduct.customizationMsg);
  const customizationStatus = users.user?.customization?.map((customProduct) => customProduct.customizedStatus);
  const customizationId = users.user?.customization?.map((customProduct) => customProduct._id);

  const vendorApproval = users.user?.customization?.map((customProduct) => customProduct.vendorApproval);
  const filteredVendorApproval = users.user?.customization
    .filter((customProduct) => customizationId.includes(customProduct._id) && customProduct.customizedStatus === 'Pending')
    .map((customProduct) => customProduct.vendorApproval);

  console.log("lsjdbkfds", filteredVendorApproval)
  // ************************************************
  // Extract the customizedPrice from the found customization
  const customProductDetails = users.user?.customization?.find(
    (customProduct) => customProduct.productDetails.productId === productId
  );
  const customizedPrice = customProductDetails ? customProductDetails.customizedPrice : null;
  const vendorResponse = customProductDetails ? customProductDetails.vendorResponse : null;

  // Assuming users.user.customization is the array of customizations
  const pendingCustomizations = users.user?.customization?.filter(
    (customization) => customization.customizedStatus === 'Pending' && customization.productDetails.productId === productId
  )

  // Extract vendorResponse and customizedPrice from the pending customizations
  const pendingVendorResponses = pendingCustomizations?.map(
    (customization) => customization.vendorResponse
  );
  const pendingCustomizedPrices = pendingCustomizations?.map(
    (customization) => customization.customizedPrice
  );

  // ************************************************

  const specificCustomizationMessage = users?.user?.customization.find(customization => customization.customizedStatus === 'Pending' && customization.productDetails.productId === productId);

  const messageString = specificCustomizationMessage ? specificCustomizationMessage.customizationMsg : '';



  const handleCustomizationChange = (event) => {
    if (!users?.user?._id) {
      toast.error(ErrorConstants.Error.CUSTOMIZATION_LOGIN_ERROR);
      return;
    }
    const loggedInVendorId = users?.user?.vendorDetails;

    if (loggedInVendorId === vendor) {
      toast.error(ErrorConstants.Error.CUSTOMIZATION_OWN_ERROR);
      return;
    }
    const isCustomizing = event.target.value === "yes";
    setIsCustomizing(isCustomizing);

    if (isCustomizing) {
      const currentProductId = productId;

      // Find all indexes of the current product in customProductIds array
      const indexes = customProductIds.reduce((acc, id, index) => {
        if (id === currentProductId) {
          acc.push(index);
        }
        return acc;
      }, []);

      // Check if any of the customizations for the currentProductId is 'Pending'
      const isAnyPending = indexes.some(index => customizationStatus[index] === 'Pending');

      if (isAnyPending) {
        // If any customization is pending, hide the input field
        // You might need to add code here to hide the input field based on your application logic
        // For example, you might have a state variable like setShowInputField(false);
        toast.warning(ErrorConstants.Error.CUSTOMIZATION_PENDING_WARNING);
        setDetailsSubmitted(true);
        setIsPending(true);
        return;
      }

      // Check if the currentProductId is in customProductIds array and the customizationStatus is 'accepted'
      const isAnyAccepted = indexes.some(index => customizationStatus[index] === 'Accepted');
      if (isAnyAccepted) {
        setCustomizationInput('');
        setWordCount(0);
        return;
      }
    }

    setCustomizationInput('');
    setWordCount(0);

  };

  const [exceedsLimit, setExceedsLimit] = useState(false);



  useEffect(() => {
    setIsWishlist(localWishlist.some(item => item.id === productId));
  }, [localWishlist, data]);

  useEffect(() => {
    const localWishlistData = localStorage.getItem('wishlist');
    if (localWishlistData) {
      setLocalWishlist(JSON.parse(localWishlistData));
    }
  }, []);

  const handleAddToWishlist = () => {
    const loggedInVendorId = users?.user?.vendorDetails;

    if (loggedInVendorId === vendor) {
      toast.error(ErrorConstants.Error.OWN_PRODUCT_WISHLIST);
      return;
    }

    const updatedLocalWishlist = [...localWishlist, { id: productId }];
    setLocalWishlist(updatedLocalWishlist);

    // Save the local wishlist to local storage
    localStorage.setItem('wishlist', JSON.stringify(updatedLocalWishlist));

    const newData = {
      id: users && users.user && users.user._id,
      pId: productId,
    };

    dispatch(addToWishlist(newData))
      .then((result) => {
        if (addToWishlist.fulfilled.match(result)) {
          setIsWishlist(true);
          toast.success(SuccessConstant.Success.ITEM_ADDED_TO_WISHLIST);
        } else {
          console.error('Error adding product to the wishlist:', result.payload);
        }
      });
  };

  const handleRemoveFromWishlist = () => {
    const updatedLocalWishlist = localWishlist.filter(item => item.id !== productId);
    setLocalWishlist(updatedLocalWishlist);

    // Save the updated local wishlist to local storage
    localStorage.setItem('wishlist', JSON.stringify(updatedLocalWishlist));

    const newData = {
      id: users && users.user && users.user._id,
      pId: productId,
    };

    dispatch(deleteFromWishlist(newData))
      .then((result) => {
        if (deleteFromWishlist.fulfilled.match(result)) {
          setIsWishlist(false);
          toast.success(SuccessConstant.Success.ITEM_REMOVED_FROM_WISHLIST);
        } else {
          console.error('Error removing product from the wishlist:', result.payload);
        }
      });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(file);
      setImageData(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  //NOTE - 
  const handleQuantityChange = (updatedAmount) => {
    setAmount(updatedAmount);
  };

  const handleToggleWishlist = () => {
    if (!users || !users.user || !users.user._id) {
      toast.error(ErrorConstants.Error.LOGIN_WISHLIST_ERROR);
      return;
    }

    if (isWishlist) {
      handleRemoveFromWishlist();
      setIsWishlist(false);
    } else {
      handleAddToWishlist();
      setIsWishlist(true);
    }
  };


  const [selectedSize, setSelectedSize] = useState("");
  const [showSizeChart, setShowSizeChart] = useState(false);
  const [dimensions, setDimensions] = useState("")
  const [ProductName, setProductName] = useState("");
  const [originalPrice, setoriginalPrice] = useState("");
  const [discountPrice, setdiscountPrice] = useState("");
  const [amount, setAmount] = useState(1);
  const [selectedCategory, setselectedCategory] = useState("");
  const [modelSize, setmodelSize] = useState("");
  const [material, setmaterial] = useState("");
  const [internalMaterial, setinternalMaterial] = useState("");
  const [selectedSizes, setselectedSizes] = useState("");
  const [Description, setDescription] = useState("");
  const [ratings, setRatings] = useState("");
  const [vendor, setvendor] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [Sizech, setSizech] = useState("https://static.nike.com/a/images/f_auto/dpr_1.3,cs_srgb/w_706,c_limit/2696715b-0b95-4c43-b43c-ab428a52b436/how-to-measure-your-foot-to-find-the-right-shoe-size.jpg");
  const [Delivery, setDelivery] = useState("Enter the pincode of your area to check product availability and delivery options")
  const [pincode, setPincode] = useState("");
  const [isEligible, setIsEligible] = useState(null);
  const validPincodes = ['452008', '452009', '452010', '452011', '454773'];
  const [Customization, setCustomization] = useState("")
  const [isCustomizing, setIsCustomizing] = useState(false)
  const [showCustomization, setShowCustomization] = useState(false);
  const [active, setActive] = useState(true)
  const [stock, setStock] = useState(0);
  const [verified, setVerified] = useState("");
  const [theEkaivPrice, setTheEkaivPrice] = useState('');
  const [isActive, setIsActive] = useState(null)
  const [endDate, setEndDate] = useState('')
  const [theEkaivDiscountPrice, setTheEkaivDiscountPrice] = useState('');
  const [weight, setWeight] = useState("");



  const toggleCustomization = (e) => {
    e.preventDefault();
    setShowCustomization(!showCustomization);
  };

  useEffect(() => {
    fetch(`${API_URL}/api/Products/getProduct/${productId}`)
      .then((response) => response.json())
      .then((response) => {
        setProductName(response.product.productName);
        setselectedCategory(response.product.selectedCategory);
        setTheEkaivPrice(response.product.theEkaivPrice)
        setTheEkaivDiscountPrice(response.product.theEkaivDiscountPrice)
        setoriginalPrice(response.product.originalPrice);
        setdiscountPrice(response.product.discountPrice);
        setDescription(response.product.description);
        setmodelSize(response.product.modelSize);
        setmaterial(response.product.material);
        setinternalMaterial(response.product.internalMaterial);
        setselectedSizes(response.product.selectedSizes);
        setRatings(response.product.ratings);
        setvendor(response.product.vendorId);
        setCustomization(response.product.customization);
        setSizech(response.product.sizeChartImage);
        setStock(response.product.stock);
        setDimensions(response.product.dimensions);
        setStock(response.product.stock);
        setVerified(response.product.status);
        setWeight(response.product.weight);
        setIsLoading(false)
      }).catch((error) => {


        console.error("error fetching data")

      })
  }, [productId])


  useEffect(() => {
    fetch(`${API_URL}/api/vendor/getSpecificVendorById/${vendor}`)
      .then((response) => response.json())
      .then((response) => {

        setVendorName(response.vendor?.Businessname)
        setIsActive(response.vendor?.activeStore?.isActive)
        setEndDate(new Date(response.vendor?.activeStore?.endDate).toLocaleDateString('en-GB'));

        // setActive(response.vendor.activeStore.isActive)
      }).catch((error) => {

        console.error("error fetching data")
      })
  }, [vendor])


  const calculateSavingAmount = () => {
    if (theEkaivPrice && theEkaivDiscountPrice) {
      const savingAmount = theEkaivPrice - theEkaivDiscountPrice;
      return savingAmount.toFixed(2);
    }
    return 0;
  };


  function formatTextWithBullets(text) {
    if (text) {
      const lines = text.split("\n");
      return (
        <ul style={{ listStyleType: "disc" }}>
          {lines.map((line, index) => (
            <li key={index}>
              {line.trim().endsWith(".") ? line.trim() : line.trim() + "."}
            </li>
          ))}
        </ul>
      );
    } else {
      // Handle the case where text is null or undefined, e.g., return an empty div.
      return <div></div>;
    }
  }

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const toggleSizeChart = () => {
    setShowSizeChart(!showSizeChart);
    if (!showSizeChart) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  const closeSizeChart = () => {
    setShowSizeChart(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    const handleEscapeKeyPress = (event) => {
      if (event.key === "Escape" && showSizeChart) {
        closeSizeChart();
      }
    };

    document.addEventListener("keydown", handleEscapeKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, [showSizeChart]);

  const navigate = useNavigate();

  const handleBuyNow = () => {

    if (!users || !users.user || !users.user._id) {
      toast.error(ErrorConstants.Error.LOGIN_ERROR);
      return;
    }
    const loggedInVendorId = users?.user?.vendorDetails;

    if (loggedInVendorId === vendor) {
      toast.error(ErrorConstants.Error.OWN_PRODUCT_BUY_ERROR);
      return;
    }
    if (
      (selectedCategory === "clothing" ||
        selectedCategory === "Men" ||
        selectedCategory === "kids" ||
        selectedCategory === "Boy" ||
        selectedCategory === "Girl" ||
        selectedCategory === "Women") &&
      !selectedSize
    ) {
      toast.error(ErrorConstants.Error.SIZE_ERROR);
      return
    }


    const newData = {
      id: users?.user?._id,
      pId: productId,
      quantity: amount,
      size: selectedSize || null,
    };

    dispatch(addToCart(newData))
      .then((result) => {

        if (addToCart.fulfilled.match(result)) {

          navigate("/checkout"); // Navigate to the checkout page for these categories

        } else {
          //FIXME - Will fix later for the already in cart
          navigate("/checkout");
        }
      });

  };
  const handleCustomBuyNow = (e) => {
    e.preventDefault();

    const currentProductId = productId;

    // Find the index of the current product in customProductIds array
    const index = customProductIds.indexOf(currentProductId);

    // Check if the currentProductId is in customProductIds array
    if (index !== -1) {
      const currentCustomizationId = customizationId[index];

      const newData = {
        id: users?.user?._id,
        pId: productId,
        quantity: amount,
        size: null,
        customPrice: customizedPrice,
        customPay: true,
        customizationMessage: messageString,
        customizationId: [currentCustomizationId], // Send only the customization ID that matches the current product
      };

      dispatch(addToCart(newData))
        .then((result) => {
          if (addToCart.fulfilled.match(result)) {
            navigate("/checkout");
          } else {
            // FIXME - Will fix later for the already in cart
            navigate("/checkout");
          }
        });
    } else {
      // Handle the case where the currentProductId is not found in customProductIds
      toast.error(ErrorConstants.Error.CUSTOMIZATION_ID_ERROR);
    }
  };



  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!customizationInput || !customizationInput.trim()) {
  //     // If empty or null, show an error toast and return without setting the state
  //     toast.error("Please fill in the customization details first");
  //     return;
  //   }
  //   setCustomizationInput(customizationInput);
  //   setDetailsSubmitted(true);
  //   toast.success("Customization saved successfully");
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!customizationInput || !customizationInput.trim()) {
      // If empty or null, show an error toast and return without setting the state
      toast.error(ErrorConstants.Error.CUSTOMIZATION_FILL_ERROR);
      return;
    }

    // Construct the data to be sent to the backend
    const dataToSend = {
      productId: productId, // Replace with the actual product ID
      customizationMsg: customizationInput,
      imageUrl: imageData
    };



    try {
      const response = await fetch(`${API_URL}/api/user/sendCustomization`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        // Handle success, e.g., show a success toast
        toast.success(SuccessConstant.Success.CUSTOMIZATION_DETAILS_SUCCESS);
        setDetailsSubmitted(true);
        dispatch(getUser());
      } else {
        // Handle error, e.g., show an error toast
        const errorData = await response.json();
        toast.error(`Error: ${errorData.message || ErrorConstants.Error.CUSTOMIZATION_ALREADY_EXIXT_ERROR}`);
        setDetailsSubmitted(true);
      }
    } catch (error) {
      // Handle unexpected errors, e.g., show an error toast
      console.error('Error:', error);

      toast.error(ErrorConstants.Error.UNEXPECTED_ERROR);
    }
  };

  const handleEdit = () => {
    // Reset the customization details on edit
    setDetailsSubmitted(false);
    setCustomizationInput("");
  };


  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  const getCategoryName = () => {
    if (selectedCategory === 'Women') {
      return 'Women';
    } else if (selectedCategory === 'Men') {
      return 'Men';
    } else if (selectedCategory === 'kids') {
      return 'Kids';
    } else if (selectedCategory === 'home-decor') {
      return 'Home Decor';
    } else if (selectedCategory === 'accessories') {
      return 'Accessories';
    } else if (selectedCategory === 'Boy') {
      return `Boys`;
    } else if (selectedCategory === 'Girl') {
      return `Girls`;
    } else if (selectedCategory === 'homemade-food') {
      return 'Homemade Food';
    } else {
      return selectedCategory;
    }
  };

  const checkDeliveryEligibility = () => {
    if (validPincodes.includes(pincode)) {
      setDelivery("This pincode is eligible for delivery."); // Update the delivery status message
      setIsEligible(true);
    } else {
      setDelivery("Sorry, this pincode is not eligible for delivery."); // Update the delivery status message
      setIsEligible(false);
    }
  };
  const handlePincodeChange = (e) => {
    let newValue = e.target.value.trim(); // Trim any whitespace

    // Update the pincode state only if the entered value is not empty
    if (newValue.length > 0) {
      setPincode(newValue);
    } else {
      setPincode(""); // Reset the pincode if empty
      setIsEligible(null); // Reset the eligibility status
    }
  };


  //customiztion input field validations
  const handleCustomizationInput = (e) => {
    const inputValue = e.target.value;
    if (inputValue.trim() === "") {
      setExceedsLimit(false);
      setWordCount(0);
      setCustomizationInput("");
      return;
    }
    const words = inputValue.trim().split(/\s+/);
    const wordCount = words.length;

    // Check if the word count exceeds the limit (in this case, 10)
    if (wordCount > 150 || (wordCount === 150 && inputValue.endsWith(" "))) {
      // If the limit is exceeded, do not accept any more input
      setExceedsLimit(true);
      setCustomizationInput(inputValue.slice(0, -1));
      return;
    } else {
      // If the limit is not exceeded, set exceedsLimit to false
      setExceedsLimit(false);
    }

    // Check if the input contains only valid characters (letters, numbers, etc.)
    const isValidInput = /^[a-zA-Z0-9\s]+$/.test(inputValue);

    if (!isValidInput) {
      // If input contains invalid characters, do not accept any more input
      return;
    }

    // Accept input up to the limit of 10 words with valid characters
    setWordCount(wordCount);
    setCustomizationInput(inputValue);
  };


  return (
    <>
      <div className="flex flex-col gap-2 w-full h-max-[400px] p-4 border rounded-sm mb-4">

        {isloading ?
          <div className="flex justify-center items-center w-full h-max-[400px] p-4 border rounded-sm mb-4">

            <Loader />
          </div>
          :
          <>
            <div>
              <span className=" text-[#81848f]">
                The Ekaiv's Special {getCategoryName()}
              </span>
              <h1 className="text-xl  text-violet-600 ">{ProductName}</h1>
            </div>
            <hr className="my-2 border-gray-300" />

            <div className="flex sm:flex-row flex-row items-center mb-4 justify-between">

              <div className="flex flex-row items-center text-2xl">
                {verified === 'Pending' || verified === 'Rejected' ? (
                  <span className="text-yellow-500 mr-5">
                    {verified}
                  </span>
                ) : (
                  <>
                    <span className="text-[#333] font-Roboto mr-5">
                      ₹{theEkaivPrice === 0 ? theEkaivPrice : theEkaivDiscountPrice}
                    </span>
                    <span className="text-xl text-[gray] mr-1">MRP</span>
                    <h4 className="text-xl text-[gray] line-through">
                      {theEkaivPrice ? "₹" + theEkaivPrice : null}
                    </h4>
                    {theEkaivPrice && theEkaivDiscountPrice && (
                      <p className="text-green-500 text-lg ml-3">
                        {`(Rs.${calculateSavingAmount()} OFF)`}
                      </p>
                    )}
                  </>
                )}
              </div>
              {verified !== "Rejected" && verified !== "Pending" && (
                <>
                  {isWishlist ? (
                    <AiFillHeart
                      size={30}
                      className="cursor-pointer"
                      style={{ color: 'red' }}
                      title="Remove from wishlist"
                      onClick={handleToggleWishlist}
                    />
                  ) : (
                    <AiOutlineHeart
                      size={22}
                      className="cursor-pointer"
                      style={{ color: 'red' }}
                      title="Add to wishlist"
                      onClick={handleToggleWishlist}
                    />
                  )}
                </>
              )}

            </div>

            {/* Quantity */}
            {isActive !== null ? (
              <>
                {isActive ? (
                  <>
                    {stock === 0 ? (
                      <p className='text-red-500 flex  text-xl mr-2 mt-4'>OUT OF STOCK</p>
                    ) : (
                      <>
                        <div className="flex md:flex-row sm:flex-col flex-col ">
                          <h2 className="flex  text-xl mr-2 mt-4">Quantity : </h2>

                          <div className="flex md:flex-row flex-row items-center lg:text-xl md:text-xl sm:text-md text-md">
                            <button
                              className="flex bg-gray-200 py-2 px-5 text-2xl rounded-lg text-violet-800"
                              onClick={() => {
                                setAmount((prev) => {
                                  if (prev <= 1) {
                                    toast.error(ErrorConstants.Error.QUANTITY_ERROR)
                                    return 1;
                                  }
                                  const updatedAmount = prev - 1;
                                  handleQuantityChange(updatedAmount);
                                  return updatedAmount;
                                });
                              }}
                            >
                              -
                            </button>

                            <span className="flex py-4 px-6 rounded-lg md:text-xl sm:text-md text-md">
                              {amount}
                            </span>
                            <button
                              className="flex bg-gray-200 py-2 px-4 rounded-lg text-violet-800"
                              onClick={() => {
                                setAmount((prev) => {
                                  if (prev >= stock) {
                                    toast.error(ErrorConstants.Error.LIMIT_ERROR)
                                    return prev;
                                  }
                                  const updatedAmount = prev + 1;
                                  handleQuantityChange(updatedAmount); // Pass the updated amount to the function
                                  return updatedAmount;
                                });
                              }}
                            >
                              +
                            </button>

                          </div>

                        </div>

                        {(
                          selectedCategory === "Men" ||
                          selectedCategory === "Boy" ||
                          selectedCategory === "Girl" ||
                          selectedCategory === "kids" ||
                          selectedCategory === "Women" ||
                          selectedCategory === "clothing") && (
                            <div className="w-full mt-4 text-lg font-semibold ">
                              <div className="flex justify-between">
                                {selectedCategory === "kids" || selectedCategory === 'Boy' || selectedCategory === 'Girl' ? (
                                  <p>
                                    Select Age
                                  </p>
                                ) : (
                                  <p>
                                    {(selectedCategory === "clothing" || selectedCategory === "Men" || selectedCategory === "Women") && (
                                      <p>
                                        Select Size
                                      </p>
                                    )}
                                  </p>
                                )}
                                {(selectedCategory === "clothing" || selectedCategory === "Men" || selectedCategory === "Women" || selectedCategory === "kids" || selectedCategory === "Boy" || selectedCategory === "Girl") && (
                                  <button
                                    className="px-10 text-[#8A58DC] hover:text-[#F16080]"
                                    onClick={toggleSizeChart}
                                  >
                                    Size Chart
                                  </button>
                                )}
                              </div>

                              <div className="mt-2">
                                {selectedSizes ? (
                                  selectedSizes.map((size) => (
                                    <label
                                      key={size}
                                      className="inline-flex items-center space-x-2 p-2"
                                    >
                                      <input
                                        type="radio"
                                        value={size}
                                        checked={selectedSize === size}
                                        onChange={handleSizeChange}
                                        className="form-radio cursor-pointer h-4 w-4"
                                      />
                                      <span>{size}</span>
                                    </label>
                                  ))
                                ) : (
                                  <p>Loading sizes...</p>
                                )}
                              </div>
                            </div>
                          )}
                        {(
                          selectedCategory === "accessories" ||
                          selectedCategory === "home-decor" || selectedCategory === "other"
                        ) && (
                            <div className="mt-2">
                              <p>Dimensions: {dimensions}</p>
                            </div>
                          )}
                        {showSizeChart && (
                          <>
                            <div className="fixed inset-0 flex items-center justify-center z-50">
                              <div className="bg-white p-5 rounded-lg shadow-lg relative">
                                <button
                                  className="absolute top-2 right-2 text-[#8A58DC] font-thin hover:text-[#F16080]"
                                  onClick={toggleSizeChart}
                                >
                                  <RxCross2 />
                                </button>
                                {Sizech ? (
                                  <img className="h-96" src={Sizech} alt="Size Chart" />
                                ) : (
                                  <p>No size chart available</p>
                                )}
                              </div>
                            </div>
                            <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
                          </>
                        )}

                        {Customization === 'yes' && (
                          <div className="mb-4">
                            <form>
                              <h2 className="font-bold text-md mr-2 mt-4">
                                This product can be customized. Do you want to customize it?
                              </h2>
                              <div className="flex md:flex-row sm:flex-col flex-col gap-4 mb-4 ml-2">
                                <label className="font-semibold text-xl mr-2 mt-4 cursor-pointer">
                                  <input
                                    type="radio"
                                    name="customization"
                                    value="yes"
                                    onChange={handleCustomizationChange}
                                    checked={isCustomizing}
                                    className="h-4 w-4 cursor-pointer"
                                  />
                                  {' '}
                                  Yes
                                </label>
                                <label className="font-bold text-xl mr-2 mt-4 cursor-pointer">
                                  <input
                                    type="radio"
                                    name="customization"
                                    value="no"
                                    onChange={handleCustomizationChange}
                                    checked={!isCustomizing}
                                    className="h-4 w-4 cursor-pointer"
                                  />
                                  {' '}
                                  No
                                </label>
                              </div>
                              {isCustomizing && !detailsSubmitted ? (
                                <div className="flex flex-col">
                                  <InputField
                                    placeholder="Please provide the appropriate details for the customization."
                                    type="text"
                                    name="customization"
                                    id="customization"
                                    value={customizationInput}
                                    onChange={handleCustomizationInput}
                                    onClick={stopPropagation}
                                    disabled={detailsSubmitted}
                                  />
                                  <p className={`${exceedsLimit ? 'text-red-500' : 'text-gray-500'} ml-1 mb-4 text-sm`}>
                                    {exceedsLimit ? 'Word limit reached (150 words max)' : `${wordCount}/150 words`}
                                  </p>
                                  <div className="p-1 felx flex-col gap-2 mb-5">
                                    <p className="text-gray-500">Please add image according to yours customization dezires:</p>
                                    <input type="file" accept="image/*" onChange={handleImageChange} required />
                                    {imageData && (
                                      <img src={imageData} alt="Preview" className="h-20 w-20 mt-2 object-cover object-center" />
                                    )}
                                  </div>
                                  <div>
                                    <Button
                                      style="primary"
                                      onClick={handleSubmit}
                                      type="submit"
                                    >
                                      Submit
                                    </Button>
                                    {detailsSubmitted && (
                                      <Button
                                        onClick={handleEdit}
                                        style="primary"
                                        type="submit"
                                      >
                                        Edit
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              ) : detailsSubmitted && !isPending ? (
                                <div>
                                  <div className="mt-4 p-4 bg-green-100 border border-green-300 rounded-md">
                                    <div className="flex justify-start items-center mb-4">
                                      <svg className="w-6 h-6 text-green-500 inline-block mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                      </svg>
                                      <span className="text-green-500 inline-block font-semibold">
                                        Great news!
                                      </span>
                                    </div>
                                    <div className="flex flex-col gap-1 justify-start items-start">
                                      <span className="text-gray-600 ml-2">
                                        Your customization request has been successfully sent.
                                      </span>
                                      <span className="text-gray-600 ml-2">
                                        Please patiently await the seller's reply. 🌟
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : detailsSubmitted && isPending && (
                                <div>
                                  <div className="mt-4 ml-2 p-4 bg-yellow-100 border border-yellow-300 rounded-md">
                                    <span className="text-yellow-500 ml-2 inline-block font-semibold">
                                      Your product customization is pending.
                                    </span>
                                    <div className="flex flex-col gap-1 justify-start items-start">
                                      <span className="text-gray-600 ml-2">
                                        Please wait for the customization to be processed.
                                      </span>
                                      <span className="text-gray-600 ml-2">
                                        You will be notified once it is completed.
                                      </span>
                                    </div>
                                  </div>
                                  <div className="p-2">
                                    <div className="flex flex-row items-center gap-2 mt-4">

                                      <Button
                                        onClick={toggleCustomization}
                                        style="primary"                                  >
                                        {showCustomization ? 'Hide Customization Request' : 'Show Customization Request'}
                                      </Button>

                                    </div>
                                    {console.log("kjsdbf", users.user)}
                                    {showCustomization && (
                                      <>
                                        <div className="flex flex-col bg-gray-200 p-2 pb-5 mt-4 rounded-md w-full">
                                          <div className="flex justify-end">
                                            <div className="mt-4 p-2 w-fit bg-gray-100 border border-gray-400 rounded-md">
                                              <p className="text-black flex justify-end ml-2">
                                                {messageString}
                                              </p>
                                            </div>
                                          </div>
                                          {filteredVendorApproval && vendorResponse && pendingVendorResponses ? (
                                            <div className="flex justify-start">
                                              <div className="mt-4 p-2 w-fit bg-gray-300 border border-gray-400 rounded-md">
                                                <p className="text-black flex justify-start mr-2">
                                                  {pendingVendorResponses}
                                                </p>
                                              </div>
                                            </div>
                                          ) : (
                                            null
                                          )
                                          }
                                        </div>
                                        {filteredVendorApproval && customizedPrice && filteredVendorApproval ? (
                                          <>
                                            <div className="flex flex-col">
                                              <div className="flex justify-end mt-4">
                                                <Button title="Pay Customization Price" onClick={handleCustomBuyNow} style="secondary" variant="customizedPrices">
                                                  ₹{pendingCustomizedPrices}
                                                </Button>
                                              </div>
                                              <div className="p-2 flex flex-col mt-2 text-sm">
                                                <p className="text-center">Click on "Green" button if you want to purchase this product with customization.</p>
                                                <p className="text-center">or</p>
                                                <p className="text-center">Click on "Buy it now" button if you want to purchase this product without customization.</p>
                                              </div>
                                            </div>
                                          </>
                                        ) : null}
                                      </>
                                    )}
                                  </div>
                                </div>
                              )}
                            </form>
                          </div>
                        )}

                        {verified === "Rejected" ? (
                          <span className="text-2xl text-red-500 text-center">This product is Rejected</span>
                        ) : verified === "Pending" ? (
                          <span className="text-2xl text-yellow-500 text-center">Product approval is pending...</span>
                        ) : (
                          <div className="flex lg:flex-row gap-2 md:flex-col flex-col items-center">
                            <Button style="secondary" variant="addToCart" onClick={handleAddToCart}>
                              Add to Cart
                            </Button>

                            <Button style="secondary" variant="buyNow" onClick={handleBuyNow}>
                              Buy it now
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <span className="text-2xl text-yellow-500 text-center">This vendor is currently inactive. Please check back again after {endDate}.</span>
                )}
              </>
            ) : (
              <> </>
            )}


          </>}
      </div>

      <ToastContainer position="top-center" autoClose={1000} hideProgressBar />


      {(selectedCategory === "clothing" || selectedCategory === "Men" || selectedCategory === "Women" || selectedCategory === "kids" || selectedCategory === "Boy" || selectedCategory === "Girl") && (
        <ExpandableDiv
          title="Model Details"
          content={
            <div className="text-md">Model size is {modelSize && modelSize.toUpperCase()}.</div>
          }
        />
      )}


      <ExpandableDiv
        title="Product Details"
        content={
          <div className="p-7">{formatTextWithBullets(Description)}
            <span className="text-md font-semibold">Product Overall Weight : </span> {weight}</div>
        }
      />

      <ExpandableDiv
        title="Know Your Product"
        content={
          <div>

            {selectedCategory === 'homemade-food' ? (
              <p>
                Ingredients: {material}
              </p>
            ) : (
              <p>
                {selectedCategory === 'Men' ||
                  selectedCategory === 'kids' ||
                  selectedCategory === 'Girl' ||
                  selectedCategory === 'Boy' ||
                  selectedCategory === 'Women'
                  ? 'Fabric'
                  : 'Material'
                }: {material}
              </p>
            )}
            {selectedCategory !== 'accessories' && selectedCategory !== 'other' && selectedCategory !== 'home-decor' && (
              <p>{selectedCategory === 'homemade-food' ? ' ' : 'Inner Fabric :'} {internalMaterial}</p>
            )}
          </div>
        }
      />

      <ExpandableDiv
        title="Vendor Details"
        content={
          <div>
            <p className=" text-gray-400">Fulfilled By</p>

            <h1 className=" underline underline-offset-2 mb-3">THE EKAIV</h1>

            <p className=" text-gray-400">
              Name Of Seller
            </p>

            <p className='mb-3'>{vendorName}</p>

            <p className=" text-gray-400">Country</p>
            <p>India</p>

            <Link
              to={{
                pathname: `/seller-profile/${vendor}`,
              }}
            >

              <button className="mt-4 bg-[#8A58DC] text-white py-2 px-4 rounded-md" onClick={() => window.scrollTo(0, 0)}>
                {" "}
                Seller's Profile
              </button>
            </Link>


          </div>
        }
      />

      <ExpandableDiv
        title="Return and Exchange Policy"
        content={
          <div>
            This product is not eligible for returns or replacement. Please note
            that once purchased, this item cannot be returned or exchanged.
            Kindly ensure that you are certain about your purchase before
            proceeding.
          </div>
        }
      />

      {/* Ratings */}
      <ExpandableDiv
        title="Ratings and Reviews"
        content={
          <div className="w-full p-3 text-lg  bg-[#FCFCFC] mb-3 border rounded-sm">
            <div className=" mx-auto rounded-sm">

              <div className="sm:flex items-center pl-3 gap-x-5">
                {/* Ratings */}
                <div className=" flex flex-col px-4 text-center py-3 sm:mb-0 mb-2 ">
                  <h1 className="text-4xl font-[600] text-[#343246] ">
                    {ratings}{" "}
                    <span className="text-yellow-500" role="img" aria-label="star">
                      &#9733;
                    </span>
                  </h1>
                  <p className="text-2xl text-gray-500">
                    {ratings !== null ? `${ratings} Ratings` : "No Ratings"}
                  </p>
                </div>

                <div>
                  <Rating
                    name="read-only"
                    value={ratings}
                    readOnly
                    className="flex w-full justify-center"
                  />
                </div>
              </div>
              <div className="w-full border-t border-gray-300 my-4"></div>
              <div className="mt-5">

                <ProductsComments productId={productId} user={users} />
              </div>
            </div>
          </div>
        }
      />

    </>
  );
}
