import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL, config } from '../../../config/config';
import { RxCross2 } from "react-icons/rx";
import { IoIosArrowDown } from "react-icons/io";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../common/Loader';
import SuccessConstant from '../../../constants/SuccessConstant';
import ErrorConstants from '../../../constants/ErrorConstants';

const OurOrders = () => {
    const [customizationRequests, setCustomizationRequests] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [vendorResponse, setVendorResponse] = useState('');
    const [customizedPrice, setCustomizedPrice] = useState('');
    const [approval, setApproval] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const [acceptedCustomizations, setAcceptedCustomizations] = useState([]);
    const userIdsArray = [];
    const [loading, setLoading] = useState(true);
    customizationRequests.forEach((data) => {
        data.customization.forEach((product) => {
            // Store userId in an array
            userIdsArray.push(product.userId);
        });
    });
    // get users with cutomization request
    useEffect(() => {
        const getData = async () => {
            await axios.get(`${API_URL}/api/admin-profile/getUsersWithCustomization`, config)
                .then((response) => {
                    setCustomizationRequests(response.data.data);
                    console.log("ksabfsaf", response.data.data);
                    const customizationData = response.data.data;


                    const vendorApprovalValues = customizationData.map(user =>
                        user.customization.map(customization => customization.vendorApproval)
                    ).flat();



                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                });
        }
        getData();
    }, []);



    const handleApproveClick = (product) => {
        setSelectedProduct(product);
        setShowPopup(true);
    }

    const handlePopupClose = () => {
        setSelectedProduct(null);
        setShowPopup(false);
        setVendorResponse(''); // Clear the input field for vendor response
        setCustomizedPrice(''); // Clear the input field for customized price
    }


    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        // Check if the input contains only numeric characters
        if (/^[0-9]*$/.test(inputValue) || inputValue === '') {
            setCustomizedPrice(inputValue);
        }
    };

    const handleExpansionToggle = () => {
        setIsExpanded(!isExpanded);
    }
    const token = JSON.parse(localStorage.getItem('token'));

    const sendCustomizationResponse = async () => {
        try {
            const response = await axios.post(
                `${API_URL}/api/admin-profile/saveCustomizedPrice`,
                {
                    customizationId: selectedProduct.customizationId,
                    customizedPrice: customizedPrice,
                    vendorResponse: vendorResponse,
                    userId: selectedProduct.userId,
                    vendorApproval: true,
                }, config
            );
            const responseData = response.data;
            if (responseData.success) {
                // Show different toasts based on input provided
                if (vendorResponse && customizedPrice) {
                    // Both fields are filled
                    toast.success(SuccessConstant.Success.PRICE_UPDATE_SUCCESS);
                } else if (vendorResponse) {
                    // Only vendor response is filled
                    toast.success(SuccessConstant.Success.VENDOR_RESPONSE_SUCCESS);
                } else if (customizedPrice) {
                    // Only customized price is filled
                    toast.success(SuccessConstant.Success.PRICE_SUCCESS);
                }

                // Update accepted customizations without resetting
                setAcceptedCustomizations((prevAccepted) => [...prevAccepted, selectedProduct.customizationId]);
            } else {
                console.error(responseData.error);
                toast.error(responseData.error);
                // Handle error, if needed
            }
        } catch (error) {
            console.error('Error while calling the API:', error);
            toast.error(ErrorConstants.Error.ERROR);
        }
    };
    const handleFormSubmit = () => {
        // Check if at least one of the fields is not empty
        if (vendorResponse.trim() || customizedPrice.trim()) {
            // Call the function to send the responseMessage and customizedPrice
            sendCustomizationResponse();

            // Reset state values
            setVendorResponse('');
            setCustomizedPrice('');

            // Close the popup
            setShowPopup(false);
        } else {
            // Handle validation error
            console.error('At least one field (response message or customized price) must be filled');
            toast.error(ErrorConstants.Error.ONE_FEILD_ERROR);
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={3000} />
            {loading ? (
                // Display loader while fetching data
                <div className="flex justify-center items-center h-screen">
                    <Loader />
                </div>
            ) :
                customizationRequests.length === 0 ? (
                    <div className='flex flex-col justify-center items-center p-5 gap-5 mt-10' >
                        <img className=' h-[350px] w-auto' src="https://img.freepik.com/free-vector/removing-goods-from-basket-refusing-purchase-changing-decision-item-deletion-emptying-trash-online-shopping-app-laptop-user-cartoon-character-vector-isolated-concept-metaphor-illustration_335657-2843.jpg?w=740&t=st=1708551228~exp=1708551828~hmac=dd3909620e24b4668bb4d398873d8fe8588e43294820f5a404fe7f9b97dd6d4b" alt='no orders img' />
                        <p className='font-bold text-2xl text-[#8A58DC]'>No Orders Available for Customization</p>
                    </div>
                ) : (
                    <div className='w-full h-auto p-5'>
                        <div className='flex flex-row gap-5 mb-5 font-bold text-2xl justify-center items-center text-[#8A58DC] whitespace-nowrap cursor-pointer' onClick={handleExpansionToggle}>
                            <p>Customization Requests from the users</p>
                            <IoIosArrowDown className={`transition-transform duration-300 transform ${isExpanded ? 'rotate-180' : ''}`} />
                        </div>
                        {isExpanded && (
                            <div className='overflow-x-auto'>
                                <table className='w-full border rounded-lg bg-white'>
                                    <thead>
                                        <tr className='flex font-poppins font-medium p-2 justify-between border-b'>
                                            <th className='w-1/6 border-r'>Product ID</th>
                                            <th className='w-2/6 border-r'>Product Name</th>
                                            <th className='w-2/6 border-r'>Customer Name</th>
                                            <th className='w-2/6 border-r'>Customer Email</th>
                                            <th className='w-2/6 border-r'>Customer Phone</th>
                                            <th className='w-2/6 border-r'>Customization Message</th>
                                            <th className='w-1/6 border-r'>Cutomization Image</th>
                                            <th className='w-2/6 border-r'>Vendor Name</th>
                                            <th className='w-2/6 border-r'>Vendor Business Name</th>
                                            <th className='w-2/6 border-r'>Vendor Details</th>
                                            <th className='w-2/6'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className='bg-gray-100'>
                                        {customizationRequests?.map((data) => (
                                            <React.Fragment key={data.productId}>
                                                {data?.customization?.map((product, index) => (
                                                    <tr key={index} className='flex font-poppins font-medium p-2 justify-between border-b'>
                                                        <td className='w-1/6 border-r'>{product.productDetails.productId}</td>
                                                        <td className='w-2/6 border-r'>{product.productDetails.name}</td>
                                                        <td className='w-2/6 border-r'>{data.fullname}</td>
                                                        <td className='w-2/6 border-r'>{data.email}</td>
                                                        <td className='w-2/6 border-r'>{data.phone}</td>
                                                        <td className='w-2/6 border-r'>
                                                            {product?.customizationMsg && product?.customizationMsg?.length > 20 ? `${product?.customizationMsg?.slice(0, 50)}...` : product?.customizationMsg}
                                                        </td>
                                                        <td className="w-1/6 border-r">
                                                            {product.imageUrl && product.imageUrl.url ? (
                                                                <img src={product.imageUrl.url} alt="Product Customization" className="h-48 w-auto rounded-lg" />
                                                            ) : (
                                                                'No Image Available'
                                                            )}
                                                        </td>
                                                        <td className='w-2/6 border-r'>{product.vendorDetails.businessName}</td>
                                                        <td className='w-2/6 border-r'>{product.vendorDetails.businessEmail}</td>
                                                        <td className='w-2/6 border-r'>{product.vendorDetails.phoneNumber}</td>
                                                        <td className='w-2/6'>
                                                            {acceptedCustomizations.includes(product._id) || product.vendorApproval ? (
                                                                <span className="text-green-600 font-semibold">Accepted</span>
                                                            ) : (
                                                                <button
                                                                    className={`bg-[#8A58DC] p-2 w-20 font-sm text-white rounded-lg border-none font-semibold`}
                                                                    type="button"
                                                                    onClick={() => handleApproveClick({ productId: product.productDetails.productId, customizationId: product._id, userId: product.userId })}
                                                                >
                                                                    View
                                                                </button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {selectedProduct && showPopup && (
                            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center p-5 bg-opacity-50 bg-gray-800 ">
                                <div className="bg-gray-200 h-max flex flex-col rounded-lg p-5 relative">
                                    <span className='font-semibold text-center text-xl pb-2 mb-4'>Customization Response</span>
                                    {/* Cross icon */}
                                    <button
                                        className="text-[#8A58DC] font-thin hover:text-[#F16080] absolute top-2 right-2"
                                        onClick={handlePopupClose}
                                    >
                                        <RxCross2 />
                                    </button>
                                    {/* Popup content */}
                                    <div className="flex flex-col gap-5">
                                        <div className='w-[650px]'>
                                            <label className='text-lg'>Customization Message :</label>
                                            <div className='flex border border-gray-500 bg-gray-300 rounded-md' >
                                                {customizationRequests.map((data) => (
                                                    <React.Fragment key={data.productId}>
                                                        {data.customization
                                                            .filter((product) => product.productDetails.productId === selectedProduct.productId)
                                                            .map((product, index) => (
                                                                <div key={index} className='ml-2 p-2'>
                                                                    {product._id === selectedProduct.customizationId && (
                                                                        <>
                                                                            {product.customizationMsg}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            ))}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>

                                        <div className='flex flex-row gap-5'>
                                            <input type="text"
                                                placeholder='Enter response message here'
                                                maxLength={500}
                                                className="border border-gray-300 w-full outline-[#8A58DC] rounded-md p-2"
                                                value={vendorResponse}
                                                onChange={(e) => setVendorResponse(e.target.value)}
                                            />
                                        </div>
                                        <div className='flex flex-row gap-5'>
                                            <input
                                                type="text"
                                                maxLength={8}
                                                placeholder='Enter updated price here'
                                                className="border border-gray-300 w-full outline-[#8A58DC] rounded-md p-2"
                                                value={customizedPrice}
                                                onChange={handleInputChange}
                                            />

                                        </div>
                                        <button className="bg-[#8A58DC] hover:opacity-90 p-2 font-sm text-white rounded-lg border-none font-semibold"
                                            onClick={handleFormSubmit}
                                        >Submit</button>
                                    </div>

                                </div>
                            </div>

                        )}

                    </div>
                )}
        </>
    );
}

export default OurOrders;


