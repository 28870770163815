import React from 'react'
import BrandingTag from '../component/Home/BrandingTag'
import Instagram from '../component/Home/Instagram'

function Help() {
  return (
    <>
    <Instagram />
    <BrandingTag />
    </>
  )
}

export default Help