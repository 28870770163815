import React from "react";
import { Link } from "react-router-dom";

function VendorDashboardMobileCard({
  order,
  productDetail,
  formatDate,
  scrollTo,
  handleStatusChange,
  showProcessButton,
  changeStatus
}) {
  return (
    <div>
      <div className="bg-white p-3 rounded-md">
        <div className="flex flex-row gap-2">
          <div className="text-xs font-bold w-[60%]">
            Customer: {order?.shippingAddress?.fullname}
          </div>
          <div className="text-[10px] text-[#A9A9A9] w-[40%]">
            {formatDate(order.createdAt)}
          </div>
        </div>
        <div className="mt-2 border-t pt-2">
          <div className="grid grid-cols-2">
            <div>
              {/* <p className='text-xs font-bold'>Order Id:</p> */}
              <p className="text-xs font-bold">
                Price:{" "}
                <span className="text-xs text-[#A9A9A9]">
                  {productDetail.price * productDetail.quantity}
                </span>
              </p>
              <p className="text-xs font-bold">
                Quantity:{" "}
                <span className="text-xs text-[#A9A9A9]">
                  {productDetail.quantity}
                </span>
              </p>
            </div>
            <div className="flex justify-center items-center">
              <Link
                to={{
                  pathname: `/SpecificProductsPage/${productDetail.additionalInfo?.product?._id}`,
                  state: {
                    productId: productDetail.additionalInfo?.product?._id,
                  },
                }}
                target="_blank"
                onClick={scrollTo}
              >
                {productDetail.additionalInfo?.product?.images?.[0] && (
                  <img
                    src={productDetail.additionalInfo.product.images[0]}
                    alt="Product image"
                    className="w-16 h-16 md:w-24 md:h-24 object-fill"
                  />
                )}
              </Link>
            </div>
          </div>
          <div className="flex justify-center items-center mt-2">
            <div className="w-full">
              <button className="bg-orange-100 sm:w-28 sm:h-10 rounded-md sm:rounded-2xl text-xs font-bold w-20 h-6 text-orange-600">
                {order.orderStatus}
              </button>
            </div>
          
          {showProcessButton && (
            <div className="flex justify-center items-center mt-2">
              <div className="w-full">
                <button
                  onClick={() => handleStatusChange(order._id, changeStatus)}
                  className="bg-orange-400 text-white text-xs font-bold w-20 h-6 rounded-[6px]"
                >
                  {changeStatus}
                </button>
              </div>
            </div>
          )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorDashboardMobileCard;
