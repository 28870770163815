import React, { useEffect, useState, useRef } from "react";
import { RxCross2 } from "react-icons/rx";
import {getVendor,updateVendorPersonal} from "../../../Redux/features/vendor/vendorSlices";
import { toast } from "react-hot-toast";
import axios from "axios";
import { API_URL} from "../../../config/config";
import ErrorConstants from "../../../constants/ErrorConstants";
import SuccessConstant from "../../../constants/SuccessConstant";
import { useSelector, useDispatch } from "react-redux";

function PersonalDetailsSettings() {
  const dispatch = useDispatch();
  const vendor = useSelector((state) => state.vendor);
  const vendorId = vendor?.vendor?._id;
  
  const [generalEditMode, setGeneralEditMode] = useState(false);
  const { PersonalDetails } = vendor?.vendor || {};
  const { name: vendorName, email:vendorEmail, phoneNumber, alternateNumber, gender:vendorGender } = PersonalDetails || {};

const [name, setName] = useState(vendorName || '');
const [email, setEmail] = useState(vendorEmail || '');
const [mobile, setMobile] = useState(phoneNumber || '');
const [altMobile, setAltMobile] = useState(alternateNumber || '');

const [altNewMobileNumber, setAltNewMobileNumber] = useState("");
const [showOtpPopup, setShowOtpPopup] = useState(false);
const [otpInput1, setOtpInput1] = useState("");
const [otpInput2, setOtpInput2] = useState("");
const [otpSent, setOtpSent] = useState(false);

const [gender, setGender] = useState(vendorGender || '');
  const [emailEditMode, setEmailEditMode] = useState(false);
  const [mobileEditMode, setMobileEditMode] = useState(false);
  const [altMobileEditMode, setAltMobileEditMode] = useState(true);
  const [otpInputVisible, setOtpInputVisible] = useState(false);
  const [newMobileNumber, setNewMobileNumber] = useState("");
 
  const [otp, setOtp] = useState("");
  

  const [dob, setDob] = useState(() => {
    if (
      vendor &&
      vendor.vendor &&
      vendor.vendor.PersonalDetails &&
      vendor.vendor.PersonalDetails.dateOfBirth
    ) {
      const rawDate = vendor.vendor.PersonalDetails.dateOfBirth;
      const parsedDate = new Date(rawDate);
      const formattedDate = parsedDate.toISOString().split("T")[0];
      return formattedDate;
    }
    return "";
  });
  const handleDobChange = (event) => {
    setDob(event.target.value);
  };
  useEffect(() => {
    setName(vendorName || '');
  }, [vendorName]);

  useEffect(() => {
    setGender(vendorGender || '')
  }, [vendorGender])
  
  const handleGeneralSaveClick = () => {
    let hasError = false;
    if (mobileEditMode && !otpSent) {
      toast.error(ErrorConstants.Error.PHONE_ERROR);
      return;
    }

    // Check if all required fields are filled
    if (!name || !email || !mobile || !altMobile || !dob || !gender) {
      toast.error(ErrorConstants.Error.ALL_FEILD_ERROR);
      return;
    }
    if (!hasError) {
    const updatedPersonalDetails = {
      name : name,
      gender : gender,
      dateOfBirth : dob,
     
    }
    dispatch(updateVendorPersonal(updatedPersonalDetails))
    setGeneralEditMode(false);
    setMobileEditMode(false);
    toast.success(SuccessConstant.Success.DETAILS_UPDATED);
  }
  };

  
  const handleAltMobileInputChange = (e) => {
    // Allow only digits in the alternate mobile number input
    const sanitizedValue = e.target.value.replace(/\D/g, ""); // Replace non-digit characters with an empty string
    setAltNewMobileNumber(sanitizedValue);
  };
  
  const handleAltMobileSendOTP = async () => {
    if (!altNewMobileNumber) {
      toast.error(ErrorConstants.Error.ALT_PHONE_EMPTY_ERROR);
      return;
    }

    setOtpInputVisible(true);
    setShowOtpPopup(true);

    try {
      // Call your API to send OTP to the existing mobile number
      const response1 = await axios.post(`${API_URL}/api/otp-verfication/send-number-otp`, { mobile: altMobile });

      if (response1.data.success) {
        toast.success(SuccessConstant.Success.OTP_SENT_SUCCESS);
      } else {
        toast.error(ErrorConstants.Error.OTP_EXISTING_ERROR);
      }

      
        const response2 = await axios.post(`${API_URL}/api/otp-verfication/send-number-otp`,{ mobile: altNewMobileNumber });

        if (response2.data.success) {
          toast.success(
            SuccessConstant.Success.VendorProfile.OTP_SENT_NEW_SUCCESS
          );
          setOtpSent(true);
        } else {
          toast.error(ErrorConstants.Error.OTP_NEW_ERROR);
        }
      
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error(ErrorConstants.Error.OTP_FAIL_ERROR);
    }
  };

  const handleOtpInputChange1 = (e) => {
    // Allow only digits in the first OTP input
    const sanitizedValue = e.target.value.replace(/\D/g, ""); // Replace non-digit characters with an empty string
    setOtpInput1(sanitizedValue.slice(0, 6)); // Limit the length to 6 digits
  };

  const handleOtpInputChange2 = (e) => {
    // Allow only digits in the second OTP input
    const sanitizedValue = e.target.value.replace(/\D/g, ""); // Replace non-digit characters with an empty string
    setOtpInput2(sanitizedValue.slice(0, 6)); // Limit the length to 6 digits
  };

  const handleOtpSubmit = async () => {
    try {
      const response1 = await axios.post(`${API_URL}/api/otp-verfication/verify-otp`, { mobile: altMobile, otp: otpInput1 });
      const response2 = await axios.post(`${API_URL}/api/otp-verfication/verify-otp`, { mobile: altNewMobileNumber, otp: otpInput2 });
  
      if (response1.data.success && response2.data.success) {
        toast.success(SuccessConstant.Success.OTP_VERIFY_SUCCESS);
        setAltMobile(altNewMobileNumber);
        setAltNewMobileNumber('');
        setShowOtpPopup(false);
        setOtpInput1('');
        setOtpInput2('');
        setOtpSent(false);
      } else {
        toast.error(ErrorConstants.Error.OTP_VERIFY_FAIL);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error(ErrorConstants.Error.OTP_VERIFY_FAIL);
    }
  };

  const handleAltMobileCancelClick = () => {
   
    if (otpSent && !altNewMobileNumber) {
      toast.error("Please verify the new alternate number before editing.");
      return;
    }
    setAltMobileEditMode(false);
    setAltNewMobileNumber('');
  setShowOtpPopup(false);
  setOtpInput1('');
  setOtpInput2('');
  setOtpSent(false);
   
  };
  

  const handleOtpCancelClick = () => {
    setShowOtpPopup(false);
  setOtpInput1('');
  setOtpInput2('');
  setOtpSent(false);

    
  };

  
  const handleResendOTP = async () => {
    try {
      
      const resendSuccess = true;

      if (resendSuccess) {
        toast.success(SuccessConstant.Success.OTP_SUCCESS);
      } else {
        toast.error(ErrorConstants.Error.OTP_ERROR);
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
      toast.error(ErrorConstants.Error.OTP_ERROR);
    }
  };

  

  const handleGeneralEditClick = () => setGeneralEditMode(!generalEditMode);

  const handleGeneralCancelClick = () => {
    setGeneralEditMode(false);

    setName(
      vendor &&
        vendor.vendor &&
        vendor.vendor.PersonalDetails &&
        vendor.vendor.PersonalDetails.name
    );

    setDob(() => {
      if (
        vendor &&
        vendor.vendor &&
        vendor.vendor.PersonalDetails &&
        vendor.vendor.PersonalDetails.dateOfBirth
      ) {
        const rawDate = vendor.vendor.PersonalDetails.dateOfBirth;
        const parsedDate = new Date(rawDate);
        const formattedDate = parsedDate.toISOString().split("T")[0];
        return formattedDate;
      }
      return "";
    });
    setGender(
      vendor &&
        vendor.vendor &&
        vendor.vendor.PersonalDetails &&
        vendor.vendor.PersonalDetails.gender
    );
  };
  

  
  
 
  

 

  return (
    <div>
      <div className="flex flex-col space-y-8">
        {/* name field */}
        <div className="flex flex-col md:flex-row gap-x-6 md:items-center mt-10">
          <label className="font-semibold md:w-48"> Name</label>
          {generalEditMode ? (
            <input
              type="text"
              value={name}
              maxLength={30}
              onChange={(e) => {
                const inputValue = e.target.value;
                // Allow only alphabets
                const sanitizedValue = inputValue
                  .replace(/[^a-zA-Z\s]/g, "")
                  .slice(0, 30);
                setName(sanitizedValue);
              }}
              className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
            />
          ) : (
            <div className="flex md:items-center">
              <input
                type="text"
                value={name}
                readOnly
                className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
              />
            </div>
          )}
        </div>
          
          {/* email address */}
        <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
          <label className="font-semibold md:w-48 ">Email Address</label>
          
            <div className="flex md:items-center">
              <input
                type="text"
                value={email}
                readOnly
                className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
              />
             
            </div>
         
        </div>

        {/* mobile number */}

        <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
          <label className="font-semibold md:w-48">Mobile Number</label>
          
            <div className="flex md:items-center">
              <input
                type="text"
                value={mobile}
                readOnly
                className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
              />
              
            </div>
         
        </div>

        {/* alternate mobile number */}
        <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
          <label className="font-semibold md:w-48">
            Alterante Mobile Number
          </label>
         
            
            <div className="flex md:items-center">
              <input
                type="tel"
                value={`91${altMobile}`}
                readOnly
                className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
              />
              
            </div>
       
        </div>

        {/* -------------------- */}

        <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
          <label className="font-semibold md:w-48">DOB</label>
          {generalEditMode ? (
            <input
              type="date"
              value={
                 dob
              }
              onChange={handleDobChange}
              className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
              max={`${new Date().getFullYear()}-12-31`}
            />
          ) : (
            <div className="flex md:items-center">
              <input
                type="date"
                value={
                  dob instanceof Date ? dob.toISOString().split("T")[0] : dob
                }
                readOnly
                className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
              />
            </div>
          )}
        </div>

        <div className="flex flex-col md:flex-row gap-x-6 md:items-center mt-10">
          <label className="font-semibold md:w-48">Gender</label>

          {generalEditMode ? (
            <div className="flex md:items-center p-2">
            <select
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              className="w-[80%] md:w-full p-2 h-12 border rounded-xl focus:outline-none cursor-pointer focus:border-[#8A58DC]"
              required
            >
              <option value="" disabled>
                Select Gender *
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            </div>
          ) : (
            <div className="flex md:items-center p-2">
              <select
                value={gender}
                className="w-[80%] md:w-full p-2 h-12 border rounded-xl focus:outline-none cursor-pointer focus:border-[#8A58DC]"
                required
              >
                <option value="" disabled>
                  Select Gender *
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
          )}
        </div>
        <div className='flex flex-row gap-4'>
                {generalEditMode ? (
                  <div>
                    <button
                      className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                      onClick={handleGeneralSaveClick}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                      onClick={handleGeneralEditClick}
                    >
                      Edit
                    </button>
                  </div>
                )}
                {generalEditMode && (
                  <div>
                    <button
                      className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                      onClick={handleGeneralCancelClick}
                    >
                      Cancel
                    </button>
                  </div>
                )}

              </div>
      </div>
    </div>
  );
}

export default PersonalDetailsSettings;
