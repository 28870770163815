import React from 'react'
import constant from '../../../constants/ImageConstants'

const SELLERIMAGE=constant.IMAGES_IMAGES.BECOME_A_SELLER.SELLERIMAGE;
const AccountSetting = () => {
    return (
        <>
            <div className='p-2 mt-4 w-96 flex flex-col justify-center items-center gap-10 '>
                <img
                    src={SELLERIMAGE}
                    alt=""
                />
                <p className='font-bold text-3xl p-2 text-[#8459C7] animate-bounce' >Coming Soon...</p>
            </div>
        </>
    )
}

export default AccountSetting