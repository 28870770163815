import { Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteAddress } from '../../Redux/features/auth/authSlice'
import toast from 'react-hot-toast'
import SuccessConstant from '../../constants/SuccessConstant'


const AddressCard = ({ openAddAddressForm, setAddressId }) => {
  const scalingFactor = 1.1

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const removeAddress = async (addressId) => {
    try {
      await dispatch(deleteAddress({ addressId, id: user._id }));
      toast.success(SuccessConstant.Success.RemoveAddress);
    } catch (error) {
      toast.error('Failed to delete address. Please try again.');
    }
  };

  const openEditForm = (addId) => {
    setAddressId(addId);
    openAddAddressForm(true);

  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "20px" }}>
        {
          user.address.map((element) => {
            return <Box
              sx={{
                display: "flex",
                gap: "10px",
                width: '500px',
                maxWidth: "full",
                position: "relative",
                padding: "10px 30px 10px 30px",
                borderRadius: "12px",
                cursor: "pointer",
                transition: `all .2s ease-in-out`,
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "30px",
                '@media screen and (max-width: 995px)': {
                  width: '400px',
                },
                '@media screen and (max-width: 500px)': {
                  width: '300px',
                },
                '@media screen and (max-width: 360px)': {
                  width: '240px',
                },

                overflow: 'hidden',
                '&:hover': {
                  transform: `scale(${scalingFactor})`,
                  transition: `all .2s ease-in-out`
                },
                boxShadow:
                  "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
              }}
            >
              <Box sx={{ height: "100%", textAlign: "left", wordBreak: "break-all", whiteSpace: 'pre-line', textOverflow: 'ellipsis', }}>
                <Typography variant="h5">{element.fullname}</Typography>
                <Typography variant="p" sx={{ wordBreak: "break-all" }}>{`${element.flat}, ${element.area}, ${element.landmark}, ${element.city}, ${element.state}, ${element.pinCode}, ${element.country}`}</Typography>
                <Typography variant="p" component={"div"}>{element.phoneNumber}</Typography>
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "2px", marginTop: "5px" }}>
                    <Button variant='text' sx={{ color: "#8A58DC" }} onClick={() => openEditForm(element._id)}>Edit</Button>
                    <div style={{ border: "1px solid #ccc", width: "0", height: "20px" }} />
                    <Button sx={{ color: "#8A58DC" }} onClick={() => removeAddress(element._id)}>Remove</Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          })
        }
      </Box>
    </>
  )
}

export default AddressCard