import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import Loader from '../../common/Loader'
import { Link } from "react-router-dom"
import ErrorConstants from '../../../constants/ErrorConstants';
import SuccessConstants from '../../../constants/SuccessConstant';
const AccountingSheet = () => {

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [pricingDetails, setPricingDetails] = useState([]);


  useEffect(() => {
    // Fetch products when the component mounts
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      // Ensure all data is fetched before attempting to save
      await Promise.all(products.map((product, index) => fetchSavedValues(product._id, index)));

      const response = await fetch('/api/admin-profile/getAllProducts/superAdmin');
      const data = await response.json();

      // Initialize pricingDetails with default values for each product
      const initialPricingDetails = {};
      data.forEach((product, index) => {
        initialPricingDetails[index] = {
          productDetails: {
            productId: product._id,
            productName: product.productName,
            category: product.selectedCategory,
            image: product.images[0],
            originalPrice: product.originalPrice,
            discountedPrice: product.discountPrice,
            customization: product.customization,
            vendorId: product.vendorId,
            status: product.status,
          },
          vendorPriceDiscounted: product.discountPrice,
          vendorPriceOriginal: product.originalPrice,
          gstAsProductCategory: '',
          vendorPricePlusGST: '',
          gstPrice: '',
          shippingPrefix: '',
          ekaivPercentage: '',
          ekaivPricePerProductCommission: '',
          EkaivGST: '',
          ekaivDiscounted: '',
          ekaivOriginal: '',
          actualShippingPrice: '',
          customizedVendorPrice: '',
          customizedEkaivPrice: '',
          phonePay: '',
          phonePayPrice: '',
          ekaivEarning: '',
          totalEarning: '',
        };
      });


      setProducts(data);
      setPricingDetails(initialPricingDetails);
      setLoading(false);

      // Fetch saved values for each product
      fetchSavedValuesForAllProducts(data);

    } catch (error) {
      console.error('Error fetching products:', error);
      setLoading(false);
    }
  };

  const fetchSavedValues = async (productId, productIndex) => {
    try {
      const response = await fetch(`/api/admin-profile/getAccountingSheet/${productId}`);

      if (response.ok) {
        const savedData = await response.json();

        setPricingDetails((prevDetails) => {
          const updatedDetails = { ...prevDetails };
          updatedDetails[productIndex] = {
            ...updatedDetails[productIndex],
            ...savedData,
          };
          return updatedDetails;
        });
      } else {
        console.error('Error fetching saved values:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching saved values:', error);
    }
  };

  useEffect(() => {
    if (products.length > 0) {
      fetchSavedValuesForAllProducts(products);
    }
  }, [products]);


  const fetchSavedValuesForAllProducts = async (data) => {
    try {
      if (data && Array.isArray(data)) {
        const fetchPromises = data.map((product, index) => fetchSavedValues(product._id, index));
        await Promise.all(fetchPromises);
      } else {
        console.error('Invalid or undefined data:', data);
      }
    } catch (error) {
      console.error('Error fetching saved values for all products:', error);
    }
  };

  // Fetch saved values for each product
  fetchSavedValuesForAllProducts();


  const handleInputChange = (productIndex, fieldName, value) => {

    // Define a regular expression to allow only numbers and decimals
    const numberDecimalPattern = /^[0-9]{0,8}(\.[0-9]{0,10})?$/;

    // Check if the input value matches the pattern
    if (!numberDecimalPattern.test(value)) {
      // If the input doesn't match the pattern, don't update the state
      return;
    }

    // If the input matches the pattern, update the state
    const updatedPricingDetails = { ...pricingDetails };
    updatedPricingDetails[productIndex] = {
      ...updatedPricingDetails[productIndex],
      [fieldName]: value,
    };

     // Perform automatic calculation for phonePayPrice if ekaivDiscounted or phonePay is updated
     if (fieldName === 'ekaivDiscounted' || fieldName === 'phonePay') {
      const ekaivDiscounted = parseFloat(updatedPricingDetails[productIndex].ekaivDiscounted) || 0;
      const phonePay = parseFloat(updatedPricingDetails[productIndex].phonePay) || 0;
      const phonePayPrice = (ekaivDiscounted * phonePay) / 100;
      updatedPricingDetails[productIndex] = {
        ...updatedPricingDetails[productIndex],
        phonePayPrice: phonePayPrice.toFixed(2), // Assuming you want to round to 2 decimal places
      };
    }

     // Perform automatic calculation for ekaivPricePerProductCommission if vendorPricePlusGST or ekaivPercentage is updated
     if (fieldName === 'vendorPricePlusGST' || fieldName === 'ekaivPercentage') {
      const vendorPricePlusGST = parseFloat(updatedPricingDetails[productIndex].vendorPricePlusGST) || 0;
      const ekaivPercentage = parseFloat(updatedPricingDetails[productIndex].ekaivPercentage) || 0;
      const ekaivPricePerProductCommission = (vendorPricePlusGST * ekaivPercentage) / 100;
      updatedPricingDetails[productIndex] = {
        ...updatedPricingDetails[productIndex],
        ekaivPricePerProductCommission: ekaivPricePerProductCommission.toFixed(2), // Assuming you want to round to 2 decimal places
      };
    }
    

    setPricingDetails(updatedPricingDetails);
  };


  const handleSave = async (productIndex) => {
    try {
      setLoading(true);

      const product = products[productIndex];
      const productDetails = {
        productId: product._id,
        productName: product.productName,
        category: product.selectedCategory,
        image: product.images[0],
        originalPrice: product.originalPrice,
        discountedPrice: product.discountPrice,
        customization: product.customization,
        vendorId: product.vendorId,
        status: product.status,
      };
      // Check if any field is empty
      const pricingData = pricingDetails[productIndex];
      const hasEmptyField = Object.values(pricingData).some(value => value === '');

      if (hasEmptyField) {
        toast.error(ErrorConstants.Error.ALL_FELID_ERROR);
        return;
      }

      const response = await fetch(`/api/admin-profile/accounting-sheet/${product._id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          productDetails,
          ...pricingDetails[productIndex]
        }),
      });

      if (response.ok) {

        toast.success(SuccessConstants.Success.SAVE_SUCCESS);
      } else {
        console.error('Error saving data:', response.statusText);
        toast.error(ErrorConstants.Error.ERROR);
      }
    } catch (error) {
      console.error('Error saving data:', error);
      toast.error(ErrorConstants.Error.SAVE_ERROR);
    }
    finally {
      setLoading(false);
    }
  };


  return (
    <div className=" container mx-auto ml-3 mb-8">
      {loading && <Loader className="flex justify-center items-center" />}
      <div className="table-container overflow-x-auto flex gap-2 flex-col">
        <table className="w-full table-auto border-collapse border border-gray-100 ">
          <thead >
            <tr >
              <th className="bg-gray-200 font-bold px-4 py-2 "><div>a</div>Product Details
              </th>
              <th className="bg-gray-200 font-bold px-4 py-2"><div>b</div>Vendor Discounted Price
              </th>
              <th className="bg-gray-200 font-bold px-4 py-2 "><div>c</div>Vendor Original Price
              </th>
              <th className="bg-gray-200 font-bold px-4 py-2"><div>d</div>Customized Vendor Price
              </th>
              <th className="bg-gray-200 font-bold px-4 py-2"><div>e</div>Customized Ekaiv Price
              </th>
              <th className="bg-gray-200 font-bold px-4 py-2"><div>f</div>GST on Product(%)
              </th>
              <th className="bg-gray-200 font-bold px-4 py-2"><div>g</div>GST on Product
              </th>
              <th className="bg-gray-200 font-bold px-4 py-2"><div>h</div>Vendor Discounted Price + GST
              </th>

              <th className="bg-gray-200 font-bold px-4 py-2"><div>i</div>Shipping Prefix
              </th>
              <th className="bg-gray-200 font-bold px-4 py-2"><div>j</div>Ekaiv Referral Fee(%)
              </th>
              <th className="bg-gray-200 font-bold px-4 py-2"><div>k</div>Ekaiv Referral Fee
              </th>
              <th className="bg-gray-200 font-bold px-4 py-2"><div>l</div>Ekaiv GST (18%)
              </th>
              <th className="bg-gray-200 font-bold px-4 py-2"><div>m</div>Ekaiv Discounted
              </th>
              <th className="bg-gray-200 font-bold px-4 py-2"> <div>n</div>Ekaiv Original
              </th>
              <th className="bg-gray-200 font-bold px-4 py-2"><div>o</div>Actual Shipping Price
              </th>

              <th className="bg-gray-200 font-bold px-4 py-2"><div>p</div>Payment Gateway (%)
              </th>
              <th className="bg-gray-200 font-bold px-4 py-2"><div>q</div>PhonePe Price
              </th>
              <th className="bg-gray-200 font-bold px-4 py-2"><div>r </div>Ekaiv Earning
              </th>
              <th className="bg-gray-200 font-bold px-4 py-2"><div>s</div>Total Earning
              </th>
              <th className="bg-gray-200 font-bold px-4 py-2"> Action
              </th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, productIndex) => (
              <tr key={productIndex}>
                <td className="py-2 px-4 border-b border-r border-gray-200">
                  
                  <Link
                    target='_blank'
                    to={{
                      pathname: `/SpecificProductsPage/${product._id}`,
                      state: { productId: product._id },
                    }}>
                    <img src={product.images[0]} alt={`Product ${productIndex + 1}`} className="w-16 h-16 object-cover" />
                  </Link>
                </td>
                <td className="py-2 px-4 border-b border-r border-gray-200">
                  {product.discountPrice}
                </td>
                <td className="py-2 px-4 border-b border-r border-gray-200">
                  {product.originalPrice}
                </td>
                <td className="py-2 px-4 border-b border-r border-gray-200">
                  <input
                    type="text"
                    value={pricingDetails[productIndex]?.customizedVendorPrice || ''}
                    onChange={(e) => handleInputChange(productIndex, 'customizedVendorPrice', e.target.value)}
                    className='w-28'
                  />
                </td>
                <td className="py-2 px-4 border-b border-r border-gray-200">
                  <input
                    type="text"
                    value={pricingDetails[productIndex]?.customizedEkaivPrice || ''}
                    onChange={(e) => handleInputChange(productIndex, 'customizedEkaivPrice', e.target.value)}
                    className='w-28'
                  />
                </td>
                <td className="py-2 px-4 border-b border-r border-gray-200">
                  <input
                    type="text"
                    value={pricingDetails[productIndex]?.gstAsProductCategory || ''}
                    onChange={(e) => handleInputChange(productIndex, 'gstAsProductCategory', e.target.value)}
                    className='w-28'
                  />
                </td>
                <td className="py-2 px-4 border-b border-r border-gray-200">
                  <input
                    type="text"
                    value={pricingDetails[productIndex]?.gstPrice || ''}
                    onChange={(e) => handleInputChange(productIndex, 'gstPrice', e.target.value)}
                    className='w-28'
                  />
                </td>
                <td className="py-2 px-4 border-b border-r border-gray-200">
                  <input
                    type="text"
                    value={pricingDetails[productIndex]?.vendorPricePlusGST || ''}
                    onChange={(e) => handleInputChange(productIndex, 'vendorPricePlusGST', e.target.value)}
                    className='w-28'
                  />
                </td>

                <td className="py-2 px-4 border-b border-r border-gray-200">
                  <input
                    type="text"
                    value={pricingDetails[productIndex]?.shippingPrefix || ''}
                    onChange={(e) => handleInputChange(productIndex, 'shippingPrefix', e.target.value)}
                    className='w-28'
                  />
                </td>
                <td className="py-2 px-4 border-b border-r border-gray-200">
                  <input
                    type="text"
                    value={pricingDetails[productIndex]?.ekaivPercentage || ''}
                    onChange={(e) => handleInputChange(productIndex, 'ekaivPercentage', e.target.value)}
                    className='w-28'
                  />
                </td>
                <td className="py-2 px-4 border-b border-r border-gray-200">
                  <input
                    type="text"
                    value={pricingDetails[productIndex]?.ekaivPricePerProductCommission || ''}
                    onChange={(e) => handleInputChange(productIndex, 'ekaivPricePerProductCommission', e.target.value)}
                    className='w-28'
                  />
                </td>
                <td className="py-2 px-4 border-b border-r border-gray-200">
                  <input
                    type="text"
                    value={pricingDetails[productIndex]?.EkaivGST || ''}
                    onChange={(e) => handleInputChange(productIndex, 'EkaivGST', e.target.value)}
                    className='w-28'
                  />
                </td>
                <td className="py-2 px-4 border-b border-r border-gray-200">
                  <input
                    type="text"
                    value={pricingDetails[productIndex]?.ekaivDiscounted || ''}
                    onChange={(e) => handleInputChange(productIndex, 'ekaivDiscounted', e.target.value)}
                    className='w-28'
                  />
                </td>
                <td className="py-2 px-4 border-b border-r border-gray-200">
                  <input
                    type="text"
                    value={pricingDetails[productIndex]?.ekaivOriginal || ''}
                    onChange={(e) => handleInputChange(productIndex, 'ekaivOriginal', e.target.value)}
                    className='w-28'
                  />
                </td>
                <td className="py-2 px-4 border-b border-r border-gray-200">
                  <input
                    type="text"
                    value={pricingDetails[productIndex]?.actualShippingPrice || ''}
                    onChange={(e) => handleInputChange(productIndex, 'actualShippingPrice', e.target.value)}
                    className='w-28'
                  />
                </td>

                <td className="py-2 px-4 border-b border-r border-gray-200">
                  <input
                    type="text"
                    value={pricingDetails[productIndex]?.phonePay || ''}
                    onChange={(e) => handleInputChange(productIndex, 'phonePay', e.target.value)}
                    className='w-28'
                  />
                </td>
                <td className="py-2 px-4 border-b border-r border-gray-200">
                  <input
                    type="text"
                    value={pricingDetails[productIndex]?.phonePayPrice || ''}
                    onChange={(e) => handleInputChange(productIndex, 'phonePayPrice', e.target.value)}
                    className='w-28'
                  // readOnly
                  />
                </td>
                <td className="py-2 px-4 border-b border-r border-gray-200">
                  <input
                    type="text"
                    value={pricingDetails[productIndex]?.ekaivEarning || ''}
                    onChange={(e) => handleInputChange(productIndex, 'ekaivEarning', e.target.value)}
                    className='w-28'
                  />
                </td>
                <td className="py-2 px-4 border-b border-r border-gray-200">
                  <input
                    type="text"
                    value={pricingDetails[productIndex]?.totalEarning || ''}
                    onChange={(e) => handleInputChange(productIndex, 'totalEarning', e.target.value)}
                    className='w-28'
                  />
                </td>
                <td>
                  <div className="mt-4">
                    <button
                      className="bg-purple-500 text-white py-2 px-8 rounded-lg shadow hover:bg-purple-700"
                      onClick={() => handleSave(productIndex)}
                    >
                      Save
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>



    </div>
  );
};

export default AccountingSheet;
