import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { API_URL, config } from '../../config/config';
import Button from '../common/Button';
import Loader from "../common/Loader";

const OrderCards = ({ orderStatus, userAddress, productsDetails, createdAt, orderId }) => {


  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadedOrderId, setDownloadedOrderId] = useState(null);

  const handleDownload = async (orderId) => {
    try {
      setIsDownloading(true);
      setDownloadedOrderId(orderId);

      const response = await axios.get(`${API_URL}/api/order/download/${orderId}`, { ...config, responseType: 'blob' });

      // Create a blob object from the response data
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Create a temporary URL for the blob object
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `invoice_${orderId}.pdf`);
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Cleanup: Remove the temporary link and URL
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      setIsDownloading(false);
      setDownloadedOrderId(null);

    } catch (error) {
      setIsDownloading(false);
      setDownloadedOrderId(null);
      console.error('Error downloading invoice:', error);
    }
  };


  const handleCardClick = () => {
    setIsExpanded(!isExpanded);
  };

  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {

        if (productsDetails.length > 0) {
          // Fetch product details using the API endpoint
          const productDetailsPromises = productsDetails.map((products) =>
            axios.get(`${API_URL}/api/products/getProduct/${products.productId}`)
          );

          const productDetailsData = await axios.all(productDetailsPromises);

          // Extract and set product details
          const formattedProducts = productDetailsData.map((response) => {
            // Find the corresponding product details based on productId
            const productDetail = productsDetails.find(
              (detail) => detail.productId == response.data.product._id
            );
            // Combine product details and formatted product data
            return {
              ...response.data,
              quantity: productDetail ? productDetail.quantity : 0,
              price: productDetail ? productDetail.price : 0,
            };
          });

          setProducts(formattedProducts);
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
        // Handle error if necessary
      }
    };

    fetchProductDetails();
  }, [productsDetails]);

  return (
    <>
      {
        products.map((product) => {
          return <div
            className={`w-full shadow-md mb-4 rounded-md ${isExpanded ? 'h-auto' : 'h-48'}`}
            onClick={handleCardClick}
          >
            <div className='flex flex-col sm:flex-row cursor-pointer w-full h-auto '>
              <div className=' flex flex-row'>
                <div className='' onClick={() => navigate(`/SpecificProductsPage/${product?.product?._id}`)}>
                  <img
                    className='h-20 sm:h-48 w-20 sm:w-48 p-2 object-cover'
                    src={product?.product?.images[0]}
                    loading='lazy-loading'
                    alt="ekaiv"
                  />

                </div>
                <div className='p-2  sm:hidden flex flex-col text-start text-gray-400'>
                <h1 className='font-semibold test-sm sm:text-base md:text-lg text-black'>{product.product.productName.length > 20 ? `${product.product.productName.slice(0, 35)}...` : product.product.productName}</h1>
                  {/* <p className='text-[10px]'>Color: gray</p>
                  <p className='text-[10px]'>Size: xl</p> */}
                  {/* {console.log("size", product.product.dimensions)} */}
                  <div className='block sm:hidden'>
                  <p>Will be InProcess Soon</p>
                  {product?.price} Rs/-
                  {orderStatus !== 'NewOrders' ? (orderStatus === 'Completed' ? (
                    <p className='text-green-500'>Delivered</p>
                  ) : (
                    <p className='text-orange-500'>Processing...</p>
                  )) : <></>}
                 
                </div>
                </div>

              </div>

              <div className='flex flex-col min-[500px]:flex-row justify-between items-center w-full h-full p-5'>
                <div className='p-2 sm:w-[25%] hidden sm:flex flex-col text-start text-gray-400'>
                  <h1 className='font-semibold text-lg text-black'>{product.product.productName.length > 20 ? `${product.product.productName.slice(0, 35)}...` : product.product.productName}</h1>
                  {/* {product.product.selectedCategory != 'homemade-food' && <><p>Color: gray</p>
                    <p>Size: xl</p></>} */}
                </div>
                <div className='hidden sm:block w-[25%]'>
                  <p>Will be InProcess Soon</p>
                  {product?.price} Rs/-
                  {orderStatus !== 'NewOrders' ? (orderStatus === 'Completed' ? (
                    <p className='text-green-500'>Delivered</p>
                  ) : (
                    <p className='text-orange-500'>Processing...</p>
                  )) : <></>}

                </div>
                <div className='flex flex-col gap-2 items-center'>

                  {/* Show loader only for the clicked download button */}
                  {isDownloading && downloadedOrderId === orderId && <Loader />}
                  {
                    orderStatus !== 'NewOrders' && <div className='flex-col items-center p-2'>
                      {orderStatus !== 'Completed' ? (
                        <div className="flex justify-center items-center gap-2">

                        </div>
                      ) : (

                        <div className="flex justify-center items-center gap-2">
                          <div className="w-3 h-3 rounded-full bg-green-500"></div>
                          <p className='text-black font-semibold break-words'>Delivered</p>
                        </div>
                      )}
                      <p className='font-medium whitespace-nowrap text-xs'>
                        {orderStatus === 'Completed'
                          ? 'Your item has been delivered'
                          : 'Your item will be InTransit'}
                      </p>
                    </div>
                  }
                  {!isDownloading && (
                    <Button style="primary" onClick={() => handleDownload(orderId)} >
                      Download
                    </Button>
                  )}
                </div>
              </div>

            </div>
            {isExpanded && (
              <div className='flex flex-col min-[500px]:flex-row p-4 justify-between'>
                <div className='text-left'>
                  <h1 className='font-semibold text-black'>Delivery Address:</h1>
                  <p>{`${userAddress.flat}, ${userAddress.area}, ${userAddress.landmark}, ${userAddress.city}, ${userAddress.state}, ${userAddress.pinCode}, ${userAddress.country}`}</p>

                  <h1 className='font-semibold text-black'>Contact Info:</h1>
                  <p className="text-md font-bold">{userAddress.fullname}</p>
                  <p>Mob. No.: {userAddress.phoneNumber}</p>
                </div>
                <div className='min-[500px]:text-right'>
                  <p className='font-semibold text-black'> <span className='font-light text-gray-400'></span></p>
                  <p className='font-semibold text-black'> <span className='font-light text-gray-400'></span> </p>
                  <p className='font-semibold text-black'>Quantity: <span className='font-light text-gray-400'>{product?.quantity}</span></p>
                </div>
              </div>
            )}
          </div>
        })

      }

    </>
  );
}

export default OrderCards;


