import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { forgotPasswordWithEmail, forgotPasswordWithPhone } from '../../Redux/features/auth/authSlice';
import { useDispatch } from 'react-redux'
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { API_URL } from '../../config/config';
import Button from '../../component/common/Button';
import Loader from '../../component/common/Loader';
import InputField from '../../component/common/InputField';
import ErrorConstants from '../../constants/ErrorConstants';
import SuccessConstant from '../../constants/SuccessConstant';


const ForgotPassword = () => {
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [resendTimer, setResendTimer] = useState(300);
  const [emailOrPhoneError, setEmailOrPhoneError] = useState('');
  const [otpError, setOtpError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [resetHash, setResetHash] = useState('')
  const [imageLoaded, setImageLoaded] = useState(false);


  const sendOTP = () => {

    if (!emailOrPhone) {
      toast.error(ErrorConstants.Error.PHONE_EMAIL_ERROR)
      setEmailOrPhoneError('Please enter your email or phone.');
      setShowOTP(false);
    } else {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(emailOrPhone).toLowerCase())) {
        dispatch(forgotPasswordWithEmail({ email: emailOrPhone })).then((response) => {
          if (response.error) {

            toast.error(response.payload)
            // setSuccessMessage('')
            // setErrorMessage(response.payload)
            // dispatch(resetMessage())
          } else {

            toast.success(response.payload.message)
            setResetHash(response.payload.fullHash)
            setEmailOrPhoneError('');
            setShowOTP(true);
            // Start the timer when OTP is sent
            startResendTimer();
            // setErrorMessage('')
            // setSuccessMessage(response.payload.message)
            // window.location.href = '/'
            // dispatch(resetMessage())
          }
        })
      } else if (emailOrPhone.match(/^\d+$/)) {
        dispatch(forgotPasswordWithPhone({ phone: emailOrPhone })).then((response) => {
          if (response.error) {


            toast.error(response.payload)

            // setSuccessMessage('')
            // setErrorMessage(response.payload)
            // dispatch(resetMessage())
          } else {
            setEmailOrPhoneError('');
            toast.success(response.payload)
            setShowOTP(true);
            // Start the timer when OTP is sent
            startResendTimer();
            // setErrorMessage('')
            // setSuccessMessage(response.payload.message)
            // window.location.href = '/'
            // dispatch(resetMessage())
          }
        })

      } else {
        setEmailOrPhoneError('Please enter your valid email or phone.');
      }
    }
  };
  const verifyOTP = async () => {
    // If the OTP field is empty, show an error toast

    // If the OTP field is empty, show an error toast
    if (otpValue.length === 0) {
      toast.error(ErrorConstants.Error.OTP_ENTER_ERROR);
      return; // Early return to avoid further code execution
    }
    await axios.post(`${API_URL}/api/auth/verify-reset-password-otp`, { emailOrPhone: emailOrPhone, otp: otpValue, resethash: resetHash }).then(response => {
      // setErrorMessage('')


      localStorage.setItem('resetPasswordToken', response.data.resetPasswordToken);
      toast.success(SuccessConstant.Success.OTP_SUCCESS)
      navigate('/reset-password');
      // setSuccessMessage(response.data)
      // setShowOtpInput(false)
    })
      .catch(error => {
        // setSuccessMessage('')
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        // setErrorMessage(message)

      })
  };

  const handleOTPChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters

    setOtpValue(numericValue);


    // Show the "Continue" button when OTP is entered
    if (numericValue.length === 6) {
      setOtpError('');
      setShowContinueButton(true);
    } else {

      setOtpError('Please enter a 6-digit OTP.');
      setShowContinueButton(false);
    }
  };

  const startResendTimer = () => {
    // Start a 1-minute 30-second timer
    const timerInterval = setInterval(() => {
      setResendTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          // When the timer reaches zero, clear the interval
          clearInterval(timerInterval);
          return 0;
        }
      });
    }, 1000);
  };

  const resetTimer = () => {
    // Reset the timer to 1 minute and 30 seconds when "Resend OTP" is clicked
    setResendTimer(90);
  };

  useEffect(() => {
    if (showOTP) {
      startResendTimer();
    }

    // Clear the timer when the component unmounts
    return () => {
      clearInterval(resendTimer);
    };
  }, [showOTP]);


  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const links = document.querySelectorAll(".scroll-link");
    links.forEach((link) => {
      link.addEventListener("click", scrollToTop);
    });

    return () => {
      // Remove event listeners when the component unmounts
      links.forEach((link) => {
        link.removeEventListener("click", scrollToTop);
      });
    };
  }, []);

  return (
    <>
      <div className='h-full w-auto bg-[#f7fafc]'>
        <div className="inset-0 flex items-center justify-center z-50 p-10 sm:p-20">
          <div className="bg-white rounded-lg p-8 shadow-lg max-w-4xl w-full">

            <div className="flex flex-col mr-5 md:flex-row">
              <div className="md:w-1/2">
                <img
                  src="https://img.freepik.com/free-vector/reset-password-concept-illustration_114360-7876.jpg?size=626&ext=jpg&ga=GA1.1.584503204.1684751112&semt=ais"
                  alt="Image1"
                  loading='lazy-loading'
                  className="h-auto w-full mx-auto md:ml-0 md:mr-8 mb-4 md:mb-0"
                  onLoad={() => setImageLoaded(true)}
                />
                {!imageLoaded && (
                  <div className="w-full mx-auto h-[200px] flex items-center justify-center">
                    <Loader />
                  </div>
                )}
              </div>
              <div className="md:w-1/2">
                <h2 className="text-3xl font-bold mb-4">Forgot Password</h2>
                <InputField
                  type="email"
                  id="email"
                  label='Email or Phone'
                  name="email"
                  value={emailOrPhone}
                  onChange={(e) => {
                    const lowercasedValue = e.target.value.toLowerCase(); // Convert input value to lowercase
                    setEmailOrPhone(lowercasedValue);
                    // Enable the "Send OTP" button when emailOrPhone is not empty
                    setEmailOrPhoneError('');
                  }}
                  placeholder="exapmle@gmail.com"
                />
                {emailOrPhoneError && <p className="text-red-500 text-sm">{emailOrPhoneError}</p>
                }

                {!showOTP ? (
                  <div className='mt-4'>
                    <Button
                      style="primary"
                      onClick={sendOTP}
                    >
                      Send OTP
                    </Button>
                  </div>
                ) : (
                  <div className="flex flex-col mt-2">
                    <InputField
                      type="text"
                      label="Enter OTP"
                      value={otpValue}
                      maxLength={6}
                      onChange={handleOTPChange}
                      placeholder="Enter OTP *"
                    />
                    {otpError && <p className="text-red-500 text-sm">{otpError}</p>}

                    <div className="flex flex-col mb-2">
                      <p>
                        Didn't receive OTP?{" "}
                        {resendTimer > 0 ? (
                          <span className='text-gray-400'>
                            Resend ({Math.floor(resendTimer / 60)}:
                            {String(resendTimer % 60).padStart(2, "0")} )
                          </span>
                        ) : (
                          <div
                            className="text-[#8A58DC] cursor-pointer"
                            onClick={() => {
                              resetTimer();
                              sendOTP();
                            }}
                          >
                            Resend OTP
                          </div>
                        )}
                      </p>
                    </div>
                    <Button
                      style="primary"

                      onClick={verifyOTP}
                    >
                      Continue
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
