import React, { useState } from 'react'
import { FaArrowRight } from "react-icons/fa";
import constant from '../../constants/ImageConstants';
import Button from '../common/Button';
import Loader from '../common/Loader';


const ERROR404 = constant.IMAGES_ERRORCRVES.ERROR404;
const CURVES = constant.IMAGES_ERRORCRVES.CURVES;

function Error404() {
    const goBack = () => {
        window.history.back(); // This function navigates back to the previous page in history
    };
    const [imageLoaded, setImageLoaded] = useState(false);

    return (

        <div className=' p-2  bg-gray-100 '>
            <div className=' rounded-md  '>
                <div className=' top-[25%] min-[425px]:top-[30%] sm:top-[40%] md:top-[39%] xl:top-[45%] w-full grid grid-cols-2 py-10 '>
                    <div className=' flex items-start justify-center'>
                        <div>
                            <h1 className=' font-[700] text-[20px] min-[425px]:text-[30px] sm:text-[38px] md:text-[48px] sm:pt-2 md:pt-5'>Ooops...</h1>
                            <h1 className=' text-sm min-[425px]:text-[15px] sm:text-lg md:text-2xl sm:pt-2'>Page not found</h1>
                            <h2 className='pt-1 md:pt-5 text-[5px] min-[425px]:text-[10px] sm:text-xs md:text-sm mb-5'>Sorry, We could not find the requested page.<br /> her prenior </h2>
                            <Button onClick={goBack} style='tertiary'>Go Back
                                <FaArrowRight />
                            </Button>
                        </div>
                    </div>

                    <div className=' '>
                        <img src={ERROR404} loading='lazy-loading' onLoad={() => setImageLoaded(true)} alt="error" className=' h-[70%] min-[425px]:h-[70%] sm:h-[80%] md:h-[90%]' />
                        {!imageLoaded && (
                            <div className="w-full mx-auto h-[70%] min-[425px]:h-[70%] sm:h-[80%] md:h-[90%] flex items-center justify-center">
                                <Loader />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Error404
