const data = {
    SHIPPING_CHARGES: 40,
    ADDITIONAL_CHARGES: 0
}


const IMAGES_LOADER = {
    LOGO: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2Floader%2FLogo.png?alt=media&token=4b94a5aa-fd59-4993-a032-f45a0f6469d0",

}

const IMAGES_ERRORCRVES = {
    ERROR404: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FErrorsCurves%2F404error.png?alt=media&token=6e710641-9ec5-4bf5-9453-5b2cb50e8f23",

    POLYGON: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FErrorsCurves%2FPolygon.png?alt=media&token=6d652b5b-7670-416a-8360-90a7b570dafd",

    CURVES: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FErrorsCurves%2Fcurves.png?alt=media&token=ddcd91f0-883c-42d4-ad99-75c3b2e79abc",

    TICKMARK: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FErrorsCurves%2Ftickmark.png?alt=media&token=3853bbda-ba13-421b-9b1e-abad48839e5e",

}

const IMAGES_LOGO = {
    INDIA: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2Flogo%2Findia.png?alt=media&token=21f1229e-c5c3-4bb9-a944-f838e517bd29",

    LOGO: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2Flogo%2FLogo.png?alt=media&token=9f248210-09c5-4744-8499-c1474fe2b101",

    THEEKAIVLOGO: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2Flogo%2FTheEkaivlogo.svg?alt=media&token=ca50ce7e-5be1-47e2-8cec-030d48baa716",

}

const IMAGES_IMAGES = {
    ABOUT_US: {
        ABOUT_US_IMG: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAboutUs%2FAboutUsimage4.svg?alt=media&token=dab20ea1-4bea-4fcd-af13-ad23f912bebb",


        ABOUT_US_LOGO: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAboutUs%2FAboutUsLogoEkaive.svg?alt=media&token=96fb0068-9ca2-45d0-a358-a11bd158b797",

        EARRING: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAboutUs%2Fearring.jpeg?alt=media&token=bed54b1c-6a45-471c-9b54-96bf31ddb918",

        SHOP:"https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAboutUs%2FShpoImg.png?alt=media&token=0edea5c2-b36a-4cb3-9f1f-bc6c4754399b",

    },

    ASSISTANCE: {
        BUSINESS_Registrations: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAssitance%2FBusiness%20Registrations%2FranjanaErankar.jpg?alt=media&token=012b7dfa-dab1-4c99-ba45-cca0332665dc",

        COMMUNITY_WELFARE: {
            CAIT: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAssitance%2FCommunity%20%26%20Welfare%2FCAIT-LOGO-1.png?alt=media&token=db21f160-82b1-477d-8a88-48d2d60dfcbf",

            ZAIP: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAssitance%2FCommunity%20%26%20Welfare%2Fzhep%20logo.png?alt=media&token=687d39b4-9272-46bb-a4ad-f15518930c97",
        },

        DIGITAL_MARKETING_GRAPHIC_DESIGNING: {
            HAPPYREPUBLICDAY: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAssitance%2FDigital%20Marketing%20Graphic%20Designing%2FHappyRepublicDay.jpeg?alt=media&token=d04c5d47-f7b0-4077-9c90-1ff09751e071",

            DR_NISHANT: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAssitance%2FDigital%20Marketing%20Graphic%20Designing%2FDr%20Nishant.png?alt=media&token=ae40ed27-3c20-49ee-a860-1b25439372b2",


            ONAM: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAssitance%2FDigital%20Marketing%20Graphic%20Designing%2Fonam.png?alt=media&token=81bed5a2-7394-4a6f-b164-5cc8aa695ba5",


            PADWA: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAssitance%2FDigital%20Marketing%20Graphic%20Designing%2Fpadwa.png?alt=media&token=fa2e8cde-ffd4-4a1d-9a62-d259666009ce",


        },
        LEGAL_ADVISORY: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAssitance%2FLegal%20Advisory%2Flawer.jpeg?alt=media&token=99ff7d12-8683-419e-ba74-27bcbba2505a",

        PHOTOGRAPHY: {
            IMAGE1: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAssitance%2FPhotography%2FImage1.png?alt=media&token=cd6f5b9d-af58-439d-8c3e-ac8ee75f2067",

            IMAGE2: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAssitance%2FPhotography%2FImage2.png?alt=media&token=bf8e76af-09bf-406f-8ab6-03db8dd62664",


            IMAGE3: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAssitance%2FPhotography%2FImage3.png?alt=media&token=a1cae53c-c9d8-410e-87eb-07f7a220b9c2",

            IMAGE4: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAssitance%2FPhotography%2FImage4.png?alt=media&token=4f66e157-e2dc-4dfc-b0df-b6123d181306",

        },


        TRAINING_DEVELOPMENT: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAssitance%2FTraining%20%26%20Development%2Fekaiv%20workshop.png?alt=media&token=746ad858-27a9-4760-9c93-d051b79145a3",

        WEB_APP_SOFTWARE_DEVELOPMENT: {
            EMAIL_LOGO: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAssitance%2FWeb%20App%20Software%20Development%2F8.png?alt=media&token=c0a80a68-d233-4e1c-92a5-f48ee9e0a559",
            PERCEPTIONS: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAssitance%2FWeb%20App%20Software%20Development%2Fperceptions.png?alt=media&token=99c3b0cf-098d-4a7c-ae0f-c8ba370a13ec",
            EKAIV_INSTA: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2Flogo%2FEkaiv%20Logo.png?alt=media&token=77fe4b4d-7641-4702-8b01-1b9197e28b3f",
        },

        ROBOT: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FAssitance%2Frobot-final.png?alt=media&token=02a964ae-541d-47bb-8085-37ce19cf3cc6",
        //delete robote image from firebase..

    },
    BECOME_A_SELLER: {
        BADGES: {
            BULE: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FBecomeASeller%2FBadges%2FBlue.png?alt=media&token=43145262-0945-4b9b-b4a6-3cae790c6051",
            ORANGE: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FBecomeASeller%2FBadges%2FOrange.png?alt=media&token=410d29be-9310-4701-9c8d-aa4681840c06",
            PURPLE: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FBecomeASeller%2FBadges%2FPurple.png?alt=media&token=8dfca2f6-416f-447c-bbfc-8898781f2721",
            QUESTION: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FBecomeASeller%2FBadges%2Fquestion.jpg?alt=media&token=1fea755e-380b-4e6a-8414-22d0da816221",
            TICK: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FBecomeASeller%2FBadges%2FGroup%201%20(1).png?alt=media&token=3a929956-b1a7-4ba5-8426-164a76f6f10e",
        },
        STARTSELLING: {
            CAPITAL: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FBecomeASeller%2FStartSelling%2Fcapital.png?alt=media&token=e13a45f4-00f8-4192-b9d3-b3c97a77c2eb",
            EKAIVESELLING: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FBecomeASeller%2FStartSelling%2Fekaivselling.png?alt=media&token=3c00e944-2daa-4198-9222-e12590c577ca",
            FAKEPAN: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FBecomeASeller%2FStartSelling%2FfakePan.png?alt=media&token=ff6de67f-a33b-4874-8292-adb5e50b5fe6",
            PRIVACY: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FBecomeASeller%2FStartSelling%2Fprivacy.jpg?alt=media&token=cf3c6c67-3d14-4caa-993f-b611412c36c0",
            SEAMSTRESS: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FBecomeASeller%2FStartSelling%2FSeamstress-bro.png?alt=media&token=86595270-7f47-4d57-a60d-7b0108bc7827",
        },
        SELLING_PAGE:{
            EKAIV:"https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FBecomeASeller%2FSellingPage%2F252.png?alt=media&token=1faa44f4-6617-481b-982d-f70332184e2d "
        },
        RIBBON: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FBecomeASeller%2Fribbon.png?alt=media&token=c47f5e06-0353-4b3a-929f-e8379ba2aa34",
        SELLERIMAGE: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FBecomeASeller%2Fsellerimage.svg?alt=media&token=313f3ed5-5066-4e1f-bb4e-0c722ce050dd",
    },
    CATEGORIES: {
        ACCESSORIES: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FCategories%2FCategories47.png?alt=media&token=42dbd92f-e6c8-4896-b27b-402ad557e2aa",
        HOME_DECOR: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FCategories%2FCategories85.png?alt=media&token=593fcf9e-aabd-498a-a8b2-3cb1f17119b1",
        HOMEMADE_FOOD: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FCategories%2F585.png?alt=media&token=4fe051e3-62db-4c7b-b264-464fc07359cd",
        KIDS: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FCategories%2FKids.png?alt=media&token=a1b0225d-ceda-4022-9a86-239dbff87e07",
        MENS: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets%2FHome%20Page%2Fharsh%20150px..png?alt=media&token=b06c3445-a4d1-433a-ad3a-2558f476fd1c",
        WOMEN: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets%2FHome%20Page%2Fdhanashree%20150px.png?alt=media&token=2159f590-7654-463f-ae81-d09f9aacccf3",
    },

    HOME: {
        HOMEPAGE1: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FHome%2FHOMEPAGE%203.png?alt=media&token=a92f4e37-2fcc-450c-8d01-31f1b73394ea",
        HOMEPAGE2: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FHome%2Fhomepage%202..png?alt=media&token=baa9d7c6-a554-4cd7-bd44-61b6322050a6",
        HOMEPAGE_GREEN: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FHome%2Fhomepage%20green.png?alt=media&token=4618832b-fdc8-4f9e-b4e5-ca02f0df6fa7",

        HOMEPAGE_KIDS: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FHome%2Fhomepage%20kids.png?alt=media&token=d138da5d-9942-4c48-85ed-0f59033fd4f1",

        LOGIN: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets%2FHome%20Page%2Fgroup-customers-shopping-online-store-huge-tablet-sale-internet-shop-buyer-with-purchases-cart-flat-illustration_74855-18344-removebg-preview.png?alt=media&token=b5f851a6-cfca-45c3-b09a-c596e09d2f03"
    },

    FESTIVE:{
        BANNER:"https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FHome%2FRectangle%201454.png?alt=media&token=50c9b58a-725f-4c55-bdc3-98ddd36fbe9a",
        FESTIVEONE:"https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FHome%2FRectangle%201455.png?alt=media&token=4b40adb8-0393-46d6-bbea-64638a49cd1e",
        FESTIVETWO:"https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FHome%2FRectangle%201457.png?alt=media&token=0edd30bc-ab63-49f0-82b1-88469f495d9b",
        FESTIVETHREE:"https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FHome%2FRectangle%201456.png?alt=media&token=f5611396-ef32-42a3-85db-e20330f8e40f",
        FESTIVEFOUR:"https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FHome%2FRectangle%201458.png?alt=media&token=0b489f7b-5cb1-48ee-a63b-3240bbff5ab3",
    },

    INSTAGRAM: {
        FIRST: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FInstagram%2FFirst.png?alt=media&token=a17b4cdb-2704-4d6f-860f-55f1b6e52b51",
        THIRD: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FInstagram%2FThird.png?alt=media&token=5c02b0c9-477c-44b7-bcee-746df713ead1",
        SECOND: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FInstagram%2FSecond.png?alt=media&token=eba7df38-f7da-4be3-8dcd-6340eb5c2cac",
        FOURTH: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FInstagram%2FFourth.png?alt=media&token=fbf82754-8740-42d1-8a42-e55b7bc4e44e",
        FIFTH: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FInstagram%2FFifth.png?alt=media&token=56fb86ff-c8a5-4b0c-ac0c-0ad8cd064f69",
        SIXTH: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FInstagram%2FSixth.png?alt=media&token=afa06575-b916-4548-b712-b9b5ae53cb0b",
        Seventh: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FInstagram%2FSeventh.png?alt=media&token=72d7af64-fe4d-4b5c-80fd-74dcf03cada8",
    },
    USER_PROFILE: {
        CONTACT: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FUserProfile%2Fcontact.png?alt=media&token=71038ab8-10bc-4c58-a7e4-cc7ac571efe7",
        LOCATION: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FUserProfile%2Flocation.png?alt=media&token=333620a9-6022-410f-8ca5-f426d14e0f23",
        LOGOUT: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FUserProfile%2Flogout.png?alt=media&token=02e181a3-599f-4ffa-ae55-7b5170fe83a2",
        ORDER: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FUserProfile%2Forder.png?alt=media&token=9f51e8b6-7d00-4f7c-a52a-a42588355941",
        PAYMENT: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FUserProfile%2Fpayment.png?alt=media&token=0ea965e8-69d2-4733-9110-84e4a16d8b27",
        ROBOT_ADDRESS: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FUserProfile%2Frobot-address-error.png?alt=media&token=83c0b253-e67b-4201-bf69-4e36f54537ee",
        SECURRITY: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FUserProfile%2Fsecurity.png?alt=media&token=c39d9511-c056-4755-bd96-88ab60dc8484",
        USER_PROFILE: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FUserProfile%2Fuser-profile.png?alt=media&token=e61492c6-4f04-4bd4-8c14-a0d0e8510933",
        USER: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FUserProfile%2Fuser.png?alt=media&token=44a76bd3-0b16-42d8-82f8-28e032ee2a30",

    },
    WHY_SELL_ON_EKAIV: {
        LOG01: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FwhySellOnEkaive%2Flogo1.svg?alt=media&token=c73d1d15-f092-41c5-92a1-8c88035573d3",
        LOG02: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FwhySellOnEkaive%2Flogo2.svg?alt=media&token=66f9fa1a-b7e2-4609-b3e3-e85ca2343e95",
        LOG03: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FwhySellOnEkaive%2Flogo3.svg?alt=media&token=22886817-2635-4c8f-af56-5b77f0d52921",
        LOG04: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FwhySellOnEkaive%2Flogo4.svg?alt=media&token=5330c9c2-da7c-407e-a6a3-d0ac30982569",
        LOG05: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FwhySellOnEkaive%2Flogo5.svg?alt=media&token=2151298d-0ef6-4355-ae81-f31ae0ccf95d",
        LOG06: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FwhySellOnEkaive%2Flogo6.svg?alt=media&token=c26a99ef-522a-4c34-9aad-70c223f9d12e",
        LOG07: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FwhySellOnEkaive%2Flogo7.svg?alt=media&token=297e9f3f-5442-4067-9963-6c4173383ba2",
        LOG08: "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FwhySellOnEkaive%2Flogo8.svg?alt=media&token=ce3e831b-c557-4602-bab8-5513edd12db1",
    }


}

const COLOR_CONSTANT={
    
}

export default {data,IMAGES_LOADER,IMAGES_ERRORCRVES,IMAGES_LOGO,IMAGES_IMAGES};