import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import orderService from "./orderService"


// const saveOrder = JSON.parse( localStorage.getItem('order'))
   
const initialState = {
    order : {} ,
    isError : false,
    isLoading : false,
    isSuccess: false,
    message : "",
}

const orderSlices = createSlice({
    name: "order" ,
    initialState,
    reducers: {
        reset : (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
        },
        resetMessage : (state) => {
            state.message = ""
        } 
    },

    extraReducers : (builder) => {
        builder
        .addCase(getOrder.pending , (state )=>{
            state.isLoading = true
            state.isError = false
            state.isSuccess = false
        })
        .addCase(getOrder.fulfilled , (state , action)=>{
         
            state.isLoading = false
            state.isSuccess = true
            state.order = action.payload.order
            state.message = action.payload.message
            state.isError = false
        })
        .addCase(getOrder.rejected , (state , action)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload  
        })


    }
 
})


export const getOrder = createAsyncThunk("/getAllOrders", async (data,thunkAPI) => {

    try {
        const res =  await orderService.getOrder(data);
       
        return res;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
}) 


export const {reset, resetMessage} = orderSlices.actions
export default orderSlices.reducer
