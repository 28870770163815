import React from 'react'
import ProductVerify from '../component/SuperAdmin/Verify/ProductVerify';

const ProductVerifyPage = () => {
    return (
        <>
            <ProductVerify />
        </>
    )
}

export default ProductVerifyPage;
