import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ProtectedRoute, ProtectedUserRoute, ProtectedVendorRoute, ProtectedAdminRoute } from './utils/ProtectedRoute';
import { memo, useEffect, useState } from 'react';
import Layout from './pages/Layout';
import HomePage from './pages/HomePage';
import CataloguePage from './pages/CataloguePage';
import SpecificProductsPage from './pages/SpecificProductsPage';
import AssistancePage from './pages/AssistancePage';
import AboutUs from './pages/AboutUs';
import FaqPage from './pages/FaqPage';
import Loader from './component/common/Loader';
import Vendor from './pages/Vendor';
import SellerRegistration from './pages/SellerRegistration';
import BecomeASellerPage from './pages/BecomeASellerPage';
import UserProfilePage from './pages/UserProfilePage';
import WishlistPage from './pages/WishlistPage';
import Help from './pages/Help';
import ContinueSignup from './pages/ContinueSignup';
import toast, { Toaster } from 'react-hot-toast';
import LoginPage from './pages/LoginPage';
import { resetMessageOtp } from './Redux/features/otpVerification/otpSlice';
import { getUser, resetMessage } from './Redux/features/auth/authSlice';
import CartPage from './pages/Cart';
import ContactUs from './pages/ContactUs';
import SellerProfile from './pages/SellerProfile';
import CarrerPage from './pages/CarrerPage';
import OurBlog from './pages/OurBlog';
import Reviews from './pages/Reviews';
import TermsAndCondition from './pages/TermsAndConditons';

import { Add } from '@mui/icons-material';
import CheckoutPage from './pages/CheckoutPage';
import LiveChat from './pages/LiveChat';

import VendorMenuBar from './component/VendorProfile/Dashboard/VendorMenuBar';
import SingUp from './pages/SignUp';
import SuperAdmin from './pages/SuperAdmin';
import ProductVerifyPage from './pages/ProductVerifyPage';
import VendorVerify from './pages/VendorVerifyPage';
import ForgetPassword from './pages/Forget Password/ForgotPassword';
import ResetPassword from './pages/Forget Password/ResetPassword';
import { Helmet } from 'react-helmet'

import Success from './component/payment/Success';
import Cancel from './component/payment/Cancel';


import EditProduct from './component/VendorProfile/AddProduct/EditProduct';

import VendorAccountPolicy from './pages/VendorAccountPolicy';
import Privacy from './pages/Privacy';
import EkaivSelling from './pages/EkaivSelling';
import Error404 from './component/payment/Error404';
import OrderConfirmed from './component/payment/OrderConfirmed';
import Bages from './pages/Bages';
import ShippingPolicy from './pages/ShippingPolicy';
import ScrollArrow from './component/common/ScrollToTop';
import ReturnPolicy from './pages/ReturnPolicy';
import SellingPage from './pages/SellingPage';
import Products from './pages/Products/Products';
import SpecificBlogPage from './pages/SpecificBlogPage';

function App() {
  const { auth, user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch()
  useEffect(() => {
    const fetchUser = async () => {
      try {
        await dispatch(getUser()).unwrap();
      } catch (error) {
        console.error('Error fetching user:', error);
      } finally {

        setLoading(false);
      }
    };

    fetchUser();
  }, [auth, dispatch]);
  const cartLength = user?.cartsItems?.length

  if (loading) {
    return <div className="fixed inset-0 overflow-hidden w-full h-full flex justify-center items-center z-50">
      <Loader />
    </div>
  }
  const MemoizedCataloguePage = memo(CataloguePage);

  return (

    <div >
      <Helmet>
        <title>The Ekaiv</title>

      </Helmet>
      <Routes>


      //NOTE - Protected Vendor Routes

        <Route path='/vendor-profile' element={<ProtectedVendorRoute> <Vendor /> </ProtectedVendorRoute>}> </Route>
        {/* <Route path="/edit/:prodcutId" element={<ProtectedVendorRoute> <EditProduct /> </ProtectedVendorRoute>} /> */}


      //NOTE - Protected Admin Routes

        <Route path='/admin-profile' element={<ProtectedAdminRoute> <SuperAdmin /> </ProtectedAdminRoute>}></Route>


        <Route path='/' element={<Layout />}>

      //NOTE - User Routes

          <Route index element={<HomePage />} />
          <Route path='/seller-profile/:vendorId' element={<SellerProfile />}></Route>
          <Route path='/catalogue' element={<MemoizedCataloguePage />} />
          <Route path='/SpecificProductsPage/:productId' element={<SpecificProductsPage />} />
          <Route path='/assistance' element={<AssistancePage />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/success' element={<Success />} />
          <Route path='/cancel' element={<Cancel />} />
          <Route path='/404error' element={<Error404 />} />
          <Route path='*' element={<Navigate to='/404error' replace={true} />} />
          <Route path='/orderConfirmed' element={<OrderConfirmed />} />
          <Route path='/faq' element={<FaqPage />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/live-chat' element={<LiveChat />} />
          <Route path='/carrer-page' element={<CarrerPage />}></Route>
          <Route path='/our-blog' element={<OurBlog />}></Route>
          <Route path='/specific-blog/:blogId' element={<SpecificBlogPage />}></Route>
          <Route path='/reviews' element={<Reviews />}></Route>
          <Route path='/terms-and-condition' element={<TermsAndCondition />}></Route>
          <Route path='/products' element={< Products />} />
          <Route path='/become-seller' element={<BecomeASellerPage />} />
          <Route path='/help' element={<Help />} />
          <Route path='/continue-signup' element={<ContinueSignup />} />
          <Route path='/forgot-password' element={<ForgetPassword />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/privacy-policy' element={<Privacy />} />
          <Route path='/vendor-account-policy' element={<VendorAccountPolicy />} />
          <Route path='/Ekaiv-selling' element={<EkaivSelling />} />
          <Route path='/badges' element={<Bages />} />
          <Route path='/shipping-policy' element={<ShippingPolicy />} />
          <Route path='/return-policy' element={<ReturnPolicy />} />
          <Route path='/selling-page' element={<SellingPage />} />


      //NOTE - Protected User Routes

          <Route path='/wishlist' element={<ProtectedUserRoute> <WishlistPage /> </ProtectedUserRoute>}></Route>
          <Route path='/seller-registration' element={<ProtectedUserRoute> <SellerRegistration /> </ProtectedUserRoute>}></Route>

          //REVIEW -
          <Route path='/checkout' element={(auth !== null && cartLength > 0) ? <CheckoutPage /> : <Navigate to='/signin' replace={true} />}></Route>
          <Route path='/user-profile' element={<ProtectedRoute> <UserProfilePage /> </ProtectedRoute>} />
          <Route path='/signup' element={auth === null ? <SingUp /> : <Navigate to='/' replace={true} />} />
          <Route path='/signin' element={auth === null ? <LoginPage /> : <Navigate to='/' replace={true} />} />

          {/* <Route path='/signup' element={<ProtectedRoute> <SingUp/> </ProtectedRoute>} />
          <Route path='/signin' element={<ProtectedRoute> <LoginPage/> </ProtectedRoute>} /> */}

      //NOTE - Protected Vendor Routes
          {/* <Route path="/edit/:prodcutId" element={<ProtectedVendorRoute> <EditProduct /> </ProtectedVendorRoute>} /> */}

      //NOTE - Protected Admin Routes

          <Route path='/admin-profile' element={<ProtectedAdminRoute> <SuperAdmin /> </ProtectedAdminRoute>} />
          <Route path='/vendor-verify/:itemId' element={<ProtectedAdminRoute> <VendorVerify /> </ProtectedAdminRoute>} />
          <Route path='/product-verify/:itemId' element={<ProtectedAdminRoute> <ProductVerifyPage /> </ProtectedAdminRoute>} />

        </Route>

      </Routes>
      <Toaster position='top-center' />
      <ScrollArrow />
    </div>
  );
}

export default App;
