import React, { useState } from 'react';
import axios from 'axios'
import { API_URL, config } from '../../../config/config';
import Button from '../../common/Button';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

function EditBlog({ blog, onClose, getBlogs }) {

  const navigate = useNavigate();
  const [title, setTitle] = useState(blog.title);
  const [imageFile, setImageFile] = useState(null);
  const [blogContent, setBlogContent] = useState(blog.blogContent);
  const [imageUrl, setImageUrl] = useState(blog.blogImage.url);


  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${API_URL}/api/admin-profile/blog/${blog._id}`, config);
      toast.success('Blog deleted sucessfully')
      onClose();
      getBlogs();
    } catch (error) {

      toast.error('Error deleting blog. Please try again.');
    }
  };

  const compressImage = async (file, maxSizeInBytes) => {
    const options = {
      maxSizeMB: maxSizeInBytes / (1024 * 1024),
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };

    // Check if the provided image is under the max size; if so, return the original file
    if (file.size <= maxSizeInBytes) {
      return file;
    }

    try {
      const compressedFile = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            let width = img.width;
            let height = img.height;
            const maxDim = Math.max(width, height);
            if (maxDim > options.maxWidthOrHeight) {
              const scale = options.maxWidthOrHeight / maxDim;
              width *= scale;
              height *= scale;
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(
              (blob) => {
                if (blob) {
                  resolve(new File([blob], file.name, { type: file.type }));
                } else {
                  reject(new Error('Image compression failed'));
                }
              },
              file.type,
              options.maxSizeMB
            );
          };
        };
        reader.onerror = (error) => reject(error);
      });
      return compressedFile;
    } catch (error) {
      throw error; // Rethrow the error to be caught by the caller
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // if (!imageFile && title === blog.title && blogContent === blog.blogContent) {
    //   toast.info('No changes detected. Nothing to save.');
    //   onClose();
    //   getBlogs();
    //   return;
    // }

    try {
      // let imageDataUrl = imageUrl;

      // if (imageFile) {
      //   const compressedFile = await compressImage(imageFile, 1024 * 1024);
      //   const reader = new FileReader();
      //   reader.onloadend = () => {
      //     imageDataUrl = reader.result;
      //   };
      //   reader.readAsDataURL(compressedFile);
      // }

      const payload = {};
      if (title !== blog.title) {
        payload.title = title;
      }
      if (blogContent !== blog.blogContent) {
        payload.blogContent = blogContent;
      }
      // if (imageFile) {
      //   payload.blogImage = imageDataUrl;
      // }

      // Ensure payload has at least one key-value pair before   making the request
      if (Object.keys(payload).length === 0) {
        toast.info('No changes detected. Nothing to save.');
        onClose();
        getBlogs();
        return;
      }

      const response = await axios.put(`${API_URL}/api/admin-profile/edit-blog/${blog._id}`, payload, config);
      toast.success('Blog updated successfully');
      onClose();
      getBlogs();
    } catch (error) {
      console.error('Error updating blog:', error);
      toast.error('Error updating blog. Please try again and update all the fields.');
    }
  };



  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (!(selectedFile instanceof Blob)) {
      console.error('Invalid file type:', selectedFile);
      return;
    }

    setImageFile(selectedFile);

    try {
      // Compress the selected file if needed
      const compressedFile = await compressImage(selectedFile, 1024 * 1024);

      // Set the compressed file as the image data
      setImageUrl(compressedFile);

      // Optionally, you can perform further processing with the compressed file
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  };



  return (
    <div className=" p-4 mx-auto ">
      <h1 className="text-2xl font-bold mb-4">Edit Blog</h1>
      <div className='bg-gray-200 p-2 w-fit rounded-md'>
        <div className='flex flex-row gap-5'>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left p-5">
            <div className="mt-2 flex flex-col gap-5">
              <input
                type="text"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
              <div className="flex items-center gap-4">
                {imageUrl && (
                  <img src={imageUrl} alt={title} className="h-48 w-auto rounded-lg" />
                )}
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <textarea
                placeholder="Content"
                value={blogContent}
                onChange={(e) => setBlogContent(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md mt-2"
                required
              ></textarea>
            </div>
          </div>
        </div>
        <div className="mt-4 flex w-full gap-5 ml-9" >
          <Button onClick={handleDelete} style='primary'>
            Delete
          </Button>
          <Button onClick={handleSave} style='primary' >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EditBlog;