import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AccountDetails from "./AccountDetails";
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import InfoIcon from '@mui/icons-material/Info';
import DropBox from '../SellerRegistration/DropBox';
import { IconButton, Tooltip } from "@mui/material";
import { GiScaleMail, GiDoubleNecklace, GiBedLamp, GiWaterBottle } from "react-icons/gi";
import { TbCategory } from "react-icons/tb";
import { toast } from 'react-hot-toast';
import { Button } from '@mui/material';
import { API_URL } from "../../config/config";
import axios from "axios";
import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorConstants from "../../constants/ErrorConstants";

const BusinessDetails = ({ onPreviousStep, onNextStep, setAllData, allData }) => {
  const [isEditing, setIsEditing] = useState(true);
  const [isCreateAccountOpen, setIsCreateAccountOpen] = useState(false);
  const [selectedTypeOfBusiness, setSelectedTypeOfBusiness] = useState([]);
  const [showInfo, setShowInfo] = useState({});
  const [fieldsEmpty, setFieldsEmpty] = useState(false);
  const [typeOfBusinessEmpty, settypeOfBusinessEmpty] = useState(false);
  const [Businessname, setBusinessname] = useState("");
  const [BusinessEmail, setBusinessEmail] = useState("");
  const [addressLine1, setaddressLine1] = useState("");
  const [addressLine2, setaddressLine2] = useState("");
  const [landmark, setLandmark] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [about, setAbout] = useState("");

  const [documentsUploaded, setDocumentsUploaded] = useState({
    businessRegistration: false,
    businessAddressProof: false,
    foodLicenseProof: false,
  });
  const [businessRegistration, setbusinessRegistration] = useState(null)
  const [foodLicenseProof, setfoodLicenseProof] = useState(null)
  const [businessAddressProof, setbusinessAddressProof] = useState(null)
  const [wordLimitReached, setWordLimitReached] = useState(false);
  const [showEmailOtpInput, setShowEmailOtpInput] = useState(false);
  const [resetHash, setResetHash] = useState('')
  const [verified, setVerified] = useState(false)
  const [showOTP, setShowOTP] = useState(false);
  const [otpEmailValue, setOtpEmailValue] = useState('');
  const [businessRegistrationUploaded, setBusinessRegistrationUploaded] = useState(false);
  const [businessAddressProofUploaded, setBusinessAddressProofUploaded] = useState(false);
  const [foodLicenseProofUploaded, setFoodLicenseProofUploaded] = useState(false);
  const [otpEmailVerified, setOtpEmailVerified] = useState(false);

  const categories = [
    { id: 'clothing', icon: <GiScaleMail className="text-[50px]" />, name: 'CLOTHING' },
    { id: 'accessories', icon: <GiDoubleNecklace className="text-[50px]" />, name: 'ACCESSORIES' },
    { id: 'home-decor', icon: <GiBedLamp className="text-[50px]" />, name: 'HOME DECOR' },
    { id: 'homemade-food', icon: <GiWaterBottle className="text-[50px]" />, name: 'HOMEMADE FOOD' },
    { id: 'other', icon: <TbCategory className="text-[50px]" />, name: 'OTHERS' },
  ];

  const handlebusinessRegistration = (event) => {
    const file = event.target.files[0];
    checkAndTransformPdf(file, setbusinessRegistration, event);

  };

  const handlefoodLicenseProof = (event) => {
    const file = event.target.files[0];
    checkAndTransformPdf(file, setfoodLicenseProof, event);
  };

  const handlebusinessAddressProof = (event) => {
    const file = event.target.files[0];
    checkAndTransformPdf(file, setbusinessAddressProof, event);
  };

  const checkAndTransformPdf = (pdfFile, setPdfState, event) => {
    const maxSize = 1 * 1024 * 1024; // 1MB in bytes

    if (pdfFile.size > maxSize) {
      toast.error(ErrorConstants.Error.FILE_SIZE_ERROR);
      event.target.value = null;
      return;
    }
    if (!isPdfFile(pdfFile)) {
      toast.error(ErrorConstants.Error.PDF_UPLOAD_ERROR);
      event.target.value = null;
      return;
    }
    TransformPdf(pdfFile, setPdfState);
  };

  // Add a new function to transform PDF files
  const TransformPdf = (pdfFile, setPdfState) => {
    const reader = new FileReader();
    reader.readAsDataURL(pdfFile);
    reader.onload = () => {
      setPdfState(reader.result);
    };
  };

  const toggleInfo = (e) => {
    // Check if the category is already selected
    if (selectedTypeOfBusiness.includes(e)) {
      // If it's selected, remove it from the list
      setSelectedTypeOfBusiness(selectedTypeOfBusiness.filter(item => item !== e));
      setShowInfo(prevState => ({ ...prevState, [e]: false })); // Close the info for the category
    } else {
      // If it's not selected, add it to the list
      setSelectedTypeOfBusiness([...selectedTypeOfBusiness, e]);
      setShowInfo(prevState => ({ ...prevState, [e]: true })); // Open the info for the category
    }
  };

  const validateOtp = (otp) => {
    const otpRegex = /^\d{1,6}$/;
    return otpRegex.test(otp);
  };

  const handleOtpChange = (e) => {
    const enteredOtp = e.target.value;
    setOtpEmailValue(enteredOtp);

    if (enteredOtp.trim() === '') {
      setOtpError('OTP is required');
    } else if (!validateOtp(enteredOtp)) {
      setOtpError('Invalid OTP format');
    } else {
      setOtpError('');
    }
  };
  const [otpError, setOtpError] = useState('');
  const emailOtp = async () => {
    let email = BusinessEmail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      toast.error(ErrorConstants.Error.EMAIL_ERROR_MESSAGE);
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/api/auth/emailotp`, { email });
      toast.success(response.data.message);
      setResetHash(response.data.fullHash);
      setShowEmailOtpInput(true);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message);
    }
  };
  const verifyEmailOtp = async () => {
    try {
      const response = await axios.post(`${API_URL}/api/auth/verifyemailotp`, { email: BusinessEmail, otp: otpEmailValue, resethash: resetHash });
      toast.success(response.data.message);
      setVerified(true)
      setShowEmailOtpInput(false);
      setOtpEmailVerified(true);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message);
    }
  };



  const isValidBusinessEmail = (email) => {
    // Regular expression for a basic email format check
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPANNumber = (value) => {
    const panNumberRegex = /^[A-Za-z]{5}\d{4}[A-Za-z]$/;
    return panNumberRegex.test(value);
  };

  const isValidGSTNumber = (value) => {
    const gstNumberRegex = /^[0-9]{2}[A-Za-z]{4}[0-9A-Za-z]{1}[0-9]{4}[A-Za-z]{1}[0-9A-Za-z]{3}$/;
    return gstNumberRegex.test(value);
  };

  useEffect(() => {
    // Avoid re-render caused by setAllData inside useEffect
    if (!Businessname ||
      !BusinessEmail ||
      !addressLine1 ||
      !addressLine2 ||
      !landmark ||
      !city ||
      !state ||
      !pincode ||
      !panNumber ||
      !gstNumber ||
      !about ||
      !selectedTypeOfBusiness ||
      !businessRegistration ||
      !foodLicenseProof ||
      !businessAddressProof) {
      return;
    }

    setAllData((prevData) => ({
      ...prevData,
      Businessname: Businessname,
      BusinessEmail: BusinessEmail,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      landmark: landmark,
      city: city,
      state: state,
      pincode: pincode,
      panNumber: panNumber,
      gstNumber: gstNumber,
      about: about,
      selectedtypeOfBusiness: selectedTypeOfBusiness,
      businessRegistration: businessRegistration,
      foodLicenseProof: foodLicenseProof,
      businessAddressProof, businessAddressProof
    }));
  }, [Businessname, BusinessEmail, addressLine1, addressLine2, landmark, city, state, pincode, panNumber, gstNumber, about, selectedTypeOfBusiness, businessRegistration, foodLicenseProof, businessAddressProof]);



  const handleNextClick = () => {
    // Check if any of the required fields are empty
    if (
      !Businessname ||
      !addressLine1 ||
      !addressLine2 ||
      !landmark ||
      !city ||
      !state ||
      !pincode ||
      !panNumber ||
      !gstNumber ||
      !about ||
      !selectedTypeOfBusiness ||
      !businessRegistration ||
      !businessAddressProof
    ) {

      toast.error(ErrorConstants.Error.ALL_FIELD_ERROR_MESSAGE);
      setFieldsEmpty(true);
      settypeOfBusinessEmpty(true);
      return;
    }
    if (BusinessEmail && verified == false) {
      toast.error(ErrorConstants.Error.BUSINESS_EMAIL_ERROR )
      return
    }

    if (BusinessEmail && !isValidBusinessEmail(BusinessEmail)) 
    {
      toast.error(ErrorConstants.Error.EMAIL_ERROR_MESSAGE);
      setFieldsEmpty(true);
      return;
    }

    if ( !isValidPANNumber(panNumber)) 
    {
      toast.error(ErrorConstants.Error.INVALID_PAN_NUM);
      setFieldsEmpty(true);
      return;
    }

    if (!isValidGSTNumber(gstNumber) ) 
    {
      toast.error(ErrorConstants.Error.INVALID_GST);
      setFieldsEmpty(true);
      return;
    }

    if (selectedTypeOfBusiness.includes('homemade-food') && !foodLicenseProof) {
      toast.error(ErrorConstants.Error.FOOD_LICENSE_ERROR);
      setFieldsEmpty(true);
      settypeOfBusinessEmpty(true);
      return;
    }
    if (!isPdf(businessRegistration)) {
      toast.error(ErrorConstants.Error.Bussiness_PDF_ERROR);
      return;
    }
    if (!isPdf(foodLicenseProof) && selectedTypeOfBusiness.includes('homemade-food')) {
      toast.error(ErrorConstants.Error.FOOD_LICENSE_ERROR);
      return;
    }
    if (!isPdf(businessAddressProof)) {
      toast.error(ErrorConstants.Error.BUSSINESS_ADDRESS_ERROR);
      return;
    }
    // Check if a category is selected
    if (!selectedTypeOfBusiness || selectedTypeOfBusiness.length === 0) {
      toast.error(ErrorConstants.Error.BUSSINESS_CATEGORY_ERROR)
      settypeOfBusinessEmpty(true);
      return;
    }

    if (
      !isValidPANNumber(panNumber) ||
      !isValidGSTNumber(gstNumber)
    ) {
      toast.error(ErrorConstants.Error.ALL_FIELD_ERROR_MESSAGE)
      setFieldsEmpty(true);
      return;
    }

    setAllData((prevData) => ({
      ...prevData,
      Businessname: Businessname,
      BusinessEmail: BusinessEmail,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      landmark: landmark,
      city: city,
      state: state,
      pincode: pincode,
      panNumber: panNumber,
      gstNumber: gstNumber,
      about: about,
      selectedtypeOfBusiness: selectedTypeOfBusiness,
      businessRegistration: businessRegistration,
      foodLicenseProof: foodLicenseProof,
      businessAddressProof, businessAddressProof
    }))

    setIsEditing(false);
    // Fields are not empty, and required documents are uploaded, proceed to the next step
    onNextStep();
    setFieldsEmpty(false);
    settypeOfBusinessEmpty(false);

  };

  const handlePreviousClick = () => {
    setIsEditing(true); // Set isEditing to true when moving to the previous step
    onPreviousStep();
  };

  const isPdfFile = (file) => {
    return file && file.type === "application/pdf";
  };
  const isPdf = (file) => {
    return file && file.startsWith("data:application/pdf");
  };
  const handleDocumentUpload = (documentType) => {
    // Your code to handle the document upload goes here

    // Assuming you have successfully uploaded the document
    switch (documentType) {
      case "businessRegistration":
        setBusinessRegistrationUploaded(true);
        break;
      case "businessAddressProof":
        setBusinessAddressProofUploaded(true);
        break;
      case "foodLicenseProof":
        setFoodLicenseProofUploaded(true);
        break;
      default:
        break;
    }
  };

  const handleAboutChange = (e) => {
    const inputText = e.target.value;
    const wordCount = inputText.trim().split(/\s+/).length;

    if (wordCount <= 120) {
      setAbout(inputText);
      setWordLimitReached(false);
    } else {
      setWordLimitReached(true);
    }
  };

  return (
    <>

      <div className='flex md:flex-row items-start justify-evenly gap-10 p-2 sm:p-10 sm:flex-col flex-col'>
        {/* left div */}
        <div className="font-bold md:sticky top-10">
          <h1>Business Details</h1>
        </div>

        {/* ------------FORM------------ */}
        {isCreateAccountOpen ? (
          <AccountDetails />
        ) : (
          <form action="">
            <div className="flex flex-col gap-7 mb-10 w-full">
              <div className="flex flex-col">
                <input
                  type="text"
                  placeholder="Enter Business Name *"
                  className="w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                  required
                  maxLength={80}
                  value={Businessname}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Allow all characters
                    const sanitizedValue = inputValue.slice(0, 80);
                    setBusinessname(sanitizedValue);
                  }}
                />
                {fieldsEmpty && !Businessname.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Please fill Business Name .
                  </p>
                )}
              </div>
              <div className="flex flex-row justify-center items-center whitespace-nowrap gap-2">
                <input
                  type="email"
                  placeholder="Business Email Id (Optional)"
                  className="w-full p-2 h-12 px-3 border rounded-xl pr-[90px] focus:outline-none focus:border-[#8A58DC]"
                  required
                  value={BusinessEmail}
                  maxLength={50}
                  onChange={(e) => {
                    const inputValue = e.target.value.toLowerCase();
                    setBusinessEmail(inputValue);

                    // Check if the entered value is a valid email
                    if (isValidBusinessEmail(inputValue) || inputValue === "") {
                      setFieldsEmpty(false);
                    } else {
                      setFieldsEmpty(true);
                    }
                  }}
                />
                {otpEmailVerified ? (
                  <span className="text-green-500 ml-2"><VerifiedIcon /></span>
                ) : (
                  <button className="text-[#8A58DC]" onClick={emailOtp}>
                    {showOTP ? 'Resend OTP' : 'Send OTP'}
                  </button>
                )}

              </div>
              {showEmailOtpInput &&
                <div className='flex w-full relative items-center'>
                  <input
                    type="text"
                    value={otpEmailValue}
                    onChange={handleOtpChange}
                    maxLength={6}
                    placeholder='Enter OTP'
                    className="w-full h-12 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#8A58DC]"
                  />
                  {otpEmailVerified ? (
                    <span className="text-green-500 ml-2">Verified</span>
                  ) : (
                    <Button variant='text' size='small' onClick={verifyEmailOtp} style={{ fontWeight: 'bold', color: '#8A58DC' }} >
                      Verify
                    </Button>
                  )}
                </div>
              }
              {fieldsEmpty && !BusinessEmail.trim() && (
                <p className="flex text-red-500 ml-2">
                  Please fill Business Email.
                </p>
              )}
              {!isValidBusinessEmail(BusinessEmail) && BusinessEmail.trim() && (
                <p className="flex text-red-500 ml-2">
                  Please enter a valid email address (E.g., test@example.com).
                </p>
              )}



              <div className="flex flex-col">

                <input
                  type="text"
                  placeholder="Business Address Line1 *"
                  className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                  required
                  maxLength={50}
                  value={addressLine1}
                  onChange={(e) => setaddressLine1(e.target.value)}
                />
                {fieldsEmpty && !addressLine1.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Please fill the Business Address Line 1.
                  </p>
                )}
              </div>

              <div className="flex flex-col">
                <input
                  type="text"
                  placeholder="Business Address Line2 *"
                  className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                  required
                  maxLength={50}
                  value={addressLine2}
                  onChange={(e) => setaddressLine2(e.target.value)}

                />
                {fieldsEmpty && !addressLine2.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Please fill the Business Address Line 2.
                  </p>
                )}
              </div>

              <div className="flex flex-col">
                <input
                  type="text"
                  placeholder="Landmark *"
                  className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                  required
                  value={landmark}
                  maxLength={30}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Allow everything except digits
                    const sanitizedValue = inputValue.replace(/[0-9]/g, '').slice(0, 30);
                    setLandmark(sanitizedValue);
                  }}

                />
                {fieldsEmpty && !landmark.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Please fill the Landmark.
                  </p>
                )}
              </div>
              <div className="flex flex-col">
                <input
                  type="text"
                  placeholder="City *"
                  className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                  required
                  maxLength={30}
                  value={city}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Allow only alphabets
                    const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '').slice(0, 30);
                    setCity(sanitizedValue);
                  }}
                />
                {fieldsEmpty && !city.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Please fill the City.
                  </p>
                )}
              </div>
              <div className="flex flex-col">
                <input
                  type="text"
                  placeholder="State *"
                  className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                  required
                  maxLength={30}
                  value={state}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Allow only alphabets
                    const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '').slice(0, 30);
                    setState(sanitizedValue);
                  }}
                />
                {fieldsEmpty && !state.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Please fill the State.
                  </p>
                )}
              </div>
              <div className="flex flex-col">
                <input
                  type="text"
                  placeholder="Pincode *"
                  className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                  required
                  value={pincode}
                  maxLength={6}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Check if the entered value is numeric and does not start with 0
                    if (/^[1-9]\d*$/.test(inputValue) || inputValue === "") {
                      setPincode(inputValue);
                    }
                  }}
                />
                {fieldsEmpty && !pincode.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Please fill the Pincode.
                  </p>
                )}
              </div>
              <div className="flex flex-col">
                <input
                  type="text"
                  placeholder="PAN Number *"
                  className="p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC] w-full"
                  required
                  value={panNumber}
                  maxLength={10}
                  onChange={(e) => {
                    const inputValue = e.target.value.toUpperCase();
                    setPanNumber(inputValue);

                    // Check if the entered value is a valid PAN number
                    if (isValidPANNumber(inputValue) || inputValue === "") {
                      setFieldsEmpty(false);
                    } else {
                      setFieldsEmpty(true);
                    }
                  }}
                />
                {fieldsEmpty && !panNumber.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Please fill the PAN number.
                  </p>
                )}
                {!isValidPANNumber(panNumber) && panNumber.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Please enter a valid PAN number (E.g., AAAAA9999A).
                  </p>
                )}
              </div>
              <div className="flex flex-col">
                <input
                  type="text"
                  placeholder="GST Number *"
                  className="p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC] w-full"
                  value={gstNumber}
                  maxLength={15}
                  onChange={(e) => {
                    const inputValue = e.target.value.toUpperCase(); // Convert to uppercase

                    setGstNumber(inputValue);
                    // Check if the entered value is a valid GST number
                    if (isValidGSTNumber(inputValue) || inputValue === "") {
                      setFieldsEmpty(false);
                    } else {
                      setFieldsEmpty(true);
                    }
                  }}
                />
                {fieldsEmpty && !gstNumber.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Please fill the GST number.
                  </p>
                )}
                {!isValidGSTNumber(gstNumber) && gstNumber.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Please enter a valid GST number (E.g., 06BZAHM6385P6Z2).
                  </p>
                )}
              </div>

            </div>


            {/* ------------CATAGORIES------------ */}

            <div className="font-bold mb-4">
              <div className="mb-4">
                <span>What you will be selling on Ekaiv ?</span>
                <span className="ml-2 text-red-500">*</span>
              </div>

              <div className="flex flex-wrap w-full h-full gap-5">
                {categories.map((category) => (
                  <div
                    key={category.id}
                    className={`flex w-1/3 h-20 p-2 border border-slate-200 rounded-md justify-center items-center hover:scale-105 cursor-pointer hover:shadow-sm ${selectedTypeOfBusiness.includes(category.id) ? "bg-[#8A58DC] text-white" : ""
                      }`}
                    onClick={() => toggleInfo(category.id)}
                  >
                    <div className="flex flex-col justify-center items-center text-xs p-1 sm:text-base">
                      {category.icon}
                      {category.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {typeOfBusinessEmpty && (!selectedTypeOfBusiness || selectedTypeOfBusiness.length === 0) && (
              <p className="flex text-red-500 ml-2">
                Please select at least one category.
              </p>
            )}



            {/* ------------Drop Boxes------------- */}
            {selectedTypeOfBusiness && (
              <div className="font-bold w-full grid grid-cols-1 gap-2 mb-4">
                {selectedTypeOfBusiness.length > 0 && (
                  <div key={selectedTypeOfBusiness[0]} className="flex flex-col">
                    <div>
                      {selectedTypeOfBusiness.includes('homemade-food') && (
                        <div>
                          <div className="flex flex-row">
                            <p className="mt-3 mb-2">Food License Proof</p>
                            <Tooltip
                              title="Any document that has your food license verification. For example, FSSAI (upload PDF less than 1 MB)."
                            >
                              <IconButton>
                                <InfoIcon className="text-[#8A58DC] top-4 cursor-pointer" />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <div>
                            <input
                              type='file'
                              className="mt-2 border rounded p-4 w-full "
                              id="foodLicenseProofDocumentUpload"
                              name="foodLicenseProofDocumentUpload"
                              accept=".pdf"

                              onChange={handlefoodLicenseProof}
                            ></input>
                          </div>
                          {documentsUploaded['homemade-food'] && (
                            <p className="text-green-500">Food License Proof uploaded successfully!</p>
                          )}
                        </div>
                      )}
                      <div className="flex flex-row">
                        <p className="mt-3 mb-2">Business Registration Document.</p>
                        <Tooltip
                          title="Gumasta licence or UDYAM Registration or MSME Registration or any other state-based business registration document (upload PDF less than 1 MB)."
                        >
                          <IconButton>
                            <InfoIcon className="text-[#8A58DC] top-4 cursor-pointer" />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div>
                        <input
                          type="file"
                          className="mt-2 border rounded p-4 w-full"
                          id="businessRegistrationDocumentUpload"
                          name="businessRegistrationDocumentUpload"
                          accept=".pdf"
                          onChange={handlebusinessRegistration}
                        />
                      </div>
                      {businessRegistrationUploaded && (
                        <p className="text-green-500">Business registration document uploaded successfully!</p>
                      )}
                      <div className="mt-4">
                        <div className="flex flex-row">
                          <p className="mt-3 mb-2">Business Address Proof</p>
                          <Tooltip
                            title="Any document that has your business address for verification. For example, Electricity Bill (upload PDF less than 1 MB)."
                          >
                            <IconButton>
                              <InfoIcon className="text-[#8A58DC] top-4 cursor-pointer" />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div>
                          <input
                            type="file"
                            className="mt-2 border rounded p-4 w-full"
                            id="businessAddressProofDocumentUpload"
                            name="businessAddressProofDocumentUpload"
                            accept=".pdf"
                            onChange={handlebusinessAddressProof}
                          />
                        </div>
                      </div>
                      {businessAddressProofUploaded && (
                        <p className="text-green-500">Business Address Proof uploaded successfully!</p>
                      )}
                    </div>
                    {(documentsUploaded['homemade-food'] || documentsUploaded['other']) && (
                      <p className="text-green-500">
                        {selectedTypeOfBusiness.includes('homemade-food') ? 'Food License Proof' : 'Business Address Proof'} uploaded successfully!
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className="flex flex-col">
              <div className="font-bold flex flex-row items-center">
                <span className="mt-3 mb-2">About</span>
                <span className="ml-2 text-red-500">*</span>
                <Tooltip
                  title="Write about your Business Details in (100-120) words."
                >
                  <IconButton>
                    <InfoIcon className="text-[#8A58DC] top-4 cursor-pointer" />
                  </IconButton>
                </Tooltip>
              </div>
              <input
                type="text"
                placeholder="About *"
                className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                required
                value={about}
                onChange={handleAboutChange}
              />
              <p className="flex ml-2">
                Word Count: {about.trim().split(/\s+/).length} / 120
              </p>
              {fieldsEmpty && !about.trim() && (
                <p className="flex text-red-500 ml-2">
                  Please fill the About field.
                </p>
              )}
              {wordLimitReached && (
                <p className="flex text-red-500 ml-2">Word limit (120) reached.</p>
              )}
            </div>


            {/* ------------BUTTON------------ */}

            <div className="flex flex-row gap-5 p-5">

              <Link
                onClick={handlePreviousClick}
                className="relative inline-flex items-center justify-center px-6 py-1 sm:py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-[#8A58DC] rounded-full shadow-md group"
              >
                <span className="absolute inset-0 flex items-center text-4xl justify-center w-full h-full text-white duration-300 translate-x-full bg-[#8A58DC] group-hover:translate-x-0 ease">
                  <IoIosArrowRoundBack />
                </span>
                <span className="absolute flex items-center justify-center w-full h-full text-[#8A58DC] font-bold transition-all duration-300 transform group-hover:translate-x-full ease">
                  Previous
                </span>
                <span className="relative invisible">Previous</span>
              </Link>

              <button
                onClick={handleNextClick}
                type="button"
                class="relative inline-flex items-center justify-center  px-6 py-1 sm:py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-[#8A58DC] rounded-full shadow-md group"
              >
                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full text-4xl bg-[#8A58DC] group-hover:translate-x-0 ease">
                  <IoIosArrowRoundForward />
                </span>
                <span class="absolute flex items-center justify-center w-full h-full text-[#8A58DC] font-bold transition-all duration-300 transform group-hover:translate-x-full ease">
                  Next Section
                </span>
                <span class="relative invisible">Next Section</span>
              </button>
            </div>
          </form>
        )}

        {/* -------------right part---------------- */}
        <div className='flex p-5 md:w-[500px] md:sticky top-32'>
          <img src="https://img.freepik.com/premium-vector/onlin-ebusiness-partnership-two-male-cartoon-characters-businessmen-shaking-hands-making-business-deal-business-management-remotely-flat-vector-illustration_241107-1243.jpg?size=626&ext=jpg&ga=GA1.1.584503204.1684751112&semt=ais" loading='lazy-loading' alt="registration" />
        </div>
      </div >
    </>
  );
}
export default BusinessDetails;