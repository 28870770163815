import React, { useState } from 'react';
import AdminMenuBar from '../component/SuperAdmin/Dashboard/AdminMenuBar'
import AdminNavbar from '../component/SuperAdmin/Dashboard/AdminNavbar';
import Dashboard from '../component/SuperAdmin/Dashboard/Dashboard';
import ManageVendors from '../component/SuperAdmin/ManageVendors/ManageVendors';
import ManageProduct from '../component/SuperAdmin/ManageProducts/ManageProduct'
import AccountSetting from '../component/SuperAdmin/AccountSetting/AccountSetting';
import FestiveBanner from '../component/SuperAdmin/FestivalBanners/FestiveBanner';
import OurOrders from '../component/SuperAdmin/Our Orders/OurOrders';
import Report from '../component/SuperAdmin/Report/Report';
import Logout from '../component/UserProfile/Logout';
import CreateCategory from '../component/SuperAdmin/CreateCategory/CreateCategory';
import OurBlogs from '../component/SuperAdmin/Our Blogs/OurBlogs';
import AccountingSheet from '../component/SuperAdmin/Pricing/AccountingSheet';
import AboutUs from '../component/SuperAdmin/AboutUs/AboutUs';

const SuperAdmin = () => {
    const [activeTab, setActiveTab] = useState('dashboard');
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const [activeOrders, setActiveOrders] = useState('newOrders');


    return (
        <>
            <div className="flex flex-row bg-gray-100">
                <AdminMenuBar activeTab={activeTab} handleTabClick={handleTabClick} />
                <div className='flex flex-col w-full'>
                    <div>
                        <AdminNavbar setActiveTab={setActiveTab} />
                    </div>

                    <div className=' bg-gray-100 flex justify-center items-center' >
                        {activeTab === 'dashboard' && (
                            <>
                                <Dashboard />
                            </>
                        )}
                    </div>
                    <div className=' bg-gray-100' >
                        {activeTab === 'vendors' && (
                            <>
                                <ManageVendors />

                            </>
                        )}
                    </div>
                    <div className=' bg-gray-100' >
                        {activeTab === 'products' && (
                            <>
                                <ManageProduct />
                            </>
                        )}
                    </div>

                    <div className=' bg-gray-100 flex justify-center items-center' >

                        {activeTab === 'setting' && (
                            <>
                                <AccountSetting />
                            </>
                        )}
                    </div>
                    <div className=' bg-gray-100 flex justify-center items-center' >

                        {activeTab === 'banner' && (
                            <>
                                <FestiveBanner />
                            </>
                        )}
                    </div>
                    <div className=' bg-gray-100 flex justify-center items-center' >

                        {activeTab === 'ourorders' && (
                            <>
                                <OurOrders />
                            </>
                        )}
                    </div>
                    <div className=' bg-gray-100 flex justify-center items-center' >

                        {activeTab === 'ourblogs' && (
                            <>
                                <OurBlogs />
                            </>
                        )}
                    </div>
                    <div className=' bg-gray-100 flex justify-center items-center' >

                        {activeTab === 'createcategory' && (
                            <>
                                <CreateCategory />
                            </>
                        )}
                    </div>


                    <div className=' bg-gray-100 flex justify-center items-center' >

                        {activeTab === 'report' && (
                            <>
                                <Report />
                            </>
                        )}
                    </div>
                    <div className=' bg-gray-100 flex justify-center items-center' >

                        {activeTab === 'accountingsheet' && (
                            <>
                                <AccountingSheet />
                            </>
                        )}
                    </div>

                    <div className=' bg-gray-100 flex justify-center items-center'>

                    {activeTab === 'aboutus' && (
                            <>
                                <AboutUs/>
                            </>
                        )}

                    </div>

                    <div className=' bg-gray-100' >
                        {activeTab === 'logout' && (
                            <>
                                <Logout />
                            </>
                        )}
                    </div>


                </div>
            </div>
        </>
    )
}

export default SuperAdmin;
