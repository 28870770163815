import React, { useEffect } from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import { Link } from 'react-router-dom'

const Footer = () => {

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const links = document.querySelectorAll(".footer-link");
    links.forEach((link) => {
      link.addEventListener("click", scrollToTop);
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", scrollToTop);
      });
    };
  }, []);

  //icons
  const socialMediaLinks = [
    { name: "Facebook", url: "https://facebook.com/TheEkaiv", icon: <AiFillFacebook size={25} /> },
    { name: "Twitter", url: "https://twitter.com/TheEkaiv", icon: <AiOutlineTwitter size={25} /> },
    { name: "Instagram", url: "https://instagram.com/the_ekaiv/", icon: <AiFillInstagram size={25} /> },
    { name: "YouTube", url: "https://www.youtube.com/channel/UCtnGrvphg8rffi1XK3lADwg", icon: <AiFillYoutube size={25} /> }
  ];

  //company links
  const company = [
    {
      name: "About Us",
      link: "/about-us",
    },
    {
      name: "Careers",
      link: "/carrer-page",
    },
    {
      name: "Our Blog",
      link: "/our-blog",
    },
    {
      name: "Reviews",
      link: "/reviews",
    }
  ]

  //support links
  const support = [
    {
      name: "FAQ's",
      link: "/faq",
    },
    {
      name: "Contact Us",
      link: "/contact-us",
    },
    {
      name: "Live Chat",
      link: "/live-chat",
    },
    {
      name: "Become A Seller",
      link: "/become-seller",
    }
  ]

  //user policy
  const userPolicy = [
    {
      name: "Terms & Conditions",
      link: "/terms-and-condition",
    },
    {
      name: "Vendor Account Policy",
      link: "/vendor-account-policy",
    },
    {
      name: "Shipping Policy",
      link: "/shipping-policy",
    },
    {
      name: "Return Policy",
      link: "/return-policy",
    }
  ]



  return (
    <div className=" text-white" style={{
      background: `linear-gradient(90deg, rgba(255,107,103,0.7) 14%, rgba(231,88,146,0.7) 24%, rgba(208,69,188,0.7) 42%, rgba(149,84,217,0.7) 65%, rgba(102,104,231,0.7) 84%, rgba(27,136,254,0.7) 100%)`
    }}>
      <div className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 sm:px-8 px-5 py-16 lg:text-center">
        <div className="px-5 text-center sm:text-start flex sm:block flex-col items-center">
          <br />

          <p>Where Creativity Meets Convenience <br />
            Your Destination for Unique Finds.
          </p>
          <div className="flex items-center space-x-4 mt-4 text-white ">
            {/*icons*/}
            {socialMediaLinks.map((item, index) => (
              <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">
                <span className="  cursor-pointer hover:text-[#000000]">{item.icon}</span>
              </a>
            ))}
          </div>
        </div>

        <div className="text-center lg:text-start">
          <h1 className="mb-1 font-semibold">Company</h1>
          <div className=" flex flex-col">
            {/*company links*/}
            {company.map((item, index) => (
              <Link
                key={index}
                to={item.link}
                className="text-white hover:text-gray-900 duration-300 text-sm cursor-pointer leading-6 footer-link"
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>

        <div className="text-center sm:text-start">

          <h1 className="mb-1 font-semibold">Support</h1>
          <div
            className=" flex flex-col "
          >
            {support.map((item, index) => (
              <Link key={index} to={item.link} className="text-white-700 hover:text-gray-900 duration-300
              text-sm cursor-pointer leading-6 footer-link">{item.name}</Link>
            ))}
          </div>
        </div>

        <div className="text-center lg:text-start text-white">
          <h1 className="mb-1 font-semibold">User Policy</h1>
          <div
            className=" flex flex-col "
          >
            {userPolicy.map((item, index) => (
              <Link key={index} to={item.link} className="text-white-700 hover:text-gray-900 duration-300
              text-sm cursor-pointer leading-6 footer-link">{item.name}</Link>
            ))}
          </div>
        </div>
      </div>

      <div
        className="grid grid-cols-1 sm:grid-cols-2  gap-10
         text-center pt-2 text-white text-sm pb-8 w-full "
      >
        <div className="flex justify-start items-start px-10"><span>© 2023 Perceptions Technologies. All rights reserved.</span></div>
        <div className=" w-full flex items-center justify-center md:items-end md:justify-end px-10">
          <Link to='/privacy-policy' className="footer-link "><span >Privacy Policy</span></Link>
        </div>

      </div>
    </div>
  );
};

export default Footer;