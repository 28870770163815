import React from 'react'

function VendorAccountPolicy() {
    const emailServices = "services@theekaiv.in";
    const emailSupport = "support@theekaiv.in";
    const handleClick = () => {
        window.open(`mailto:${emailSupport}`);
      };
    const handleClickServices = () => {
        window.open(`mailto:${emailServices}`);
    };
    return (
        <div>
            <div className='flex justify-center items-center bg-gray-100 text-justify'>
                <div className='w-[85%] pt-10 text-justify'>
                    <div className='text-center w-full font-bold text-xl p-5'>
                        Vendor Account and Delete Policy 

                    </div>
                    <div className=' break-words'>
                        <p>
                        At The Ekaiv, we prioritize transparency and user control. Our Delete Policy outlines how we handle user accounts and data. Please carefully review the following details:
                        </p>
                        <br />
                        <p>
                        <span className=' font-bold '>1. User Account Deletion:</span><br />
                        <br />
                        Users have the option to log out of their accounts at any time, ensuring their active session is terminated.
                        Account deletion is not mandatory, providing users with continued access to their accounts even after logging out.
                        
                        </p><br />
                        <p>
                        <span className=' font-bold '>2. Data Security:</span><br />
                        <br />
                        User data, including addresses and other details shared during registration, is handled with the utmost care.
                        Passwords are encrypted to enhance security, protecting user credentials from unauthorized access.
                        </p><br />
                        <p>
                        <span className=' font-bold '>3. Account Management:</span><br />
                        <br />
                        Users have the flexibility to update their contact and email details through the account management features.
                        The option to change personal information ensures that user accounts remain accurate and up-to-date.
                        </p><br/>
                        <p>
                        <span className=' font-bold '>4. Vendor Account Deletion:</span><br />
                        <br />
                        Vendor accounts engaging in suspicious activities or violating community norms may be subject to deletion by The Ekaiv team.
                        Batches, signifying genuine and proper vendor responses, are provided based on adherence to our guidelines.
                        </p><br/>
                        <p>
                        <span className=' font-bold '>5. User Control:</span><br />
                        <br />
                        Users are empowered to manage their accounts, including logging out, updating details, and controlling their overall account settings.
                        The decision to delete an account remains at the discretion of the user, allowing for continued use or account removal.
                        </p><br/>
                        <p>
                        <span className=' font-bold '>6. Accountability:</span><br />
                        <br />
                        Users are encouraged to adhere to community guidelines and norms to maintain a positive and secure environment.
                        Vendors must comply with ethical practices, and failure to do so may result in account deletion.
                        </p><br />
                        <p>
                        <span className=' font-bold '>7. Batches for Genuine Vendors:</span><br />
                        <br />
                        Genuine vendors, demonstrating proper responses and adherence to guidelines, may be awarded batches to signify their authenticity.
                        Batches serve as recognition for vendors committed to maintaining high standards on The Ekaiv platform.
                        </p><br />
                        
                        <p>
                        <span className=' font-bold '>8. Team Oversight:</span><br />
                        <br />
                        The Ekaiv team actively monitors vendor activities to ensure compliance with community norms.
                        In cases of suspicious activities or policy violations, the team may exercise the right to delete vendor accounts.
                        </p><br />
                        <p>
                        <span className=' font-bold '>9. User Assistance:</span><br />
                        <br />
                        For any assistance regarding account management, users can contact our support team at <span className=' text-blue-400 cursor-pointer' onClick={handleClick} >{emailSupport}</span>.
                        Questions or concerns about the Delete Policy can be addressed by reaching out to <span className=' text-blue-400 cursor-pointer' onClick={handleClickServices}>{emailServices}</span>.
                        By using The Ekaiv App and Website, users and vendors agree to abide by the outlined Delete Policy. This policy ensures user control and data security while maintaining a secure and reliable platform for our community.
                        <br />
                        </p>
                        <p>
                        Thank you for being a part of The Ekaiv, where user privacy and control are paramount!
                        </p><br />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default VendorAccountPolicy
