import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL, config } from '../../../config/config';
import { toast } from 'react-toastify';

function PopularCard({ title, imageUrl, blogId }) {
    const [loading, setLoading] = useState(true);
    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate();

    const getBlogs = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${API_URL}/api/admin-profile/getallblogs`, config);
            setBlogs(response.data.blog);
        } catch (error) {
            toast.error('Error fetching blogs');
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getBlogs();
    }, []);

    const go = () => {
        navigate(`/specific-blog/${blogId}`);
        window.scrollTo(0, 0);
    };

    return (
        <div>
            <div className='flex gap-10 justify-normal items-center cursor-pointer'>
                <img src={imageUrl.url} alt={title} onClick={go} className='w-20 h-20' />
                <span className='flex-wrap h-20' onClick={go}>{title.slice(0, 70)}</span>
            </div>
            <hr className='mt-2' />
        </div>
    )
}

export default PopularCard