// ShareModal.js

import React from 'react';
import PropTypes from 'prop-types';

const ShareModal = ({ children, onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-20 bg-black bg-opacity-50">
            <div className="bg-white w-80 p-6 rounded-lg shadow-md">
                <div className="flex items-center justify-between mb-4">
                    <h2 className="text-xl font-semibold">Share Options</h2>
                    <button className="text-gray-500 hover:text-gray-700" onClick={onClose}>
                        Close
                    </button>
                </div>
                <div className="grid grid-cols-2 gap-4">
                    {children}
                </div>
            </div>
        </div>
    );
};

ShareModal.propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ShareModal;
