import React from 'react'

const Aboutusvedio = () => {
  return (
    <div>
      <div className="w-full h-[450px] mt-16 bg-transparent rounded-lg shadow-sm p-3 relative cursor-pointer">
      <iframe className="w-full h-full"
       src="https://www.youtube.com/embed/5sUrbyi9bKc?si=Ehm-qnDdv9HxwJmq" 
       title="YouTube video player" 
       frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
      allowfullscreen></iframe>
          
          
        </div>
      </div>
    
  
  )
}

export default Aboutusvedio
