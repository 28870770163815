import React, { useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { API_URL, config } from '../../../config/config';
import Loader from '../../common/Loader';
import ErrorConstants from '../../../constants/ErrorConstants';
import Button from '../../common/Button';
import SuccessConstant from '../../../constants/SuccessConstant';

const EditProduct = ({ productId }) => {
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState(null)
    const [images, setImages] = useState([]);
    const [sizeChartImage, setSizeChartImage] = useState('');
    const [productName, setProductName] = useState('');
    const vendor = useSelector((state) => state.vendor);
    const categories = vendor.vendor.BussinessDetails.typeOfBusiness;
    const [description, setDescription] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [type, setType] = useState('');
    const [kidsCategory, setkidsCategory] = useState('');
    const [customization, setCustomization] = useState('');
    const [material, setMaterial] = useState('');
    const [internalMaterial, setInternalMaterial] = useState('');
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState('');
    const [dimensions, setDimensions] = useState('');
    const [originalPrice, setOriginalPrice] = useState('');
    
    const [discountPrice, setDiscountPrice] = useState('');
    const [modelSize, setModelSize] = useState('');
    const [stock, setStock] = useState('');
    const [processingTime, setProcessingTime] = useState('');
    const [shelfLife, setShelfLife] = useState('');
    const [limitOfManufacturing, setLimitOfManufacturing] = useState('');

    // -------------------------------------------------------
    const [productNameError, setProductNameError] = useState(false);
    const [subcategoryError, setSubcategoryError] = useState(false);
    const [typeError, setTypeError] = useState(false);
    const [kidsCategoryError, setKidsCategoryError] = useState(false);
    const [customizationError, setCustomizationError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [materialError, setMaterialError] = useState(false);
    const [internalmaterialError, setInternalMaterialError] = useState(false);
    const [processingTimeError, setProcessingTimeError] = useState(false);
    const [limitOfManufacturingError, setLimitOfManufacturingTimeError] = useState(false);
    const [productImageError, setProductImageError] = useState(false);
    const [sizeError, setSizeError] = useState(false);
    const [yearError, setYearError] = useState(false);
    const [modelSizeError, setModelSizeError] = useState(false);
    const [sizeChartImageError, setSizeChartImageError] = useState(false);
    const [dimensionsError, setDimensionsError] = useState(false);
    const [originalPriceError, setOriginalPriceError] = useState(false);
    const [discountPriceError, setDiscountPriceError] = useState(false);
    const [stockError, setStockError] = useState(false);
    const [shelfLifeError, setShelfLifeError] = useState(false);
    // ******************************************
    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                if (!params) {
                    console.error('Product or productId is undefined');
                    return;
                }

                const response = await axios.get(
                    `${API_URL}/api/Products/getProduct/${productId}`, config
                );

                const productDetails = response.data;
                setProducts(productDetails.product);
                

            } catch (error) {
                console.error('Error fetching product details:', error);
            }
        };

        fetchProductDetails();
    }, [params]);


    const handleVideoChange = (event) => {
        const videoFile = event.target.files[0];

        const maxSize = 50 * 1024 * 1024; // 50 MB in bytes
        if (videoFile && videoFile.size > maxSize) {
            alert('File size exceeds the maximum limit (50 MB). Please choose a smaller file.');
            return;
        }

        transformVideo(videoFile);
    };

    const transformVideo = (videoFile) => {
        const reader = new FileReader();
        reader.readAsDataURL(videoFile);
        reader.onload = () => {
            const videoData = reader.result;
            setSelectedVideo(videoData);
        };
    };

    const handleProductImageChange = (event) => {
        const productImages = event.target.files;
        transformProductImages(productImages);
    };

    const transformProductImages = (productImages) => {
        const newImages = [];

        for (let i = 0; i < productImages.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(productImages[i]);
            reader.onload = () => {
                newImages.push(reader.result);
                if (newImages.length === productImages.length) {
                    setImages(newImages);
                }
            };
        }
    };

    const handleImageChange = (event) => {
        const sizeChartImageFile = event.target.files[0];
        transformSizeChartImage(sizeChartImageFile);
    };

    const transformSizeChartImage = (sizeChartImageFile) => {
        const reader = new FileReader();
        reader.readAsDataURL(sizeChartImageFile);
        reader.onload = () => {
            const sizeChartImageData = reader.result;
            setSizeChartImage(sizeChartImageData);
        };
    };

    const handleSizeChange = (event) => {
        const size = event.target.value;
        setSelectedSizes((prevSelectedSizes) => {
            if (prevSelectedSizes.includes(size)) {
                return prevSelectedSizes.filter((s) => s !== size);
            } else {
                return [...prevSelectedSizes, size];
            }
        });
    };

    // Add similar handlers for other input fields as needed





    useEffect(() => {
        if (products) {
            setProductName(products.productName);
            setDescription(products.description);
            setSelectedCategory(products.selectedCategory);
            setType(products.type);
            setkidsCategory(products.kidsCategory);
            setMaterial(products.material);
            setCustomization(products.customization);
            setInternalMaterial(products.internalMaterial);
            setSelectedSizes(products.selectedSizes);
            setDimensions(products.dimensions);
            setOriginalPrice(products.originalPrice);
            setDiscountPrice(products.discountPrice)
            setStock(products.stock);
            setProcessingTime(products.processingTime);
            setShelfLife(products.shelfLife);
            setLimitOfManufacturing(products.limitOfManufacturing);
            setImages(products.images);
            setSizeChartImage(products.setSizeChartImage);
        }
        
    }, [params, products])

    if (selectedCategory == 'Girl') {
        setSelectedCategory('clothing');
        setType('kids');
        setkidsCategory('Girl');
        return; // Exit early
    } else if (selectedCategory == 'Boy') {
        setSelectedCategory('clothing');
        setType('kids');
        setkidsCategory('Boy');
        return; // Exit early
    }else if( selectedCategory == 'Men'){
        setSelectedCategory('clothing');
        setType('Men')
    }else if(selectedCategory == 'Women'){
        setSelectedCategory('clothing');
        setType('Women')
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        // Validation: Check if the product name is empty
        if (!productName) {
            setProductNameError(true);
            toast.error(ErrorConstants.Error.PRODUCT_NAME_ERROR);
            setLoading(false);
            return;
        }
        // Validation: Check if the category and subcategory are selected
        if (!selectedCategory) {
            setSubcategoryError(true);
            toast.error(ErrorConstants.Error.CATEGORY_SELECT_ERROR);
            setLoading(false);
            return;
        }
        
       

        if (selectedCategory === 'clothing') {
            if (!type) {
                setTypeError(true);
                toast.error(ErrorConstants.Error.CATEGORY_TYPE_ERROR);
                setLoading(false);
                return;
            }

            if (type === 'kids' && !kidsCategory) {
                setKidsCategoryError(true);
                toast.error(ErrorConstants.Error.CATEGORY_KIDS_ERROR);
                setLoading(false);
                return;
            }
        }

        // Validation: Check if customization is selected
        if (!customization) {
            setCustomizationError(true);
            toast.error(ErrorConstants.Error.PRODUCT_CUSTOMIZATION_ERROR);
            setLoading(false);
            return;
        }

        // Validation: Check if the Product Specification is empty or exceeds the maximum character limit
        if (!description || description.length > 1000) {
            setDescriptionError(true);
            toast.error(ErrorConstants.Error.PRODUCT_SPECIFICATION_ERROR);
            setLoading(false);
            return;
        }

        // Validation: Check if the material field is empty for 'homemade-food' category
        if (selectedCategory === 'homemade-food' && !material) {
            setMaterialError(true);
            toast.error(ErrorConstants.Error.HOMEMADE_ERROR);
            setLoading(false);
            return;
        }

        // Validation: Check if the material field is empty for 'clothing' category
        if (selectedCategory === 'clothing' && !material) {
            setMaterialError(true);
            toast.error(ErrorConstants.Error.CLOTHING_ERROR);
            setLoading(false);
            return;
        }

        // Validation: Check if the internalMaterial field is empty for 'clothing' category
        if (selectedCategory === 'clothing' && !internalMaterial) {
            setInternalMaterialError(true);
            toast.error(ErrorConstants.Error.CLOTHING_ERROR);
            setLoading(false);
            return;
        }

        // Additional Material Validation: Check if the material field is empty for specific categories
        if (['accessories', 'home-decor', 'other' /* add other categories here */].includes(selectedCategory) && !material) {
            setMaterialError(true);
            toast.error(ErrorConstants.Error.MATERIAL_ERROR);
            setLoading(false);
            return;
        }

        // Validation: Check if the Processing time is empty
        if (!processingTime) {
            setProcessingTimeError(true);
            toast.error(ErrorConstants.Error.PROCESSING_ERROR);
            setLoading(false);
            return;
        }
        // Validation: Check if the limit of manufacturing is empty
        if (!limitOfManufacturing) {
            setLimitOfManufacturingTimeError(true);
            toast.error(ErrorConstants.Error.MANUFACTURING_ERROR);
            setLoading(false);
            return;
        }

        // Check if no images are selected
        if (images.length === 0) {
            setProductImageError(true);
            toast.error(ErrorConstants.Error.ONE_IMG_ERROR);
            setLoading(false);
            return;
        }

        //  Check if the Size and year is empty
        if (selectedCategory === 'clothing') {
            if (type === 'kids' && selectedSizes.length === 0) {
                setYearError(true);
                toast.error(ErrorConstants.Error.ONE_YEAR_ERROR);
                setLoading(false);
                return;
            } else if (type !== 'kids' && selectedSizes.length === 0) {
                setSizeError(true);
                toast.error(ErrorConstants.Error.ONE_SIZE_ERROR);
                setLoading(false);
                return;
            }
        }


        //  Check if the Size chart is empty
        if (selectedCategory === 'clothing') {
            // Validation: Check if the Size Chart Image is uploaded
            if (!products.sizeChartImage) {
                setSizeChartImageError(true);
                toast.error(ErrorConstants.Error.ONE_SIZE_CHART_ERROR);
                setLoading(false);
                return;
            }

            // Validation: Check if the Model Size field is empty
            if (!products.modelSize) {
                setModelSizeError(true);
                toast.error(ErrorConstants.Error.MODEL_ERROR);
                setLoading(false);
                return;
            }
        }

        // Validation in your handleSubmit function
        if (['accessories', 'home-decor', 'other'].includes(selectedCategory)) {
            // Validation: Check if the dimensions field is empty
            if (!dimensions) {
                setDimensionsError(true);
                toast.error(ErrorConstants.Error.DIMENSIONS_ERROR);
                setLoading(false);
                return;
            } else {
                setDimensionsError(false);
            }
        }
        // Validation: Check if the Original Price is empty
        if (!originalPrice) {
            setOriginalPriceError(true);
            toast.error(ErrorConstants.Error.ORIGINAL_PRICE_ERROR);
            setLoading(false);
            return;
        }
        // Validation: Check if the Discount Price is empty
        if (!discountPrice) {
            setDiscountPriceError(true);
            toast.error(ErrorConstants.Error.DISCOUNTED_PRICE_ERROR);
            setLoading(false);
            return;
        }
        // Validation: Check if the Stock is empty
        if (!stock) {
            setStockError(true);
            toast.error(ErrorConstants.Error.STOCK_ERROR);
            setLoading(false);
            return;
        }

        // Validation in your handleSubmit function
        if (selectedCategory === 'homemade-food') {
            // Validation: Check if the shelf life is empty
            if (!shelfLife) {
                setShelfLifeError(true);
                toast.error(ErrorConstants.Error.SHELF_ERROR);
                setLoading(false);
                return;
            } else {
                setShelfLifeError(false);
            }
        }

        let categoryValue = '';

        if (selectedCategory === 'clothing') {
            if (type === 'kids') {
                categoryValue = kidsCategory; // Set category to 'Boy' or 'Girl'
            } else {
                categoryValue = type; // Set category to 'Men' or 'Women'
            }
        } else {
            categoryValue = selectedCategory; // Set category for other categories (e.g., 'Home Decor', 'Homemade Food')
        }


        try {
            const updatedProductData = {
                // Include all the fields you want to update
                productName,
                description,
                selectedCategory: categoryValue,
                type,
                kidsCategory: kidsCategory,
                material,
                internalMaterial,
                processingTime,
                sizeChartImage: products.sizeChartImage,
                selectedSizes: selectedSizes,
                images,
                dimensions,
                originalPrice,
                discountPrice,
                modelSize: products.modelSize,
                stock,
                customization,
                shelfLife,
                limitOfManufacturing,

            };


            const response = await axios.put(`${API_URL}/api/vendor/updateProduct/vendors/${products._id}`, updatedProductData, config);

            if (response.status >= 200 && response.status < 300) {
                toast.success(SuccessConstant.Success.PRODUCT_UPDATE_SUCCESS);
                window.location.href = '/vendor-profile';
            } else {
                toast.error('Failed to update product');
            }
        } catch (error) {
            // Handle errors
            console.error('Error updating product:', error);
            toast.error('Failed to update product 2');
        }
    };

    const handleCancel = () => {
        toast.success(SuccessConstant.Success.EditDiscard);
        window.location.href = '/vendor-profile';
    };
    return (

        <div className='container mx-auto p-4 mb-2'>
            <form className='max-w-full mx-auto bg-[#F5f6f9] shadow-md rounded-lg px-8 pt-6 pb-8 mb-4 '>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className='left w-full flex-wrap flex flex-col   px-2 mb-4'>
                        <div className='flex flex-col mt-2'>
                            <div className='flex gap-2 '>

                                <label htmlFor='productName' className="text-sm font-semibold">Product Name</label>
                                <div>
                                    <Tooltip title={<h1 style={{ fontSize: 16 }}>Do not exceed 180 characters when entering the product name.</h1>} >
                                        <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                    </Tooltip>
                                </div>

                            </div>

                            <input
                                type='text'
                                placeholder='Add name of your product'
                                value={productName}
                                maxLength={180}
                                onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    setProductName(selectedValue);
                                    setProductNameError(false);
                                }}
                                className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                            />
                            {productNameError && <p className="text-red-500 text-sm mt-1">Please enter the Product Name.</p>}
                        </div>
                        <div className='flex flex-col mt-2'>
                            <label htmlFor='category' className="text-sm font-semibold">Choose a category</label>
                            <select
                                id='category'
                                name='category'
                                value={selectedCategory}
                                onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    setSelectedCategory(selectedValue);
                                    setSubcategoryError(false);
                                }}
                                className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                            >
                                <option value=''>Select a category</option>
                                {categories?.map((category, index) => (
                                    <option key={index} value={category}>
                                        {category.charAt(0).toUpperCase() + category.slice(1)}
                                    </option>
                                ))}

                            </select>
                            {subcategoryError && <p className="text-red-500 text-sm mt-1">Please select the required Category.</p>}
                        </div>
                        <div className='flex flex-col mt-2'>

                            {selectedCategory === 'clothing' ? <>
                                <label htmlFor='type' className="text-sm font-semibold">
                                    Type</label>
                                <select id='type' name='type' value={type}
                                    onChange={(e) => {
                                        setType(e.target.value);
                                        setTypeError(false); // Reset description error on change
                                    }}
                                    className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                >
                                    <option value=''>Select a type</option>
                                    <option value='Men'>Men</option>
                                    <option value='Women'>Women</option>
                                    <option value='kids'>Kids</option>

                                </select>{typeError && <p className="text-red-500 text-sm mt-1">Please select the Type Of Category.</p>} </> : <></>

                            }
                        </div>

                        <div className='flex flex-col mt-2'>
                            {(selectedCategory === 'clothing' &&
                                type === 'kids') && (<>
                                <label htmlFor='type' className="text-sm font-semibold">Kids Category</label>
                                <select id='kidsCategory' name='kidsCategory' value={kidsCategory}
                                    onChange={(e) => {
                                        setkidsCategory(e.target.value);
                                        setKidsCategoryError(false); // Reset description error on change
                                    }}
                                    className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full">

                                    <option value=''>Select a kidscategory</option>
                                    <option value='Boy'>Boy</option>
                                    <option value='Girl'>Girl</option>

                                </select>
                            </>
                            )}

                        </div>

                        <div className='flex flex-col mt-4'>
                            <div className='flex gap-2'>
                                <label htmlFor='customization' className="text-sm font-semibold">Is your product customizable </label>
                                <div>
                                    <Tooltip title={<h1 style={{ fontSize: 16 }}>If you sell customizable product then select Yes otherwise No</h1>} >
                                        <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                    </Tooltip>
                                </div>
                            </div>
                            <select className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                id='customization' name='customization'
                                value={customization}
                                onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    setCustomization(selectedValue);
                                    setCustomizationError(false); // Reset customization error on change
                                }}>
                                <option value=''>Select Yes or No</option>
                                <option value='yes'>Yes</option>
                                <option value='no'>No</option>

                            </select>
                            {customizationError && <p className="text-red-500 text-sm mt-1">Please select whether the product is Customizable.</p>}

                        </div>

                        <div className='flex flex-col mt-2'>
                            <div className='flex gap-2'>
                                <label htmlFor='description' className="text-sm font-semibold">Product Specification</label>
                                <div>
                                    <Tooltip title={<h1 style={{ fontSize: 16 }}>Do not exceed 1000 characters when entering the product description.</h1>} >
                                        <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                    </Tooltip>
                                </div>
                            </div>
                            <textarea
                                id='description'
                                name='description'
                                rows='8'
                                value={description}
                                maxLength={1000}
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                    setDescriptionError(false); // Reset description error on change
                                }}
                                className="w-full mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC]"
                            ></textarea>
                            {descriptionError && <p className="text-red-500 text-sm mt-1">Product Specification must not be empty and should not exceed 1000 characters.</p>}
                        </div>

                        <div className='flex flex-col mt-2'>
                            <label htmlFor='material' className="text-sm font-semibold">
                                {selectedCategory === 'homemade-food' ? 'Ingredients' : ['Men', 'Women', 'kids', 'Girl', 'Boy'].includes(selectedCategory) ? 'Fabric' : 'Material'}
                            </label>
                            <input
                                type='text'
                                placeholder={
                                    selectedCategory === 'homemade-food'
                                        ? 'Add ingredients for homemade food'
                                        : ['Men', 'Women', 'kids', 'Girl', 'Boy'].includes(selectedCategory) ? 'Fabric' : 'Material'
                                }
                                value={material}
                                maxLength={100}
                                onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    setMaterial(selectedValue);
                                    setMaterialError(false);
                                }}
                                className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                            />
                            {(['accessories', 'home-decor', 'other' /* add other categories here */].includes(selectedCategory) && materialError) && (
                                <p className="text-red-500 text-sm mt-1">Please enter Material Details.</p>
                            )}
                            {selectedCategory === 'homemade-food' && materialError && (
                                <p className="text-red-500 text-sm mt-1">Please enter Ingredients for Homemade Food.</p>
                            )}
                            {selectedCategory === 'clothing' && materialError && (
                                <p className="text-red-500 text-sm mt-1">Please enter Fabric Details for clothing.</p>
                            )}
                        </div>
                        {selectedCategory === 'clothing' ? (
                            <div className='flex flex-col mt-2'>
                                <label htmlFor='internalMaterial' className="text-sm font-semibold">Inner Fabric</label>
                                <input
                                    type='text'
                                    placeholder='Inner Fabric'
                                    value={internalMaterial}
                                    maxLength={50}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        setInternalMaterial(selectedValue);
                                        setInternalMaterialError(false);
                                    }}
                                    className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                />
                                {internalmaterialError && <p className="text-red-500 text-sm mt-1">Please enter Inner Fabric.</p>}
                            </div>

                        ) : null}


                        <div className='flex flex-col mt-2'>
                            <div className='flex gap-2'>
                                <label htmlFor='processing-time' className="text-sm font-semibold">Processing Time</label>
                                <div>
                                    <Tooltip title={<h1 style={{ fontSize: 16, }}>Enter the number of days required for processing the order. (enter only digits)</h1>} >
                                        <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                    </Tooltip>
                                </div>

                            </div>

                            <input
                                type='text'
                                placeholder='Add processing time for the item'
                                value={processingTime}
                                maxLength={3}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const regex = /^[0-9]*$/; // Regular expression to allow only digits

                                    if (regex.test(inputValue)) {
                                        setProcessingTime(inputValue);
                                        setProcessingTimeError(false)
                                    }
                                }}
                                className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                            />
                            {processingTimeError && <p className="text-red-500 text-sm mt-1">Please enter Processing Time.</p>}
                        </div>

                        <div className='flex flex-col mt-4'>
                            <div className='flex gap-2'>
                                <label htmlFor='limitOfManufacturing' className="text-sm font-semibold">Limit Of Manufacturing</label>
                                <div>
                                    <Tooltip title={<h1 style={{ fontSize: 16 }}>The maximum quantity of this product that can be manufactured.  (enter only digits)</h1>} >
                                        <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                    </Tooltip>
                                </div>
                            </div>
                            <input
                                type='text'
                                placeholder='Limit Of Manufacturing'
                                value={limitOfManufacturing}
                                maxLength={20}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const regex = /^[0-9]*$/; // Regular expression to allow only digits

                                    if (regex.test(inputValue)) {
                                        setLimitOfManufacturing(inputValue);
                                        setLimitOfManufacturingTimeError(false);
                                    }
                                }}

                                className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                            />
                            {limitOfManufacturingError && <p className="text-red-500 text-sm mt-1">Please enter Limit Of Manufacturing.</p>}
                        </div>
                    </div>

                    <div className='right flex flex-col w-full flex-wrap px-2 mb-4 mt-2'>
                        <div className='flex gap-2'>
                            <label className="text-sm font-semibold">Product Images</label>
                            <div>
                                <Tooltip title={<h1 style={{ fontSize: 16, }}>You need to add minimum 1 and maximum 5 images. Pay attention to the quality of the pictures you add, comply with the background color standards. Pictures must be in certain dimensions. Ensure that the product showcases all the details.</h1>} >
                                    <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                </Tooltip>
                            </div>
                        </div>

                        <div className="flex flex-col flex-wrap mt-2 w-full lg:w-full md:w-1/3">
                            <div className='flex flex-row gap-2'>
                                {/* showing already uploded image */}
                                {products?.images?.map((image, index) => (
                                    <div key={index}>
                                        <Link to={image} target="_blank" rel="noopener noreferrer">
                                            <img src={image} alt={`Image ${index}`} className='w-16 h-16' />
                                        </Link>
                                    </div>
                                ))}
                            </div>


                        </div>
                        <div className="w-full lg:w-full md:w-1/3 px-2 mb-4 mt-3">
                            {selectedCategory === "clothing" ? <>
                                <div className="mt-2">

                                    <div className="flex gap-4 flex-wrap">
                                        {type === 'kids' ? (
                                            <div className="flex-col flex mt-2 ">
                                                <h3 className="text-sm font-semibold ">Select Years:</h3>
                                                <div className=''>
                                                    {['0-1', '1-3', '3-5', '5-7', '7-9', '9-11', '11-13'].map((size, index) => (
                                                        <label key={index} className="inline-flex items-center mt-2">
                                                            <input
                                                                type="checkbox"
                                                                value={size}
                                                                checked={selectedSizes.includes(size)}
                                                                onChange={(e) => {
                                                                    handleSizeChange(e)
                                                                    setYearError(false);
                                                                }}
                                                                className="form-checkbox h-4 w-4 bg-custom-purple flex"
                                                            />
                                                            <span className="ml-2 mr-4">{size}</span>
                                                        </label>
                                                    ))}
                                                </div>
                                                {yearError && <span className="text-red-500 text-sm mt-1">Please select at least one Year.</span>}
                                            </div>
                                        ) : (
                                            <div className="flex gap-4 mt-2">
                                                <h3 className="text-sm font-semibold">Select Sizes:</h3>
                                                <div>
                                                    {['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'].map((size, index) => (
                                                        <label key={index} className="inline-flex items-center mt-2">
                                                            <input
                                                                type="checkbox"
                                                                value={size}
                                                                checked={selectedSizes.includes(size)}
                                                                onChange={(e) => {
                                                                    handleSizeChange(e)
                                                                    setSizeError(false);
                                                                }}
                                                                className="form-checkbox h-4 w-4 bg-custom-purple flex"
                                                            />
                                                            <span className="ml-2 mr-4">{size}</span>
                                                        </label>
                                                    ))}
                                                </div>
                                                {sizeError && <span className="text-red-500 text-sm mt-1">Please select at least one Size.</span>}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="mt-2 flex gap-2">

                                </div> </> : <></>}


                        </div>
                        <div className="w-full md:w-1/2 lg:w-full mb-4 mt-2">
                            {selectedCategory === 'clothing' && (
                                <>
                                    <label className="text-sm font-semibold" htmlFor='size-chart'>Size Chart Image</label>
                                    <div>
                                        <input
                                            type='file'
                                            className="mt-2 border rounded p-4 "
                                            id="imageUpload"
                                            name="imageUpload"
                                            accept="image/"
                                            onChange={(e) => {
                                                handleImageChange(e)
                                                setSizeChartImageError(false);
                                            }}
                                        ></input>

                                        {products.sizeChartImage && (
                                            <div>
                                                <Link to={products.sizeChartImage} target="_blank" rel="noopener noreferrer">
                                                    <img
                                                        src={products.sizeChartImage}
                                                        alt="Size chart Image"
                                                        className="p-2 h-40 object-center object-cover"
                                                    />
                                                </Link>
                                            </div>
                                        )}
                                        {!products.sizeChartImage && (
                                            <p className="text-red-500 text-sm mt-1">No Image found.</p>
                                        )}
                                        {sizeChartImageError && (
                                            <p className='text-red-500 text-sm mt-1 '>Please upload the Size Chart Image.</p>
                                        )}
                                    </div>
                                    <label htmlFor='model-size' className="text-sm font-semibold mt-4">Model Size</label>
                                    <input
                                        type='text'
                                        placeholder='Add size of the model'
                                        value={products.modelSize}
                                        maxLength={20}
                                        onChange={(e) => {
                                            setModelSize(e.target.value)
                                            setModelSizeError(false)
                                        }}
                                        className="mt-2 px-3 py-2 border w-full rounded-md focus:outline-none focus:border-[#8A58DC] "
                                    />
                                    {modelSizeError && (
                                        <p className='text-red-500 text-sm mt-1 '>Please enter the Model Size.</p>
                                    )}
                                </>
                            )}
                            {selectedCategory !== 'clothing' && selectedCategory !== 'homemade-food' && (
                                <div className="w-full md:w-1/2 lg:w-full mb-2 mt-2">
                                    <label htmlFor='dimensions' className="text-sm font-semibold">Dimensions</label>
                                    <input
                                        type='text'
                                        maxLength={20}
                                        placeholder="Add product dimensions (e.g., 10x12x5 )"
                                        value={dimensions}
                                        onChange={(e) => {
                                            setDimensions(e.target.value)
                                            setDimensionsError(false)
                                        }}
                                        className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full "
                                    />
                                    {dimensionsError && (
                                        <p className="text-red-500 text-sm mt-1">Please enter the Product Dimensions.</p>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col lg:flex-row gap-4  ">
                            <div>
                                <label htmlFor='original-price' className="text-sm font-semibold mt-2">Original Price</label>
                                <input

                                    type='text'
                                    placeholder='Add original price of the product'
                                    value={originalPrice}
                                    maxLength={6}
                                    onChange={(e) => {
                                        let newValue = parseInt(e.target.value, 10);
                                        if (isNaN(newValue) || newValue < 0) {
                                            newValue = 0;
                                        }
                                        setOriginalPrice(newValue);
                                        setOriginalPriceError(false);
                                        // Ensure discounted price is always less than or equal to the original price
                                        if (discountPrice > newValue) {
                                            setDiscountPrice(newValue);
                                        }
                                    }}
                                    className="mt-2 px-3 py-2 w-full border rounded-md focus:outline-none focus:border-[#8A58DC]"
                                />
                                {originalPriceError && <p className="text-red-500 text-sm mt-1">Please enter the Original Price.</p>}

                            </div>

                            <div>
                                <label htmlFor='discount-price' className="text-sm font-semibold mt-2 ">Discounted Price</label>
                                <input
                                    type='text'
                                    placeholder='Add discount price of the product'
                                    value={discountPrice}
                                    maxLength={6}
                                    onChange={(e) => {
                                        let newValue = parseFloat(e.target.value);
                                        if (isNaN(newValue) || newValue < 0) {
                                            newValue = 0;
                                        }

                                        // Ensure discounted price is always less than or equal to the original price
                                        if (newValue > originalPrice) {
                                            newValue = originalPrice;
                                        }

                                        setDiscountPrice(newValue);
                                        setDiscountPriceError(false);
                                    }}
                                    className="mt-2 px-3 py-2 w-full border rounded-md focus:outline-none focus:border-[#8A58DC]"
                                />
                                {discountPriceError && <p className="text-red-500 text-sm mt-1">Please enter the Discounted Price.</p>}

                            </div>
                        </div>

                        <div className='flex flex-col mt-2'>
                            <label htmlFor='stock' className="text-sm font-semibold">Stock</label>
                            <input
                                type='text'
                                placeholder='Stock of product'
                                value={stock}
                                maxLength={10}
                                onChange={(e) => {
                                    let newValue = parseInt(e.target.value, 10);
                                    if (isNaN(newValue) || newValue < 0) {
                                        newValue = 0;
                                    }
                                    setStock(newValue);
                                    setStockError(false);
                                }}
                                className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                            />
                            {stockError && <p className="text-red-500 text-sm mt-1">Please enter the Stock.</p>}

                        </div>




                        {selectedCategory === 'homemade-food' && (
                            <div className='flex flex-col mt-4'>
                                <label htmlFor='Shelf-life' className="text-sm font-semibold">Shelf Life</label>
                                <input
                                    type='text'
                                    placeholder='Add shelf life of the item. (in months)'
                                    value={shelfLife}
                                    maxLength={30}
                                    onChange={(e) => {
                                        setShelfLife(e.target.value)
                                        setShelfLifeError(false)
                                    }}
                                    className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                />
                                {shelfLifeError && (<p className="text-red-500 text-sm mt-1">Please enter the Shelf Life.</p>)}
                            </div>
                        )}

                    </div>
                </div>
                <div className="w-full mt-4 flex flex-row gap-4">
                    <Button type='submit'
                        style='tertiary'
                        onClick={handleSubmit}
                    >Save Product</Button>
                    <Button type='button'
                        style='tertiary'
                        onClick={handleCancel}
                    >Cancel</Button>
                </div>
            </form>
            <Toaster />
        </div>
    );
}
export default EditProduct;

