import React, { useState, useEffect } from "react";
import TablePagination from "@mui/material/TablePagination";
import VendorAnalytics from "./VendorAnalytics";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL, config } from "../../../config/config";
import Pagination2 from "../../common/Pagination2";

const ManageVendors = () => {
  const productsPerPage = 30;
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [active, setActive] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    // Function to fetch products from the server`
    const fetchVendors = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/admin-profile/getAllvendors/superAdmin?page=${page}`,
          config
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching vendors:", error);
      }
    };

    fetchVendors();
  }, []);

  // Calculate the total number of pages
  const totalPages = Math.ceil(data.length / productsPerPage);

  // Calculate the index of the first and last product to display on the current page
  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = Math.min(startIndex + productsPerPage, data.length);

  // Filter the products to display only the ones for the current page
  const currentProducts = data.slice(startIndex, endIndex);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
    scrollToActivePage(pageNumber);
  };

  const scrollToActivePage = (pageNumber) => {
    const paginationButton = document.getElementById(`page-${pageNumber}`);
    if (paginationButton) {
      paginationButton.scrollIntoView({ behavior: "smooth", inline: "center" });
    }
  };
  return (
    <>
      <div className="p-4 ">
        <VendorAnalytics />
        <div className="flex flex-col h-screen">
          <h2 className="text-lg md:text-xl lg:text-2xl self-start">Product</h2>{" "}
          {/* Align the heading to the start */}
          {active !== "NewProductDetailsAdd" && (
            <div className="mt-5 overflow-x-auto">
              <table className="w-full md:w-[100%] border rounded-lg bg-white ">
                <thead>
                  <tr className="flex font-poppins font-medium p-2 justify-between">
                    <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">
                      Business Name
                    </th>
                    <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">
                      Vendor Name
                    </th>
                    <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">
                      Location
                    </th>
                    <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">
                      Status
                    </th>
                    <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody className="bg-gray-100">
                  {data.slice(startIndex, endIndex)?.map((item) => (
                    <tr
                      key={item._id}
                      className="flex font-poppins font-medium p-2 justify-between"
                    >
                      <td className="w-2/12 justify-center items-center">
                        <div className="flex flex-col text-[#4779CC]">
                          <div className="">
                            {item.BussinessDetails?.Businessname}
                          </div>
                        </div>
                      </td>
                      <td className="w-2/12 text-center">
                        {item.PersonalDetails?.name}
                      </td>
                      <td className="w-2/12 text-center">
                        {item.BussinessDetails?.BusinessAddress?.city}
                      </td>

                      <td
                        className={`w-2/12 text-center ${item.status === "Verified"
                            ? "text-green-500"
                            : item.status === "Rejected"
                              ? "text-red-500"
                              : "text-orange-500"
                          }`}
                      >
                        {item.status}
                      </td>

                      <td className="w-2/12 justify-center items-center">
                        <Link to={`/vendor-verify/${item._id}`}>
                          <button
                            className={`w-full md:w-28 h-9 justify-center items-center rounded-lg bg-[#8A58DC] text-white font-poppins text-lg md:text-base lg:text-lg font-semibold tracking-wide`}
                          >
                            {item.status === "Verified" ? "Edit" : "Verify"}
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {/* Custom pagination */}
        <div>
          <Pagination2
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default ManageVendors;
