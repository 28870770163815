import React, { useState } from 'react';
import { API_URL, config } from '../../../config/config';
import axios from 'axios';

const Report = () => {
    const [name, setName] = useState('');
    const [image, setImage] = useState(null);
    const [imageData, setImageData] = useState('');
    const [description, setDescription] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImage(file);
            setImageData(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formData = {
                name,
                imageUrl: imageData, // Include base64 encoded image data
                description,
            };

            const response = await axios.post(`${API_URL}/api/admin-profile/Training`, formData, config);

            

            // Reset form fields after successful submission
            // setName('');
            // setImage(null);
            // setImageData('');
            // setDescription('');
            // setErrorMessage('');
        } catch (error) {
            console.error('Error creating legal advisor:', error);
            setErrorMessage('Failed to create legal advisor. Please try again.');
        }
    };

    return (
        <div>
            <h2>Create Legal Advisor</h2>
            {errorMessage && <p>{errorMessage}</p>}
            <div>
                <label>Name:</label>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
            </div>
            <div>
                <label>Image:</label>
                <input type="file" accept="image/*" onChange={handleImageChange} required />
                {imageData && (
                    <img src={imageData} alt="Preview" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                )}
            </div>
            <div>
                <label>Description:</label>
                <textarea value={description} onChange={(e) => setDescription(e.target.value)} required />
            </div>
            <button onClick={handleSubmit}>Submit</button>
        </div>
    );
};

export default Report;
