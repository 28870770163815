import React from 'react'
import ParticularProduct from '../component/SpecificProduct/ParticularProduct'
import { useParams } from 'react-router-dom';

function SpecificProductsPage() {
  const { productId } = useParams();
  return (
    
    <>
      <ParticularProduct />
    </>

  )
}

export default SpecificProductsPage 