import React,{useEffect, useState} from 'react'
import AllNewOrders from './AllNewOrders'
import AllInProcessOrders from './AllInProcessOrders'
import AllTransitOrders from './AllTransitOrders'
import AllDeliveredOrders from './AllDeliveredOrders'

function AllOrders() {
    const [activeOrders, setActiveOrders] = useState('newOrders');

  const handleOrders = (tabName) => {
    setActiveOrders(tabName);
  }

    return (
        <div>
            <div className="flex ml-5 space-x-2 min[450px]:space-x-4 p-1 ">
                <button
                    onClick={() => handleOrders('newOrders')}
                    className={`py-2 px-2 min[450px]:px-4 text-xs min[450px]:text-base rounded ${activeOrders === 'newOrders' ? 'bg-[#8A58DC] text-white' : 'bg-gray-200 text-gray-800'
                        }`}
                >
                    New Orders
                </button>
                <button
                    onClick={() => handleOrders('inProcessOrders')}
                    className={`py-2 px-2 min[450px]:px-4 text-xs min[450px]:text-base rounded ${activeOrders === 'inProcessOrders' ? 'bg-[#8A58DC] text-white' : 'bg-gray-200 text-gray-800'
                        }`}
                >
                    In Process
                </button>
                <button
                    onClick={() => handleOrders('transitOrders')}
                    className={`py-2 px-2 min[450px]:px-4 text-xs min[450px]:text-base rounded ${activeOrders === 'transitOrders' ? 'bg-[#8A58DC] text-white' : 'bg-gray-200 text-gray-800'
                        }`}
                >
                    Transit
                </button>
                <button
                    onClick={() => handleOrders('deliveredOrders')}
                    className={`py-2 px-2 min[450px]:px-4 text-xs min[450px]:text-base rounded ${activeOrders === 'deliveredOrders' ? 'bg-[#8A58DC] text-white' : 'bg-gray-200 text-gray-800'
                        }`}
                >
                    Delivered Orders
                </button>


            </div>
            {activeOrders === 'newOrders' && <AllNewOrders/>}
                    {activeOrders === 'inProcessOrders' && <AllInProcessOrders/>}
                    {activeOrders === 'transitOrders' && <AllTransitOrders/>}
                    {activeOrders === 'deliveredOrders' && <AllDeliveredOrders/>}

        </div>
    )
}

export default AllOrders
