import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { IoIosLock } from "react-icons/io";
import constant from '../constants/ImageConstants';
import Button from '../component/common/Button';
import Loader from '../component/common/Loader';

const LOGO = constant.IMAGES_LOGO.LOGO;
const EKAIVESELLING = constant.IMAGES_IMAGES.BECOME_A_SELLER.STARTSELLING.EKAIVESELLING;
const FAKEPAN = constant.IMAGES_IMAGES.BECOME_A_SELLER.STARTSELLING.FAKEPAN;
const CAPITAL = constant.IMAGES_IMAGES.BECOME_A_SELLER.STARTSELLING.CAPITAL;
const SEAMSTRESS = constant.IMAGES_IMAGES.BECOME_A_SELLER.STARTSELLING.SEAMSTRESS;
const PRIVACY = constant.IMAGES_IMAGES.BECOME_A_SELLER.STARTSELLING.PRIVACY;
const RIBBON = constant.IMAGES_IMAGES.BECOME_A_SELLER.RIBBON;

function EkaiveSelling() {
    const navigate = useNavigate();

    const handlescroll = () => {
        window.scrollTo(0, 0);
        navigate('/selling-page');
    }

    const [imageLoaded, setImageLoaded] = useState(false);

    const steps = [
        {
            title: "Step 1:",
            content: "Add basic Business and Personal Details."

        },
        {
            title: "Step 2:",
            content: "Add your Business related Documents (GST  no., PAN no. And other required documents as per the category of sale)."

        },
        {
            title: "Step 3:",
            content: "Add your Account details on which you want your payments to be made."

        },
    ]

    const seller = [
        {
            title: "1.	Create an Account:",
            content: [
                "•	All you need is your GST, PAN Card, and an active bank account. ",
                "•	For non-GST products, register with your PAN details."
            ]
        },
        {
            title: "2.	List Your Product:",
            content: [
                "•	Complete the product listing, providing detailed product and brand information."
            ]
        },
        {
            title: "3.	Track Your Business:",
            content: [
                "•	Easily monitor orders, sales growth, and payment statements on our certified dashboard and app.",
                "•	Once verified as an The Ekaiv vendor, your payments are securely deposited into your bank account."
            ]
        }
    ]

    const whyEkaiv = [
        {
            title: "Increased Visibility:",
            content: " We make your products visible to a vast consumer base."
        },
        {
            title: "Make Your Own Identity:",
            content: " Our Catelogue page gives your business it's own identity.",
            link: "/catalogue"

        },
        {
            title: "Secure Daily Payments:",
            content: " Payments are safely deposited directly into your account after the immediate delivery of the product, even for pay-on-delivery orders."

        },
        {
            title: "Expert Support:",
            content: " Gain support from our experts in areas such as business growth and tax-related queries.",
            link: "/assistance#abc"
        },
        {
            title: "Efficient Product Delivery:",
            content: " We take care of delivering your products, ensuring a hassle-free experience for you."
        }

    ]

    const useInfo = [
        {
            content: "•	Provide effective services and improve efficiency."
        },
        {
            content: " •	Respond to your inquiries, requests, and complaints."
        },
        {
            content: "•	Send you marketing and promotional material with your consent."
        },
        {
            content: "•	Monitor and analyze usage patterns and trends."
        },
        {
            content: "•	Offer job opportunities in case of vacancies."
        }
    ]

    const customize = [
        {
            content: "1.	Ensure all business details provided are correct, as they will be verified by our CA."
        },
        {
            content: "2.	By filling the details, you agree to share the agreed-upon margin with 'The Ekaiv Team.'"
        },
        {
            content: "3.	Upload quality photographs of your products to attract consumers."
        },
        {
            content: "4.	Clearly specify the level of customization you can offer in your product details."
        },
        {
            content: "5.	Respond promptly to consumer customization requests received via WhatsApp."
        },
        {
            content: "6.	The amount added to the product pricing will be considered with 'The Ekaiv' margin."
        },
        {
            content: "7.	Payments will be credited to your account after the product reaches the consumer."
        },
        {
            content: "8.	Check our 'Our Badges' page to understand how to become a highly desirable vendor."
        }
    ]

    const security = [
        {
            title: "Your Choices:",
            content: [
                " •	Opt-out of receiving marketing communication.",
                "•	Request access, correction, or deletion of your personal information."
            ]
        },
        {
            title: "Links to Third-Party Websites:",
            content: [
                " •	Be cautious when providing information on third-party websites linked from ours."
            ]
        },
        {
            title: "Changes to Our Privacy Policy:",
            content: [
                "•	We may update our privacy policy to comply with legal requirements. The latest version will be posted."
            ]
        },

    ]   

    return (
        <div className=' p-3 md:p-5 '>
           
                <div>
                    {/* header Start */}

                    <div className=' grid grid-cols-1 sm:grid-cols-2 p-5'>
                        <div className='py-5 px-2 sm:p-5 flex flex-col justify-center items-center'>
                            <div className=' pt-10'>

                                <h1 className='text-4xl font-bold'>Becoming A Seller</h1>
                                <p className='text-sm pt-4 text-justify'>
                                    Embark on a rewarding journey with The Ekaiv, seize the opportunity to become a seller and showcase your unique handcrafted products across the nation. Join us, and let's create something extraordinary together!
                                </p><br />
                            </div>
                            <div className=''>

                                <h1 className='text-2xl text-center md:text-left font-bold pt-2'>Three Easy Steps of Account Creation:</h1>

                                <p className='pl-5 pt-3 text-sm'>
                                    {steps.map((item, index) => (
                                        <span key={index}>
                                            <span className=' font-bold'>{item.title}</span>{item.content} <br />
                                        </span>
                                    ))}
                                </p>
                                <p className='text-sm pt-2'> And after the approval of your details, we’ll send you WhatsApp message and then you can start uploading your products.</p>

                                <h1 className='text-lg font-bold pt-2'>How to Get Your The Ekaiv Vendor ID:</h1>
                                <p className='pt-2 pl-5 text-sm'>
                                    •	Enter your GST number for GST categories.<br />
                                    •	For non-GST categories, provide your PAN number.

                                </p>

                            </div>
                        </div>
                        <div className=' flex justify-center items-center'>
                            <img className=" w-full mx-auto" onLoad={() => setImageLoaded(true)} loading='lazy-loading' src={EKAIVESELLING} alt="Seller" />
                            {!imageLoaded && (
                                <div className="w-full mx-auto h-[200px] flex items-center justify-center">
                                    <Loader />
                                </div>
                            )}
                        </div>

                    </div>
                    {/* header end */}

                    {/* start selling start */}
                    <div className=' grid grid-cols-1 md:grid-cols-3 py-20'>
                        <div className=' flex justify-center items-center'>
                            <img className=" w-[90%] mx-auto " onLoad={() => setImageLoaded(true)} loading='lazy-loading' src={FAKEPAN} alt="fakePan" />
                            {!imageLoaded && (
                                <div className="w-full mx-auto h-[200px] flex items-center justify-center">
                                    <Loader />
                                </div>
                            )}
                        </div>
                        <div className=' flex flex-col justify-center items-center px-5'>
                            <h1 className='text-2xl font-bold text-center'>How To Start Selling</h1>
                            <div className='pt-2 sm:pt-10'>
                                {seller.map((item, index) => (
                                    <span key={index}>
                                        <span className=' font-bold'> {item.title}</span>
                                        <br />
                                        {item.content.map((item, index) => (
                                            <p key={index} className=' text-sm pl-5'>
                                                {item}<br />
                                            </p>
                                        ))}
                                    </span>
                                ))}
                            </div>
                        </div>
                        <div className=' flex justify-center items-center'>
                            <img className=" w-full mx-auto" onLoad={() => setImageLoaded(true)} loading='lazy-loading' src={CAPITAL} alt="capital" />
                            {!imageLoaded && (
                                <div className="w-full mx-auto h-[200px] flex items-center justify-center">
                                    <Loader />
                                </div>
                            )}
                        </div>
                    </div>
                    {/* start selling end */}

                    {/* why choose ekaiv start */}
                    <div>
                        <img src={RIBBON} loading='lazy-loading' alt="ribbon" className='w-full py-20' />
                    </div>
                    <div className=' grid grid-cols-1 gap-5 sm:grid-cols-2 px-5 py-10 gap-y-20 '>
                        <div className='order-2 sm:order-1 flex justify-center items-center sm:px-10'>
                            <img src={LOGO} onLoad={() => setImageLoaded(true)} loading='lazy-loading' alt="logo" />
                            {!imageLoaded && (
                                <div className="w-full mx-auto h-[200px] flex items-center justify-center">
                                    <Loader />
                                </div>
                            )}
                        </div>
                        <div className='order-1 sm:order-2 flex justify-center items-center '>


                            <div className=' flex flex-col'>
                                <h1 className='text-3xl font-bold text-center md:text-left'>Why "The Ekaiv" ?</h1>
                                <p className='pt-4 sm:pr-5 text-justify text-sm'>
                                    {whyEkaiv.map((item, index) => (
                                        <span key={index}>
                                            <span className={`font-bold text-base ${item.link ? 'text-blue-700 hover:text-purple-700' : ''}`}>
                                                {item.link ? (
                                                    <Link to={item.link} onClick={() => window.scrollTo(0, 0)}>{item.title}</Link>
                                                ) : (
                                                    <>
                                                        {item.title}
                                                    </>
                                                )}

                                            </span>
                                            {item.content}<br />
                                        </span>
                                    ))}
                                </p>
                                <div className='w-[100px] mt-5'>
                                    <Button onClick={handlescroll} style='primary' className='w-full'> Seller</Button></div>
                            </div>
                            <div>

                                {/* steps were written here */}
                            </div>
                        </div>
                    </div>
                    <div className="border-t border-gray-300 my-4 mt-10"></div>

                    {/* why choose ekaiv end */}

                    {/* privacy policy section start  */}

                    <div className=' grid grid-cols-1 sm:grid-cols-2  pt-5 '>
                        <div className=' flex flex-col items-center justify-center pt-10 px-5 md:px-14 '>
                            <div>
                                <div>
                                    <h1 className='text-3xl font-bold' >Privacy Policy for The Ekaiv:</h1>
                                    <p className=' text-justify text-sm pt-4'>Welcome to The Ekaiv! Our privacy policy ensures the utmost security of your personal information. We collect voluntary information like name, email, and phone number. Additionally, we gather data like IP address, browser type, and device information for a personalized experience.</p>

                                </div>
                                <div>
                                    <h1 className='text-lg font-bold pt-4 text-center md:text-left'>How We Use Your Information:</h1>
                                    <p className=' pl-5 pt-4 text-sm'>

                                        {useInfo.map((item, index) => (
                                            <span key={index}>
                                                {item.content}<br />
                                            </span>
                                        ))}

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className=' flex justify-center items-center '>
                            <img
                                src={PRIVACY}
                                alt="img1"
                                loading='lazy-loading'
                                className=' object-contain h-[90%] w-[90%]'
                                onLoad={() => setImageLoaded(true)}
                            />
                            {!imageLoaded && (
                                <div className="w-full mx-auto h-[200px] flex items-center justify-center">
                                    <Loader />
                                </div>
                            )}
                        </div>
                    </div>
                    {/* privacy policy section end */}

                    <div className="border-t border-gray-300 my-4 mt-10"></div>

                    {/* customization section start */}

                    <div className=' py-10  grid grid-cols-1 md:grid-cols-2'>
                        <div className=' flex justify-center items-center'>
                            <img src={SEAMSTRESS} onLoad={() => setImageLoaded(true)} alt="Seamstress-bro" loading='lazy-loading' className='w-[60%]' />
                            {!imageLoaded && (
                                <div className="w-full mx-auto h-[200px] flex items-center justify-center">
                                    <Loader />
                                </div>
                            )}
                        </div>
                        <div className=' flex flex-col justify-center items-center'>
                            <h1 className='text-2xl font-bold text-center lg:text-left px-10 ' >For Vendors Offering Customization</h1>

                            <p className='px-10 py-7 text-sm  text-justify'>
                                {customize.map((item, index) => (
                                    <span key={index}>
                                        {item.content.split('Our Badges').map((part, i) => (
                                            <React.Fragment key={i}>
                                                {part}
                                                {i !== item.content.split('Our Badges').length - 1 && <Link to='/badges' onClick={() => window.scrollTo(0, 0)} className=' font-bold'>Our Badges</Link>}
                                            </React.Fragment>
                                        ))}
                                        <br />
                                    </span>
                                ))}
                            </p>
                        </div>
                    </div>

                    {/* customization section end */}
                    {/* security start */}
                    <div className=' flex justify-center items-center'>
                        <div className=' flex items-center justify-center px-5 md:px-14 py-10 w-full lg:w-[50%] bg-gray-100'>
                            <IoIosLock className=' relative text-gray-200 hidden md:block ' size={300} />
                            <div className='w-full lg:w-[50%] p-5 md:p-10 md:absolute'>
                                <div>
                                    <h1 className='text-3xl font-bold '>Security of Your Personal Data:</h1>
                                    <p className='pt-4 text-sm text-justify'>We employ various security technologies to protect your data. However, it's your responsibility to keep your username and password confidential.</p>

                                </div>
                                {security.map((item, index) => (
                                    <div key={index}>
                                        <h1 className='text-lg font-bold pt-4' >{item.title}</h1>
                                        {item.content.map((des, i) => (
                                            <p className=' text-sm'>
                                                {des}<br />
                                            </p>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* security ends */}


                    <div className='w-full md:p-5  flex flex-col justify-center items-center mt-10'>
                        <div className=' flex flex-col justify-center items-center md:px-2 break-words'>
                            <h1 className='text-3xl text-center font-bold'>Contact Us:</h1>
                            <p className=' text-center pt-5 '>
                                For any questions or concerns about your privacy, <br />
                                contact us at: <a href="mailto:services@theekaiv.in" className="text-blue-700 break-words text-[13px] sm:text-sm">services@theekaiv.in</a><br />
                                for Financial queries contact us at: <a href="mailto:support@theekaiv.in" className="text-blue-700 break-words text-[13px] sm:text-sm">support@theekaiv.in</a> <br />
                            </p>

                        </div>
                        <div>
                            <p className=' font-semibold text-lg text-center pt-10 '>
                                "Join us in creating a valuable and long-term partnership.<br></br> Together, let's make your journey with The Ekaiv extraordinary!"
                            </p>

                        </div>

                    </div>
                </div>
       
        </div>
    )
}

export default EkaiveSelling
