import React, { useEffect, useState } from 'react';
import { Search } from 'react-feather';
import WishlistProductCard from './WishlistProductCard';
import ProductCard from '../ProductCard/ProductCard';
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import Loader from '../common/Loader';
import { getPopularProducts } from '../../Redux/features/product/productsSlice'; // Adjust the import path accordingly
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs'
import { API_URL, config } from '../../config/config';
import Button from '../common/Button';


const Wishlist = () => {

  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [productData, setProductData] = useState([]); // Product data from the backend
  const users = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [numCards, setNumCards] = useState(3);
  const [data, setData] = useState([]);

  // Use this useEffect to fetch product data from the backend
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const productDataResponse = await axios.get(`${API_URL}/api/user/getWishlist`, config);
        setData(productDataResponse.data);

        if (productDataResponse.data) {
          setProductData(productDataResponse.data);
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
        // Handle the error here (e.g., show an error message to the user).
      }
    };

    fetchProductData();
  }, [users]);



  const handleLoadMore = () => {
    setNumCards(numCards + 3);
  };

  const handleLoadLess = () => {
    setNumCards(3);
  };

  const scrollTo = () => {
    window.scrollTo(0, 0);
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };


  // you may also like product 
  const [popularProducts, setPopularProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Make an API request to fetch popular products
    dispatch(getPopularProducts())
      .then((response) => {
        const products = response.payload.products; // Extract the products array from the response
        setPopularProducts(products);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        // setLoading(false);
      });
  }, [dispatch]);

  if (loading) {
    return <div className=' flex justify-center h-screen items-center'><Loader /></div>; // You can replace this with a loading spinner.
  }

  if (error) {
    return <div>Error fetching popular products: {error.message}</div>;
  }
  const slideLeft = () => {
    var slider = document.getElementById('slider');
    slider.scrollLeft = slider.scrollLeft - 500;
  };
  const slideRight = () => {
    var slider = document.getElementById('slider');
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  return (
    <>

      {/* search bar "second" */}
      <div className='p-5 sm:pl-10 sm:pr-10 w-full flex justify-around items-center gap-2'>
        <div className="relative rounded-full bg-gray-100 p-1 w-3/5 sm:w-4/5"> {/* Set width to w-4/5 (85%) */}
          <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
            <Search className="h-5 w-5" />
          </div>
          <input
            className="block pl-12 pr-1 py-1.5 rounded-full outline-[#8A58DC] shadow-lg w-full"
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Sorting Dropdown */}
        <div className="sorting-dropdown w-2/5 sm:w-1/5"> {/* Set width to w-1/5 (15%) */}
          <select className='border w-full rounded-md p-2 outline-[#8A58DC]' value={sortBy} onChange={handleSortChange}>
            <option value="">Sort by</option>
            <option value="low-to-high">Price (Low to High)</option>
            <option value="high-to-low">Price (High to Low)</option>
            {/* <option value="newly-added">Newly Added</option> */}
          </select>
        </div>
      </div>

      {/* Wishlist product card */}


      <div className='p-4 mb-2 grid gap-5 w-full bg-[#ffffff] justify-center items-center'>
        {productData.length === 0 ? ( // Check if the wishlist is empty
          <div className="flex flex-col items-center">
            <img
              src="https://img.freepik.com/free-vector/group-customers-shopping-online-store-huge-tablet-sale-internet-shop-buyer-with-purchases-cart-flat-illustration_74855-18344.jpg?size=626&ext=jpg&ga=GA1.1.584503204.1684751112&semt=ais"
              alt="ekaiv"
              loading='lazy-loading'
              className="w-[75%]"
            />
            <h1 className="animate-bounce p-2 text-[#8A58DC] overflow-hidden whitespace-nowrap mb-2 mt-2 text-xs sm:text-3xl font-bold">
              Your Wishlist is Waiting for Treasures!
            </h1>
            <Link to="/products"><Button onClick={scrollTo} variant="contained" style='primary' >
              Discover Products Now
            </Button></Link>

          </div>
        ) : (
          <>
            {data
              .filter((item) =>
                item.productName.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .sort((a, b) => {
                if (sortBy === 'low-to-high') {
                  return a.theEkaivDiscountPrice - b.theEkaivDiscountPrice;
                } else if (sortBy === 'high-to-low') {
                  return b.theEkaivDiscountPrice - a.theEkaivDiscountPrice;
                }
                // Add more sorting options as needed
                return 0;
              })
              .slice(0, numCards)
              .map((item, index) => (
                <WishlistProductCard data={item} key={index} />
              ))}
            {/* Load more/less buttons */}
            {productData.length > 3 && ( // Only render buttons when there are more than 3 products
              numCards < productData.length ? (
                <button
                  onClick={handleLoadMore}
                  to="#_"
                  className="relative inline-flex items-center justify-center w-[25%] p-4 px-6 py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-[#8A58DC] rounded-full shadow-md group"
                >
                  <span className="absolute inset-0 flex items-center justify-center w-30 h-full text-2xl text-white duration-300 -translate-y-full bg-[#8A58DC] group-hover:translate-y-0 ease">
                    <FiChevronDown />
                  </span>
                  <button className="absolute flex items-center justify-center w-30 h-full text-[#8A58DC] font-bold transition-all duration-300 transform group-hover:translate-y-full ease">
                    Load More
                  </button>
                  <button className="relative invisible w-30">Load More</button>
                </button>
              ) : (
                <button
                  onClick={() => {
                    handleLoadLess();
                    scrollTo();
                  }}
                  className="relative inline-flex items-center justify-center w-[25%] p-4 px-6 py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-[#8A58DC] rounded-full shadow-md group"
                >
                  <span className="absolute inset-0 flex items-center justify-center w-30 h-full text-2xl text-white duration-300 translate-y-full bg-[#8A58DC] group-hover:translate-y-0 ease">
                    <FiChevronUp />
                  </span>
                  <button className="absolute flex items-center justify-center w-30 h-full text-[#8A58DC] font-bold transition-all duration-300 transform group-hover:-translate-y-full ease">
                    Load Less
                  </button>
                  <button className="relative invisible w-30">Load Less</button>
                </button>
              )
            )}
          </>
        )}
      </div >



      <div className="text-[27px] pl-10 pr-10 text-start md:text-start font-[400] font-sans pb-[10px] p-5">
        <div className="w-full flex text-start p-2 text-[27px] font-600 text-[#343246]">You may also like</div>

        <div className="flex flex-nowrap overflow-x-auto gap-x-10 no-scrollbar mb-10">
          <BsArrowLeftShort onClick={slideLeft} className='bg-white m-2 text-bgBlue left-0 rounded-full absolute opacity-40 hover:opacity-80 curser-pointer z-10 hidden group-hover:block' size={19} />
          <div id={'slider'} className="flex flex-nowrap overflow-x-auto gap-x-10 no-scrollbar">
            {popularProducts && popularProducts.map((product, index) => (
              <ProductCard data={product} key={index} />
            ))}
          </div>
          <BsArrowRightShort onClick={slideRight} className='bg-white m-2 right-0 rounded-full absolute text-bgBlue opacity-40 hover:opacity-80 curser-pointer z-10 hidden group-hover:block' size={19} />
        </div>
      </div>

    </>
  )
}

export default Wishlist