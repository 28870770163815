import React, { useEffect, useState } from 'react';
import ProductCard from '../../ProductCard/ProductCard';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { API_URL, config } from '../../../config/config';
import Loader from '../../common/Loader';
import Button from '../../common/Button';
import Pagination2 from '../../common/Pagination2';

const Store = () => {
  const productsPerPage = 30;
  const vendors = useSelector((state) => state.vendor);
  const vendorId = vendors && vendors.vendor && vendors.vendor._id;
  const [vendorProducts, setVendorProducts] = useState([]);
  const [loading, setLoading] = useState("");
  const [currentStatus, setCurrentStatus] = useState('all'); // 'all', 'verified', 'pending', 'rejected'
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await axios.get(`${API_URL}/api/vendor/products`, config);

        setVendorProducts(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (vendorId) {
      fetchData();
    }
  }, [vendorId, currentPage]);



  // Filter products based on the current status
  const filteredProducts = vendorProducts.filter((product) => {
    if (currentStatus === 'all') {
      return true;
    } else {
      return product.status === currentStatus;
    }
  });

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
    scrollToActivePage(pageNumber);
  };

  const scrollToActivePage = (pageNumber) => {
    const paginationButton = document.getElementById(`page-${pageNumber}`);
    if (paginationButton) {
      paginationButton.scrollIntoView({ behavior: "smooth", inline: "center" });
    }
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  // Calculate the index of the first and last product to display on the current page
  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = Math.min(
    startIndex + productsPerPage,
    filteredProducts.length
  );



  return (
    <div className=" ml-1 sm:ml-6">
      <h1 className=" mb-4 font-bold text-xl">Store</h1>
      {loading ? (
        <div className='flex justify-center h-[400px] items-center'>
          <Loader />
        </div>
      ) : (
        <>
          <div className="flex gap-1 min[350px]:gap-5 mb-3 w-full pr-1 min-[350px]:pr-10">
            <Button
              onClick={() => setCurrentStatus('all')}
              style='primary'
              className={`w-full ${currentStatus === 'all' && 'bg-[#F16080] font-bold'}`}
            >
              All
            </Button>
            <Button
              style='primary'
              className={`w-full ${currentStatus === 'Verified' && 'bg-[#F16080] font-bold'}`}
              onClick={() => setCurrentStatus('Verified')}
            >
              Verified
            </Button>
            <Button
              style='primary'
              className={`w-full ${currentStatus === 'Pending' && 'bg-[#F16080] font-bold'}`}
              onClick={() => setCurrentStatus('Pending')}
            >
              Pending
            </Button>
            <Button
              style='primary'
              className={`w-full ${currentStatus === 'Rejected' && 'bg-[#F16080] font-bold'}`}
              onClick={() => setCurrentStatus('Rejected')}
            >
              Rejected
            </Button>
          </div>
          {filteredProducts.length === 0 ? (
            <div className='flex flex-col justify-center items-center'>
              <h1 className='text-[#8A58DC] overflow-hidden whitespace-nowrap text-center text-3xl p-5 font-bold'>Products Not Available.</h1>
              <img
                className='h-[400px] p-5'
                src="https://img.freepik.com/free-vector/boycott-abstract-concept-vector-illustration-political-program-consumer-activism-collective-behavior-cancel-culture-moral-purchasing-solidarity-action-public-protest-abstract-metaphor_335657-4199.jpg?size=626&ext=jpg&ga=GA1.1.65071757.1704699075&semt=ais" alt="" />
            </div>
          ) : (
            <>
              <div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-y-6 mb-12 border-0">
                {filteredProducts?.slice(startIndex, endIndex)?.map((product, index) => (
                  <div className='flex flex-col' key={index}>
                    <ProductCard data={product} showWishlistAndCartButtons={false} showStatus={true} />
                  </div>
                ))}
              </div>

              {/* Custom pagination */}
              <div>
          <Pagination2
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
            </>
          )}

        </>
      )}
    </div>
  );
};

export default Store;