// VendorNavbar.js

import React, { useState, useEffect } from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import NotificationDropdown from './NotificationDropdown';
import { useSelector } from 'react-redux';
import { API_URL, config } from '../../../config/config';
import io from 'socket.io-client';

const VendorNavbar = ({ setActiveTab }) => {
  const [socket, setSocket] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isNewNotification, setIsNewNotification] = useState(false);

  const vendorId = useSelector((state) => state.vendor.vendor?._id);
  const name = useSelector((state) => state.vendor.vendor?.BussinessDetails?.Businessname);
  const status = useSelector((state) => state.vendor.vendor?.status);

  useEffect(() => {
    const socket = io(API_URL, { reconnection: true, reconnectionAttempts: Infinity });
    setSocket(socket);

    socket.on('notification', (notification) => {
      setNotifications((prevNotifications) => [...prevNotifications, notification]);
      setNotificationCount((prevCount) => prevCount + 1);
      setIsNewNotification(true);
      fetchNotifications();
      // Hide the notification after 5 seconds
      setTimeout(() => {
        setIsNewNotification(false);
      }, 5000);
    });

    fetchNotifications();

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket && vendorId) {
      socket.emit('vendorLogin', vendorId);
    }
  }, [socket, vendorId]);

  const fetchNotifications = () => {
    fetch(`${API_URL}/api/vendor/notifications`, config)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch notifications');
        }
        return response.json();
      })
      .then((data) => {
        setNotifications(data.notifications);
        setNotificationCount(data.notifications.length);
      })
      .catch((error) => {
        console.error('Error fetching notifications:', error);
      });
  };

  const toggleNotifications = () => {
    setShowNotifications((prevShowNotifications) => !prevShowNotifications);
    setIsNewNotification(false);
  };

  return (
    <div className='flex flex-row items-center py-2 md:px-10 px-4 md:h-24 h-20 bg-white border'>
      <div className="flex flex-row w-[70%] sm:w-[50%] font-poppins text-sm lg:text-2xl font-medium lg::leading-9 lg:tracking-normal text-left">
        <div className='justify-center w-[40%] sm:w-full break-words bg-pink items-start text-xs sm:text-lg lg:text-2xl px-1 sm:px-5'>
          {name}
        </div>
      </div>

      <div className="flex w-[30%] sm:w-[50%] justify-end items-center gap-5">
        <p className='sm:flex justify-end items-end text-center sm:text-lg lg:text-2xl px-1 sm:px-5'>
          Status:
          <span className={`text-center ml-2 ${status === 'Verified' ? 'text-green-500' : (status === 'Rejected' ? 'text-red-500' : 'text-orange-500')}`}>{status}</span>
        </p>

        <div className="relative inline-block items-center">
          <span className={`w-2 h-2 bg-gray-500 rounded-full ${notificationCount > 0 ? 'hidden' : ''}`}></span>
          <NotificationsNoneIcon
            className={`w-6 h-6 mr-2 cursor-pointer notification-icon ${showNotifications ? 'text-gray-500' : ''}`}
            onClick={toggleNotifications}
          />
          {notificationCount > 0 && (
            <span className="absolute top-0 right-1 w-4 h-4 flex items-center justify-center text-xs text-white bg-red-500 rounded-full">
              {notificationCount}
            </span>
          )}
          {isNewNotification && (
            <div className="absolute bg-white shadow-md p-2 top-full flex items-center right-0 mt-2 h-[40px] overflow-y-auto w-[70vw] sm:w-[30vw] xl:w-[20vw]">
              {notifications[notifications.length - 1].message && (
                notifications[notifications.length - 1].message.split(' ').slice(0, 5).join(' ') + '...'
              )}
            </div>
          )}
          {showNotifications && <NotificationDropdown notifications={notifications} />}
        </div>
        <AccountCircleRoundedIcon onClick={() => setActiveTab('account')} />
      </div>
    </div>
  );
};

export default VendorNavbar;
