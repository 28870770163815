import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom'
import sellerimg from "../../constants/ImageConstants"
import Button from '../common/Button'
import Loader from '../common/Loader'

const sell = sellerimg.IMAGES_IMAGES.BECOME_A_SELLER.SELLING_PAGE.EKAIV

const StartingSelling = () => {
  const navigate = useNavigate();

  const handleScroll = () => {
    window.scrollTo(0, 0);
    navigate('/become-seller')
  }

  const [imageLoading, setImageLoading] = useState(true);

  return (
    <div className='  flex max-lg:flex-col lg:flex-row gap-2 px-12'>
      <div className='  w-full pl-10 sm:flex md:flex flex-col justify-center sm:mb-0 mb-10'>
        <p className='sm:text-5xl text-xl mb-4 font-bold '>
          Start your Seller Journey
        </p>
        <p className='sm:text-lg  text-gray-700 text-base mb-4'>
          Join The Ekaiv
        </p>

        <div className='w-fit'>
          <Button style='tertiary' onClick={handleScroll}>
            Start Selling
          </Button>
        </div>



      </div>
      <div className='  w-full  sm:m-0'>

        <img src={sell} alt="About" loading='lazy' className=' p-5'
        onLoad={() => setImageLoading(false)} />
         {imageLoading && (
        <div className="w-full h-full flex justify-center items-center">
          <Loader /> 
        </div>
      )}
      </div>
    </div>
  )
}

export default StartingSelling
