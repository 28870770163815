import React from 'react'
import Wishlist from '../component/Wishlist/Wishlist'

const TermsAndCondition = () => {
    return (
        <div>
            <Wishlist />
        </div>
    )
}

export default TermsAndCondition
