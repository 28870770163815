import React, { useEffect, useState } from "react";
import "./AddAddressForm.css";
import InputBox from "../InputBox";
import { useDispatch, useSelector } from 'react-redux'
import { changePassword, getUser, resetMessage, updateUser } from "../../Redux/features/auth/authSlice";
import { toast, Toaster } from "react-hot-toast";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import { API_URL, config } from "../../config/config";
import ErrorConstants from "../../constants/ErrorConstants";
import SuccessConstant from "../../constants/SuccessConstant";
import Button from "../common/Button";

const inputFields = [
  {
    id: "fullname",
    label: "Full Name",
    name: "fullname",
    autofocus: true,
    autocomplete: "fullname",
  },
  {
    id: "email",
    label: "Email Address",
    name: "email",
    autocomplete: "email",
  },
  {
    id: "mobile",
    label: "Mobile Number",
    name: "phone",
    autocomplete: "mobile",
  },

];
const LoginAndSecurity = () => {
  const dispatch = useDispatch();
  const { user, isError, isSuccess, message } = useSelector((state) => state.auth);
  const [resetHash, setResetHash] = useState('')
  const [editedField, setEditedField] = useState(null);
  const [isChange, setChange] = useState(false)
  const [otpInput1, setOtpInput1] = useState('');
  const [otpInput2, setOtpInput2] = useState('');
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [showMobilePopup, setShowMobilePopup] = useState(false);
  const [showAddEmailPopup, setShowAddEmailPopup] = useState(false)
  const [tempPasswords, setTempPasswords] = useState({});

  const token = JSON.parse(localStorage.getItem('token'));

  const config = {
    headers: {
      "Content-type": "application/json",
      "Authorization": token,
    },
  };
  // const [isOtpVerified, setIsOtpVerified] = useState(false);

  const [details, setDetails] = useState({
    fullname: '',
    email: '',
    phone: '',
  })

  useEffect(() => {
    if (user) {
      setDetails({
        fullname: user?.fullname || '',
        email: user?.email || '',
        phone: user?.phone?.replace('+91', '') || '',
      })
    }
  }, [user])

  const [passwords, setPasswords] = useState({
    prevPassword: "",
    newPassword: "",
    cnewPassword: ""
  })


  const handleSendOTP = async (field) => {
    if (!details.email && !details.phone) {
      toast.error(ErrorConstants.Error.ALL_FELID_OTP_ERROR);
      return;
    }



    if (field === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(details.email)) {

        toast.error(ErrorConstants.Error.EMAIL_ERROR_MESSAGE);
        return;
      }

      if (user?.email == null) {
        try {
          const response = await axios.post(`${API_URL}/api/user/add-email-otp`, { email: details.email }, config);
          setResetHash(response.data.fullHash);
          setShowAddEmailPopup(true);
          return toast.success(response.data.message);
        } catch (error) {
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return toast.error(message);
        }
      }

      try {
        const response = await axios.put(`${API_URL}/api/user/change-email-otp`, { email: details.email }, config);
        toast.success(response.data.message);
        setResetHash(response.data.fullHash);
        setShowEmailPopup(true);
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        toast.error(message);
      }
    } else if (field === 'phone') {

      if (details.phone.length !== 10) {
        toast.error(ErrorConstants.Error.PHONE_DIGIT_ERROR);
        return;
      }

      if (`+91${details.phone}` == user?.phone) {
        toast.error(ErrorConstants.Error.PHONE_NO_DIFF_ERROR);
        return;
      }
      await axios.put(`${API_URL}/api/user/change-phone-number-otp`, { phone: details.phone }, config)
        .then(response => {
          toast.success(response.data.message);
          setShowMobilePopup(true);
        })
        .catch(error => {
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          toast.error(message);
        });
    }
  };

  const handleEdit = (fieldName) => {
    setEditedField(fieldName);
  };

  const handleResendOTP = async (field) => {
    try {
      // Add logic to resend OTP
      // For example, you can dispatch an action to resend OTP or make an API call

      // Simulating a successful resend for demonstration purposes
      // Replace this with your actual logic
      const resendSuccess = true;

      if (resendSuccess) {
        await handleSendOTP(field)
        toast.success(SuccessConstant.Success.OTP_SUCCESS);
      } else {
        toast.error(ErrorConstants.Error.OTP_ERROR);
      }
    } catch (error) {
      console.error('Error resending OTP:', error);
      toast.error(ErrorConstants.Error.OTP_ERROR);
    }
    // setIsOtpVerified(false);
  };

  const handleSaveUpdates = async (event) => {
    event.preventDefault();

    if (!details.fullname) {
      toast.error(ErrorConstants.Error.FULLNAME_ERROR);
      return;
    }

    const data = {
      fullname: details?.fullname,
    }

    dispatch(updateUser(data));
    setEditedField(null);


  }


  const handleSave = async (event) => {
    event.preventDefault();

    // Check if any of the required fields is empty
    if (!details.email && !details.phone) {
      toast.error(ErrorConstants.Error.ALL_FEILD_ERROR);
      return;
    }

    if (user?.email == null) {
      if (!otpInput1) {
        toast.error(ErrorConstants.Error.OTP_1_ERROR);
        return;
      }
      if (otpInput1 && showAddEmailPopup) {
        if (showAddEmailPopup) {
          await axios.put(`${API_URL}/api/user/add-email`, { email: details.email, otp: otpInput1, resethash: resetHash }, config)
            .then(response => {
              setShowAddEmailPopup(false);
              setEditedField(null);
              dispatch(getUser());
              return toast.success(response.data.message);
            })
            .catch(error => {
              const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
              return toast.error(message);
            });
          return;
        }

      }
    }

    if (!otpInput1 || !otpInput2) {
      toast.error(ErrorConstants.Error.OTP_1_ERROR);
      return;
    }

    if (otpInput1 && otpInput2 && (showEmailPopup || showMobilePopup)) {


      // for mobile verification
      if (showMobilePopup) {
        await axios.put(`${API_URL}/api/user/change-phone-number`, { phone: details.phone, otp: otpInput1, newOtp: otpInput2 }, config)
          .then(response => {
            toast.success(response.data.message);
            setShowMobilePopup(false);
            setEditedField(null);
            dispatch(getUser());
          })
          .catch(error => {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            toast.error(message);
          });
      }

      // for email verification
      if (showEmailPopup) {
        try {
          const response = await axios.put(`${API_URL}/api/user/change-email`, { email: details.email, otp: otpInput1, newOtp: otpInput2, resethash: resetHash }, config);
          setShowEmailPopup(false);
          setEditedField(null);
          dispatch(getUser());
          toast.success(response.data.message);
        } catch (error) {
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          toast.error(message);
        }
      }

      setOtpInput1('');
      setOtpInput2('')
    } else {
      toast.error(ErrorConstants.Error.OTP_1_ERROR);
    }
  };

  const changeInput = (e) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      const formattedValue = value.replace(/\D/g, '').slice(0, 10);
      setDetails({
        ...details,
        [name]: formattedValue,
      });
    } else if (name === 'fullname') {
      // Limit to a maximum length of 50 characters
      const trimmedValue = value.slice(0, 50);
      setDetails({
        ...details,
        [name]: trimmedValue,
      });
    } else if (name === 'email') {
      // Allow any input for email, even if it doesn't match the expected format
      setDetails({
        ...details,
        [name]: value,
      });
    } else {
      setDetails({
        ...details,
        [name]: value,
      });
    }
  };

  const cancelEdit = () => {
    setDetails({
      fullname: (user && user.fullname) || '',
      email: (user && user.email) || '',
      password: '.....',
      phone: user?.phone?.replace('+91', '') || '',
    }
    )
    setEditedField(null);
  }

  const onChangePassword = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value
    })
  }

  const handleCancelPasswordChange = () => {
    toast.error(ErrorConstants.Error.CHANGES_ERROR)
    setChange(false);
    setTempPasswords({});
  };

  // useEffect(() => {
  //   if (isError && message) {
  //     toast.error(message);
  //     console.log("error", message);
  //     dispatch(resetMessage());
  //   }
  //   if (isSuccess && message) {
  //     toast.success(message);
  //     console.log("sucess", message);
  //     dispatch(resetMessage());
  //   }
  // }, [message, isError, isSuccess])

  const handleChangePassword = () => {
    setChange(!isChange);
    dispatch(changePassword({ id: user._id, ...passwords }))
  }

  const handleOtpInputChange1 = (e) => {
    const sanitizedValue = e.target.value.replace(/\D/g, ''); // Replace non-digit characters with an empty string
    setOtpInput1(sanitizedValue.slice(0, 6)); // Limit the length to 6 digits
  };

  const handleOtpInputChange2 = (e) => {
    const sanitizedValue = e.target.value.replace(/\D/g, '');
    setOtpInput2(sanitizedValue.slice(0, 6));
  };

  return (
    <div className="mt-4 space-y-4">
      {inputFields.map((element, index) => (
        <div key={index} className="relative flex flex-col justify-start items-start">
          {editedField === element.name ? (
            <>
              <InputBox {...element} value={details[element.name]} changeInput={changeInput} />
              <div className="mt-2 flex">
                {element.name === 'phone' || element.name === 'email' ? (
                  <Button
                    style="primary" onClick={() => handleSendOTP(element.name)}
                  >
                    Send OTP
                  </Button>
                ) : (
                  <Button
                    style="primary" onClick={handleSaveUpdates}
                  >
                    Save
                  </Button>
                )}
                <div className="ml-4">
                  <Button
                    style="primary"
                    onClick={cancelEdit}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <InputBox {...element} value={details[element.name]} disabled={true} />
              <Button
                style="primary"
                onClick={() => handleEdit(element.name)}
              >
                Edit
              </Button>
            </>
          )}
        </div>
      ))}

      {showEmailPopup && (
        // Email popup content
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-5 w-[350px] rounded-md shadow-lg flex flex-col">

            <div className="flex justify-end">
              <Button style="primary"
                onClick={() => setShowEmailPopup(false)}>
                <RxCross2 />
              </Button>
            </div>
            <div className='flex justify-start p-2'>
              <label className="font-semibold md:w-48">Verify Email</label>
            </div>
            <div className="flex flex-col gap-y-4">
              <input
                type="text"
                value={otpInput1}
                onChange={handleOtpInputChange1}
                placeholder={'Enter OTP for Current Email'}
                className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC]"
              />
              <input
                type="text"
                value={otpInput2}
                onChange={handleOtpInputChange2}
                placeholder="Enter OTP for New Email"
                className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC]"
              />

              <Button
                style="primary"
                onClick={() => handleResendOTP('email')}>
                Resend OTP
              </Button>

              <Button
                style="primary"
                onClick={handleSave}
              >
                Submit OTP
              </Button>
            </div>
          </div>

        </div>
      )}


      {showAddEmailPopup && (
        // Email popup content
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-5 w-[350px] rounded-md shadow-lg flex flex-col">

            <div className="flex justify-end">
              <Button style="primary"
                onClick={() => showAddEmailPopup(false)}>
                <RxCross2 />
              </Button>
            </div>
            <div className='flex justify-start p-2'>
              <label className="font-semibold md:w-48">Verify Email</label>
            </div>
            <div className="flex flex-col gap-y-4">
              <input
                type="text"
                value={otpInput1}
                onChange={handleOtpInputChange1}
                placeholder={'Enter OTP for Current Email'}
                className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC]"
              />

              <Button
                style="primary"

                onClick={() => handleResendOTP('email')}>
                Resend OTP
              </Button>


              <Button
                style="primary"

                onClick={handleSave}
              >
                Submit OTP
              </Button>
            </div>
          </div>

        </div>
      )}

      {showMobilePopup && (
        // Mobile popup content
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-5 w-[350px] rounded-md shadow-lg">

            <div className="flex justify-end">
              <Button style="primary"

                onClick={() => setShowMobilePopup(false)}>
                <RxCross2 />
              </Button>
            </div>
            <div className='flex justify-start p-2'>
              <label className="font-semibold md:w-48">Verify Mobile Number</label>
            </div>
            <div className="flex flex-col gap-y-4">
              <input
                type="text"
                value={otpInput1}
                onChange={handleOtpInputChange1}
                placeholder={'Enter OTP for Current Number'}
                className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC]"
              />
              <input
                type="text"
                value={otpInput2}
                onChange={handleOtpInputChange2}
                placeholder="Enter OTP for New Number"
                className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC]"
              />

              <Button
                style="primary"

                onClick={() => handleResendOTP('phone')}>
                Resend OTP
              </Button>


              <Button
                style="primary"

                onClick={handleSave}
              >
                Submit OTP
              </Button>
            </div>

          </div>
        </div>

      )}


      <div className="mt-4 flex flex-col justify-start items-start">
        {!isChange && (
          <>
            <label className='text-gray-700 text-sm font-bold mb-2'>
              Change Password
            </label>
            <input
              id="change-password"
              label="Confirm New Password"
              name="new-password"
              autoComplete="new-password"
              type="password"
              maxLength={16}
              minLength={8}
              disabled={true}
              value="***********"
              className='border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline my-2'
              required
            />
          </>
        )}
        {isChange && (
          <>
            <label className='text-gray-700 text-sm font-bold mb-2'>
              Previous Password
            </label>
            <input
              id="prevPassword"
              label="Previous Password"
              name="prevPassword"
              autoComplete="prevPassword"
              type="password"
              maxLength={16}
              minLength={8}
              onChange={onChangePassword}
              className='border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline my-2'
              required
            />
            <label className='text-gray-700 text-sm font-bold mb-2'>
              New Password
            </label>
            <input
              id="newPassword"
              label="New Password"
              name="newPassword"
              maxLength={16}
              minLength={8}
              autoComplete="newPassword"
              type="password"
              onChange={onChangePassword}
              className='border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline my-2'
              required
            />
            <label className='text-gray-700 text-sm font-bold mb-2'>
              Confirm New Password
            </label>
            <input
              id="cnewPassword"
              label="Confirm New Password"
              name="cnewPassword"
              autoComplete="cnewPassword"
              maxLength={16}
              minLength={8}
              type="password"
              onChange={onChangePassword}
              className='border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline my-2'
              required
            />
          </>
        )}
        <div className="flex mt-2">
          {!isChange && (
            <Button
              style="primary"

              onClick={() => setChange(!isChange)}
            >
              Change
            </Button>
          )}
          {isChange && (
            <>
              <div className="mr-5">
                <Button
                  style="primary"

                  onClick={handleCancelPasswordChange}
                >
                  Cancel
                </Button>
              </div>
              <Button
                style="primary"

                onClick={handleChangePassword}
              >
                Save
              </Button>
            </>
          )}
        </div>
      </div>

      {/* <Toaster /> */}
    </div>

  );
};

export default LoginAndSecurity;
