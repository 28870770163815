import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './AddAddressForm.css'
import InputBox from '../InputBox'
import { addAddress, editAddress, resetMessage } from '../../Redux/features/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import ErrorConstants from '../../constants/ErrorConstants'
import Button from '../common/Button'
import SuccessConstant from '../../constants/SuccessConstant'

const inputFields = [
  {
    id: "country",
    label: "Country/Region",
    name: "country",
    autofocus: true,
    autocomplete: "country",
    defaultValue: "India",
  },
  {
    id: "fullname",
    label: "Full Name",
    name: "fullname",
    autocomplete: "fullname",
    placeholder: "Full Name",
  },
  {
    id: "mobile",
    label: "Mobile Number",
    name: "phoneNumber",
    autocomplete: "mobile",
    placeholder: 'Mobile Number',

  },
  {
    id: "pincode",
    label: "Pin Code",
    name: "pinCode",
    autocomplete: "pincode",
    placeholder: 'Pincode',

  },
  {
    id: "flats",
    label: "Flat, House No., Building, Company, Apartment",
    name: "flat",
    autocomplete: "flats",
    placeholder: 'Flat/House Number, Building, Company, Apartment',
  },
  {
    id: "area",
    label: "Area, Street, Sector, Village",
    name: "area",
    autocomplete: "area",
    placeholder: 'Area, Street, Sector, Village',
  },
  {
    id: "landmark",
    label: "Landmark",
    name: "landmark",
    autocomplete: "landmark",
    placeholder: 'Landmark',
  },
  {
    id: "town",
    label: "Town/City",
    name: "city",
    autocomplete: "city",
    placeholder: 'Town/City',
  },

]
const AddAddressForm = ({ openAddAddressForm, addressId, setAddressId }) => {
  const PHONE_REGEX = /^[0-9]{10}$/
  const { user, isSuccess, message, isError } = useSelector((state) => state.auth)
  const dispatch = useDispatch();


  useEffect(() => {
    if ((isSuccess || isError) && message) {
      setTimeout(() => {
        dispatch(resetMessage());
      }, 2000);
    }

    if (isSuccess && message === 'Address added successfully') {
      toast.success(SuccessConstant.Success.AddAddress)
      openAddAddressForm(false);
    }
    if (isSuccess && message === 'Address Editted successfully') {
      toast.success(SuccessConstant.Success.AddAddress)
      openAddAddressForm(false);
    }

  }, [isSuccess, isError, message])

  const [prevData, setPrevData] = useState(null)

  const [values, setValues] = useState({
    country: 'India',
    fullname: '',
    phoneNumber: '',
    pinCode: '',
    flat: '',
    area: '',
    landmark: '',
    city: '',
    state: ''
  })

  useEffect(() => {

    user && addressId && setPrevData(user.address.find((e) => e._id === addressId))
    prevData && setValues({
      country: 'India',
      fullname: prevData.fullname,
      phoneNumber: prevData.phoneNumber,
      pinCode: prevData.pinCode,
      flat: prevData.flat,
      area: prevData.area,
      landmark: prevData.landmark,
      city: prevData.city,
      state: prevData.state
    })

  }, [addressId, user, prevData])


  const handleEditSubmit = (event) => {
    event.preventDefault();

    // Check if any required field is empty
    if (!values.fullname || !values.phoneNumber || !values.pinCode || !values.flat || !values.area || !values.landmark || !values.city || !values.state) {
      return toast.error(ErrorConstants.Error.ALL_FELID_ERROR);
    }

    if (!PHONE_REGEX.test(values.phoneNumber)) {
      return toast.error(ErrorConstants.Error.PHONE_ERROR_MESSAGE);
    }

    if (values.pinCode.length !== 6) {
      toast.error(ErrorConstants.Error.PIN_ERROR);
      return;
    }
    const address = {
      country: 'India',
      fullname: values.fullname,
      phone: values.phoneNumber,
      pinCode: values.pinCode,
      flat: values.flat,
      area: values.area,
      landmark: values.landmark,
      city: values.city,
      state: values.state
    };
    dispatch(editAddress({ addressData: { ...address }, id: user ? user._id : null, addressId })).then((response) => {
      toast.success(SuccessConstant.Success.EditAddress);
      openAddAddressForm(false);
      return
    })
  };
  const handleAddSubmit = (event) => {
    event.preventDefault();
    // Check if any required field is empty
    if (!values.fullname || !values.phoneNumber || !values.pinCode || !values.flat || !values.area || !values.landmark || !values.city || !values.state) {
      return toast.error(ErrorConstants.Error.ALL_FELID_ERROR);
    }
    if (!PHONE_REGEX.test(values.phoneNumber)) {
      return toast.error(ErrorConstants.Error.PHONE_ERROR_MESSAGE);
    }

    if (values.pinCode.length !== 6) {
      toast.error(ErrorConstants.Error.PIN_ERROR);
      return;
    }
    const data = new FormData(event.currentTarget);
    const address = {
      country: 'India',
      fullname: data.get('fullname'),
      phone: values.phoneNumber,
      pinCode: data.get('pinCode'),
      flat: data.get('flat'),
      area: data.get('area'),
      landmark: data.get('landmark'),
      city: data.get('city'),
      state: data.get('state')
    };
    dispatch(addAddress({ ...address, id: user ? user._id : null })).then((response) => {
      toast.success(SuccessConstant.Success.AddAddress);
      openAddAddressForm(false);
      return
    })
  };

  const changeInput = (e) => {
    const { name, value } = e.target;

    if (name === 'phoneNumber') {
      const formattedValue = value.replace(/\D/g, '').slice(0, 10);
      setValues({
        ...values,
        [name]: formattedValue,
      });
    } else if (name === 'pinCode') {
      const formattedValue = value.replace(/\D/g, '').slice(0, 6);
      setValues({
        ...values,
        [name]: formattedValue,
      });
    }
    else if (['flat', 'landmark', 'area'].includes(name)) {
      const formattedValue = value.slice(0, 80);
      setValues({
        ...values,
        [name]: formattedValue,
      });
    }
    else if (name === 'fullname') {
      const formattedValue = value.slice(0, 50);
      setValues({
        ...values,
        [name]: formattedValue,
      });
    }
    else if (name === 'city') {
      const formattedValue = value.slice(0, 20);
      setValues({
        ...values,
        [name]: formattedValue,
      });
    }
    else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: '29px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: 'normal',
          lineHeight: '25px',
          color: "#404040"
        }}>{addressId ? "Edit" : "Add"} a Delivery Address</Typography>

      <Box component="form" noValidate onSubmit={addressId ? handleEditSubmit : handleAddSubmit} sx={{ mt: 3 }}>
        {
          inputFields.map((element, index) => {
            return <InputBox {...element} key={index} value={values[element.name]} changeInput={changeInput} />
          })
        }
        <div>

          <label className='block text-gray-700 text-sm font-bold text-left mb-2'>
            State
          </label>
          <select
            name='state'
            value={values.state}
            onChange={changeInput}
            className='border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-12 border-gray-300 focus:border-[#8A58DC]'
          >
            <option value="" disabled>
              Select State
            </option>
            {[
              'Andhra Pradesh',
              'Arunachal Pradesh',
              'Assam',
              'Bihar',
              'Chhattisgarh',
              'Goa',
              'Gujarat',
              'Haryana',
              'Himachal Pradesh',
              'Jharkhand',
              'Karnataka',
              'Kerala',
              'Madhya Pradesh',
              'Maharashtra',
              'Manipur',
              'Meghalaya',
              'Mizoram',
              'Nagaland',
              'Odisha',
              'Punjab',
              'Rajasthan',
              'Sikkim',
              'Tamil Nadu',
              'Telangana',
              'Tripura',
              'Uttar Pradesh',
              'Uttarakhand',
              'West Bengal',
              'Andaman and Nicobar Islands',
              'Chandigarh',
              'Dadra and Nagar Haveli and Daman and Diu',
              'Lakshadweep',
              'Delhi',
              'Puducherry',
            ].map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>

        <div className='mt-2'>

          <Button
            type="submit"
            style="primary"
          >
            Submit
          </Button>
        </div>
      </Box>
    </Box>
  )
}

export default AddAddressForm


