import React, { useMemo,useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Divider, Tooltip, Typography, useMediaQuery } from "@mui/material";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import UserProfileMenuCard from "../component/UserProfile/UserProfileMenuCard";
import { useTheme } from "@mui/system";
import YourOrders from "../component/UserProfile/YourOrders";
import YourAddresses from "../component/UserProfile/YourAddresses";
import LoginAndSecurity from "../component/UserProfile/LoginAndSecurity";
import Logout from "../component/UserProfile/Logout";
import { useSelector } from "react-redux";
import axios from "axios";
import { FaImage } from "react-icons/fa";
import { API_URL } from "../config/config";
import { toast } from 'react-hot-toast'
import constant from "../constants/ImageConstants";
import Loader from "../component/common/Loader";
import imageCompression from 'browser-image-compression';

const USER = constant.IMAGES_IMAGES.USER_PROFILE.USER;


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));



const UserProfilePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [openMenu, setOpenMenu] = useState(1);
  
  const users = useSelector((state) => state.auth);
  const userAvatar = users?.user?.userAvatar?.url
  

  const closeLogoutMenu = () => {
    setOpenMenu("Your Orders")
  }
  const [userAvatarImage, setUserAvatarImage] = useState(USER);
 
  const MenuOptions = useMemo( () => [
    {
      menu: 1,
      img: "LocalMallIcon",
      title: "Your Orders",
      discription: "Track, Return, or Buy things again.",
    },
    {
      menu: 2,
      img: "LocationOnIcon",
      title: "Your Addresses",
      discription: "Edit addresses for orders and gifts.",
    },
    // {
    //   menu: 3,
    //   img: "PaymentIcon",
    //   title: "Payment Options",
    //   discription: "Edit or add payment methods.",
    // },
    {
      menu: 4,
      img: "LockPersonIcon",
      title: "Login & Security",
      discription: "Edit Login, Name & Mobile Number.",
    },
    // {
    //   menu:5,
    //   img: "SupportAgentIcon",
    //   title: "Contact Us",
    //   discription: "Connect with us for any help.",
    // },
    {
      menu: 6,
      img: "LogoutIcon",
      title: "Logout",
      discription: " ",
    },
  ]);


  const handleImageUpload = async (event) => {
    const imageFile = event.target.files[0];

    if (imageFile) {
      if (!imageFile.type.startsWith('image/')) {
        toast.error('Please upload a valid image file.');
        return;
      }
      try {
        const compressedFile = await compressImage(imageFile, 1024 * 1024); // 1 MB
      const reader = new FileReader();

      reader.onloadend = async () => {
        const imageDataUrl = reader.result;

        // Send the base64-encoded image data to the backend
        try {
          const token = JSON.parse(localStorage.getItem('token'));

          const response = await axios.post(
            `${API_URL}/api/user/uploadAvatar`,
            { imageDataUrl },
            {
              headers: {
                'Content-type': 'application/json',
                Authorization: token,
              },
            }
          );

          toast.success('User Profile uploaded successfully!');
          const uploadedUrl = response.data.user.userAvatar.url;
          setUserAvatarImage(uploadedUrl);
          localStorage.setItem('userAvatarImage', uploadedUrl);
        } catch (err) {
          toast.error(err.response.data.error);
        }
      };

      reader.readAsDataURL(compressedFile);
    }catch (error) {
      console.error('Image compression error:', error);
      toast.error(error.response.data.error);
  }
  };
}

const compressImage = async (file, maxSizeInBytes) => {
  const options = {
      maxSizeMB: maxSizeInBytes / (1024 * 1024),
      maxWidthOrHeight: 1024,
      useWebWorker: true,
  };

  // Check if the provided image is under the max size; if so, return the original file
  if (file.size <= maxSizeInBytes) {
      return file;
  }

  try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
  } catch (error) { 
      throw error; // Rethrow the error to be caught by the caller
  }
};

  useEffect(() => {
    const storedImageUrl = localStorage.getItem('userAvatarImage');
    if (storedImageUrl) {
      setUserAvatarImage(storedImageUrl);
    } else {
      setUserAvatarImage(users?.user?.userAvatar?.url);
    }
  }, [users?.user?.userAvatar?.url]);

  


  return (
    <>
      <Box sx={{ flexGrow: 1, padding: "5px", width: "100%", }}>
        <Grid container spacing={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <Item >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  padding: "10px 15px 10px 30px",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "30px",
                    alignItems: { sm: "flex-start", xs: "center" },
                    justifyContent: { sm: "unset", xs: "center" },
                    wordBreak: "break-all",
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    sx={{
                      height: "120px",
                      width: "120px",
                      borderRadius: "50%",
                      // backgroundImage: `url(${"https://picsum.photos/200"})`,
                      backgroundSize: "cover",
                      position: "relative",

                    }}
                  >
                    {userAvatarImage ? (
                        <img
                          src={userAvatarImage}
                          alt="User Avatar"
                          className="rounded-full h-[100%] w-[100%] border border-black"  
                          loading="lazy"
                        />
                    ) : (
                        <img
                          src={userAvatar && userAvatar.url ? userAvatar.url : USER}
                          alt="User Avatar"
                          className="rounded-full h-[100%] w-[100%] border border-black"
                          loading="lazy"
                        />
                    )}




                    <Tooltip title="Upload Profile">
                      <label className="cursor-pointer transition-all duration-200 flex justify-center items-center absolute bottom-0 right-0">

                        <input
                          type="file"
                          accept="image/*"
                          className="hidden"
                          onChange={handleImageUpload}
                        />
                        <div className="p-3 rounded-full bg-black/20 m-auto"><FaImage size={"20px"} color="white" /></div>
                      </label>
                    </Tooltip>
                  </Box>
                  <Box
                    sx={{
                      lineHeight: "26.63px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      // justifyContent:"center",
                      height: "100%"
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ color: "#3C3C3C", fontWeight: "500" }}
                    >
                      {users?.user?.fullname}
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{ color: "#3C3C3C", fontWeight: "400" }}
                    >
                      <LocalPhoneOutlinedIcon
                        sx={{ color: "#8A58DC" }}
                        fontSize="small"
                      />{" "}
                      {users?.user?.phone}
                    </Typography>
                    {
                      users?.user?.email && <Typography
                        variant="p"
                        sx={{ color: "#3C3C3C", fontWeight: "400" }}
                      >
                        <EmailOutlinedIcon
                          sx={{ color: "#F16080" }}
                          fontSize="small"
                        />{" "}
                        {users?.user?.email}
                      </Typography>
                    }
                  </Box>
                </Box>

                <Box
                  sx={{
                    lineHeight: "26.63px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: { sm: "flex-start", xs: "center" },
                    justifyContent: { sm: "flex-start", xs: "center" },
                    maxWidth: "400px",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ color: "#3C3C3C", fontWeight: "500" }}
                  >
                    Personal Details
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      color: "#3C3C3C",
                      fontWeight: "400",
                      textAlign: { sm: "start", xs: "center" },
                    }}
                  >
                    ADDRESS: {users?.user?.address?.length > 0 ? users.user.address[0].flat + ", " + users.user.address[0].area + ", " +
                      users.user.address[0].landmark + " ," + users.user.address[0].city + users.user.address[0].state + ", " + users.user.address[0].pinCode :
                      "Please add your address"}
                  </Typography>
                </Box>
              </Box>
            </Item>
          </Grid>



          <Grid item xs={12}>
            <Item sx={{ width: '100%' }}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: {  sm: "column", xs: "column" },
                  '@media screen and (min-width: 1000px)': {
                    flexDirection: "row",
                  },
                  // alignItems: "center",
                  //   border: (theme) => `1px solid ${theme.palette.divider}`,
                  borderRadius: 1,
                  bgcolor: "background.paper",
                  color: "text.secondary",
                  "& svg": {
                    m: 1.5,
                  },
                  "& hr": {
                    mx: 2.5,
                  },
                  padding: "30px",
                  gap: "40px",
                  // flexWrap: "wrap",
                  justifyContent: { md: "left", sm: "center", xs: "center" },
                  // flex:"2"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: "50px",
                    flexDirection: { md: "column", sm: "row", xs: "row" },
                    flexWrap: "wrap"
                    
                  }}
                >
                  {MenuOptions.map((menuItems, index) => {
                    return (
                      <>
                        <Box
                          key={index}
                          onClick={() => {
                            setOpenMenu(menuItems.menu);
                            // document.body.scrollTop = 0; // For Safari
                            // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                            if (window.innerWidth > 900) {
                              window.scrollTo(0, 0);
                            }
                            else if (window.innerWidth > 640) {
                              window.scrollTo(0, 700);
                            }
                            else {
                              window.scrollTo(0, 1200)
                            }
                          }}
                          sx={{ display: "flex", justifyContent: "center", width: "100%", flexWrap: "wrap" }}
                        >
                          <UserProfileMenuCard {...menuItems} />
                        </Box>
                      </>
                    );
                  })}
                </Box>
                <Divider orientation={isMobile ? "horizontal" : "vertical"} flexItem />

                <Box sx={{ width: "100%" }}>
                  {openMenu === 1 && <YourOrders />}
                  {openMenu === 2 && <YourAddresses />}
                  {openMenu === 4 && <LoginAndSecurity />}
                </Box>
              </Box>
            </Item>
          </Grid>
        </Grid>
      </Box>
      {openMenu === 6 && <Logout closeLogoutMenu={closeLogoutMenu} />}
      {/* <Toaster /> */}
    </>
  );
};

export default UserProfilePage;