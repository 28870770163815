import React, { useEffect, useState } from "react";
import OrderCards from "./OrderCards";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "../../Redux/features/auth/authSlice";
import Button from "../common/Button";
import Loader from "../common/Loader";

const YourOrders = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState('');
  const productsPerPage = 3;
  const [selectedFilter, setSelectedFilter] = useState('NewOrders');
  const users = useSelector((state) => state.auth);
  const LoggedInUserId = users && users.user && users.user._id
  const { user, allOrdersData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();


  useEffect(() => {
    if (users.user) {
      setLoading(true); // Start loading
      dispatch(getAllOrders())
        .then(() => setLoading(false)) // Orders fetched, stop loading
        .catch((error) => {
          console.error("Error fetching orders:", error);
          setLoading(false); // Stop loading on error
        });
    }
  }, [dispatch, users.user]);

  const filteredOrders = allOrdersData?.filter((order) => {
    const isLoggedInUserOrder = LoggedInUserId === order.userId;
    switch (selectedFilter) {
      case "NewOrders":
        return (
          isLoggedInUserOrder &&
          order.orderStatus === "NewOrders" &&
          order.paymentStatus === "completed"
        );
      case "InProcess":
        return (
          isLoggedInUserOrder &&
          order.orderStatus === "InProcess" &&
          order.paymentStatus === "completed"
        );
      case "InTransit":
        return (
          isLoggedInUserOrder &&
          order.orderStatus === "InTransit" &&
          order.paymentStatus === "completed"
        );
      case "Completed":
        return (
          isLoggedInUserOrder &&
          order.orderStatus === "Completed" &&
          order.paymentStatus === "completed"
        );
      default:
        return true; // Return all orders if no specific filter matches
    }
  });

  // Calculate the total number of pages
  const totalPages = Math.ceil(allOrdersData?.length / productsPerPage);

  // Calculate the index of the first and last product to display on the current page
  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = Math.min(
    startIndex + productsPerPage,
    allOrdersData?.length
  );

  // Filter the products to display only the ones for the current page
  const currentProducts = allOrdersData?.slice(startIndex, endIndex);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
    scrollToActivePage(pageNumber);
  };

  const scrollToActivePage = (pageNumber) => {
    const paginationButton = document.getElementById(`page-${pageNumber}`);
    if (paginationButton) {
      paginationButton.scrollIntoView({ behavior: "smooth", inline: "center" });
    }
  };

  const style = "w-full  max-[1031px]:h-[40px]  ";

  return (
    <div className="min-[500px]:p-2 h-max w-full flex-col cols-1">
      {/* {loading ? (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      ) : ( */}
      <div>
        <div className="flex flex-row text-[9px] text-xs justify-start items-start gap-1 min-[500px]:gap-5 mb-4">
          {/* <Button variant='text' sx={{ color: "#8A58DC" }} onClick={() => setSelectedFilter('all')}>Show All</Button> */}
          <Button
            className={`${style} ${selectedFilter === 'NewOrders' ? 'bg-[#F16080] text-white' : 'text-[#8A58DC]'
              } `}
            style='primary'
            onClick={() => setSelectedFilter('NewOrders')}
          >
            New Order
          </Button>
          <Button
            className={`${style} ${selectedFilter === 'InProcess' ? 'bg-[#F16080] text-white' : 'text-[#8A58DC]'
              } `}
            style='primary'
            onClick={() => setSelectedFilter('InProcess')}
          >
            In Process
          </Button>

          <Button
            className={`${style} ${selectedFilter === 'InTransit' ? 'bg-[#F16080] text-white' : 'text-[#8A58DC]'
              } `}
            style='primary'
            onClick={() => setSelectedFilter('InTransit')}
          >
            Transit
          </Button>

          <Button
            className={`${style} ${selectedFilter === 'Completed' ? 'bg-[#F16080] text-white' : 'text-[#8A58DC]'
              }`}
            style='primary'
            onClick={() => setSelectedFilter('Completed')}
          >
            Delivered Orders
          </Button>

        </div>

        {loading ? (
          <div className="h-[350px] w-full flex justify-center items-center">
            <Loader />
          </div>
        ) : filteredOrders?.length > 0 ? (
          filteredOrders?.map((order, index) => (
            <OrderCards
              key={order.id}
              orderId={order._id}
              productsDetails={order.productsDetails}
              orderStatus={order.orderStatus}
              createdAt={order.createdAt}
              userAddress={order.shippingAddress}
            />
          ))
        ) : (
          <div className="h-full w-full py-32 flex justify-center items-center">
            <p>No Order Available</p>
          </div>
        )}
      </div>
      {/* )} */}

    </div>
  );
};

export default YourOrders;
