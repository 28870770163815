import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'


const TermsAndCondition = () => {

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const links = document.querySelectorAll(".scroll-Top");
    links.forEach((link) => {
      link.addEventListener("click", scrollToTop);
    });

    return () => {
      // Remove event listeners when the component unmounts
      links.forEach((link) => {
        link.removeEventListener("click", scrollToTop);
      });
    };
  }, []);

  //for mail

  const email = 'services@theekaiv.in';

  const handleClick = () => {
    window.open(`mailto:${email}`);
  };

  return (
    <div className=' flex justify-center items-center bg-gray-100 text-justify'>
      <div className='w-[85%] pt-10 text-justify'>
        <div >


          <div className='text-center w-full font-bold text-xl p-5'>
            Terms and Conditions
          </div>
          <div className=' break-words'>
            <p>
              This document serves as an electronic record under the Information Technology Act, 2000, and its applicable rules. It adheres to the amended provisions concerning electronic records across various statutes, as per the Information Technology Act, 2000. The generation of this electronic record is facilitated by a computer system and does not necessitate physical or digital signatures.
            </p>
            <br />
            <p>
              In alignment with Rule 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011, we publish the rules, regulations, privacy policy, and Terms of Use for accessing or utilizing the theekaiv.com website.
            </p>
            <br />
            <p>
              All products, services, or features (referred to as "Materials") accessible through theekaiv.com are owned by Perceptions Technologies a company incorporated under the Companies Act, 1956, with its registered office at 219, Jawahar Nagar, Indore 452012 M.P.
            </p>
            <br />
            <p>
              For the purposes outlined in these Terms of Use, "You" or "User" refers to any natural or legal person visiting or utilizing the website. The Ekaiv allows users to browse the website without registering. The terms "We," "Us," "Our" denote Perceptions Technologies (Product: The Ekaiv)
            </p>
            <br />
            <p>
              Your utilization of the website and its services and tools is governed by the applicable terms and conditions ("Terms of Use"), including the policies incorporated herein by reference. Using the website implies your agreement to these terms and conditions, constituting binding obligations between you and Perceptions Technologies
            </p>
            <br />
            <p>
              The website, inclusive of all Materials (excluding applicable third-party materials), is the intellectual property of The Ekaiv and is protected by global copyright laws. Compliance with worldwide copyright laws in the use of the website is expected, and any unauthorized copying of Materials is prohibited. The Ekaiv does not provide express or implied rights under patents, trademarks, copyrights, or trade secret information.
            </p>
            <br />
            <p>
              Upon using Materials provided by us, you are subject to rules, guidelines, policies, terms, and conditions specific to those services, incorporated into the Terms of Use. We reserve the right to modify the Terms of Use at our discretion, without prior notice. Your continued use of the website after changes implies acceptance of and agreement to the revisions.
            </p>
            <br />
            <p>
              Accessing, browsing, or using the website indicates your agreement to all the terms and conditions outlined in these Terms of Use. By accepting these Terms of Use, you also acknowledge and agree to be bound by The Ekaiv Policies, including the Privacy Policy available on <Link to='/privacy-policy' className='scroll-Top text-blue-400'>https://theekaiv.in/privacy-policy</Link>, subject to amendments.
            </p>
            <br />

            <p>
              <span className=' font-bold'>USER ELIGIBILITY</span><br />
              Access to the the Ekaiv website is limited to individuals capable of entering into legally binding contracts under the Indian Contract Act, 1872. Those deemed "incompetent to contract" as per the Indian Contract Act, 1872, including minors, un-discharged insolvents, etc., are ineligible to use the website. If you are a minor, i.e., under 18 years of age, you must not register as a user or use the website. Minors desiring to use the website may do so through their legal guardians or parents. The Ekaiv reserves the right to terminate your membership or deny access to the website upon discovering that you are under 18 years of age.
            </p>
            <br />
            <p>
              <span className=' font-bold'>YOUR ACCOUNT AND REGISTRATION OBLIGATIONS</span><br />
              As a user of the Ekaiv website, you are responsible for maintaining the confidentiality of your Display Name and Password. You are also accountable for all activities undertaken under your Display Name and Password. If you provide information that is untrue, inaccurate, not current, or incomplete, or if there are reasonable grounds to suspect such inaccuracies, Ekaiv retains the right to suspend, terminate, or block your membership indefinitely. Access to the website may be refused under such circumstances.
            </p>
            <br />
            <p>
              <span className=' font-bold'>COMMUNICATIONS</span><br />
              By using the Ekaiv website or sending emails or other data, information, or communication, you acknowledge that you are communicating with the Ekaiv through electronic records. You consent to receiving periodic communications via electronic records from the Ekaiv, as and when required. Communication may occur through email or any other electronic or non-electronic mode of communication.
            </p>
            <br />

            <p>
              <span className=' font-bold'>CHARGES</span><br />
              Membership on the the Ekaiv website is free of charge. The Ekaiv does not impose any fees for accessing or browsing the website. The Ekaiv reserves the right to introduce new services or modify existing services at its sole discretion.
              <br />
              <span>
                Blogs: Individuals, including but not limited to the Ekaiv employees (referred to as "Individuals" hereafter), may contribute articles and opinions on the website as "blogs" at the sole discretion of the Ekaiv. It is acknowledged that these blogs represent the personal opinions of the Individuals and may not reflect official positions of the Ekaiv. The Ekaiv retains all ownership and rights to these blogs.
              </span>
              <br />
              <span>
                Users may have the opportunity to post comments and feedback on these blogs. By doing so, users expressly agree to abide by the following:
              </span>
              <br />
            </p>
            <p className='pl-4'>
              1. Users shall not share any information to which they do not have the right, and that is misleading, harmful, harassing, defamatory, obscene, pornographic, libelous, invasive of privacy, or otherwise unlawful.<br />
              2. Users shall not engage in activities such as harassment, spamming, or promoting illegal conduct.<br />
              3. Users shall not violate any third party's rights, including intellectual property rights, rights of privacy, or rights of publicity.<br />
            </p>
            <br />
            <p>
              <span>
                The Ekaiv reserves the right to change, edit, or remove content that violates these terms. Users are solely responsible for the content they post, and the Ekaiv disclaims any responsibility or liability for such content.
              </span>
              <br />
              <span>
                Users agree to comply with applicable laws and regulations, and the Ekaiv retains the right to take necessary action against any involvement in DoS/DDoS (Distributed Denial of Services) or any other harmful activities.
              </span>
              <br />
              <span>
                Except as expressly provided, no part of the website and no materials may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, or distributed without the Ekaiv's prior written consent.
              </span>
              <br />
              <span>
                Third-party content: The website may provide information from third parties for general informational purposes. Users acknowledge that the Ekaiv does not create or endorse third-party content and is not liable for any investment decisions based on such content. External links are provided for convenience, and users visit linked sites at their own risk.
              </span>
              <br />
              <span>
                By using the Ekaiv website, users agree to these terms and conditions and are responsible for taking protective measures against viruses or other destructive elements when visiting external websites. The Ekaiv does not endorse or control linked sites and is not responsible for their content or any changes or updates.
              </span>
            </p>
            <br />

            <p>
              <span className=' font-bold'>Posting on the Ekaiv Platform:</span><br />
              <span>
                The Ekaiv does not assert ownership of the materials you provide to the Ekaiv (including feedback and suggestions) or post, upload, input, or submit to any section of the platform (each a “Submission” and collectively “Submissions”). However, by posting, uploading, inputting, providing, or submitting (“Posting”) your Submission, you grant the Ekaiv, its affiliated companies, and necessary sublicensees permission to use your Submission in connection with the operation of their business. This includes, without limitation, the license rights to: copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate, and reformat your Submission; publish your name in connection with your Submission; and the right to sublicense such rights to any other party.
              </span>
              <span>
                You acknowledge and agree that no compensation shall be paid, and no future commercial consideration has accrued with respect to the use of your Submission by the Ekaiv, as provided herein. The Ekaiv shall be under no obligation to post or use any Submission you provide, and the Ekaiv may remove any Submission at any time at its sole discretion.
              </span>
              <span>
                By posting a Submission, you warrant and represent that you own or otherwise control all the rights required under worldwide law for your Submission as described in these terms. This includes, without limitation, all the rights necessary for you to provide, post, upload, input, or submit the Submissions.
              </span>

            </p><br />
            <p>
              <span className=' font-bold'>PROFANITY POLICY</span><br />
              Ekaiv prohibits the use of language that is racist, hateful, sexual, or obscene in nature in public areas of the platform.
              This policy extends to your Submissions, Postings, and all other areas of the website that another user may view. Users may 'blur' out the bulk of offending words with asterisks (i.e., s** or f**).
              Please report any violations of this policy for review.
              If a feedback comment or any communication between users on the platform or email communication contains profanity, please review our feedback removal policy and submit a request for action/removal.
            </p>
            <br />
            <p>
              <span className=' font-bold'>PRIVACY</span><br />
              At the Ekaiv, safeguarding your privacy is a fundamental principle. We recognize that you and your Personal Information are crucial assets. Your Information, including any sensitive financial details collected (as defined under the Information Technology Act, 2000), is stored and processed on computers protected by physical and reasonable technological security measures and procedures, following the Information Technology Act 2000 and relevant rules. Our current Privacy Policy can be found at <Link to='/privacy-policy' className='scroll-Top text-blue-400'>https://theekaiv.in/privacy-policy</Link>. If you disagree with the transfer or use of your Information in this manner, please refrain from using the Ekaiv platform.
              <br />
              We may share personal information with our affiliated corporate entities. These entities may engage in marketing unless you explicitly opt-out.
              <br />
              Disclosure of personal information to third parties may occur for purposes such as providing access to our Services, meeting legal obligations, enforcing our User Agreement, supporting marketing and advertising activities, or preventing, detecting, mitigating, and investigating fraudulent or illegal activities related to our Services. Your personal information will not be disclosed to third parties for marketing and advertising purposes without your explicit consent.
              <br />
              We may disclose personal information if required by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal processes. Personal information may also be disclosed to law enforcement offices, third-party rights owners, or others if such disclosure is reasonably necessary to enforce our Terms or Privacy Policy, respond to claims of rights violations, or protect the rights, property, or personal safety of our users or the general public.
              <br />
              In the event of a merger, acquisition, or reorganization involving Ekaiv or its assets, we and our affiliates may share some or all of your personal information with the other business entity. The acquiring entity, or the new combined entity, will be required to adhere to this privacy policy concerning your personal information.
            </p>
            <br />
            <p>
              <span className='font-bold'>DISCLAIMER OF WARRANTIES AND LIABILITY</span><br />
              The Ekaiv platform, along with all materials, information/content (including software), and services provided on or through the platform, is offered on an "as is" and "as available" basis without any representation or warranties, express or implied, except as otherwise specified in writing. The Ekaiv does not guarantee that:<br />
            </p>
            <p className=' pl-4'>
              • The platform will be consistently available or available at all times; or<br />
              • The information on the platform is complete, true, accurate, or non-misleading.<br />
            </p>
            <p>The Ekaiv shall not be held liable to you in any way or in connection with the materials, use of, or otherwise related to the platform. The Ekaiv does not warrant that the platform, information, content, materials, products (including software), or services provided on or through the platform, their servers, or electronic communication sent from us are free of viruses or other harmful components.
            </p><br />
            <p>
              <span className=' font-bold'>INDEMNITY</span><br />
              You agree to indemnify and hold harmless the Ekaiv, its owner, licensee, affiliates, subsidiaries, and their respective officers, directors, agents, and employees, from any claim or demand, including reasonable attorneys’ fees, made by any third party or penalty imposed due to or arising out of your breach of these Terms of Use, Privacy Policy, and other policies, or your violation of any law, rules, or regulations or the rights (including infringement of intellectual property rights) of a third party.
            </p><br />
            <p>
              <span className=' font-bold'>LIMITATION OF DAMAGES</span><br />
              In no event shall the Ekaiv or any of its subsidiaries or affiliates be liable to any entity for any direct, indirect, special, consequential, or other damages (including, without limitation, any lost profits, business interruption, loss of information or programs or other data on your information handling system) related to the use of, or the inability to use, the content, materials, and functions of this platform or any linked platform, even if the Ekaiv is expressly advised of the possibility of such damages.
            </p><br />
            <p>
              <span className=' font-bold'>DISCLAIMER</span><br />
              The Ekaiv platform may contain inaccuracies and typographical and clerical errors. The Ekaiv expressly disclaims any obligation(s) to update the platform or any of the materials on this platform. The Ekaiv does not warrant the accuracy or completeness of the materials or the reliability of any advice, opinion, statement, or other information displayed or distributed through the platform. You acknowledge that any reliance on any such opinion, advice, statement, memorandum, or information shall be at your sole risk. Ekaiv reserves the right, in its sole discretion, to correct any errors or omissions in any portion of the platform. The Ekaiv may make changes to the platform, the materials, and the products, programs, services, or prices (if any) described on the platform at any time without notice. This platform is for informational purposes only and should not be construed as technical advice in any manner.
            </p><br />
            <p>
              <span className=' font-bold'>APPLICABLE LAW</span><br />
              These Terms of Use shall be governed by and interpreted and construed in accordance with the laws of India. The place of jurisdiction shall be exclusively in Indore.
            </p><br />
            <p>
              <span className=' font-bold'>CONTACT US</span><br />
              For any questions or comments (including all inquiries unrelated to copyright infringement) regarding the Ekaiv platform, please contact us at  <span className=' text-blue-400 cursor-pointer' onClick={handleClick}>{email}</span>

            </p><br />
            <p>
              <span className=' font-bold'>GRIEVANCE OFFICER</span><br />
              In accordance with the Information Technology Act 2000 and rules made thereunder, the name and contact details of the Grievance Officer are provided below:<br /> </p>
            <p className=' pl-2 '>Anil Raghuwanshi<br />
              Perceptions Technologies<br />
              219, Jawahar Nagar <br />
              Indore, 452012<br />
              Email:  <span className=' text-blue-400 cursor-pointer' onClick={handleClick}>{email}</span><br />
              Time: Mon - Fri (10:00 - 19:00)<br /></p>

            <br />
            {/* <p>
          By engaging with The Ekaiv App and Website, you explicitly agree to adhere to these detailed terms and conditions. Should any part of these terms be unclear or require clarification, please reach out to us at support@perceptionstechnologies.com for financial queries and for all the other queries email us at services@perceptionstechnologies.com
          
          </p><br />
          <p>
          Thank you for choosing The Ekaiv — we appreciate your commitment to our community!
          </p> */}
            <br />
          </div>
        </div>
      </div>

    </div>
  )
}

export default TermsAndCondition
