import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice'
import productsReducer from '../features/product/productsSlice';
import otpAuthReducer from '../features/otpVerification/otpSlice';
import vendorSlices from '../features/vendor/vendorSlices';
import orderReducer from '../features/order/orderSlices';



export const store = configureStore({
  reducer: {
    auth : authReducer,
    otpAuth: otpAuthReducer,   
    vendor: vendorSlices,
    products: productsReducer,
    order: orderReducer,
  },
});
