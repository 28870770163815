import React, { useState } from "react";
import { KeyboardDoubleArrowUp } from "@mui/icons-material";
import { Box } from "@mui/material";

const ScrollArrow = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 300) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 300) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  return (
    <div
      className={`fixed z-50 bottom-10 right-5 p-2 text-white rounded-full shadow-md transition-opacity ${
        showScroll ? "opacity-100 translate-y-0" : "opacity-0 translate-y-5"} bg-[#F16080] hover:scale-105`}
      style={{ animationDuration: "0.5s" }}
    //   data-aos="fade-up"
    >
      <KeyboardDoubleArrowUp
        className="text-white cursor-pointer"
        fontSize="large"
        onClick={scrollTop}
      />
    </div>
  );
};

export default ScrollArrow;
