const Error = {
    EMAIL_ERROR_MESSAGE: 'Please enter a valid email address',
    PHONE_ERROR_MESSAGE: 'Please enter a valid 10-digit phone number',
    FORM_ERROR_MESSAGE: "Error submitting form. Please try again later",
    INTERNAL_SERVER: "Internal Server Error",
    INVALID_PIN_ERROR_MESSAGE: ' Please provide a valid pin code',
    INVALID_PAN_NUM: 'Please provide correct PAN number.',
    INVALID_GST: 'Please enter a valid GST.',
    SHIPPING_ERROR_MESSAGE: "Please select shipping address.",
    EMPTY_CARD_ERROR: "Cart is empty.",
    VIEW_CART_ERROR: "Please login to view your Cart",
    WISHLIST_CART_ERROR: "Please login to view your Wishlist",
    SOMETING: "Something went wrong",
    LOGIN_FIRST_ERROR: "Please log in first to add items to your cart",
    OWN_PRODUCT_ERROR: "You cannot add your own products to the cart",
    PRODUCT_STOCK_NOTFOUND: "This product is currently out of the stock",
    PRODUCT_SIZE_ERROR: "Please select a size before adding to cart",
    LOGIN_WISHLIST_ERROR: "Please log in first to add items to your wishlist",
    SIZE_ERROR: 'Please select a size before buying the product',
    QUANTITY_ERROR: "Quantity cannot be less than 1",
    ALL_FIELD_ERROR: "Please fill in all fields before submitting.",
    MAIL_ERROR: "Failed to send the mail. Please try again later.",
    EMAIL_ERROR: "Please enter valid email or password.",
    PDF_UPLOAD_ERROR: "Please upload a PDF files for otherBusiness Document",
    FILE_SIZE_ERROR: "File size should be less than 1MB",
    Bussiness_PDF_ERROR: "Please upload a PDF files for business registration documents",
    FOOD_LICENSE_ERROR: "Please upload Food License Proof",
    BUSINESS_EMAIL_ERROR: "Please verify your business email first",
    BUSSINESS_ADDRESS_ERROR: "Please upload a PDF files for Business Address Proof Document",
    BUSSINESS_CATEGORY_ERROR: "Please select the business category",
    ALL_FIELD_ERROR_MESSAGE: "Please fill all the required fields in valid format",
    INVALID_DATA_ERROR: "Invalid date format",
    AGE_ERROR: "You must be at least 13 years old to register",
    OTP_VERIFICATION_ERROR: "Please verify OTP before proceeding",
    OTP_ENTER_ERROR: "Please enter OTP",
    SIZE_ERROR: "Please select the size",
    CUSTOMIZATION_ERROR: "Please save the customization details first",
    CUSTOMIZATION_LOGIN_ERROR: "Please log in first to add customization",
    CUSTOMIZATION_OWN_ERROR: "You cannot add customization for your own product",
    CUSTOMIZATION_PENDING_WARNING: 'Your customization for this product is pending. Please wait for the seller\'s reply.',
    OWN_PRODUCT_WISHLIST: 'You cannot add your own products to the wishlist',
    CUSTOMIZATION_ID_ERROR: 'Customization ID not found for the current product',
    CUSTOMIZATION_FILL_ERROR: 'Please fill in the customization details first',
    CUSTOMIZATION_ALREADY_EXIXT_ERROR: 'Customization for this product already exists',
    UNEXPECTED_ERROR: "Unexpected error occurred. Please try again",
    LIMIT_ERROR: "Limit is Reached",
    LOGIN_ERROR: "Please log in first!!!",
    OWN_PRODUCT_BUY_ERROR: "You cannot buy your own product.",
    CATEGORY_DELETE_ERROR: "Please select a category to delete",
    PRODUCT_DELETE_ERROR: "Please select at least one product to delete",
    PRODUCT_DELETE: "Selected product deleted successfully",
    PRODUCT_DELETE_FAIL: "Failed to delete selected product",
    PRODUCT_STOCK_ERROR: "Please select at least one product to update the stock",
    CATEGORY_IMG_ERROR: "Image URL is required for the Category Name.",
    CATEGORY_IMG_NAME_ERROR: "Image URL is required for Subcategory Name",
    CATEGORY_NAME_ERROR: " Category or Subcategory Name Error",
    CATEGORY_DELETE_FAIL_ERROR: "Category deleted successfully!",
    CATEGORY_CREATE_FAIL: "Failed to create the category. Please try again later",
    IMG_SELECT_ERROR: "Please select an image",
    MAIN_BANNER_ERROR: "Main Banner field empty",
    ERROR: "An error occurred while processing your request",
    ONE_FEILD_ERROR: 'At least one field (response message or customized price) must be filled',
    ALL_FELID_ERROR: "Please fill in all fields before saving.",
    SAVE_ERROR: "Error saving data. Please try again",
    VENDOR_REJECTED: "Please provide a reason for rejection",
    PIN_ERROR: "Please enter a valid Pin Code.",
    ALL_FELID_OTP_ERROR: "Please fill all the required fields before sending OTP.",
    OTP_ERROR: 'Failed to resend OTP. Please try again.',
    ALL_FEILD_ERROR: "Please fill in all the necessary details.",
    OTP_1_ERROR: "Please enter the OTP before saving 1.",
    OTP_6_CHAR_ERROR: "Maximum length for OTP is 6 characters",
    PHONE_ERROR: "Please enter a valid phone number.",
    PHONE_DIGIT_ERROR: "New Phone number should be of 10 digits",
    PHONE_NO_DIFF_ERROR: "New Phone number should be different from current phone number.",
    CHANGES_ERROR: "changes are not saved",
    OTP_BOTH_ERROR: "Please enter both OTPs before submitting.",
    OTP_EXISTING_ERROR: "Failed to send OTP to existing mobile number. Please try again.",
    OTP_NEW_ERROR: 'Failed to send OTP to the new mobile number. Please try again.',
    OTP_VERIFICATION_ERROR: "Failed to verify OTP. Please try again.",
    PHONE_ERROR: "Please verify your mobile number by entering OTP.",
    PHONE_EMPTY_ERROR: "Mobile Number field is empty.",
    ALT_PHONE_EMPTY_ERROR: "Alternate Mobile Number field is empty.",
    ALL_FEILD_ERROR: "Please fill in all the fields.",
    IMG_ERROR: "Please upload a valid image file.",
    OTP_FAIL_ERROR: "Failed to send OTP. Please try again.",
    EMAIL_EMPTY_ERROR: "Email field is empty. Please enter a valid email.",
    OTP_EMAIL_NEW_ERROR: "Failed to send OTP to the new email. Please try again.",
    CATEGORY_ERROR: "You can't deselect a cateogry",
    CATEGORY_SELECT_ERROR: 'Please select the required Category.',
    CATEGORY_TYPE_ERROR: 'Please select the Type Of Category.',
    CATEGORY_KIDS_ERROR: 'Please select the Kids Category.',
    PRODUCT_CUSTOMIZATION_ERROR: 'Please select whether the product is Customizable.',
    PRODUCT_SPECIFICATION_ERROR: 'Product Specification must not be empty and should not exceed 1000 characters.',
    HOMEMADE_ERROR: 'Please add Ingredients for Homemade Food.',
    CLOTHING_ERROR: 'Please enter the Fabric for clothing.',
    MATERIAL_ERROR: 'Please enter Material details.',
    PROCESSING_ERROR: 'Please enter the Processing Time.',
    ONE_IMG_ERROR: 'Please select at least one Product Image',
    ONE_YEAR_ERROR: 'Please select at least one Year.',
    ONE_SIZE_ERROR: 'Please select at least one Size.',
    ONE_SIZE_CHART_ERROR: 'Please upload the Size Chart Image.',
    MODEL_ERROR: 'Please enter the Model Size.',
    DIMENSIONS_ERROR: 'Please enter the product dimensions.',
    ORIGINAL_PRICE_ERROR: 'Please enter the Original Price.',
    DISCOUNTED_PRICE_ERROR: 'Please enter the Discount Price.',
    MANUFACTURING_ERROR: 'Please enter the Limit Of Manufacturing.',
    STOCK_ERROR: 'Please enter the Stock.',
    CREATE_ERROR: 'Failed to create the product. Please try again later.',
    SHELF_ERROR: 'Please enter the shelf life.',
    IMG_5_ERROR: 'You can only select up to 5 images.',
    IMG_INVALID_ERROR: 'Invalid file type. Please choose only JPEG, PNG, or JPG images.',
    VALIDIMG_ERROR: 'Please make sure to select only valid image files.',
    IMG_1MB_ERROR: 'File size exceeds the 1 MB limit. Please choose a smaller file.',
    BUSSINESS_LICENSE_ERROR: "Please upload a PDF files for food license documents",
    ABOUT_ERROR: "About must be filled",
    START_END_DATE_ERROR: "Please provide both start and end date",
    END_DATE_ERROR: "End date should be greater than or equal to start date",
    STATUS_ERROR: "Failed to change the status. Please try again later.",
    STOCK_0_ERROR: "Stock of product is now 0",
    PRODUCT_NEW_ERROR: "Cannot add a new product when status is Pending.",
    EDIT_PRODUCT_ERROR: "Cannot edit product with Pending status.",
    PRODUCT_NAME_ERROR: "Please enter the Product Name.",
    BUSSINESS_DOC_FORMATE_ERROR: "Please provide the documentfor other Buiness document in pdf format",
    ALL_FEILD_ERROR: "All fields must be filled",
    FULLNAME_ERROR: "Please enter Full name.",
    PDF_FORMATE_ERROR: "Please provide a pdf file",
    PHONE_EMAIL_ERROR: "Please Enter Phone No or Email",
    PAYMENT_ERROR: "Error completing Payment. Please try again later.",
    WEIGHT_ERROR:"Please Enter the product weight.",
    COMMENT_WARNING: "You have reached the comment limit for this product.",
    COMMENT_CHECK:"Please select a rating before submitting your comment.",
    COMMENT_SUBMIT:"Please comment before submitting .",
    COMMENT_5_WARNING:"You can add a maximum of 5 comments per product.",
    ABSUIVE_COMMENT:"Abusive language is not allowed. Please use appropriate language.",
    COMMENT_PRODUCT_PURCHASE:"You must purchase the product to leave a comment.",
    INVALID_IFSC:"Please enter a valid IFSC code",
    INVALID_CIF:"Please enter your 11 digit CIF Number",
    INVALID_ACCOUNT:"Please enter correct Account Number",
    NO_CHANGE:"no fields are changed",
}

export default { Error };