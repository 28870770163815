import React from 'react'
import AllOrders from '../Our Orders/AllOrders'
import ManageDashboard from '../Dashboard/ManageDashboard'
const Dashboard = () => {
    return (
        <>

            {/* <div className='p-2 mt-4 w-96 flex flex-col justify-center items-center gap-10 '>
                <img
                    src="https://img.freepik.com/premium-vector/flat-admin-dashboard-concept_108061-614.jpg?w=2000"
                    alt=""
                />
                <p className='font-bold text-3xl p-2 text-[#8459C7] animate-bounce' >Coming Soon...</p>
            </div> */}
            <div className=' flex flex-col '>

            
             <div>
                <ManageDashboard/>
            </div>
             <div>
                <AllOrders/>
            </div>

            </div>

        </>

    )
}

export default Dashboard    