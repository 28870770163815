import React, { useState, useRef, useEffect } from 'react';
import Button from '../common/Button';
import { toast } from 'react-hot-toast';
import SuccessConstant from '../../constants/SuccessConstant';
import ErrorConstants from '../../constants/ErrorConstants';
import InputField from '../common/InputField';
import commonService from '../../services/common/commonService';
import axios from 'axios';
import { API_URL } from '../../config/config';


const Legal = () => {

  const formlegal = useRef();

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    query: '',
  });

  const [advisorName, setAdvisorName] = useState('')
  const [advisorImage, setAdvisorImage] = useState('')
  const [description, setDescription] = useState('')

  const formFields = [
    { name: 'name', label: 'Name', type: 'text', placeholder: 'Name' },
    { name: 'phone', label: 'Phone', type: 'text', placeholder: 'Phone' },
    { name: 'email', label: 'Email', type: 'email', placeholder: 'Email' },
    { name: 'message', label: 'Business Type & Details', type: 'textarea', placeholder: 'Business Type & Details' },
    { name: 'query', label: 'Advisory Message', type: 'textarea', placeholder: 'Advisory Message' },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if ((name === 'name' || name === 'message') && value.length > 50) {
      // Do not update state if the name, location, or businessType exceeds 50 characters
      return;
    }
    // Ensure only digits are entered into the phone number field
    if (name === 'phone') {
      const numericValue = value.replace(/\D/g, '').slice(0, 10); // Remove non-digit characters
      setFormData({
        ...formData,
        [name]: numericValue,
      });


    } else {
      // Update formData with the new input value
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const sendEmail = async (e) => {
    e.preventDefault();

    // Your form validation logic here...
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmpty = Object.values(formData).some(value => value === '');
    if (isEmpty) {
      toast.error(ErrorConstants.Error.ALL_FIELD_ERROR);
      return;
    }
    if (!phoneRegex.test(formData.phone)) {
      toast.error(ErrorConstants.Error.PHONE_ERROR_MESSAGE);
      return;
    }
    if (!emailRegex.test(formData.email)) {
      toast.error(ErrorConstants.Error.EMAIL_ERROR_MESSAGE);
      return;
    }

    try {
      // Call the function to send email and await the response
      const response = await commonService.sendLegalAdvisoryEmail(formData);

      // Handle success response
      toast.success(SuccessConstant.Success.FORM_SUCCESS);

      // Clear the form data after successful submission
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: '',
        query: '',
      });
    } catch (error) {
      toast.error(ErrorConstants.Error.FORM_ERROR_MESSAGE);
    }

    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 5000);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/assistance`);
        setAdvisorName(response.data.LegalAdvisor.name)
        setAdvisorImage(response.data.LegalAdvisor.imageUrl.url)
        setDescription(response.data.LegalAdvisor.description)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  return (
    <>
      <div className=' bg-gray-100 p-2 sm:p-10'>

        <div className=' w-full flex flex-col justify-center items-center'>
          <h2 className="text-xl min-[510px]:text-2xl font-bold mb-4 text-gray-800">Legal Advisory</h2>
          <p className='text-sm rounded-lg py-5 px-7  text-justify'>
            Our network of Advocates and Lawyers is committed to assisting you in navigating the legal landscape of business. From contract reviews to dispute resolution, our legal experts are ready to ensure that your business operations remain smooth and legally sound. Say goodbye to unexpected legal hurdles with the assurance that you have a team of professionals supporting your journey.

          </p>
        </div>

        <div className=' grid md:grid-cols-2 grid-cols-1 gap-2 p-2'>

          <div className=' flex flex-col justify-center items-center'>
            <div className=' p-5 w-[100%] md:w-[70%]'>
              <form ref={formlegal} onSubmit={sendEmail} className="mt-4 flex flex-col gap-4">

                {formFields.map((field, index) => (
                  <InputField
                    key={index}
                    type={field.type}
                    label={field.label}
                    id={field.name}
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleInputChange}
                    placeholder={field.placeholder}
                  />
                ))}
                <Button
                  type="submit"
                  style="primary"
                  disabled={buttonDisabled}
                >
                  Book an Appointment
                </Button>

              </form>
            </div>
            {/* Add any other UI elements and functionality here */}
          </div>






          {/* <h1 className=' text-lg font-bold'>Reach Out To Us!</h1> */}
          <div className=' bg-white  rounded-2xl px-2 sm:px-5 pt-5 '>



            <div className=' flex flex-col'>
              <div className='flex md:flex-row flex-col'>
                <img src={advisorImage} loading='lazy-loading' alt="Ekaiv" className='mx-5 h-28 w-28 rounded-full' />
                <p className=' pt-14 px-5 text-2xl font-bold'>{advisorName}</p>
              </div>
              <div className=' flex flex-col p-5'>
                <p className='text-sm text-justify'>
                  {description}
                </p>
              </div>

            </div>



          </div>
        </div>

      </div>

    </>
  );
};

export default Legal;
