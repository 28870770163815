import React from 'react'
import ComingSoon from '../../../pages/ComingSoon'

function  VendorChatBot() {
  return (
	<div className='h-full'>
	  <ComingSoon/>
	</div>
  )
}

export default  VendorChatBot;

