import React, { useEffect, useState } from 'react';
import ProductCard from '../ProductCard/ProductCard';
import { useDispatch } from 'react-redux';
import { getPopularProducts } from '../../Redux/features/product/productsSlice'; 
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs'
import Loader from '../common/Loader';

const PopularProducts = () => {
  const [popularProducts, setPopularProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPopularProducts())
      .then((response) => {
        const products = response.payload.products; // Extract the products array from the response
        setPopularProducts(products);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
      });
  }, [dispatch]);

  if (error) {
    return <div>Error fetching popular products: {error.message}</div>;
  }

  const slideLeft = () => {
    var slider = document.getElementById('popularslider');
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRight = () => {
    var slider = document.getElementById('popularslider');
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  return (
    <>
      <div className="w-full p-1 sm:p-5 mb-4">
        {popularProducts && popularProducts?.length > 0 && (
          <>
            <div className="w-full flex justify-center text-center text-[27px] font-600 text-[#343246]">POPULAR PRODUCTS</div>
            <div className='w-full flex justify-center text-lg text-center text-[#343246] mt-1 mb-8'>Cherish moments with our exclusive selections!</div>

            {loading ? (
              <div className="flex justify-center"><Loader /></div>
            ) : (
              <div className='relative flex items-center justify-start group sm:p-2'>
                {/* Left arrow */}
                <div onClick={slideLeft} className="absolute left-5 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 shadow-2xl md:shadow-2xl lg:shadow-2xl xl:shadow-2xl opacity-80">
                  <BsArrowLeftShort className="rounded-full border border-spacing-4 text-white bg-[#880081]" style={{ boxShadow: "rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px" }} size={35} />
                </div>
                <div id={'popularslider'} className="flex flex-nowrap gap-5 overflow-x-auto no-scrollbar scroll-smooth pt-4 pb-4 pl-5 pr-5 space-x-4 ml-10 mr-10">
                  {popularProducts.map((product, index) => (
                    <ProductCard data={product} key={index} />
                  ))}
                </div>
                {/* Right arrow */}
                <div onClick={slideRight} className="absolute right-5 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 opacity-80">
                  <BsArrowRightShort className="rounded-full border border-spacing-4 text-white bg-[#880081]" style={{ boxShadow: "rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px" }} size={35} />
                </div>
              </div>
            )}
          </>
        )}
      </div>

    </>
  );
};

export default PopularProducts;