import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateVendorBusiness } from "../../../Redux/features/vendor/vendorSlices";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import Button from "../../common/Button";
import axios from "axios";
import { API_URL, config } from "../../../config/config";
import ErrorConstants from "../../../constants/ErrorConstants";
import SuccessConstant from "../../../constants/SuccessConstant";

function BusinessDetailsSettings() {
  const vendor = useSelector((state) => state.vendor);
  const dispatch = useDispatch();
  const vendorId = vendor && vendor.vendor && vendor.vendor._id;
  const vendorData = vendor?.vendor;
  const businessDetails = vendorData?.BussinessDetails || {};
  const businessAddress = businessDetails?.BusinessAddress || {};
  const [businessEditMode, setBusinessEditMode] = useState(false);
  const [businessName, setBusinessName] = useState(businessDetails.Businessname);
  const [businessEmail, setBusinessEmail] = useState(businessDetails.BusinessEmail);
  const [panNumber, setPanNumber] = useState(businessDetails.panNumber);
  const [addressLine1, setAddressLine1] = useState(businessAddress.addressLine1);
  const [addressLine2, setAddressLine2] = useState(businessAddress.addressLine2);
  const [landmark, setLandmark] = useState(businessAddress.landmark);
  const [city, setCity] = useState(businessAddress.city);
  const [state, setState] = useState(businessAddress.state);
  const [pincode, setPincode] = useState(businessAddress.pincode);
  const [gstNumber, setGstNumber] = useState(businessDetails.gstNumber);
  const [businessRegistration, setBusinessRegistration] = useState(businessDetails.businessRegistration);
  const [businessAddressProof, setBusinessAddressProof] = useState(businessDetails.businessAddressProof);
  const [foodLicenseProof, setFoodLicenseProof] = useState(businessDetails.foodLicenseProof);
  const [about, setAbout] = useState(businessDetails.about);
  const [typeOfBusiness, setselectedTypeOfBusiness] = useState(businessDetails.typeOfBusiness || []);
  const initiallySavedCategories = businessDetails.typeOfBusiness || [];

  const [allowRegistration, setAllowRegistration] = useState(true);
  const [allowAddressProof, setAllowAddressProof] = useState(true);
  const [allowfood, setAllowfood] = useState(true);
  const [allowOther, setAllowOther] = useState(null);
  const [fieldsEmpty, setFieldsEmpty] = useState(false);

  const isValidBusinessEmail = (email) => {
    // Regular expression for a basic email format check
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isValidPANNumber = (value) => {
    const panNumberRegex = /^[A-Za-z]{5}\d{4}[A-Za-z]$/;
    return panNumberRegex.test(value);
  };

  const isValidGSTNumber = (value) => {
    const gstNumberRegex =
      /^[0-9]{2}[A-Za-z]{4}[0-9A-Za-z]{1}[0-9]{4}[A-Za-z]{1}[0-9A-Za-z]{3}$/;
    return gstNumberRegex.test(value);
  };

  const isValidPINCode = (pincode) => {
    const pincodeRegex = /^[1-9][0-9]{5}$/;
    return pincodeRegex.test(pincode);
  };

  // business details
  const handleBusinessEditClick = () => setBusinessEditMode(!businessEditMode);

  const handleBusinessCancelClick = () => {
    setBusinessEditMode(false);
    toast.error("no fields are changed.");
    // Reset the state to the original values
    const businessDetails = vendor?.vendor?.BussinessDetails || {};
    const businessAddress = businessDetails.BusinessAddress || {};

    setBusinessName(businessDetails.Businessname ?? "");
    setBusinessEmail(businessDetails.BusinessEmail ?? "");
    setPanNumber(businessDetails.panNumber ?? "");
    setAddressLine1(businessAddress.addressLine1 ?? "");
    setAddressLine2(businessAddress.addressLine2 ?? "");
    setLandmark(businessAddress.landmark ?? "");
    setCity(businessAddress.city ?? "");
    setState(businessAddress.state ?? "");
    setPincode(businessAddress.pincode ?? "");
    setGstNumber(businessDetails.gstNumber ?? "");
    setselectedTypeOfBusiness(businessDetails.typeOfBusiness ?? []);
    setBusinessAddressProof(businessDetails.businessAddressProof ?? "");
    setFoodLicenseProof(businessDetails.foodLicenseProof ?? "");
    setBusinessRegistration(businessDetails.businessRegistration ?? "");
    setAbout(businessDetails.about ?? "");
  };


  const handleBusinessSaveClick = () => {
    let hasError = false;

    if (
      !businessName ||
      !panNumber ||
      !addressLine1 ||
      !addressLine2 ||
      !landmark ||
      !city ||
      !state ||
      !pincode ||
      !gstNumber ||
      // !businessRegistration ||
      // !businessAddressProof ||
      // !foodLicenseProof ||
      !about ||
      !typeOfBusiness

    ) {
      toast.error(ErrorConstants.Error.ALL_FIELD_ERROR);
      return;
    }
    if (allowOther === false) {
      toast.error(ErrorConstants.Error.BUSSINESS_DOC_FORMATE_ERROR);
      return;
    }
    if (!isValidPANNumber(panNumber)) {
      toast.error(ErrorConstants.Error.INVALID_PAN_NUM);
      return;
    }
    if (!isValidGSTNumber(gstNumber)) {
      toast.error(ErrorConstants.Error.INVALID_GST);
      return;
    }
    if (!isValidPINCode(pincode)) {
      toast.error("Please enter a valid PIN code (6 digits)");
      return;
    }
    if (!hasError) {
      const updateVendorBusinessDetails = {
        Businessname: businessName,
        BusinessEmail: businessEmail,
        panNumber: panNumber,
        gstNumber: gstNumber,
        typeOfBusiness: typeOfBusiness,
        about: about,
        businessRegistration: businessRegistration,
        businessAddressProof: businessAddressProof,
        foodLicenseProof: foodLicenseProof,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        landmark: landmark,
        city: city,
        state: state,
        pincode: pincode,
      };
      dispatch(updateVendorBusiness(updateVendorBusinessDetails));
      setBusinessEditMode(false);
      toast.success("Details updated successfully");
    }
  };



  const handleCategoryCheckboxChange = (category) => {
    // Check if the category is initially saved
    const isInitiallySaved = initiallySavedCategories.includes(category);

    // If the category is initially saved, prevent deselection
    if (isInitiallySaved) {
      toast.error(ErrorConstants.Error.CATEGORY_ERROR);
      return;
    }

    // If the category is not initially saved, update the state as usual
    const updatedCategories = typeOfBusiness.includes(category)
      ? typeOfBusiness.filter((c) => c !== category)
      : [...typeOfBusiness, category];

    setselectedTypeOfBusiness(updatedCategories);
  };
  //NOTE - Document Upload
  const handleBusinessRegistration = (event) => {
    const file = event.target.files[0];
    if (isPdfFile(file)) {
      setAllowRegistration(true);
      checkAndTransformPdf(file, setBusinessRegistration, event);
    } else {
      setAllowRegistration(false);
      toast.error(ErrorConstants.Error.PDF_FORMATE_ERROR);
      event.target.value = null;
      return;
    }
  };


  const handleFoodLicenseProof = (event) => {
    const file = event.target.files[0];
    if (isPdfFile(file)) {
      checkAndTransformPdf(file, setFoodLicenseProof, event);
      setAllowfood(true);
    } else {
      setAllowfood(false);
      toast.error(ErrorConstants.Error.PDF_FORMATE_ERROR);
      event.target.value = null;
      return;
    }
  };

  const handleBusinessAddressProof = (event) => {
    const file = event.target.files[0];
    if (isPdfFile(file)) {
      checkAndTransformPdf(file, setBusinessAddressProof, event);
      setAllowAddressProof(true);
    } else {
      setAllowAddressProof(false);
      toast.error(ErrorConstants.Error.PDF_FORMATE_ERROR);
      event.target.value = null;
      return;
    }
  };

  const checkAndTransformPdf = (pdfFile, setPdfState, event) => {
    const maxSize = 1 * 1024 * 1024; // 1MB in bytes

    if (pdfFile.size > maxSize) {
      toast.error(ErrorConstants.Error.FILE_SIZE_ERROR);
      event.target.value = null;
      return;
    }
    if (!isPdfFile(pdfFile)) {
      toast.error(ErrorConstants.Error.Bussiness_PDF_ERROR);
      event.target.value = null;
      return;
    }
    transformPdf(pdfFile, setPdfState);
  };

  // Add a new function to check if the file is a PDF
  const isPdfFile = (file) => {
    return file && file.type === "application/pdf";
  };
  const isPdf = (file) => {
    return file && file.startsWith("data:application/pdf");
  };
  // Modify the transformPdf function to handle both PDF and URL
  const transformPdf = (file, setPdfState) => {
    if (isPdfFile(file)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPdfState(reader.result);
      };
    } else {
      toast.error(ErrorConstants.Error.PDF_FORMATE_ERROR);
      return;
    }
  };

  return (
    <div>
      <div className="flex flex-col space-y-8">
        <div className="flex flex-col md:flex-row gap-x-6 md:items-center mt-10">
          <label className="font-semibold md:w-48">Business Name</label>
          {businessEditMode ? (
            <input
              type="text"
              value={businessName}
              maxLength={30}
              onChange={(e) => {
                const inputValue = e.target.value;
                // Allow only alphabets
                const sanitizedValue = inputValue
                  .replace(/[^a-zA-Z\s]/g, "")
                  .slice(0, 30);
                setBusinessName(sanitizedValue);
              }}
              className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
            />
          ) : (
            <div className="flex md:items-center">
              <input
                type="text"
                value={businessName}
                readOnly
                className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
              />
            </div>
          )}
        </div>
        <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
          <label className="font-semibold md:w-48">
            Business Email Address
          </label>
          {businessEditMode ? (
            <input
              type="text"
              value={businessEmail}
              maxLength={50}
              onChange={(e) => {
                const inputValue = e.target.value.toLowerCase();
                setBusinessEmail(inputValue);

                // Check if the entered value is a valid email
                if (isValidBusinessEmail(inputValue) || inputValue === "") {
                  setFieldsEmpty(false);
                } else {
                  setFieldsEmpty(true);
                }
              }}
              className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
            />
          ) : (
            <div className="flex md:items-center">
              <input
                type="text"
                value={businessEmail}
                readOnly
                className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
              />
            </div>
          )}
          {fieldsEmpty && (!businessEmail || !businessEmail.trim()) && (
            <p className="flex text-red-500 ml-2">
              Please fill Business Email.
            </p>
          )}
          {!isValidBusinessEmail(businessEmail) &&
            businessEmail &&
            businessEmail.trim() && (
              <p className="flex text-red-500 ml-2">
                Please enter a valid email address (E.g., test@example.com).
              </p>
            )}
        </div>
        <div className="flex flex-col md:flex-row gap-x-6 md:items-center mt-10">
          <label className="font-semibold md:w-48">PAN Number</label>
          {businessEditMode ? (
            <input
              type="text"
              value={panNumber}
              maxLength={10}
              onChange={(e) => {
                const inputValue = e.target.value.toUpperCase();
                setPanNumber(inputValue);

                // Check if the entered value is a valid PAN number
                if (isValidPANNumber(inputValue) || inputValue === "") {
                  setFieldsEmpty(false);
                } else {
                  setFieldsEmpty(true);
                }
              }}
              className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
            />
          ) : (
            <div className="flex md:items-center">
              <input
                type="text"
                value={panNumber}
                readOnly
                className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
              />
            </div>
          )}
          {fieldsEmpty && !panNumber.trim() && (
            <p className="flex text-red-500 ml-2">
              Please fill the PAN number.
            </p>
          )}
          {!isValidPANNumber(panNumber) && panNumber.trim() && (
            <p className="flex text-red-500 ml-2">
              Please enter a valid PAN number (E.g., AAAAA9999A).
            </p>
          )}
        </div>
        <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
          <label className="font-semibold md:w-48">GST Number</label>
          {businessEditMode ? (
            <input
              type="text"
              value={gstNumber}
              maxLength={15}
              onChange={(e) => {
                const inputValue = e.target.value.toUpperCase(); // Convert to uppercase

                setGstNumber(inputValue);
                // Check if the entered value is a valid GST number
                if (isValidGSTNumber(inputValue) || inputValue === "") {
                  setFieldsEmpty(false);
                } else {
                  setFieldsEmpty(true);
                }
              }}
              className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
            />
          ) : (
            <div className="flex md:items-center">
              <input
                type="text"
                value={gstNumber}
                readOnly
                className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
              />
            </div>
          )}
          {fieldsEmpty && !gstNumber.trim() && (
            <p className="flex text-red-500 ml-2">
              Please fill the GST number.
            </p>
          )}
          {!isValidGSTNumber(gstNumber) && gstNumber.trim() && (
            <p className="flex text-red-500 ml-2">
              Please enter a valid GST number (E.g., 06BZAHM6385P6Z2).
            </p>
          )}
        </div>
        <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
          <label className="font-semibold md:w-48">Category </label>
          {businessEditMode ? (
            <div className="flex items-center space-x-4">
              <input
                type="checkbox"
                id="categoryClothing"
                checked={typeOfBusiness.includes("clothing")}
                onChange={() => handleCategoryCheckboxChange("clothing")}
              />
              <label htmlFor="categoryClothing">Clothing</label>

              <input
                type="checkbox"
                id="categoryAccessories"
                checked={typeOfBusiness.includes("accessories")}
                onChange={() => handleCategoryCheckboxChange("accessories")}
              />
              <label htmlFor="categoryAccessories">Accessories</label>

              <input
                type="checkbox"
                id="categoryHomeDecor"
                checked={typeOfBusiness.includes("home-decor")}
                onChange={() => handleCategoryCheckboxChange("home-decor")}
              />
              <label htmlFor="categoryHomeDecor">Home Decor</label>

              <input
                type="checkbox"
                id="categoryFood"
                checked={typeOfBusiness.includes("homemade-food")}
                onChange={() => handleCategoryCheckboxChange("homemade-food")}
              />
              <label htmlFor="categoryFood">Homemade-Food</label>

              <input
                type="checkbox"
                id="categoryOther"
                checked={typeOfBusiness.includes("other")}
                onChange={() => handleCategoryCheckboxChange("other")}
              />
              <label htmlFor="categoryOther">Other</label>
            </div>
          ) : (
            <div className="flex md:items-center ">
              <div className="flex flex-wrap gap-2 ">
                {typeOfBusiness.map((category) => (
                  <span key={category} className="bg-gray-200 rounded-md p-2">
                    {category.toUpperCase()}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
          <label className="font-semibold md:w-48"> About </label>
          {businessEditMode ? (
            <textarea
              value={about}
              onChange={(e) => {
                const inputValue = e.target.value;
                const wordCount = inputValue.split(/\s+/).length;

                if (wordCount <= 120) {
                  setAbout(inputValue);
                } else {
                  <p>Word limit reached</p>;
                }
              }}
              rows={3}
              cols={50}
              className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] shadow-lg"
            />
          ) : (
            <div className="flex md:items-center">
              <textarea
                value={about}
                rows={3}
                cols={50}
                readOnly
                className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC]shadow-lg"
              />
            </div>
          )}
        </div>

        {/* //NOTE - Document */}

        <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
          <label className="font-semibold md:w-48">Business Registration</label>
          <div className="flex items-center">
            {businessEditMode ? (
              <>
                <Link
                  to={businessRegistration}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="bg-[#8A58DC] text-white rounded-md p-2">
                    View Previous Document
                  </button>
                </Link>
                <input
                  className="px-10"
                  type="file"
                  accept=".pdf"
                  onChange={handleBusinessRegistration}
                />
                {!businessRegistration && (
                  <p className="flex text-red-500 ml-2">
                    Please upload a food license document.
                  </p>
                )}
                {/* <button className="bg-green-500 text-white rounded-md p-2 ml-2">Confirm</button> */}
              </>
            ) : (
              <Link
                to={businessRegistration}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button style="primary">View Document</Button>
              </Link>
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
          <label className="font-semibold md:w-48">Business Address</label>
          <div className="flex items-center">
            {businessEditMode ? (
              <>
                <Link
                  to={businessAddressProof}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="bg-[#8A58DC] text-white rounded-md p-2">
                    View Previous Document
                  </button>
                </Link>
                <input
                  className="px-10"
                  type="file"
                  accept=".pdf"
                  onChange={handleBusinessAddressProof}
                />
                {!businessAddressProof && (
                  <p className="flex text-red-500 ml-2">
                    Please upload a food license document.
                  </p>
                )}
                {/* <button className="bg-green-500 text-white rounded-md p-2 ml-2">Confirm</button> */}
              </>
            ) : (
              <Link
                to={businessAddressProof}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button style="primary">View Document</Button>
              </Link>
            )}{" "}
          </div>
        </div>

        {typeOfBusiness.includes("homemade-food") && (
          <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
            <label className="font-semibold md:w-48">Food License</label>
            <div className="flex items-center">
              {businessEditMode ? (
                <>
                  <Link
                    to={foodLicenseProof}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="bg-[#8A58DC] text-white rounded-md p-2">
                      View Previous Document
                    </button>
                  </Link>
                  <input
                    className="px-10"
                    type="file"
                    accept=".pdf"
                    onChange={handleFoodLicenseProof}
                  />
                  {/* <button className="bg-[#8A58DC] text-white rounded-md p-2 ml-2">Confirm</button> */}
                  {!foodLicenseProof && (
                    <p className="flex text-red-500 ml-2">
                      Please upload a food license document.
                    </p>
                  )}
                </>
              ) : (
                <Link
                  to={foodLicenseProof}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button style="primary">View Document</Button>
                </Link>
              )}
            </div>
          </div>
        )}
        <div className="flex flex-col md:flex-row gap-x-6 md:items-center ">
          <label className="font-semibold md:w-48 self-start">
            Full Address
          </label>
          {businessEditMode ? (
            <div className="">
              <input
                type="text"
                value={addressLine1}
                maxLength={50}
                onChange={(e) => setAddressLine1(e.target.value)}
                className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg mb-2"
                placeholder="Address Line 1"
              />
              <input
                type="text"
                value={addressLine2}
                maxLength={50}
                onChange={(e) => setAddressLine2(e.target.value)}
                className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg mb-2"
                placeholder="Address Line 2"
              />
              <input
                type="text"
                value={landmark}
                maxLength={30}
                onChange={(e) => setLandmark(e.target.value)}
                className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg mb-2"
                placeholder="Landmark"
              />
              <input
                type="text"
                value={city}
                maxLength={30}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  // Allow only alphabets
                  const sanitizedValue = inputValue
                    .replace(/[^a-zA-Z\s]/g, "")
                    .slice(0, 30);
                  setCity(sanitizedValue);
                }}
                className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg mb-2"
                placeholder="City"
              />
              <input
                type="text"
                value={state}
                maxLength={30}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  // Allow only alphabets
                  const sanitizedValue = inputValue
                    .replace(/[^a-zA-Z\s]/g, "")
                    .slice(0, 30);
                  setState(sanitizedValue);
                }}
                className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg mb-2"
                placeholder="State"
              />
              <input
                type="text"
                value={pincode}
                maxLength={6}
                onChange={(e) => {
                  // Allow only digits and limit the length to 18 characters
                  const sanitizedValue = e.target.value
                    .replace(/[^0-9]/g, "")
                    .slice(0, 18);
                  setPincode(sanitizedValue);
                  isValidPINCode(sanitizedValue)
                }}
                className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                placeholder="Pincode"
              />
            </div>
          ) : (
            <div classname="grid grid-cols-3 md:items-center justify-center ">
              <input
                type="text"
                value={addressLine1}
                readOnly
                className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                placeholder="Address Line 1"
              />
              <input
                type="text"
                value={addressLine2}
                readOnly
                className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                placeholder="Address Line 2"
              />
              <input
                type="text"
                value={landmark}
                readOnly
                className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                placeholder="Landmark"
              />
              <input
                type="text"
                value={city}
                readOnly
                className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                placeholder="City"
              />
              <input
                type="text"
                value={state}
                readOnly
                className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                placeholder="State"
              />
              <input
                type="text"
                value={pincode}
                readOnly
                className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                placeholder="Pincode"
              />
            </div>
          )}
        </div>

        <div className="flex flex-row gap-4">
          {businessEditMode ? (
            <div>
              <button
                className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                onClick={handleBusinessSaveClick}
              >
                Save
              </button>
            </div>
          ) : (
            <div>
              <button
                className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                onClick={handleBusinessEditClick}
              >
                Edit
              </button>
            </div>
          )}
          {businessEditMode && (
            <div>
              <button
                className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                onClick={handleBusinessCancelClick}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BusinessDetailsSettings;
