import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { API_URL } from "../../config/config";
import MultiRangeSlider from "../../component/Products/PriceRangeSlider/MultiRangeSlider";
import ProductCard from "../../component/ProductCard/ProductCard";
import Loader from "../../component/common/Loader";
import Pagination from "../../component/common/Pagination";
import { useLocation } from "react-router-dom";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
const Products = () => {
  const location = useLocation();
  const [openFilter, setOpenFilter] = useState("");
  const [allCategories, setAllCategories] = useState([]);
  const [allSellers, setAllSellers] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [openCategories, setOpenCategories] = useState([]);
  const [pagination, setPagination] = useState({
    totalPages: 1,
  });
  const [filterQuery, setFilterQuery] = useState({
    category: [],
    sellers: [],
    maxPrice: "",
    minPrice: "",
    ratings: [],
    searchTerm: "",
  });

  // Get all categories
  const getAllCategories = async () => {
    try {
      const resp = await axios.get(
        `${API_URL}/api/admin-profile/getCategories`
      );
      if (resp.data) {
        const categoryNames = resp.data.map((category) => {
          const words = category.name.split("-");
          const capitalizedWords = words.map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1)
          );
          const formattedCategory = {
            name: capitalizedWords.join(" "),
            searchName: category.name,
          };

          if (category?.subcategories?.length > 0) {
            formattedCategory.subcategories = category.subcategories.map(
              (subcategory) => {
                const subcategoryWords = subcategory.name.split("-");
                const capitalizedSubcategoryWords = subcategoryWords.map(
                  (word) => word.charAt(0).toUpperCase() + word.slice(1)
                );
                const formattedSubcategory = {
                  id: subcategory._id,
                  name: capitalizedSubcategoryWords.join(" "),
                  searchName: subcategory.name,
                };

                if (subcategory?.subsubcategories?.length > 0) {
                  formattedSubcategory.subsubcategories =
                    subcategory.subsubcategories.map((subsubcategory) => ({
                      name: subsubcategory.name,
                      searchName: subsubcategory.name,
                    }));
                 
                }

                return formattedSubcategory;
              }
            );
          }

          return formattedCategory;
        });

        setAllCategories(categoryNames);
      }
    } catch (error) {
    }
  };

  // Getting all sellers
  const getAllSellers = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/vendor/`);
      const sellerData = response.data.map((seller) => ({
        id: seller._id,
        name: seller.BussinessDetails.Businessname,
      }));
      setAllSellers(sellerData);
    } catch (error) {
      console.error("Error fetching sellers:", error);
    }
  };

  // Toggler category
  const handleCategoryToggle = (categoryName) => {
    setOpenCategories((prevOpenCategories) => {
      if (prevOpenCategories.includes(categoryName)) {
        return prevOpenCategories.filter((cat) => cat !== categoryName);
      } else {
        return [...prevOpenCategories, categoryName];
      }
    });
  };

  // For changing filter
  const setFilter = (filterData) => {
    setFilterQuery((prevFilter) => ({
      ...prevFilter,
      ...filterData,
    }));
    if(openFilter != 'price-range'){


      setOpenFilter(!openFilter);
    }    
  };

  // Checkbox handler for filter change
  const handleCheckboxChange = (filterType, value) => {
    const updatedFilter = { ...filterQuery };
    if (updatedFilter[filterType].includes(value)) {
      // If value is already in the array, remove it
      updatedFilter[filterType] = updatedFilter[filterType].filter(
        (item) => item !== value
      );
    } else {
      // If value is not in the array, add it
      updatedFilter[filterType] = [...updatedFilter[filterType], value];
    }
    setFilter(updatedFilter);
    setOpenFilter("");
    // Set the new location state
    location.state = null;
  };

  // set filter query if location has some searchItem of home page all categories
  useEffect(() => {
    const searchItem = location.state?.searchItem;
    // Guard condition to check if searchItem is defined and not already in the category array
    if (!searchItem || filterQuery.category.includes(searchItem)) {
      return;
    }

    setFilterQuery((prevFilter) => {
      // Additional guard inside the state updater function to handle asynchronous state updates
      if (prevFilter.category.includes(searchItem)) {
        return prevFilter;
      }
      return {
        ...prevFilter,
        category: [searchItem],
      };
    });
  }, [location.state?.searchItem]);

  // Getting all categories and all sellers from api
  useEffect(() => {
    getAllCategories();
    getAllSellers();
  }, []);

  // when filters change call api and set current page to 1
  useEffect(() => {
    setCurrentPage(1);
  }, [filterQuery]);

  // Get products on page load
  useEffect(() => {
    // Search bar search query
    const searchParams = new URLSearchParams(location?.search);
    const searchTerm = searchParams.get("searchTerm");

    // Fetch products with the updated filter, searchBar, allcategories home page and current page
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${API_URL}/api/products/searchproducts`,
          {
            params: {
              category: [...filterQuery.category, location.state?.searchItem],
              sellers: filterQuery.sellers,
              minPrice: filterQuery.minPrice,
              maxPrice: filterQuery.maxPrice,
              ratings: filterQuery.ratings,
              searchTerm: searchTerm,
              page: currentPage,
            },
          }
        );

        setProducts(response.data.products);
        if (response.data.filteredProductsCount) {
          setPagination({
            totalPages: Math.ceil(
              response.data.totalProductsCount / response.data.resultPerPage
            ),
          });
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };
    // Call fetchProducts when either filterQuery or currentPage changes
    fetchProducts();
  }, [filterQuery, currentPage, location.search]);

  // For ratings filter
  const mapRatingToLabel = (rating) => {
    if (rating >= 4.5) return "Best Rating";
    if (rating >= 4) return "Excellent";
    if (rating >= 3.5) return "Very Good";
    if (rating >= 3) return "Good";
    return "Average";
  };

  // Pagination logic
  const handlePageChange = (newPage) => {
    if (newPage <= 0 || newPage > pagination.totalPages) return;
    setCurrentPage(newPage);
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    
    if(openFilter != 'price-range'){
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenFilter(false);
      }
    };

    const handleTouchMove = () => {
      setOpenFilter(false);
    };

    if (openFilter) {
      window.addEventListener("click", handleClickOutside);
      window.addEventListener("touchmove", handleTouchMove); // Add touchmove event listener
    } else {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("touchmove", handleTouchMove); // Remove touchmove event listener
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }
  }, [openFilter]);

  const toggleDropdown = () => {
    setOpenFilter(!openFilter);
  };

  //css
  const titles = "font-[300] text-white";
  const btn =
    " flex items-center justify-between py-1 min-[400px]:py-2  px-1 min-[400px]:px-3 bg-[#995ABA] text-[9px] min-[500px]:text-xs sm:text-base cursor-pointer w-full";
  const bgToggleOpen =
    "absolute md:relative z-50 bg-purple-100 px-3 mt-8 min-[350px]:mt-10 min-[500px]:mt-12 sm:mt-16 w-full md:mt-0 left-0 py-2  rounded-b-lg";

  const divider = (
    <span className="hidden  w-full justify-center md:flex items-center">
      <hr className="border-gray-500 w-[90%]" />
    </span>
  );
  return (
    <>
      <div className="w-full  h-[100vh] flex flex-wrap overflow-hidden">
        {/* ------------------ Left filters section --------------------------------- */}
        <div ref={dropdownRef} className="flex md:flex-[3%] bg-[#f7fafc] shadow-lg flex-row md:flex-col w-full md:h-full overflow-auto scrollbar-hide gap-1 min-[500px]:gap-2 md:gap-3 lg:gap-5 p-1 min-[500px]:p-2 md:p-3 lg:p-5">
          {/* Category filter */}
          <div
            
            className="flex flex-col  w-full items-center justify-start"
          >
            <div
              className={` ${
                openFilter === "category"
                  ? "rounded-t-md sm:rounded-t-lg"
                  : " rounded-md sm:rounded-lg"
              } ${btn} `}
              onClick={() =>
                setOpenFilter(openFilter === "category" ? "" : "category")
              }
            >
              <h1 className={titles}>Category</h1>
              <IoIosArrowDown
                className={` ${titles} transform ${
                  openFilter === "category"
                    ? "-rotate-180 rotate-counterclockwise"
                    : ""
                }`}
              />
            </div>
            {openFilter === "category" && (
              <div className={bgToggleOpen}>
                <div className="text-black gap-x-2 cursor-pointer inline-flex w-full justify-start items-center  ">
                  <input
                    type="checkbox"
                    checked={filterQuery.category.length === 0}
                    onChange={() => setFilter({ category: [] })}
                  />
                  Select None
                </div>
                {allCategories?.map((category, index) => (
                  <React.Fragment key={index}>
                    <div className="text-black cursor-pointer w-full inline-flex gap-2 justify-left items-center">
                      {category.subcategories ? (
                        <div
                          onClick={() => handleCategoryToggle(category.name)}
                          className="flex justify-center items-center"
                        >
                          <IoIosArrowDown
                            size={15}
                            className={`transform  sm:text-base text-black ${
                              openCategories.includes(category.name)
                                ? "rotate-clockwise rotate"
                                : "-rotate-90 rotate-counterclockwise"
                            }`}
                          />
                        </div>
                      ) : (
                        <input
                          type="checkbox"
                          checked={filterQuery.category.includes(
                            category.searchName
                          )}
                          onChange={() =>
                            handleCheckboxChange(
                              "category",
                              category.searchName
                            )
                          }
                        />
                      )}
                      {category.name}
                    </div>
                    {openCategories.includes(category.name) &&
                      category.subcategories &&
                      category.subcategories.map((subcategory, subIndex) => (
                        <React.Fragment>
                          <div
                            key={`${index}-${subIndex}`}
                            className="text-black cursor-pointer w-full inline-flex gap-2 justify-left items-center"
                          >
                            {subcategory.subsubcategories ? (
                              <div
                                onClick={() =>
                                  handleCategoryToggle(subcategory.name)
                                }
                                className="flex justify-center items-center"
                              >
                                <IoIosArrowDown
                                  size={15}
                                  className={`transform  sm:text-base text-black ${
                                    openCategories.includes(subcategory.name)
                                      ? "rotate-clockwise rotate"
                                      : "-rotate-90 rotate-counterclockwise"
                                  }`}
                                />
                              </div>
                            ) : (
                              <input
                                type="checkbox"
                                checked={filterQuery.category.includes(
                                  subcategory.searchName
                                )}
                                onChange={() =>
                                  handleCheckboxChange(
                                    "category",
                                    subcategory.searchName
                                  )
                                }
                              />
                            )}
                            {subcategory.name.charAt(0).toUpperCase() +
                              subcategory.name.slice(1)}
                          </div>
                          {openCategories.includes(subcategory.name) &&
                            subcategory.subsubcategories && ( // Check if subsubcategories exist
                              <div className="ml-6">
                                {subcategory.subsubcategories.map(
                                  (subsubcategory, subsubIndex) => (
                                    <div
                                      key={`${index}-${subIndex}-${subsubIndex}`}
                                      className="text-black cursor-pointer w-full inline-flex gap-2 justify-left items-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={filterQuery.category.includes(
                                          subsubcategory.searchName
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            "category",
                                            subsubcategory.searchName
                                          )
                                        }
                                      />
                                      {subsubcategory.name}
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
              </div>
            )}
            <div className="hidden md:block mx-3 ">
              {filterQuery.category.map((selectedCategory, index) => (
                <React.Fragment key={index} className="">
                  <span className="">
                    {selectedCategory
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </span>
                  {index !== filterQuery.category.length - 1 && <span>, </span>}
                </React.Fragment>
              ))}
            </div>
          </div>
          {divider}
          {/* seller filter */}
          <div className="flex flex-col  w-full items-center justify-start">
            <div
              className={` ${
                openFilter === "sellers"
                  ? "rounded-t-md sm:rounded-t-lg"
                  : "rounded-md sm:rounded-lg"
              } ${btn}`}
              onClick={() =>
                setOpenFilter(openFilter === "sellers" ? "" : "sellers")
              }
            >
              <h1 className={titles}>All Sellers</h1>
              <IoIosArrowDown
                className={` ${titles} transform ${
                  openFilter === "sellers"
                    ? "-rotate-180 rotate-counterclockwise"
                    : ""
                }`}
              />
            </div>
            {openFilter === "sellers" && (
              <div className={`${bgToggleOpen} h-48  overflow-y-auto`}>
                <div className="text-black cursor-pointer inline-flex w-full justify-start items-center gap-2">
                  <input
                    type="checkbox"
                    checked={filterQuery.sellers.length === 0}
                    onChange={() => setFilter({ sellers: [] })}
                  />
                  Select None
                </div>
                {allSellers?.map((seller, index) => (
                  <div
                    key={index}
                    className="text-black cursor-pointer w-full inline-flex gap-2 justify-left items-center"
                  >
                    <input
                      type="checkbox"
                      checked={filterQuery.sellers.includes(seller.id)}
                      onChange={() =>
                        handleCheckboxChange("sellers", seller.id)
                      }
                    />
                    {seller.name}
                  </div>
                ))}
              </div>
            )}
            <div className=" hidden md:block mx-3">
              {filterQuery.sellers.map((selectedSellers, index) => {
                const selectedSeller = allSellers.find(
                  (seller) => seller.id === selectedSellers
                );
                return (
                  <React.Fragment key={index}>
                    <span className="">
                      {selectedSeller && selectedSeller.name}
                    </span>
                    {index !== filterQuery.sellers.length - 1 && (
                      <span>, </span>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          {divider}
          {/* Price Range */}
          <div className="flex flex-col  w-full items-center justify-start">
            <div
              className={`${
                openFilter === "price-range"
                  ? "rounded-t-md sm:rounded-t-lg"
                  : "rounded-md sm:rounded-lg"
              } ${btn}`}
              onClick={() =>
                setOpenFilter(openFilter === "price-range" ? "" : "price-range")
              }
            >
              <h1 className={titles}>Select Price</h1>
              <IoIosArrowDown
                className={` ${titles} transform ${
                  openFilter === "price-range"
                    ? "-rotate-180 rotate-counterclockwise"
                    : ""
                }`}
              />
            </div>
            {openFilter === "price-range" && (
              <div className={`${bgToggleOpen} w-full py-[50px] `}>
                {/* <div className={`text-black ${(filterQuery.minPrice === 0 && filterQuery.maxPrice===1000) ? 'text-fuchsia-600' : ''}`} onClick={() => setFilter({ minPrice: 0, maxPrice: 10000 })}>Select None</div> */}
                <div className="text-black">
                  {
                    <MultiRangeSlider
                      min={0}
                      max={10000}
                      onChange={({ min, max }) =>{
                        setFilter({ minPrice: min, maxPrice: max })
                      }
                      }
                      style={{ color: "black" }}
                    />
                  }
                </div>
              </div>
            )}
          </div>
          {divider}
          {/* Ratings */}
          <div className="flex flex-col  w-full items-center justify-start">
            <div
              className={` ${
                openFilter === "sort"
                  ? "rounded-t-md sm:rounded-t-lg"
                  : "rounded-md sm:rounded-lg"
              } ${btn}`}
              onClick={() => setOpenFilter(openFilter === "sort" ? "" : "sort")}
            >
              <h1 className={titles}>Sort</h1>
              <IoIosArrowDown
                className={` ${titles} transform ${
                  openFilter === "sort"
                    ? "-rotate-180 rotate-counterclockwise"
                    : ""
                }`}
              />
            </div>
            {openFilter === "sort" && (
              <div className={bgToggleOpen}>
                <div
                  className= {`${filterQuery.ratings === 0 ? 'text-blue-500' : 'text-black'} cursor-pointer inline-flex w-full justify-start items-center gap-2`}
                  onClick={() => setFilter({ ratings: 0 })}
                >
                  Select None
                </div>
                {[4.5, 4, 3].map((rating) => (
                  <div
                    key={rating}
                    className={`${filterQuery.ratings === rating ? 'text-blue-500' : 'text-black'} cursor-pointer w-full inline-flex gap-2 justify-left items-center`}
                    onClick={() => setFilter({ ratings: rating })}
                  >
                    {mapRatingToLabel(rating)}
                  </div>
                ))}
              </div>
            )}

            <div>
              {Array.isArray(filterQuery.ratings) &&
                filterQuery.ratings.map((selectedrating, index) => {
                  const selectedLabel = mapRatingToLabel(selectedrating);
                  return (
                    <span key={index} className="mr-2">
                      {selectedLabel}
                    </span>
                  );
                })}
            </div>
          </div>
        </div>

        {/*------------------ Right Product and pagination section -----------------------------*/}
        <div className="flex flex-[100%] md:flex-[55%] lg:flex-[60%] h-full py-5 px-2 flex-col ">
          {/* Product Section */}
          {loading && (
            <div className="w-full h-full flex justify-center items-center">
              <Loader />
            </div>
          )}

          {!loading && products?.length > 0 ? (
            <div className="h-full overflow-y-auto flex justify-center items-center">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pr-5 gap-8  justify-center h-full ">
                {products?.map((product) => {
                  return <ProductCard key={product._id} data={product} />;
                })}
              </div>
            </div>
          ) : (
            !loading && (
              <div className=" text-slate-900 flex w-full h-full  justify-center items-center">
                No product available
              </div>
            )
          )}

          {/* Pagination Section */}
          <Pagination
            currentPage={currentPage}
            totalPages={pagination.totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default Products;
