import React, { useState, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ImageIcon from "@mui/icons-material/Image";
import NewProductDetailsAdd from "./NewProductDetailsAdd";
import EditProduct from "./EditProduct";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL, config } from "../../../config/config";
import { toast } from "react-hot-toast";
import Loader from "../../common/Loader";
import ErrorConstants from "../../../constants/ErrorConstants";
import Button from "../../common/Button";
import Pagination2 from "../../common/Pagination2";

const ManageProduct = () => {
  const [vendorProducts, setVendorProducts] = useState([]);
  const [loading, setLoading] = useState("");
  const vendor = useSelector((state) => state.vendor);
  const status = vendor && vendor.vendor && vendor.vendor.status;
  const vendorId = vendor && vendor.vendor && vendor.vendor._id;
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [data, setData] = useState(); // Initialize with your sample data
  const [activeComponent, setActiveComponent] = useState(null);
  const navigate = useNavigate();
  const productsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setLoading(true);

    // Make an API request to fetch vendor products when the component mounts
    const fetchVendorProducts = async () => {
      try {
        // Replace 'your-api-endpoint' with the actual API endpoint

        const response = await axios.get(
          `${API_URL}/api/vendor/products`,
          config
        ); // Make a GET request

        const data = response.data;

        setVendorProducts(data); // Update the state with the fetched products
      } catch (error) {
        console.error(error);
        // Handle errors, e.g., display an error message to the user
      } finally {
        setLoading(false);
      }
    };

    fetchVendorProducts(); // Call the API request function
  }, []);


  const handleSelectItem = (productId) => {
    const newSelectedItems = { ...selectedItems };
    newSelectedItems[productId] = !newSelectedItems[productId];
    setSelectedItems(newSelectedItems);

    // Check if all individual checkboxes are selected
    const allSelected = vendorProducts.every(
      (item) => newSelectedItems[item._id]
    );
    setSelectAll(allSelected);
  };

  const handleDeleteSelected = async () => {
    const selectedProductIds = Object.keys(selectedItems).filter(
      (productId) => selectedItems[productId]
    );

    if (selectedProductIds.length === 0) {
      // If no products are selected, show a toast and return
      toast.error(ErrorConstants.Error.PRODUCT_DELETE_ERROR);
      return;
    }

    try {
      // Make an API request to delete selected products
      await Promise.all(
        selectedProductIds.map(async (productId) => {
          try {
            await axios.delete(
              `${API_URL}/api/vendor/products/${productId}`,
              config
            );
          } catch (deleteError) {
            console.error(`Error deleting product ${productId}:`, deleteError);
            // Handle errors, e.g., display an error message to the user
          }
        })
      );

      // Update the frontend state after successful deletion
      const newVendorProducts = vendorProducts.filter(
        (product) => !selectedItems[product.id]
      );
      setVendorProducts(newVendorProducts);

      // Reset selected items and selectAll state
      setSelectedItems({});
      setSelectAll(false);
      toast.success(ErrorConstants.Error.PRODUCT_DELETE);
      window.location.reload();
    } catch (error) {
      console.error("Error deleting products:", error);
      toast.error(ErrorConstants.Error.PRODUCT_DELETE_FAIL);
      // Handle errors, e.g., display an error message to the user
    }
  };

  const handleStockSelected = () => {
    const newData = data.map((item) => {
      if (selectedItems[item.id]) {
        return { ...item, stock: "In Stock" };
      }
      return item;
    });
    setData(newData);
  };

  const handleOutStockSelected = async () => {
    const selectedProductIds = Object.keys(selectedItems).filter(
      (productId) => selectedItems[productId]
    );
    if (selectedProductIds.length === 0) {
      toast.error(ErrorConstants.Error.PRODUCT_STOCK_ERROR);
      return;
    }
    try {
      // Make an Axios request to update the stock status to "Out Stock" for selected products
      await Promise.all(
        selectedProductIds.map(async (productId) => {
          try {
            // Update the URL to match your backend route
            await axios.put(
              `${API_URL}/api/vendor/out-of-stock/${productId}`, {},
              config
            );
            toast.success(ErrorConstants.Error.STOCK_0_ERROR);
          } catch (updateError) {
            console.error(
              `Error updating stock status for product ${productId}:`,
              updateError
            );
            // Handle errors, e.g., display an error message to the user
          }
        })
      );

      // Update the frontend state after successful update
      const updatedVendorProducts = vendorProducts.map((product) => {
        if (selectedItems[product._id]) {
          return { ...product, stock: "Out Stock" };
        }

        return product;
      });
      setVendorProducts(updatedVendorProducts);

      // Reset selected items and selectAll state
      setSelectedItems({});
      setSelectAll(false);
    } catch (error) {
      console.error("Error updating stock status:", error);
      // Handle errors, e.g., display an error message to the user
    }
  };

  const selectedItemCount = Object.values(selectedItems).filter(Boolean).length;
  const isEditButtonHidden = selectedItemCount > 1;

  const handleAddNewProduct = () => {
    if (status !== "Pending") {
      setActiveComponent("NewProductDetailsAdd");
    } else {
      toast.error(ErrorConstants.Error.PRODUCT_NEW_ERROR);
    }
  };

  // Inside EditProduct.js or wherever you handle the editing logic
  // Inside your handleEditProduct function

  const handleEditProduct = () => {
    const selectedProductIds = Object.keys(selectedItems).filter(
      (productId) => selectedItems[productId]
    );

    try {
      // Ensure selectedProduct is not null
      if (selectedProductIds.length !== 1) {
        console.error("Invalid selected product:", selectedProductIds);
        return;
      }

      const productId = selectedProductIds[0]; // Take the first selected product id
      const selectedProduct = vendorProducts.find(
        (product) => product._id === productId
      );

      // Check the status before allowing the edit
      if (selectedProduct.status === "Pending") {
        // Show toaster message for pending status
        toast.error(ErrorConstants.Error.EDIT_PRODUCT_ERROR);
        return;
      }

      // Trigger the navigation to the edit product page using useNavigate
      // navigate(`/edit/${productId}`); // Assuming productId is part of the edit route

      // Set active component to 'EditProduct' if needed
      setActiveComponent("EditProduct");
      setSelectedProduct(selectedProduct);
    } catch (error) {
      console.error("Error navigating to edit page:", error);
      // Handle errors, e.g., display an error message to the user
    }
  };

  const formatCategory = (category) => {
    // Split the category into words
    const words = category.split("-");

    // Capitalize the first letter of each word
    const formattedCategory = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return formattedCategory;
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(vendorProducts.length / productsPerPage);

  // Calculate the index of the first and last product to display on the current page
  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = Math.min(
    startIndex + productsPerPage,
    vendorProducts.length
  );

  // Slice the array to get the products for the current page
  const displayedData = vendorProducts.slice(startIndex, endIndex);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
    scrollToActivePage(pageNumber);
  };

  const scrollToActivePage = (pageNumber) => {
    const paginationButton = document.getElementById(`page-${pageNumber}`);
    if (paginationButton) {
      paginationButton.scrollIntoView({ behavior: "smooth", inline: "center" });
    }
  };

  const icon = useMemo(() => <FontAwesomeIcon icon={faPlus} />, []);
  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <Loader />
        </div>
      ) : (
        <div className="p-4">
          <div className=" w-full ">
            <div className="flex flex-row justify-between w-full ">
              <h2 className="text-lg md:text-xl lg:text-2xl ml-5 pt-3 min-[400px]:pt-0">
                {activeComponent === "EditProduct" ? "Edit Product" : "Product"}
              </h2>
              <div className="ml-auto  min-[400px]:block">
                {activeComponent !== "EditProduct" && (
                  <Button onClick={handleAddNewProduct} style="primary">
                    {icon}Add New Product
                  </Button>
                )}
              </div>
            </div>
          </div>

          <div className="hidden sm:block">
            {activeComponent === "NewProductDetailsAdd" && (
              <NewProductDetailsAdd />
            )}
            {activeComponent === "EditProduct" && (
              <EditProduct productId={selectedProduct._id} />
            )}

            {activeComponent !== "NewProductDetailsAdd" &&
              activeComponent !== "EditProduct" && (
                <div className="mt-5">
                  {vendorProducts.length === 0 ? (
                    <div className="flex flex-col gap-4 justify-center items-center h-[500px]">
                      <p className="text-gray-500">
                        No Products yet. Add new Products now!
                      </p>
                      <img
                        className="w-[400px]"
                        src="https://img.freepik.com/free-vector/new-product-concept-illustration_114360-7031.jpg?w=740&t=st=1708065218~exp=1708065818~hmac=ba32f238bdffa50b71e9c65de4d877bac64934689b2e5823d8fe12e7ccc237b7"
                        alt=""
                      />
                    </div>
                  ) : (
                    <div className="overflow-x-auto">
                      <table className="w-full table-auto  border rounded-lg bg-white ">
                        <thead>
                          <tr className="font-poppins font-medium border-2">
                            <th className="border-2"></th>  {/* to align the headers */}
                            <th className="">
                              <ImageIcon className="" />
                            </th>
                            <th className=" text-md md:text-lg lg:text-xl font-semibold">
                              Name
                            </th>
                            <th className=" text-md md:text-lg lg:text-xl font-semibold">
                              Price
                            </th>
                            <th className=" text-md md:text-lg lg:text-xl font-semibold">
                              Status
                            </th>
                            <th className=" text-md md:text-lg lg:text-xl font-semibold">
                              Category
                            </th>
                            <th className=" text-md md:text-lg lg:text-xl font-semibold">
                              Stock
                            </th>
                            {/* <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">Date</th> */}
                          </tr>
                        </thead>

                        <tbody className="bg-gray-100">
                          {displayedData.map((item) => (
                            <tr
                              key={item.id}
                              className="font-poppins font-medium  p-1"
                            >
                              <td className="text-center">
                                <input
                                  key={item._id}
                                  type="checkbox"
                                  className="form-checkbox"
                                  checked={selectedItems[item._id]}
                                  onChange={() => handleSelectItem(item._id)}
                                />
                              </td>
                              <td className="flex justify-center items-center">
                                {item.images && item.images.length > 0 ? (
                                  <img
                                    src={item.images[0]} // Assuming the first image is displayed
                                    alt="product img"
                                    className="w-24 h-30 object-cover object-center p-2"
                                  />
                                ) : (
                                  <span>No Image</span>
                                )}
                              </td>
                              <td className="text-center">
                                <div className="flex flex-col text-[#4779CC]">
                                  <div className="mb-1">
                                    {item.productName &&
                                      item.productName.length > 30
                                      ? item.productName.slice(0, 40) + "..."
                                      : item.productName}
                                  </div>
                                </div>
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  color:
                                    item.status === "Pending"
                                      ? "orange"
                                      : item.status === "Rejected"
                                        ? "red"
                                        : item.status === "Verified" &&
                                          item.theEkaivDiscountPrice !== 0
                                          ? "black"
                                          : "gray",
                                }}
                              >
                                {item.status === "Pending"
                                  ? "Pending"
                                  : item.status === "Rejected"
                                    ? "Rejected"
                                    : item.status === "Verified" &&
                                      item.theEkaivDiscountPrice !== 0
                                      ? item.theEkaivDiscountPrice
                                      : ""}
                              </td>

                              <td
                                className={`text-center ${item.status === "Verified"
                                  ? "text-green-500"
                                  : item.status === "Rejected"
                                    ? "text-red-500"
                                    : "text-orange-500"
                                  }`}
                              >
                                {item.status}
                              </td>

                              <td className="text-center text-[#4779CC]">
                                {formatCategory(item.selectedCategory)}
                              </td>
                              <td
                                className={`text-center ${item.stock === "In Stock"
                                  ? "text-green-500"
                                  : "text-red-500"
                                  }`}
                              >
                                {item.stock}
                              </td>
                              {/* <td className="text-center p-2">
                        <div className="flex flex-col">
                          <div className='mt-2'>
                            {item.createdAt}
                          </div>
                        </div>
                      </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <div className="flex flex-col md:flex-row gap-4 mt-3">
                        <div className="w-full md:w-auto">
                          {Object.values(selectedItems).filter(
                            (isSelected) => isSelected
                          ).length === 1 && (
                              <button
                                className="w-full md:w-28 h-9 rounded-lg bg-[#8A58DC] text-white font-poppins text-lg md:text-base lg:text-lg font-semibold tracking-wide"
                                onClick={handleEditProduct}
                              >
                                Edit
                              </button>
                            )}
                        </div>
                        <div className="w-full md:w-auto">
                          <button
                            className="w-full md:w-28 h-9 rounded-lg bg-[#8A58DC] text-white font-poppins text-lg md:text-base lg:text-lg font-semibold tracking-wide"
                            onClick={handleDeleteSelected}
                          >
                            Delete
                          </button>
                        </div>
                        {/* <div className="w-full md:w-auto">
                    <button
                      className="w-full md:w-28 h-9 rounded-lg bg-[#3FBD6A] text-white font-poppins text-lg md:text-base lg:text-lg font-semibold tracking-wide"
                      onClick={handleStockSelected}
                    >
                      In Stock
                    </button>
                  </div> */}
                        <div className="w-full md:w-auto">
                          <button
                            className="w-full md:w-28 h-9 rounded-lg bg-[#FF6B6B] text-white font-poppins text-lg md:text-base lg:text-lg font-semibold tracking-wide"
                            onClick={handleOutStockSelected}
                          >
                            Out Stock
                          </button>
                        </div>
                      </div>
                      {/* Custom pagination */}
                      <div>
                        <Pagination2
                          currentPage={currentPage}
                          totalPages={totalPages}
                          handlePageChange={handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
          </div>
        </div>
      )}
      {/* mobile view */}

      <div className="block sm:hidden   pb-[150px]">
        {activeComponent === "NewProductDetailsAdd" && <NewProductDetailsAdd />}
        {activeComponent === "EditProduct" && (
          <EditProduct productId={selectedProduct._id} />
        )}
        {activeComponent !== "NewProductDetailsAdd" &&
          activeComponent !== "EditProduct" && (
            <div className="mt-5">
              <div className="overflow-x-auto p-2">
                <table className="w-full table-auto  border rounded-lg bg-white ">
                  <thead>
                    <tr className="font-poppins font-medium border-2">
                      <th className="p-2"></th> {/* to align the headers */}
                      <th className="">
                        <ImageIcon className="" />
                      </th>
                      <th className=" text-md md:text-lg lg:text-xl font-semibold">
                        Name
                      </th>
                      <th className=" text-md md:text-lg lg:text-xl font-semibold px-2">
                        Price
                      </th>
                      {/* <th className=" text-md md:text-lg lg:text-xl font-semibold">Category</th> */}
                      <th className=" text-md md:text-lg lg:text-xl font-semibold px-2">
                        Stock
                      </th>
                      {/* <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">Date</th> */}
                    </tr>
                  </thead>

                  <tbody className="bg-gray-100">
                    {displayedData.map((item) => (
                      <tr
                        key={item.id}
                        className="font-poppins font-medium  p-1"
                      >
                        <td className="text-center">
                          <input
                            type="checkbox"
                            className="form-checkbox"
                            checked={selectedItems[item.id]}
                            onChange={() => handleSelectItem(item._id)}
                          />
                        </td>
                        <td className="flex justify-center items-center">
                          {item.images && item.images.length > 0 ? (
                            <img
                              src={item.images[0]} // Assuming the first image is displayed
                              alt="product img"
                              className="w-24"
                            />
                          ) : (
                            <span>No Image</span>
                          )}
                        </td>
                        <td className="text-center">
                          <div className="flex flex-col text-[#4779CC] text-[10px] min-[400px]:text-xs font-bold text-bold p-1 leading-3 min-[400px]:leading-none">
                            <div className="mb-1">
                              {item.productName && item.productName.length > 30
                                ? item.productName.slice(0, 40) + "..."
                                : item.productName}
                              , {item.selectedCategory}
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          {item.theEkaivDiscountPrice}
                        </td>
                        {/* <td className="text-center text-[#4779CC]"></td> */}
                        <td
                          className={`text-center ${item.stock === "In Stock"
                            ? "text-green-500"
                            : "text-red-500"
                            }`}
                        >
                          {item.stock}
                        </td>
                        {/* <td className="text-center p-2">
                        <div className="flex flex-col">
                          <div className='mt-2'>
                            {item.createdAt}
                          </div>
                        </div>
                      </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="w-full grid grid-cols-5 fixed bottom-[60px] gap-1 mt-3 p-2">
                <div className="w-full md:w-auto">
                  {Object.values(selectedItems).filter(
                    (isSelected) => isSelected
                  ).length === 1 && (
                      <button
                        onClick={() => {
                          handleEditProduct(selectedProduct);
                        }}
                        className="w-full h-7 rounded-md bg-[#8A58DC] text-white font-poppins text-xs font-semibold"
                      >
                        Edit
                      </button>
                    )}
                </div>

                <div className="w-full md:w-auto">
                  <button
                    onClick={() => {
                      handleDeleteSelected();
                    }}
                    className="w-full h-7 rounded-md bg-[#8A58DC] text-white font-poppins text-xs font-semibold "
                  >
                    Delete
                  </button>
                </div>
                <div className="w-full md:w-auto">
                  <button
                    onClick={() => {
                      handleOutStockSelected();
                    }}
                    className="w-full h-7 rounded-md bg-[#FF6B6B] text-white font-poppins text-xs font-semibold "
                  >
                    Out Stock
                  </button>
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default ManageProduct;
