import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BrandingTag from "../component/Home/BrandingTag";
import Instagram from "../component/Home/Instagram";
import { Helmet } from "react-helmet";
import Aboutusvedio from "../component/Home/Aboutusvedio";
import constant from "../constants/ImageConstants";
import Loader from "../component/common/Loader";
import { API_URL } from "../config/config";
import axios from "axios";
const ABOUT_US_LOGO = constant.IMAGES_IMAGES.ABOUT_US.ABOUT_US_LOGO;
const ABOUT_US_EARRING = constant.IMAGES_IMAGES.ABOUT_US.EARRING;
const ABOUT_US_IMG = constant.IMAGES_IMAGES.ABOUT_US.ABOUT_US_IMG;
const SHOP = constant.IMAGES_IMAGES.ABOUT_US.SHOP;
const AboutUs = () => {
  const [aboutUsData, setAboutUsData] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Fetch About Us data when the component mounts
    fetchAboutUsData();
  }, []);

  const fetchAboutUsData = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/admin-profile/aboutUs`);
      setAboutUsData(response.data.aboutUsData);
    } catch (error) {
      console.error('Error fetching About Us data:', error);
    }
  };

  console.log("aboutUsData", aboutUsData);

  return (
    <div className="p-4">
      <Helmet>
        <title>About Us</title>
      </Helmet>
      <div className=" h-100vh w-full">
        <div className="flex flex-col justify-center items-center gap-y-2 mt-6 ">
          <h3 className="text-blue-800  gap-y-5">
            {aboutUsData?.heading1}
          </h3>
          <h1 className="text-gray-900 text-center   text-[40px]">
            {aboutUsData?.heading2}
          </h1>
          <p className="text-gray-400  text-justify mb-6 text-[14px] w-full sm:w-[50%]  ">
            {aboutUsData?.mainContent}
          </p>
        </div>

        <div className="w-full flex flex-col justify-center items-center">
          <img
            className=" h-[30%] w-[50%]"
            loading="lazy-loading"
            onLoad={() => setImageLoaded(true)}
            src={ABOUT_US_LOGO}
          />
          {!imageLoaded && (
            <div className="w-[60%] flex justify-center items-center ">
              <Loader />
            </div>
          )}
        </div>

        <div className="flex flex-col justify-center items-center mt-20">
          <h1 className="text-gray-900 justify-center items-center flex text-[40px]">
            {aboutUsData?.heading3}
          </h1>
        </div>

        <div className=" grid grid-cols-1 md:grid-cols-3">
          <div className=" flex flex-col justify-center items-center">
            <div>
              <img
                className="w-[265px] h-[225px] rounded-2xl"
                onLoad={() => setImageLoaded(true)}
                loading="lazy-loading"
                src={ABOUT_US_EARRING}
              />
              {!imageLoaded && (
                <div className="w-[265px] h-[225px] flex justify-center items-center ">
                  <Loader />
                </div>
              )}
            </div>
            <Link to="/products">
              <button onClick={() => window.scrollTo(0, 0)} className="bg-[#8A58DC] rounded-md text-white w-48 ml-8 h-10 flex items-center justify-center gap-2 hover:bg-purple-400">
                Start Shopping
              </button>
            </Link>
          </div>
          <div className="flex justify-center items-center">
            <div className=" w-[70%] h-[70%] ">
              <p className="text-gray-400 text-justify text-[14px] gap-y-10 mt-20">
                {aboutUsData?.contentforImage1}
              </p>
            </div></div>
          <div className=" flex justify-center items-center">
            <img
              className="w-[70%] h-full rounded-2xl mx-auto"
              loading="lazy-loading"
              src={SHOP}
              onLoad={() => setImageLoaded(true)}
            />
            {!imageLoaded && (
              <div className="flex justify-center items-center w-[70%] h-[70%] ">
                <Loader />
              </div>
            )}
          </div>
        </div>

        <div className=" grid grid-cols-1 md:grid-cols-3 mt-20">
          <div className="flex justify-center items-center" >

            <img
              className=" w-[300px] h-[270px] md:mx-0 mx-auto md:mb-0 mb-5"
              src={ABOUT_US_IMG}
              loading="lazy-loading"
              onLoad={() => setImageLoaded(true)}
            />
            {!imageLoaded && (
              <div className="  w-[265px] h-[225px] flex justify-center items-center ">
                <Loader />
              </div>
            )}

          </div>
          <div className=" col-span-2 flex justify-center items-center w-full">
            <div className="w-full sm:w-[50%]">
              <h3 className="text-gray-900 text-[40px]">{aboutUsData?.heading4}</h3>

              <p className="text-gray-400  text-[14px] px-5 gap-y-10 mt-5 ">
                {aboutUsData?.contentforImage2}
              </p>
              <div></div>
            </div>
          </div>

        </div>
        {/* <div className="w-full   xl:mx-28 flex justify-center items-center">
            <div className="  grid md:grid-cols-2 grid-cols-1 mt-28 justify-center items-center gap-y-5">
              
              
            </div>
            </div> */}
        <div>
          <Aboutusvedio />
        </div>

        <div>
          <BrandingTag />
        </div>

        <div>
          <Instagram />
        </div>
      </div>


    </div>
  );
};

export default AboutUs;
