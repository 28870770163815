import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import axios from 'axios'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { API_URL, config } from '../config/config';
import { toast } from 'react-hot-toast';
import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorConstants from '../constants/ErrorConstants';
import Button from '../component/common/Button';
import Loader from '../component/common/Loader';
import InputField from '../component/common/InputField';

const SingUp = () => {
  const [agreeTerms, setAgreeTerms] = useState(false);
  const navigate = useNavigate();
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpEmailVerified, setOtpEmailVerified] = useState(false);
  const [signedUp, isSignedUp] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [otpValue, setOtpValue] = useState('');
  const [otpEmailValue, setOtpEmailValue] = useState('');
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [showEmailOtpInput, setShowEmailOtpInput] = useState(false);
  const [resetHash, setResetHash] = useState('')
  const [formValid, setFormValid] = useState(false); // Track form validity
  const [changeErrors, setChangeErrors] = useState({
    'phone': '',
    'firstname': '',
    'lastname': '',
    'password': '',
    'confirmPassword': '',
    'email': ''
  });


  const [emailError, setEmailError] = useState('');
  const [otpError, setOtpError] = useState('');
  const [emailotpError, setEmailOtpError] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // const handleEmailChange = (e) => {
  //   const enteredEmail = e.target.value.toLowerCase().trim(); // Convert to lowercase
  //   setEmail(enteredEmail);

  //   if (enteredEmail.trim() === '') {
  //     setEmailError('Email is required');
  //   } else if (!validateEmail(enteredEmail)) {
  //     setEmailError('Invalid email format');
  //   } else {
  //     setEmailError('');
  //   }
  // };

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value.toLowerCase().trim(); // Convert to lowercase and trim
    setEmail(enteredEmail);

    if (enteredEmail === '') {
      setEmailError('');
    } else if (!validateEmail(enteredEmail)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };
  const validateOtp = (otp) => {
    const otpRegex = /^\d{1,6}$/;
    return otpRegex.test(otp);
  };
  const handleOtpChange = (e) => {
    const enteredOtp = e.target.value;
    setOtpValue(enteredOtp);

    if (enteredOtp.trim() === '') {
      setOtpError('OTP is required');
    } else if (!validateOtp(enteredOtp)) {
      setOtpError('Invalid OTP format');
    } else {
      setOtpError('');
    }
  };
  const handleEmailOtpChange = (e) => {
    const enteredOtp = e.target.value;
    setOtpEmailValue(enteredOtp);

    if (enteredOtp.trim() === '') {
      setEmailOtpError('OTP is required');
    } else if (!validateOtp(enteredOtp)) {
      setEmailOtpError('Invalid OTP format');
    } else {
      setEmailOtpError('');
    }
  };


  const getOtp = async (e) => {
    e.preventDefault();
    if (phone.length !== 10) {
      setChangeErrors({ ...changeErrors, ['phone']: 'Phone number should be of 10 digits' });
      return;
    }
    setChangeErrors({ ...changeErrors, ['phone']: '' });
    await axios.post(`${API_URL}/api/otp-verfication/send-number-otp`, { phone })
      .then(response => {
        toast.success(response.data);
        setShowOtpInput(true);
      })
      .catch(error => {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        toast.error(message);
      });
  }


  const emailOtp = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      toast.error(ErrorConstants.Error.Assitance.BussinessConsultants.EMAIL_ERROR_MESSAGE);
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/api/auth/emailotp`, { email });
      toast.success(response.data.message);
      setResetHash(response.data.fullHash);
      setShowEmailOtpInput(true);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message);
    }
  };


  const verifyEmailOtp = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/api/auth/verifyemailotp`, { email, otp: otpEmailValue, resethash: resetHash });

      toast.success(response.data.message);
      setShowEmailOtpInput(false);
      setOtpEmailVerified(true);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message);
    }
  };

  const handleLoginLinkClick = () => {
    window.scrollTo(0, 0); // Scroll to the top
    navigate('/signin');
  };

  const verifyOtp = async (e) => {
    e.preventDefault();

    await axios.post(`${API_URL}/api/otp-verfication/verify-number-otp`, { phone, otp: otpValue })
      .then(response => {
        toast.success(response.data);
        setShowOtpInput(false);
        setOtpVerified(true);
      })
      .catch(error => {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        toast.error(message);
      });
  }


  const setPhoneNumberChange = (e) => {
    const number = e.target.value
    setShowOtpInput(false)
    if (number === '') {
      setChangeErrors({ ...changeErrors, ['phone']: 'Phone number is required' })
    } else {
      setChangeErrors({ ...changeErrors, ['phone']: '' })
    }
    if (number.length > 10) {
      return;
    }
    if (number.match(/^\d+$/)) {
      setPhone(number);
    } else {
      setPhone('');
    }

  }
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [visible, setVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setVisible(!visible);
  };
  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasDigits = /\d/.test(password);
  const hasSymbols = /[-+_!@#$%^&*.,?]/.test(password);
  const hasMinimumLength = password.length >= 8;

  const passwordStrength = {
    weak: hasMinimumLength,
    moderate: hasMinimumLength && (hasLowerCase || hasUpperCase || hasDigits || hasSymbols),
    strong: hasMinimumLength && hasLowerCase && hasUpperCase && hasDigits && hasSymbols,
  };

  const strengthColors = {
    weak: 'bg-red-300',
    moderate: 'bg-yellow-300',
    strong: 'bg-green-300',
  };


  const handleSignup = async (e) => {
    e.preventDefault();
    // Check if any validation errors exist
    for (const key in changeErrors) {
      if (changeErrors[key] !== '') {
        toast.error(changeErrors[key]);
        return;
      }
    }

    // // Check password strength
    // if (!passwordStrength.strong) {
    //   toast.error('Password must be strong.');
    //   return;
    // }

    // // Check if password and confirm password match
    // if (password !== confirmPassword) {
    //   toast.error('Password do not match.');
    //   return;
    // }


    // If all validations pass, proceed with signup
    await axios.post(`${API_URL}/api/auth/signup`, { fullname: firstname + " " + lastname, phone, email: email ? email : '', password, cpassword: confirmPassword }).then(response => {
      setErrorMessage('')
      toast.success(response.data.message)
      isSignedUp(true);
      setShowOtpInput(false)
    })
      .catch(error => {
        setSuccessMessage('')
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        toast.error(message);
      })
  }

  useEffect(() => {
    if (signedUp) {
      isSignedUp(false);
      navigate('/signin');
      window.scrollTo(0, 0)
    }
  }, [signedUp])

  const handleTermsAndConditionsClick = () => {
    navigate('/terms-and-condition');
    window.scrollTo(0, 0);
  };

  const handlePrivacyPolicyClick = () => {
    navigate('/privacy-policy');
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    // Check if all fields are filled
    if (firstname && lastname && phone && otpValue && agreeTerms) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [firstname, lastname, phone, otpValue, agreeTerms]);
  return (
    <div className='h-full w-full bg-white'>

      <div className="flex items-center justify-center flex-col w-full p-5 pb-10 max-sm:p-0">

        <div className="rounded-2xl p-8 shadow-lg md:w-[90%] sm:w-[100%] max-sm:rounded-none">

          <h2 className="block lg:hidden text-md mt-4 text-center font-lighter text-[#222] text-opacity-50">New to The Ekaiv!</h2>

          <h2 className="block lg:hidden text-3xl font-bold mb-8 text-center ">Sign Up</h2>
          <div className="flex flex-col md:flex-col lg:flex-row ">
            <div className="lg:w-1/2 flex justify-center items-center">
              <img
                src="https://img.freepik.com/premium-vector/log-people-isometric-vector-illustration-illustration-with-sign-people-mobile-app-design_123447-4723.jpg?size=626&ext=jpg&ga=GA1.1.584503204.1684751112&semt=ais"
                alt="Image"
                loading='lazy-loading'
                className={`h-[300px] w-auto mx-auto lg:ml-0 lg:mr-8 mb-4 md:mb-0 lg:h-auto lg:w-full bg-blend-color-burn ${imageLoaded ? 'block' : 'hidden'}`}
                onLoad={() => setImageLoaded(true)}
              />
              {!imageLoaded && (
                <div className="w-full mx-auto h-[200px] flex items-center justify-center">
                  <Loader />
                </div>
              )}
            </div>
            <div className="lg:w-1/2">
              <h2 className="hidden lg:block text-md mt-4 text-center font-lighter text-[#222] text-opacity-50">New to The Ekaiv!!</h2>
              <h2 className="hidden lg:block text-3xl font-bold mb-8 text-center">Sign  Up</h2>
              <form className="w-full flex justify-center">
                <div className='flex flex-col justify-center md:w-[70%] sm:w-[100%] gap-4'>
                  {/* <div >
                    <input
                      type="text"
                      value={fullname}
                      onChange={(e) => {
                        if (e.target.value === '') setChangeErrors({ ...changeErrors, ['fullname']: 'fullname is required' })
                        else {
                          setChangeErrors({ ...changeErrors, ['fullname']: '' })
                        }
                        setFullName(e.target.value)
                      }}
                      placeholder='First and Last Name'
                      className="w-full h-12 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#8A58DC]"
                    />
                  </div> */}
                  <div >
                    <InputField
                      type="text"
                      value={firstname}
                      maxLength={50}
                      onChange={(e) => {
                        if (e.target.value === '') setChangeErrors({ ...changeErrors, ['firstname']: 'First Name is required' })
                        else {
                          setChangeErrors({ ...changeErrors, ['firstname']: '' })
                        }
                        setFirstName(e.target.value)
                      }}
                      placeholder='First Name'
                      required
                    />
                  </div>
                  {(changeErrors.firstname !== '') && <div className='text-red-500 text-sm'>{changeErrors.firstname}</div>}
                  <div >
                    <InputField
                      type="text"

                      value={lastname}
                      maxLength={50}
                      onChange={(e) => {
                        if (e.target.value === '') setChangeErrors({ ...changeErrors, ['lastname']: 'Last Name is required' })
                        else {
                          setChangeErrors({ ...changeErrors, ['lastname']: '' })
                        }
                        setLastName(e.target.value)
                      }}
                      placeholder='Last Name'

                      required
                    />
                  </div>
                  {(changeErrors.lastname !== '') && <div className='text-red-500 text-sm mt-2'>{changeErrors.lastname}</div>}
                  <div className='flex w-full relative items-center'>
                    <input
                      type="text"
                      value={phone}
                      onChange={setPhoneNumberChange}
                      placeholder='Phone Number'
                      className="w-full pl-11 h-12 border border-gray-300 rounded-lg focus:outline-none focus:border-[#8A58DC]"
                    /><div className='absolute left-3'>+91</div>
                    {otpVerified ? (
                      <span className="text-green-500 ml-2"><VerifiedIcon /></span>
                    ) : (
                      <React.Fragment>
                        {!showOtpInput && (
                          <Button style='primary' className='w-fit ml-2' onClick={getOtp}>
                            Verify
                          </Button>

                        )}
                        {showOtpInput && (
                          <Button style='primary' className='w-fit ml-2 whitespace-nowrap' onClick={getOtp}>
                            Resend OTP
                          </Button>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                  {(changeErrors.phone !== '') && <div className='text-red-500 text-sm mt-2'>{changeErrors.phone}</div>}
                  {showOtpInput &&
                    <div className='flex w-full relative items-center'>
                      <input
                        type="text"
                        value={otpValue}
                        onChange={handleOtpChange}
                        disabled={otpVerified}
                        maxLength={6}
                        placeholder='Enter OTP'
                        className="w-full h-12 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#8A58DC]"
                      />
                      {otpVerified ? (
                        <span className="text-green-500 ml-2">Verified</span>
                      ) : (
                        <Button style='primary' onClick={verifyOtp} className='w-fit ml-2' >
                          Verify
                        </Button>
                      )}
                    </div>
                  }
                  {(otpError !== '') && <div className='text-red-500 text-sm'>{otpError}</div>}

                  <div className='flex w-full relative items-center'>
                    <input
                      type="text"
                      value={email}
                      onChange={handleEmailChange}
                      disabled={otpEmailVerified}
                      placeholder="Email (Optional)"
                      className="w-full h-12 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#8A58DC]"
                    />
                    {otpEmailVerified ? (
                      <span className="text-green-500 ml-2"><VerifiedIcon /></span>
                    ) : (
                      <React.Fragment>
                        {!showEmailOtpInput && (
                          <Button style='primary' className='w-fit ml-2' onClick={emailOtp}>
                            Verify
                          </Button>
                        )}
                        {showEmailOtpInput && (

                          <Button style='primary' className='w-fit ml-2 whitespace-nowrap' onClick={emailOtp}>
                            Resend OTP
                          </Button>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                  {(changeErrors.emailError !== '') && <div className='text-red-500 text-sm '>{changeErrors.emailError}</div>}
                  {showEmailOtpInput &&
                    <div className='flex w-full relative items-center'>
                      <input
                        type="text"
                        value={otpEmailValue}
                        onChange={handleEmailOtpChange}
                        maxLength={6}
                        placeholder='Enter OTP'
                        className="w-full h-12 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#8A58DC]"
                      />
                      {otpEmailVerified ? (
                        <span className="text-green-500 ml-2">Verified</span>
                      ) : (
                        <Button style='primary' className='w-fit ml-2' onClick={verifyEmailOtp}>
                          Verify
                        </Button>
                      )}
                    </div>
                  }
                  {(emailotpError !== '') && <div className='text-red-500 text-sm mt-2'>{emailotpError}</div>}

                  {/* {emailError && <div className="text-red-500 text-sm mt-2">{emailError}</div>} */}
                  <div>
                    <div className="relative">
                      <input
                        className="w-full h-12 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#8A58DC]"

                        type={visible ? 'text' : 'password'}
                        value={password}
                        placeholder='Password'
                        onChange={(e) => {
                          if (e.target.value === '') setChangeErrors({ ...changeErrors, ['password']: 'Password is required' })
                          else {
                            setChangeErrors({ ...changeErrors, ['password']: '' })
                          }
                          setPassword(e.target.value)
                        }}
                        minLength={8}
                        maxLength={16}
                      />

                      {/* eye open/close */}
                      <span
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                        onClick={togglePasswordVisibility}
                      >
                        {visible ? (
                          <AiOutlineEye className="h-6 w-6 text-gray-500 hover:text-[#8A58DC] transition-colors duration-300" />
                        ) : (
                          <AiOutlineEyeInvisible className="h-6 w-6 text-gray-500 hover:text-[#8A58DC] transition-colors duration-300" />
                        )}
                      </span>

                    </div>
                    <label className="block text-sm text-black opacity-50 mt-2">
                      Must be at least 8 characters.
                    </label>
                    {/* Password strength indicator */}
                    {password && (
                      <div className="mt-2">
                        <div className="flex">
                          <div className={`w-1/3 h-1 ${passwordStrength.weak ? strengthColors.weak : ''} transition-colors duration-300`}></div>
                          <div className={`w-1/3 h-1 ${passwordStrength.moderate ? strengthColors.moderate : ''} transition-colors duration-300`}></div>
                          <div className={`w-1/3 h-1 ${passwordStrength.strong ? strengthColors.strong : ''} transition-colors duration-300`}></div>
                        </div>

                        <div className="grid justify-between mt-1 text-xs">
                          <div className="text-gray-500">
                            <span className={`mr-2 ${hasMinimumLength ? 'text-green-500' : 'text-red-500'}`}>{hasMinimumLength ? '✓' : '✗'}</span> 8-16 characters
                          </div>
                          <div className="text-gray-500">
                            <span className={`mr-2 ${hasLowerCase ? 'text-green-500' : 'text-red-500'}`}>{hasLowerCase ? '✓' : '✗'}</span> At least 1 lowercase letter
                          </div>
                          <div className="text-gray-500">
                            <span className={`mr-2 ${hasUpperCase ? 'text-green-500' : 'text-red-500'}`}>{hasUpperCase ? '✓' : '✗'}</span> At least 1 uppercase letter
                          </div>
                          <div className="text-gray-500">
                            <span className={`mr-2 ${hasDigits ? 'text-green-500' : 'text-red-500'}`}>{hasDigits ? '✓' : '✗'}</span> At least 1 digit
                          </div>
                          <div className="text-gray-500">
                            <span className={`mr-2 ${hasSymbols ? 'text-green-500' : 'text-red-500'}`}>{hasSymbols ? '✓' : '✗'}</span> Contains special symbol -+_!@#$%^&*.,?
                          </div>
                        </div>



                      </div>
                    )}
                    {(changeErrors.password !== '') && <div className='text-red-500 text-sm mt-2'>{changeErrors.password}</div>}
                  </div>
                  {/* confirm password */}
                  <div  >
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="w-full h-12 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-[#8A58DC]"
                      placeholder="Confirm Password"
                      maxLength={16}
                      minLength={8}
                      value={confirmPassword}
                      onChange={(e) => {
                        if (e.target.value === '') setChangeErrors({ ...changeErrors, ['confirmPassword']: 'confirm Password is required' })
                        else {
                          setChangeErrors({ ...changeErrors, ['confirmPassword']: '' })
                        }
                        setConfirmPassword(e.target.value)
                      }
                      }

                    />
                    {/* Warning message */}
                    {confirmPassword && password !== confirmPassword && (
                      <label className="block text-sm text-red-500 mt-1">
                        Passwords do not match.
                      </label>
                    )}
                    {confirmPassword && password === confirmPassword && (
                      <label className="block text-sm text-green-500 mt-1">
                        Passwords matched.
                      </label>
                    )}
                    {(changeErrors.confirmPassword !== '') && <div className='text-red-500 text-sm mt-2'>{changeErrors.confirmPassword}</div>}
                  </div>

                  {(errorMessage !== '') ? <div className='text-center text-red-500 text-sm'>
                    {errorMessage}
                  </div> : (successMessage) && <div className='text-center text-green-500 text-sm'>
                    {successMessage}
                  </div>}

                  <div className="flex items-center mt-4">
                    <input
                      type="checkbox"
                      id="agreeTermsCheckbox"
                      checked={agreeTerms}
                      onChange={() => setAgreeTerms(!agreeTerms)}
                      className="mr-2"
                    />
                    <label htmlFor="agreeTermsCheckbox" className="text-sm text-[#222] text-opacity-80">
                      I agree to the{' '}
                      <span
                        className='text-[#8A58DC] cursor-pointer'
                        onClick={handleTermsAndConditionsClick}
                      >
                        Terms & Conditions
                      </span>{' '}
                      and{' '}
                      <span
                        className='text-[#8A58DC] cursor-pointer'
                        onClick={handlePrivacyPolicyClick}
                      >
                        Privacy Policy.
                      </span>
                    </label>

                  </div>

                  <div >
                    <Button
                      type="button"
                      disabled={!formValid} // Disable button if form is not valid
                      onClick={handleSignup}
                      style='tertiary'
                      className={`w-full ${!formValid ? 'opacity-50 cursor-not-allowed' : ''} hover:bg-[#965df0] whitespace-normal`}

                    >
                      Continue
                    </Button>
                  </div>


                  {/* horizontal line with OR text */}
                  <div className="flex items-center my-2">
                    <hr className="flex-grow border-t border-gray-300" />
                    <span className="mx-2 text-gray-500">OR</span>
                    <hr className="flex-grow border-t border-gray-300" />
                  </div>

                  <p className=" mt-4 text-center text-[#222] text-opacity-80 text-sm">
                    Already a user?{' '}
                    <Link to="/signin" className="text-[#8A58DC]" onClick={handleLoginLinkClick}>
                      Login
                    </Link>
                  </p>



                </div>
              </form>
            </div>
          </div>
        </div>
      </div >

    </div >
  );
};

export default SingUp;