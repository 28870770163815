import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { BiSort, BiRupee } from 'react-icons/bi';
import axios from 'axios';
import { API_URL, config } from '../../../config/config';
import adminService from '../../../services/admin/adminService';

export default function VendorAnalytics() {
  
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminService.fetchAllVendors();
        setVendors(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Filter vendors based on status
  const pendingVendors = vendors.filter(vendor => vendor.status === 'Pending');
  const verifiedVendors = vendors.filter(vendor => vendor.status === 'Verified');
  const rejectedVendors = vendors.filter(vendor => vendor.status === 'Rejected');

  return (
    <div className=''>
      <h1 className="mt-3 mb-3 ml-6">Sales</h1>
      <div className="flex flex-col md:flex-row gap-5 p-5 ">
        <Card
          variant="outlined"
          orientation="horizontal"
          className="w-full md:w-1/3 rounded-xl cursor-pointer"
          sx={{
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
            borderRadius: '1rem', // Rounded corners
            '&:hover': {
              boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
              borderColor: 'neutral.outlinedHoverBorder',
            },
          }}
        >
          <div>
            <AccountBoxIcon className='m-2 text-green-800' />
            <div className="flex justify-center items-center flex-col mb-4">
              <div className="p-4">
                <p className="text-2xl flex justify-center items-center font-bold text-[#737587]">{vendors.length}</p>
              </div>
              <p className="font-bold text-2xl">Total Vendors</p>
            </div>
          </div>
        </Card>

        <Card
          variant="outlined"
          orientation="horizontal"
          className="w-full md:w-1/3 rounded-xl cursor-pointer"
          sx={{
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
            borderRadius: '1rem', // Rounded corners
            '&:hover': {
              boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
              borderColor: 'neutral.outlinedHoverBorder',
            },
          }}
        >

          <div>
            <AccountBoxIcon className='m-2 text-green-800' />
            <div className="flex justify-center items-center flex-col">
              <div className="p-4">
                <p className="text-2xl flex justify-center items-center font-bold text-[#737587]">{verifiedVendors.length}</p>
              </div>
              <p className="font-bold text-2xl">Verified Vendors</p>
            </div>
          </div>
        </Card>
        <Card
          variant="outlined"
          orientation="horizontal"
          className="w-full md:w-1/3 rounded-xl cursor-pointer"
          sx={{
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
            borderRadius: '1rem', // Rounded corners
            '&:hover': {
              boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
              borderColor: 'neutral.outlinedHoverBorder',
            },
          }}
        >
          <div>
            <AccountBoxIcon className='m-2 text-green-800' />
            <div className="flex justify-center items-center flex-col">
              <div className="p-4">
                <p className="text-2xl flex justify-center items-center font-bold text-[#737587]">{pendingVendors.length}</p>
              </div>
              <p className="font-bold text-2xl">Pending Vendors</p>
            </div>
          </div>
        </Card>
        <Card
          variant="outlined"
          orientation="horizontal"
          className="w-full md:w-1/3 rounded-xl cursor-pointer"
          sx={{
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
            borderRadius: '1rem', // Rounded corners
            '&:hover': {
              boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
              borderColor: 'neutral.outlinedHoverBorder',
            },
          }}
        >
          <div>
            <AccountBoxIcon className='m-2 text-green-800' />
            <div className="flex justify-center items-center flex-col">
              <div className="p-4">
                <p className="text-2xl flex justify-center items-center font-bold text-[#737587]">{rejectedVendors.length}</p>
              </div>
              <p className="font-bold text-2xl">Rejected Vendors</p>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
