import React from 'react'

const SellingCharges = () => {
  return (
    <div className='  sm:p-10 p-5'>
      <p className=' sm:text-5xl text-2xl mb-4 font-bold sm:px-12 px-8 '>
      Discover Ekaiv Selling Charges
      </p>
      
      <p className=' sm:text-2xl text-lg mb-4 font-bold sm:px-12 px-8 text-[#A855F7]'>
        Referral Fee (based on Category)
      </p>
      <p className=' sm:text-xl text-base mb-8 sm:px-12 px-8'>
      For each product sold, the Ekaiv applies selling charges, which vary based on the product category. These charges are calculated based on the discounted price set by the seller for the product.
      </p>
      <div className=' sm:px-12 px-8'>
        <div className=' border border-white-300 rounded-xl p-2 sm:p-4 shadow-md py-6 mb-10 sm:mb-20 border-l-4'>
        <p className=' sm:text-xl text-lg mb-2 font-bold px-4'>
        Here's how to calculate Referral Fees:
        </p>
        <p className=' sm:text-lg text-gray-700 text-sm px-4 mb-4'>
        Total Referral Fees = Item price x Referral Fee percentage
        </p>
        <p className=' sm:text-base text-xs px-4'>
        For instance, if you're selling a Photo Frame priced at ₹1000 and the referral fee percentage for the painting category is 5%, then Referral Fees = ₹1000 * 5% = ₹50.
        </p>
      </div>
      </div>
     <p className=' sm:text-4xl text-2xl font-bold mb-8 sm:px-5 px-2 text-center'>
     Referral Fee based on Items
     </p>

     <div className=' grid grid-cols-1 sm:grid-cols-2 gap-4 p-4 sm:px-12 px-8'>

      <div className=' border border-white-300 rounded-xl p-4 py-6 shadow-md border-l-8'>
        <p className=' sm:text-2xl text-lg mb-2 font-bold px-8 text-[#A855F7]'>
        For Fragile items: <br/>Across all categories

        </p>
        <p className=' sm:text-lg text-gray-700 text-base px-8 '>
        For products priced below ₹1000, the referral fee is 8%
        </p>
        <p className=' sm:text-lg text-gray-700 text-base px-8'>
        For products priced above ₹1000, the referral fee is 7%.
        </p>
        </div>

        <div className=' border border-white-300 rounded-xl p-4 shadow-md py-6 border-l-8'>
        <p className=' sm:text-2xl text-lg mb-2 font-bold px-8 text-[#A855F7]'>
        For Non-fragile items: <br/>Across all categories
        </p>
        <p className=' sm:text-lg text-gray-700 text-base px-8 '>
        For products priced below ₹500, the referral fee is 5%.
        </p>
        <p className=' sm:text-lg text-gray-700 text-base px-8 '>
        For products priced above ₹500, the referral fee is 4%.
        </p>
      </div>
      </div>

     

    </div>
  )
}

export default SellingCharges
