import React, { useState, useEffect } from 'react';
import {useSelector} from 'react-redux'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import NotificationDropdown from './NotificationDropdown';

const AdminNavbar = ({ setActiveTab }) => {
    const {user} = useSelector((state) => state.auth)
    const notificationCount = 0;

    const [isActive, setIsActive] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);

    const toggleNotifications = () => {
        setShowNotifications(!showNotifications);
    };

    const notifications = [
        { message: ' Great news! You have a new order. Order #12345 has been placed by a customer.' },
        { message: ' Inventory Alert: The stock for Product X is running low. Only 5 units left.' },
        { message: ' You have received a new product review. A customer has left a 5-star rating and positive feedback for Product Y.' },
        { message: ' A potential customer has sent you an inquiry. Please check your messages for more details.' },
        { message: ' Congratulations! You have made a sale. Order #67890 has been successfully processed.' },
    ];

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (showNotifications) {
                const notificationIconElement = document.querySelector('.notification-icon');
                if (notificationIconElement && !notificationIconElement.contains(event.target)) {
                    setShowNotifications(false);
                }
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [showNotifications]);

    return (
        <div className='md:flex items-center justify-between py-2 md:px-10 px-4 md:h-24 h-20 bg-white border'>
            <div className="font-poppins text-2xl font-medium leading-9 tracking-normal text-left">
                Hi {user.fullname.split(' ')[0]}!
            </div>

            <div className="flex items-center space-x-8">
                {isActive && (
                    <div className="account-settings">
                        {/* Add your account settings content here */}
                    </div>
                )}

                <div className="relative inline-block">
                    <span className={`w-2 h-2 mr-2 bg-gray-500 rounded-full ${notificationCount > 0 ? 'hidden' : ''}`}></span>
                    <NotificationsNoneIcon
                        className={`w-6 h-6 mr-2 cursor-pointer notification-icon ${showNotifications ? 'text-gray-500' : ''}`}
                        onClick={toggleNotifications}
                    />
                    {notificationCount > 0 && (
                        <span className="absolute top-0 right-1 w-4 h-4 flex items-center justify-center text-xs text-white bg-red-500 rounded-full">
                            {notificationCount}
                        </span>
                    )}
                    {showNotifications && <NotificationDropdown notifications={notifications} />}
                </div>

                <AccountCircleRoundedIcon onClick={() => setActiveTab('account')} />

            </div>
        </div>
    );
}

export default AdminNavbar;
