import React, { useEffect, useState } from 'react'
import BlogCard from './BlogCard/BlogCard'
import PopularCard from './BlogCard/PopularCard';
import axios from 'axios';

import { API_URL } from '../../config/config';
import Loader from '../common/Loader';

function Blog() {

    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState('');
    const [popularBlogs, setPopularBlogs] = useState([]);

    useEffect(() => {
        fetchBlogs();
    }, []);

    const fetchBlogs = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_URL}/api/admin-profile/getallblogs`);
            const allBlogs = response.data.blog;
            console.log("all blogs", allBlogs);

            // Filter only the verified blogs
            const verifiedBlogs = allBlogs.filter(blog => blog.status === 'verified');

            const sortedBlogs = [...verifiedBlogs].sort((a, b) => b.clickCount - a.clickCount);
            const popularBlogs = sortedBlogs.slice(0, 5);

            setBlogs(verifiedBlogs);
            setPopularBlogs(popularBlogs);
        } catch (error) {
            console.error('Error fetching blogs:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className='pt-2 pl-2 sm:p-5'>
                {/* top part */}
                <div className='w-full sm:h-[260px] bg-gradient-to-r from-[#F16080] to-[#5156E9] bg-opacity-30 relative min-[460px]:left-5 rounded-l-3xl'>
                    <div className='flex flex-row items-center w-fit p-2 gap-5'>
                        <div className='flex flex-col gap-5 text-white pl-2 min-[370px]:pl-5  md:pl-20 pt-2 pr-2'>
                            <h1 className='text-3xl font-semibold'>THE EKAIV BLOGS</h1>
                            <span className=' flex'>
                                <p className=' whitespace-break-spaces max-[370px]:text-xs max-[460px]:text-sm'>Welcome to our blog, where we delve into the enchanting world of literature. Discover insightful reviews, thoughtful recommendations, and deep dives into both new releases and timeless classics. Join our vibrant community of book lovers and share your passion for reading!</p>
                                <img className='block sm:hidden  h-24 w-24 min-[370px]:h-32 min-[370px]:w-32 ' src="https://s3-alpha-sig.figma.com/img/b18e/ad84/1615f34dceb0e5b962a06bd6601e5b6d?Expires=1716768000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=G31Fty3l4jG-X0gsVMxzS-r8SvPKsBD-JolHV6e38cyo7WcTBX7bZmvdPEF21YTOJgZUlJcZx2KAYZn8t~7lTO6XEHw8hDUEbnR5~stnELkXfqGTqOOzYcftAY2RC92oPq4VA0SJ2NgAtlvi1umBlK3lopeJT7~w65BgwlpZTlp1wyq7H7Epx1jYZTmkHrxs0axKItBj7Wkt-rSwsvD1ULXTK6DEKR0j9m3w24YiIuvEQqj3av59AwcfdC2qniQSIE7m5u2NC7NuJcgJHXT4nwa288HbZADd-MoaQgIez7Ihr2ra~wnt8B2CR8CQRR0jfSs9TantRKOU39VHw~0KEQ__" alt="blog" />
                            </span>
                        </div>
                        <div className='h-50 w-50 pr-5 md:pr-20 hidden sm:block'>
                            <img className=' object-cover' src="https://s3-alpha-sig.figma.com/img/b18e/ad84/1615f34dceb0e5b962a06bd6601e5b6d?Expires=1716768000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=G31Fty3l4jG-X0gsVMxzS-r8SvPKsBD-JolHV6e38cyo7WcTBX7bZmvdPEF21YTOJgZUlJcZx2KAYZn8t~7lTO6XEHw8hDUEbnR5~stnELkXfqGTqOOzYcftAY2RC92oPq4VA0SJ2NgAtlvi1umBlK3lopeJT7~w65BgwlpZTlp1wyq7H7Epx1jYZTmkHrxs0axKItBj7Wkt-rSwsvD1ULXTK6DEKR0j9m3w24YiIuvEQqj3av59AwcfdC2qniQSIE7m5u2NC7NuJcgJHXT4nwa288HbZADd-MoaQgIez7Ihr2ra~wnt8B2CR8CQRR0jfSs9TantRKOU39VHw~0KEQ__" alt="blog" />
                        </div>
                    </div>
                </div>
                {loading ? (
                    <div className='flex justify-center items-center h-[350px]'>
                        <Loader />
                    </div>
                ) :
                    blogs.length === 0 ? (
                        <div className='flex justify-center h-[500px] text-gray-500 items-center'>
                            Blogs not available
                        </div>
                    ) : (
                        <>
                            {/* middle part */}
                            < div className='flex flex-col min-[1160px]:flex-row justify-between md:pl-10 md:pr-10 mt-4'>
                                {/* new blogs part */}
                                <div className='w-full p-5'>
                                    <h1 className='font-semibold text-xl mb-4'>New Blogs</h1>
                                    <div className="flex flex-col gap-4">
                                        {blogs?.map((blog) => (
                                            <div key={blog._id}>
                                                <BlogCard
                                                    title={blog.title}
                                                    content={blog.blogContent}
                                                    imageUrl={blog.blogImage}
                                                    blogId={blog._id}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {/* popular blogs part */}
                                <div className='w-full md:w-[600px] p-5'>
                                    <h1 className='font-semibold text-xl mb-4'>Popular</h1>
                                    <div className="flex flex-col gap-4 mt-4">
                                        {popularBlogs?.map((blog) => (
                                            <div key={blog._id}>
                                                <PopularCard
                                                    title={blog.title}
                                                    imageUrl={blog.blogImage}
                                                    blogId={blog._id}

                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
            </div>
        </>

    )
}

export default Blog