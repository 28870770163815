import React, { useState, useRef } from 'react'
import { toast } from 'react-hot-toast';
import constant from '../../constants/ImageConstants';
import SuccessConstant from '../../constants/SuccessConstant';
import ErrorConstants from '../../constants/ErrorConstants';
import Button from '../common/Button';
import InputField from '../common/InputField';
import commonService from '../../services/common/commonService';
const { ASSISTANCE: { WEB_APP_SOFTWARE_DEVELOPMENT: { EMAIL_LOGO, PERCEPTIONS } } } = constant.IMAGES_IMAGES;


function SAWdev() {
  const form = useRef();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    requirement: '',
    businessSpecifications: '',
  });
  const sendEmail = async (e) => {
    e.preventDefault();


    if (Object.values(formData).every(value => !value)) {
      toast.error(ErrorConstants.Error.ALL_FEILD_ERROR);
      return;
    }

    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate phone number and email
    if (!phoneRegex.test(formData.phone)) {
      toast.error(ErrorConstants.Error.PHONE_ERROR_MESSAGE);
      return;
    } else if (!emailRegex.test(formData.email)) {
      toast.error(ErrorConstants.Error.EMAIL_ERROR_MESSAGE);
      return;
    }

    try {
      // Call the function to send the email
      const response = await commonService.sendSAWDevEmail(formData);


      // Handle successful response
      toast.success(SuccessConstant.Success.FORM_SUCCESS);
      // Clear the form data after successful submission
      setFormData({
        name: '',
        email: '',
        phone: '',
        requirement: '',
        businessSpecifications: '',
      });
    } catch (error) {
      // Handle errors
      toast.error(ErrorConstants.Error.FORM_ERROR_MESSAGE);
    }

    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 5000);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Validate name field length
    if (name === 'name' && value.length > 50) {
      return; // Do not update state if the name exceeds 50 characters
    }
    setFormData({
      ...formData, [name]: value,
    });
  };

  const handlePhoneInputChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 10); // Limit to 10 characters
    setFormData({ ...formData, phone: inputValue });
  };


  // Define an array of form fields for rendering
  const formFields = [
    { name: 'name', label: 'Name', type: 'text', placeholder: 'Name' },
    { name: 'email', label: 'Email', type: 'email', placeholder: 'Email' },
    { name: 'phone', label: 'Phone', type: 'tel', placeholder: 'Phone' },
    { name: 'requirement', label: 'Requirement', type: 'text', placeholder: 'Requirements' },
    { name: 'businessSpecifications', label: 'Business Specifications', type: 'text', placeholder: 'Business Specifications before connecting' },
  ];



  const handleFormSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <div className=' bg-gray-100 p-2 md:p-10'>

        <div className=' w-full flex justify-center items-center'>
          <h2 className="text-xl min-[510px]:text-2xl font-bold mb-4 text-gray-800">Web / App / Software Development</h2>
        </div>
        <div className=' grid md:grid-cols-2 grid-cols-1 gap-2 p-2'>
          <div className=' flex justify-center items-center'>
            <div className=' w-[100%] md:w-[70%]'>
              <form ref={form} onSubmit={sendEmail} className="mt-4 flex flex-col">
                <div className="flex flex-wrap -mx-4">
                  {formFields.map((field, index) => (
                    <div key={index} className={`mb-4 px-4 ${field.name === 'email' || field.name === 'phone' ? 'w-full md:w-1/2' : 'w-full'}`}>
                      <InputField
                        id={field.name}
                        label={field.label}
                        type={field.type}
                        value={formData[field.name]}
                        onChange={field.name === 'phone' ? handlePhoneInputChange : handleInputChange}
                        placeholder={field.placeholder}
                      />
                    </div>
                  ))}
                </div>
                <Button
                  type="submit"
                  style="primary"
                  disabled={buttonDisabled}
                >
                  Submit
                </Button>
              </form>



              {/* Add any other UI elements and functionality here*/}
            </div>
          </div>

          <div className='  '>
            {/* <p className=' bg-rose-200 rounded-lg p-2 text-center '>
      Tell us about your unique requirements, and our team will guide you through the entire process of website, application, or software development. We focus on creating a seamless online presence for your business, ensuring that your digital platforms are not just functional but tailored to meet the specific needs of your growing enterprise.
      </p> */}

            <div class="container mx-auto min-[936px]:px-4 py-8 max-w-md">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-1 min-[868px]:gap-6">

                <div class=" bg-white rounded-lg p-2 shadow-lg flex justify-center items-center ">
                  <img src={PERCEPTIONS} loading='lazy-loading' alt="Perceptions Technologies" className=' object-contain w-[55%]' />
                </div>


                <div class=" bg-white rounded-lg text-center px-4 py-5 shadow-lg ">
                  {/* <h1 className=' font-bold text-lg'>Perceptions Technologies</h1> */}
                  <p className='pt-2 text-xs text-justify'>Tell us about your unique requirements, and our team will guide you through the entire process of website, application, or software development.</p>

                  {/* <p className="flex items-center justify-center">
  <span className="mr-2"><FaLocationDot /></span>
  Indore
</p> */}

                </div>


                <div class=" bg-white rounded-lg py-5 shadow-lg ">
                  {/* <h1 className="flex flex-col items-center text-xs">
  <FaPhoneSquareAlt size={18}/>
 <span className='pt-2'> +91 9620864378</span>
</h1> */}
                  <p class="text-xs px-4  text-justify">
                    We focus on creating a seamless online presence for your business, ensuring that your digital platforms are not just functional but tailored to meet the specific needs of your growing enterprise.
                  </p>
                </div>


                <div class=" bg-white rounded-lg px-1 py-4 shadow-lg flex justify-center items-center ">
                  <p className="flex flex-col items-center text-center text-xs ">
                    {/* <IoIosMail size={22} /> */}
                    <img src={EMAIL_LOGO} alt="Email Logo" loading='lazy-loading' className=' h-14 w-14' />
                    <span className='break-words'>services@ perceptionstechnologies.com</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </>
  )
}

export default SAWdev
