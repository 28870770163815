import axios from 'axios';
import { API_URL ,config} from '../../config/config'; // Adjust the import path as necessary

// Function to fetch banner data from the API
const fetchBannerData = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/admin-profile/bannerall`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Failed to fetch banner data');
        }
    } catch (error) {
        console.error('Error fetching banner data:', error);
        throw error; // Re-throw error so the calling code can handle it
    }
};

// Function to fetch categories data from the API
const fetchCategoriesData = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/admin-profile/getCategories`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Failed to fetch categories data');
        }
    } catch (error) {
        console.error('Error fetching categories:', error);
        throw error; // Re-throw error so the calling code can handle it
    }
};

const fetchAllVendors = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/admin-profile/getAllVendors/superAdmin`, config);
        return response.data;  // Return the data from the response
    } catch (error) {
        console.error('Error fetching vendors:', error);
        throw error;
    }
};

const fetchAllProducts = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/admin-profile/getAllProducts/superAdmin`, config);
        return response.data;  // Return the data from the response
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};


export default {
    fetchBannerData,
    fetchCategoriesData,
    fetchAllVendors,
    fetchAllProducts,
};
