import React, { useEffect, useState } from 'react'
import Button from '../../common/Button';
import axios from 'axios';
import { API_URL, config } from '../../../config/config';
import Loader from '../../common/Loader';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function BlogCard({ title, content, imageUrl, blogId }) {
    const [loading, setLoading] = useState(true);
    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate();

    const getBlogs = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${API_URL}/api/admin-profile/getallblogs`, config);
            setBlogs(response.data.blog);
        } catch (error) {
            toast.error('Error fetching blogs');
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getBlogs();
    }, []);

    const handleReadMore = async () => {
        try {
            await axios.put(`${API_URL}/api/admin-profile/blog/${blogId}`);
            navigate(`/specific-blog/${blogId}`);

            window.scrollTo(0, 0);
        } catch (error) {
            toast.error('Error incrementing click count');
        }
    };


    return (
        <div>
            {loading ? (
                <div className='flex justify-center items-center h-[500px]'>
                    <Loader />
                </div>
            ) : (
                <>
                    <div className='flex flex-col sm:flex-row justify-normal items-start bg-gray-100 rounded-md gap-5 p-2'>
                        <img src={imageUrl.url} alt="" className='w-56 h-56 p-2' />
                        <div className='flex flex-col gap-5 sm:w-[600px] p-2'>
                            <h1 className='font-semibold text-xl'>{title.slice(0, 100)}</h1>
                            <span className='flex-wrap h-20'>{content.slice(0, 220)}</span>

                            <Button style="primary" className='w-fit' onClick={handleReadMore}>
                                Read More
                            </Button>

                        </div>

                    </div>
                </>
            )}
        </div>
    )
}

export default BlogCard