import React, { useState, useRef, useEffect } from 'react';
import { FiEdit3 } from "react-icons/fi";

const AddressCard = ({ address, onSelect, selected, onEditSelect }) => {

  const [isChecked, setIsChecked] = useState(selected);
  const cardRef = useRef(null);


  const handleCardClick = () => {
    setIsChecked(!isChecked);
   
    if(isChecked){
      onSelect(address);
    }
  };

  useEffect(() => {
    if (isChecked) {
      // Scroll the card into view when it is selected
      cardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center', // Align the card in the center of the scroll container
        inline: 'center', // Center the card horizontally
      });
    }
  }, [isChecked]);

  const addressStyles = {
    display: '-webkit-box',
    WebkitLineClamp: 2, // Number of lines to display
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };
  const renderName = () => {
    const maxLength = 20; // Set your desired maximum length for the name
    const truncatedName = address?.fullname.length > maxLength
      ? `${address?.fullname.substring(0, maxLength)}...`
      : address?.fullname;

    return (
      <span className="ml-2 font-semibold">
        {truncatedName}
      </span>
    );
  };


  const handleEditClick = () => {
    onEditSelect(address)
  };



  return (
    <div
      ref={cardRef}
      onChange={handleCardClick}
      className={`relative flex justify-center items-center w-full h-[200px] sm:h-[250px] md:h-[250px] lg:w-[300px] xl:w-[350px] p-10 border cursor-pointer rounded-[10px] shadow-md bg-[#FFFFFF] ${selected ? 'border-[#8A58DC]' : ''}`}
    >
      <div className="absolute top-3 right-5 cursor-pointer text-[#F16080] hover:text-[#8A58DC] z-1" onClick={handleEditClick}>
        <FiEdit3 />
      </div>
      <div className='cursor-pointer'>
        <label className="mb-2 inline-flex items-center cursor-pointer" >
          <input
            type="radio"
            className="form-radio text-[#8A58DC]"
            checked={selected}
          />
        {renderName()}
        </label>
        <p style={addressStyles} className='mb-2 text-[#9B9B9B] break-all'>Address: {`${address?.flat}, ${address?.area}, ${address?.landmark}, ${address?.city}, ${address?.state}, ${address?.pinCode}, ${address?.country}`}</p>
        <p className='mb-2 text-[#9B9B9B]'>Contact: {address?.phoneNumber}</p>
      </div>
    </div>
  );
};

export default AddressCard;