import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import { API_URL } from '../../config/config';
import { toast } from 'react-hot-toast';
import commonService from '../../services/common/commonService';
import Loader from '../common/Loader';
import SuccessConstant from '../../constants/SuccessConstant';
import ErrorConstants from '../../constants/ErrorConstants';
import Button from '../common/Button';
import InputField from '../common/InputField';


function DmGd() {

  const [ IMAGE1, setIMAGE1 ] = useState('')
  const [ IMAGE2, setIMAGE2 ] = useState('')
  const [ IMAGE3, setIMAGE3] = useState('')
  const [ IMAGE4, setIMAGE4 ] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/assistance/getGraphicImages`)
        setIMAGE1(response.data.GraphicImage.firstImage.url)
        setIMAGE2(response.data.GraphicImage.secondImage.url)
        setIMAGE3(response.data.GraphicImage.thirdImage.url)
        setIMAGE4(response.data.GraphicImage.fourthImage.url)

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const formdm = useRef();

  const [loadingImages, setLoadingImages] = useState(true);
  const handleImageLoad = () => {
    setLoadingImages(false);
  };

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    requirement: '',
    businessType: '',
    location: '',
  });

  const sendEmail = async (e) => {
    e.preventDefault();

    // Validation logic here
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if any form field is empty
    const isEmpty = Object.values(formData).some(value => value === '');
    if (isEmpty) {
      toast.error(ErrorConstants.Error.ALL_FIELD_ERROR);
      return;
    }

    // Validate phone number
    if (!phoneRegex.test(formData.phone)) {
      toast.error(ErrorConstants.Error.PHONE_ERROR_MESSAGE);
      return;
    } else if (!emailRegex.test(formData.email)) {
      toast.error(ErrorConstants.Error.EMAIL_ERROR_MESSAGE);
      return;
    }

    try {
      // Call the function to send email
      const response = await commonService.sendDigitalMarketingGraphicDesigningEmail(formData);

      // Check if the response indicates success
      if (response.success) {
        // Display a success toast
        toast.success(SuccessConstant.Success.FORM_SUCCESS);

        // Reset the form data
        setFormData({
          name: '',
          email: '',
          phone: '',
          requirement: '',
          businessType: '',
          location: '',
        });
      } else {
        // Display an error toast for a failed submission
        toast.error(ErrorConstants.Error.FORM_ERROR_MESSAGE);
      }
    } catch (error) {
      // Handle any error during the request
      console.error('Error submitting form:', error);
      toast.error(ErrorConstants.Error.FORM_ERROR_MESSAGE);
    }
  };


  const previousWorks = [
    {
      title: 'Creative Event Design',
      description: 'Capturing the essence of people through striking portrait photography. Memorable moments in every frame.',
      imageUrl: IMAGE1,
    },
    {
      title: 'Modern Interior Design',
      description: 'Innovative interior designs that transform living spaces. Harmony and style in every room.',
      imageUrl: IMAGE2,
    },
    {
      title: 'Abstract Art Photography',
      description: 'Exploring abstract forms and colors in the world of photography. Unveiling artistry in everyday scenes.',
      imageUrl: IMAGE3,
    },
    {
      title: 'Logo Design',
      description: 'Crafting unique logos that symbolize brands. Graphic design expertise that leaves a lasting impression.',
      imageUrl: IMAGE4,
    },
    // Add more works with specific details and actual image URLs
  ];


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if ((name === 'name' || name === 'location' || name === 'businessType') && value.length > 50) {
      // Do not update state if the name, location, or businessType exceeds 50 characters
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handlePhoneInputChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 10); // Limit to 10 characters

    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: inputValue,
    }));


  };



  // Define an array of form fields for rendering
  const formFields = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'Name',
    },
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      placeholder: 'Email',
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'tel',
      placeholder: 'Phone',
    },
    {
      name: 'requirement',
      label: 'Requirement Type',
      type: 'text',
      placeholder: 'Your Requirement',
    },
    {
      name: 'businessType',
      label: 'Business Type',
      type: 'text',
      placeholder: 'Business Type',
    },
    {
      name: 'location',
      label: 'Location',
      type: 'text',
      placeholder: 'Location',
    },
    // Add more form fields as needed
  ];

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Implement logic to handle form submission with the formData state.
    // Example: Log form data to the console
  };

  return (
    <>
      <div className='bg-gray-100'>


        <div className=" p-4">
          <h2 className="text-xl min-[510px]:text-2xl text-center font-bold mb-4 text-gray-800">Digital Marketing / Graphic Designing</h2>

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {previousWorks.map((work, index) => (
              <div key={index} className=" flex justify-center items-center relative">
                <div className="">
                  {loadingImages && <div className="absolute inset-0 flex items-center justify-center"><Loader /></div>}
                  <img
                    src={work.imageUrl}
                    alt={work.title}
                    onLoad={handleImageLoad}
                    className="w-[300px] h-[300px]  rounded-md object-contain opacity-100 hover:opacity-100  "
                  />

                </div>
              </div>
            ))}
          </div>
        </div>

        <div className=' grid md:grid-cols-2 grid-cols-1 gap-2 p-2 pb-5 mb-5'>


          <div className=' flex flex-col justify-center items-center'>
            <div className='p-5 w-[100%] md:w-[70%]'>
              <form ref={formdm} onSubmit={sendEmail} className="mt-4 flex flex-col">
                <div className="flex flex-wrap -mx-4">
                  {formFields.map((field, index) => (
                    <div key={index} className={`mb-4 px-4 ${field.name === 'email' || field.name === 'phone' ? ' w-full md:w-1/2' : 'w-full'}`}>
                      <InputField
                        type={field.type}
                        label={field.label}
                        id={field.name}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={field.name === 'phone' ? handlePhoneInputChange : handleInputChange}
                        placeholder={field.placeholder}
                      />
                    </div>
                  ))}
                </div>
                <Button
                  type="submit"
                  style="primary"
                  disabled={buttonDisabled}
                >
                  Submit
                </Button>
              </form>
            </div>
            {/* Add any other UI elements and functionality here*/}
          </div>

          <div className=' flex flex-col justify-center  rounded-lg  order-1 md:order-2 py-20 md:py-5 px-2 md:px-10 lg:px-20'>
            <h1 className=' font-bold text-2xl text-violet-500 pl-2 sm:first-letter:indent-2'>Contact Us to Get Started ...</h1>
            <p className=' text-sm rounded-lg p-2 text-justify'>
              Transform your marketing strategy with our graphic designing and digital marketing assistance. Our experts will collaborate with you to create visually stunning designs and strategic digital marketing campaigns. Whether it's crafting eye-catching festival posts or developing a comprehensive growth strategy, we're here to amplify your brand's online presence and reach.
            </p>

          </div>
        </div>
      </div>
    </>
  )
}

export default DmGd
