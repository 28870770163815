import React from 'react';
import Blog from '../component/Blog/Blog';
const OurBlog = () => {

  return (
    <>
      <Blog />
    </>
  );
};

export default OurBlog;
