import React from "react";
import Button from "../../common/Button";

function ButtonsAccountSettings({ activeTab, handleTabChange }) {
  return (
    <div>
      <div className="flex w-full gap-1 sm:gap-2">
        <Button
          onClick={() => handleTabChange("general")}
          className={`w-full ${
            activeTab === "general"
              ? "bg-[#8A58DC] text-white"
              : "bg-gray-300 text-gray-700"
          }`}
          style="primary"
        >
          Personal Details
        </Button>
        <Button
          onClick={() => handleTabChange("business")}
          className={`w-full ${
            activeTab === "business"
              ? "bg-[#8A58DC] text-white"
              : "bg-gray-300 text-gray-700"
          }`}
          style="primary"
        >
          Business Details
        </Button>
        <Button
          onClick={() => handleTabChange("account")}
          className={`w-full ${
            activeTab === "account"
              ? "bg-[#8A58DC] text-white"
              : "bg-gray-300 text-gray-700"
          }`}
          style="primary"
        >
          Account Details
        </Button>
      </div>
    </div>
  );
}

export default ButtonsAccountSettings;
