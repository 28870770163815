import React from 'react';
import Button from '../common/Button';
import InputField from '../common/InputField';

function BillingForm({ formData, formErrors, handleInputChange, handleSubmit, closeForm }) {
    const inputFields = [
        { name: 'fullname', label: 'Full Name' },
        { name: 'phoneNumber', label: 'Mobile Number', maxLength: 10 },
        { name: 'flat', label: 'Flat/House No.' },
        { name: 'area', label: 'Area' },
        { name: 'landmark', label: 'Landmark' },
        { name: 'city', label: 'Town/City' },
        { name: 'pinCode', label: 'Pincode', maxLength: 6 },
        {
            name: 'state',
            label: 'State',
            type: 'select',
            options: [
                'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat',
                'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra',
                'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu',
                'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands',
                'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Lakshadweep', 'Delhi', 'Puducherry'
            ]
        }
    ];

    return (
        <form onSubmit={(e) => handleSubmit(e, inputFields)} className='border rounded-lg p-4 '>
            {inputFields.map((field, index) => (
                <div className='mb-4' key={index}>
                    <label className='block text-gray-700 text-sm font-bold mb-2'>
                        {field.label}
                    </label>
                    <InputField
                        type={field.type || 'text'}
                        id={field.name}
                        value={formData[field.name]}
                        onChange={handleInputChange}
                        maxLength={field.maxLength}
                        options={field.options}
                        placeholder={field.label}
                    />
                    {formErrors[field.name] && (
                        <p className='text-red-500 text-sm mt-2'>{formErrors[field.name]}</p>
                    )}
                </div>
            ))}
            <div className='flex gap-4 max-[380px]:w-full max-[767px]:w-fit min-[940px]:w-fit '>
                <Button style='secondary' variant='customizedPrices' type='submit'>Save</Button>
                <Button style='secondary' variant='addToCart' onClick={closeForm}>Cancel</Button>
            </div>
        </form>
    );
}

export default BillingForm;
