import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Loader from '../common/Loader';
import { API_URL } from '../../config/config';
import { FaUserAlt } from "react-icons/fa";

function SpecificBlog() {
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const { blogId } = useParams();

    useEffect(() => {
        const fetchBlog = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${API_URL}/api/admin-profile/blog/${blogId}`);
                setBlog(response.data.blog);
            } catch (error) {
                console.error('Error fetching specific blog:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlog();
    }, [blogId]); // Trigger fetchBlog when blogId changes

    if (loading) {
        return (
            <div className='flex justify-center items-center h-[500px]'>
                <Loader />
            </div>
        );
    }

    if (!blog) {
        return <div>No blog found for ID: {blogId}</div>;
    }

    const formattedDate = new Date(blog.createdAt).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    return (
        <div className='bg-gray-100'>
            <div className='flex flex-col gap-5 p-5 sm:p-12'>
                <h1 className='font-semibold text-3xl w-2/3  md:mb-10 p-4 md:p-10'>{blog.title}</h1>
                <div className='flex gap-3 pl-4 md:ml-10'>
                    <FaUserAlt />
                    <span>By The Ekaiv | {formattedDate} </span>
                </div>
                <div className='w-full flex justify-center items-center bg-white p-1 h-[500px]'>
                    <img src={blog.blogImage.url} alt="Blog Image" className='object-contain h-full w-full' />
                </div>
                <p className='p-4 md:p-10'>{blog.blogContent}</p>
            </div>
        </div>
    );
}

export default SpecificBlog;
