import axios from 'axios'
import { API_URL } from '../../../config/config';






const sendNumberOtp = async(userData) => {
    const response = await axios.post(`${API_URL}/api/otp-verfication/send-number-otp` , userData)
   
    return response.data
}


 


const verifyNumberOtp = async (userData) =>{
  
    const response = await axios.post(`${API_URL}/api/otp-verfication/verify-number-otp`,userData);
 
    return response.data;
}

// const signup=async(userData)=>{
//     const response = await axios.post(`${API_URL}/api/auth/signup/` , userData)
//     if(response.data){
//      localStorage.setItem('user' , response.data.user);
//     }
//     return response.data
// }

const otpService = {
    sendNumberOtp,
    verifyNumberOtp,
}

export default otpService