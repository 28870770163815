import React from 'react'
import SellerProfile from '../component/SellerProfile/SellerProfile'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Sellerprofile =  () => {
  const {vendorId} = useParams();

  return (
    <>
    <Helmet>
      <title>Seller Profile</title>
    </Helmet>
    <SellerProfile />
    

    </>
  )
}

export default Sellerprofile