import axios from 'axios';
import { API_URL } from '../../config/config'; // Adjust the import path as necessary

// Function to send an email
const sendBusinessConsultants = async (emailData) => {
    try {
        const response = await axios.post(`${API_URL}/api/email/sendBusinessConsultantsEmail`, emailData);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Failed to send email');
        }
    } catch (error) {
        console.error('Error sending email:', error);
        throw error; // Re-throw error so the calling code can handle it
    }
};

// Function to send email for CA assistance
const sendCAAssistanceEmail = async (formData) => {
    try {
        const response = await axios.post(`${API_URL}/api/email/sendCAAssistanceEmail`, formData);
        if (response.status === 200 && response.data.success) {
            return response.data; // Return the response data if successful
        } else {
            throw new Error(response.data.message || 'Failed to send email');
        }
    } catch (error) {
        console.error('Error sending email:', error);
        throw error; // Re-throw error so the calling code can handle it
    }
};

// Function to send email for Community Engagement
const sendCommunityEngagementEmail = async (formData) => {
    try {
        const response = await axios.post(`${API_URL}/api/email/sendCommunityEngagementEmail`, formData);
        // Check the API response and handle errors appropriately
        if (response.status === 200 && response.data.success) {
            return response.data; // Return the response data if successful
        } else {
            throw new Error(response.data.message || 'Failed to send email');
        }
    } catch (error) {
        console.error('Error sending email:', error);
        throw error; // Re-throw error so the calling code can handle it
    }
};




// Function to send email for Designs and Photography
const sendDesignNPhotographyEmail = async (formData) => {
    try {
        // Make an HTTP POST request to the API endpoint
        const response = await axios.post(`${API_URL}/api/email/sendDesignNPhotographyEmail`, formData);
        
        // Check if the response status is 200 and if the data contains a success flag
        if (response.status === 200 && response.data.success) {
            return response.data; // Return the response data if successful
        } else {
            // If the request was not successful, throw an error with a message from the response data
            throw new Error(response.data.message || 'Failed to send email');
        }
    } catch (error) {
        // Log any errors that occur
        console.error('Error sending email:', error);
        // Re-throw the error so the calling code can handle it
        throw error;
    }
};

// Function to send email for Digital Marketing / Graphic Designing
const sendDigitalMarketingGraphicDesigningEmail = async (formData) => {
    try {
        // Make an HTTP POST request to the API endpoint
        const response = await axios.post(`${API_URL}/api/email/sendGraphicDesigningEmail`, formData);
        
        // Check if the response status is 200 and if the data contains a success flag
        if (response.status === 200 && response.data.success) {
            return response.data; // Return the response data if successful
        } else {
            // If the request was not successful, throw an error with a message from the response data
            throw new Error(response.data.message || 'Failed to send email');
        }
    } catch (error) {
        // Log any errors that occur
        console.error('Error sending email:', error);
        // Re-throw the error so the calling code can handle it
        throw error;
    }
};



// Function to send an email for Training and Learning
const sendTrainingAndLearningEmail = async (formData) => {
   
    try {
        // Make a POST request to the API endpoint
        const response = await axios.post(`${API_URL}/api/email/sendTraningAndLearningEmail`, formData);
        
        // Check the API response and handle errors appropriately
        if (response.status == 200 && response.data.success) {
            return response.data; // Return the response data if successful
        } else {
            // console.log();
            // If the request was not successful, throw an error with a message from the response data
            throw new Error(response.data.message || 'Failed to send email');
        }
    } catch (error) {
    
        console.error('Error sending email:', error);
        // Re-throw the error so the calling code can handle it
        throw error;
    }
};

// Function to send an email for Legal Advisory
const sendLegalAdvisoryEmail = async (formData) => {
    try {
        // Make a POST request to the API endpoint
        const response = await axios.post(`${API_URL}/api/email/sendLegalAdvisoryEmail`, formData);
        
        // Check the API response and handle errors appropriately
        if (response.status === 200 && response.data.success) {
            return response.data; // Return the response data if successful
        } else {
            // If the request was not successful, throw an error with a message from the response data
            throw new Error(response.data.message || 'Failed to send email');
        }
    } catch (error) {
        console.error('Error sending email:', error);
        // Re-throw the error so the calling code can handle it
        throw error;
    }
};



// Function to send an email for Business Registrations
const sendBusinessRegistrationsEmail = async (formData) => {
    try {
        // Make a POST request to the API endpoint
        const response = await axios.post(`${API_URL}/api/email/sendBusinessRegistrationsEmail`, formData);

        // Check the API response and handle errors appropriately
        if (response.status === 200 && response.data.success) {
            return response.data; // Return the response data if successful
        } else {
            // If the request was not successful, throw an error with a message from the response data
            throw new Error(response.data.message || 'Failed to send email');
        }
    } catch (error) {
        console.error('Error sending email:', error);
        // Re-throw the error so the calling code can handle it
        throw error;
    }
}

// Function to send email for web/app/software development inquiries
const sendSAWDevEmail = async (formData) => {
    try {
        const response = await axios.post(`${API_URL}/api/email/sendSAWWebEmail`, formData);

        // Check if the response is successful
        if (response.status === 200 && response.data.success) {
            return response.data;
        } else {
            // Throw an error if the request was not successful
            throw new Error(response.data.message || 'Failed to send email');
        }
    } catch (error) {
        console.error('Error sending email:', error);
        throw error;
    }
};


// Function to fetch all orders from the API
const fetchAllOrders = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/order/getAllOrders`);
        return response.data;
    } catch (error) {
        console.error('Error fetching orders:', error);
        throw error;
    }
};
// Function to fetch product details by ID
const fetchProductById = async (productId) => {
    try {
        const response = await axios.get(`${API_URL}/api/Products/getProduct/${productId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching product details:', error);
        throw error;
    }
};




export default {
    sendBusinessConsultants,
    sendCAAssistanceEmail,
    sendCommunityEngagementEmail,
    sendDesignNPhotographyEmail,
    sendDigitalMarketingGraphicDesigningEmail,
    sendTrainingAndLearningEmail,
    sendLegalAdvisoryEmail,
    sendBusinessRegistrationsEmail,
    sendSAWDevEmail,
    fetchAllOrders,
    fetchProductById,
};
