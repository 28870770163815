import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { updateVendorAccount } from '../../../Redux/features/vendor/vendorSlices';
import { toast } from 'react-hot-toast'
import { Link } from 'react-router-dom';
import Button from '../../common/Button';
import ErrorConstants from '../../../constants/ErrorConstants';
import SuccessConstant from '../../../constants/SuccessConstant';

function AccountDetailsSettings() {
  const vendor = useSelector((state) => state.vendor);
  const dispatch = useDispatch();
const accountDetails = vendor?.vendor?.AccountDetais || {};

  const [allowOther, setAllowOther] = useState(null)
  const [accountHolderName, setAccountHolderName] = useState(accountDetails.AccountHolderName || "");
  const [accountNumber, setAccountNumber] = useState(accountDetails.AccountNumber || "");
  const [ifscCode, setIfscCode] = useState(accountDetails.IFSCCode || "");
  const [branch, setBranch] = useState(accountDetails.Branch || "");
  const [cifNumber, setCifNumber] = useState(accountDetails.CIFNumber || "");
  const [accCity, setAccCity] = useState(accountDetails.City || "");
  const [accState, setAccState] = useState(accountDetails.State || "");
  const [otherBusinessDocument, setOtherBusinessDocument] = useState(accountDetails.otherBusinessDocument || "");
  const [accountEditMode, setAccountEditMode] = useState(false);
  const [IFSCCodeError, setIFSCCodeError] = useState("");
  const [CIFNumberError, setCIFNumberError] = useState("");

  // Account Details State
  const handleAccountEditClick = () => setAccountEditMode(!accountEditMode);
 
  const handleAccountSaveClick = () => {
    let hasError = false;
    if (
      !accountHolderName ||
      !accountNumber ||
      !ifscCode ||
      !branch ||
      !cifNumber ||
      !accCity ||
      !accState ||
      !otherBusinessDocument
    ) {
      toast.error(ErrorConstants.Error.ALL_FIELD_ERROR);
      return;
    }
    if (allowOther === false) {
      toast.error(ErrorConstants.Error.BUSSINESS_DOC_FORMATE_ERROR)
      return
    }
    if (!isValidIFSCCode(ifscCode)) {
      toast.error(ErrorConstants.Error.INVALID_IFSC);
      return;
    }
    if (!isValidCIFNumber(cifNumber)) {
      toast.error(ErrorConstants.Error.INVALID_CIF);
      return;
    }
    if (!isValidAccountNumber(accountNumber)) {
      toast.error(ErrorConstants.Error.INVALID_ACCOUNT);
      return;
    }
    if (!hasError) {
    const updatedAccountDetails = {
      AccountHolderName: accountHolderName,
      AccountNumber: accountNumber,
      IFSCCode: ifscCode,
      Branch: branch,
      CIFNumber: cifNumber,
      City: accCity,
      State: accState,
      otherBusinessDocument: otherBusinessDocument,
    };
    dispatch(updateVendorAccount(updatedAccountDetails));
    setAccountEditMode(false);
    toast.success(SuccessConstant.Success.DETAILS_UPDATED);
  }
  };


    
  const isValidIFSCCode = (code) => {
    // Regular expression for IFSC code validation
    const ifscRegex = /^[A-Z]{4}[0-9A-Za-z]{7}$/;
    return ifscRegex.test(code);
  };

  const isValidCIFNumber = (number) => {
    // Regular expression for validating numbers only
    const numberRegex = /^[0-9]+$/;
    return numberRegex.test(number);
  };

  const isValidAccountNumber = (number) => {
    const numberRegex = /^\d{11,18}$/;
    return numberRegex.test(number);
};

  const handleAccountCancelClick = () => {
    setAccountEditMode(false);
    toast.error(ErrorConstants.Error.NO_CHANGE);
    // Reset the state to the original values
    const vendorDetails = vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.AccountDetais;
  if (vendorDetails) {
    setAccountHolderName(vendorDetails.AccountHolderName);
    setAccountNumber(vendorDetails.AccountNumber);
    setIfscCode(vendorDetails.IFSCCode);
    setBranch(vendorDetails.Branch);
    setCifNumber(vendorDetails.CIFNumber);
    setAccCity(vendorDetails.City);
    setAccState(vendorDetails.State);
    setOtherBusinessDocument(vendorDetails.otherBusinessDocument);
  }
  };

  const handleotherBusinessDocument = (event) => {
    const file = event.target.files[0];
    if (isPdfFile(file)) {
      checkAndTransformPdf(file, setOtherBusinessDocument, event);
      setAllowOther(true)
    } else {
      setAllowOther(false);
      toast.error(ErrorConstants.Error.PDF_FORMATE_ERROR);
      event.target.value = null;
      return;
    }
  };
  const isPdfFile = (file) => {
    return file && file.type === "application/pdf";
  };
  const transformPdf = (file, setPdfState) => {
    if (isPdfFile(file)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPdfState(reader.result);
      };
    } else {
      toast.error(ErrorConstants.Error.PDF_FORMATE_ERROR);
      return;
    }
  };

  const checkAndTransformPdf = (pdfFile, setPdfState, event) => {
    const maxSize = 1 * 1024 * 1024; // 1MB in bytes

    if (pdfFile.size > maxSize) {
      toast.error(ErrorConstants.Error.FILE_SIZE_ERROR);
      event.target.value = null;
      return;
    }
    if (!isPdfFile(pdfFile)) {
      toast.error(ErrorConstants.Error.Bussiness_PDF_ERROR);
      event.target.value = null;
      return;
    }
    transformPdf(pdfFile, setPdfState);
  };

  return (
    <div>
            <div className="account-details flex flex-col space-y-8 ">
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center mt-10">
                <label className="font-semibold md:w-48">Account Holder Name</label>
                {accountEditMode ? (
                  <input
                    type="text"
                    value={accountHolderName}
                    onChange={(e) => {
                      const inputElement = e.target;
                      const inputValue = e.target.value.toUpperCase();
                      const cursorPosition = inputElement.selectionStart; // Get the cursor position
                      const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '').slice(0, 30);
                      setAccountHolderName(sanitizedValue);
                      // Restore the cursor position
                      setTimeout(() => {
                        inputElement.setSelectionRange(cursorPosition, cursorPosition);
                      });
}}
                    maxLength={30}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                  />
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={accountHolderName}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
              </div>

              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">Account Number</label>
                {accountEditMode ? (
                  <input
                    type="text"
                    value={accountNumber}
                    onChange={(e) => {
                      // Allow only digits and limit the length to 18 characters
                      const sanitizedValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 18);
                      setAccountNumber(sanitizedValue);
                      isValidAccountNumber(sanitizedValue)
                    }}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    minLength={9}
                    maxLength={18}
                    pattern="[0-9]*"

                  />
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={accountNumber}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
              </div>
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">IFSC Code</label>
                {accountEditMode ? (
                  <input
                    type="text"
                    value={ifscCode}
                    maxLength={11}
                    onChange={(e) => {
                      const inputValue = e.target.value.toUpperCase();
                      setIfscCode(inputValue);

                      // Check if the entered value is a valid IFSC code
                      if (isValidIFSCCode(inputValue) || inputValue === "") {
                        setIFSCCodeError("");
                      } else {
                        setIFSCCodeError("Please enter a valid IFSC code (E.g.,  SBIN0005943).");
                      }
                    }}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                  />


                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={ifscCode}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
                <div className='flex flex-col md:flex-row gap-x-6 md:items-center'>
                  {IFSCCodeError && (
                    <p className="text-red-500">{IFSCCodeError}</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">Branch</label>
                {accountEditMode ? (
                  <input
                    type="text"
                    value={branch}
                    maxLength={20}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Allow only alphabets
                      const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '').slice(0, 20);
                      setBranch(sanitizedValue);
                    }}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                  />
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={branch}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
              </div>
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">CIF Number</label>
                {accountEditMode ? (
                  <input
                    type="text"
                    value={cifNumber}
                    maxLength={11}
                    onChange={(e) => {
                      const inputValue = e.target.value.toUpperCase();
                      setCifNumber(inputValue);

                      // Check if the entered value is valid (contains only numbers)
                      if (isValidCIFNumber(inputValue) || inputValue === "") {
                        setCIFNumberError("");
                      } else {
                        setCIFNumberError("Please enter your 11 digit CIF Number.");
                      }
                    }}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                  />
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={cifNumber}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
                <div className="flex flex-col md:flex-row gap-x-6 md:items-center">

                  {CIFNumberError && (
                    <p className="text-red-500">{CIFNumberError}</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">City</label>
                {accountEditMode ? (
                  <input
                    type="text"
                    value={accCity}
                    maxLength={30}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Allow only alphabets
                      const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '').slice(0, 30);
                      setAccCity(sanitizedValue);
                    }}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                  />
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={accCity}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
              </div>
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">State</label>
                {accountEditMode ? (
                  <input
                    type="text"
                    value={accState}
                    maxLength={30}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Allow only alphabets
                      const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '').slice(0, 30);
                      setAccState(sanitizedValue);
                    }}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                  />
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={accState}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
              </div>

              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">Bank Document</label>
                <div>
                  {accountEditMode ? (
                    <>
                      <Link to={otherBusinessDocument} target="_blank" rel="noopener noreferrer">
                        <button className="bg-[#8A58DC] text-white rounded-md p-2">View Document</button>
                      </Link>
                      <input className='px-10' type="file" accept=".pdf" onChange={handleotherBusinessDocument} />
                      {/* <button className="bg-green-500 text-white rounded-md p-2 ml-2">Confirm</button> */}
                    </>
                  ) : (
                    <Link to={otherBusinessDocument} target="_blank" rel="noopener noreferrer">
                      <Button style='primary'>View Document</Button>

                    </Link>
                  )}
                </div>
              </div>

               <div className='flex flex-row gap-4'>
                {accountEditMode ? (
                  <div>
                    <button
                      className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                      onClick={handleAccountSaveClick}
                    >
                      Save
                    </button>

                  </div>
                ) : (
                  <div>
                    <button
                      className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                      onClick={handleAccountEditClick} 
                    >
                      Edit
                    </button>
                  </div>
                )}
                {accountEditMode && (
                  <div>
                    <button
                      className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                      onClick={handleAccountCancelClick}
                    >
                      Cancel
                    </button>
                  </div>
                )}

              </div> 
            </div>
       
    </div>
  )
}

export default AccountDetailsSettings
