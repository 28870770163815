import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import ImageIcon from '@mui/icons-material/Image';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import { API_URL, config } from '../../../config/config';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CreateCategory from '../CreateCategory/CreateCategory';
import ProductVerify from '../Verify/ProductVerify';
import Pagination2 from '../../common/Pagination2';
import adminService from '../../../services/admin/adminService';



const ManageProduct = () => {
  const{fetchAllProducts,fetchAllVendors}=adminService
  const productsPerPage = 30;
  const [selectedItems, setSelectedItems] = useState({});
  const [data, setData] = useState([]);
  const [active, setActive] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedId, setSlectedId] = useState('');
  const [activeComponent, setActiveComponent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch products with business name and vendor information
        const productResponse = await fetchAllProducts();
        const vendorResponse = await  fetchAllVendors();

        // Combine product data with business name and vendor information
        const combinedData = productResponse.map((product) => {
          const vendorInfo = vendorResponse.find((vendor) => vendor._id === product.vendorId);
          return {
            ...product,
            businessName: vendorInfo?.BussinessDetails?.Businessname || '',
            vendorName: vendorInfo?.PersonalDetails?.name || '',
          };
        });

        setData(combinedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  const handleReject = async () => {
    try {
      // Check if any products are selected
      if (Object.keys(selectedItems).length === 0) {
        console.error('No products selected for rejection');
        return;
      }

      // Get an array of selected product IDs
      const selectedIds = Object.keys(selectedItems);

      // Create an array to store all the promises for rejection requests
      const rejectionPromises = selectedIds.map(async (productId) => {
        const response = await axios.put(
          `${API_URL}/api/admin-profile/products/${productId}/reject`
        );

      });

      // Wait for all rejection requests to complete
      await Promise.all(rejectionPromises);

      // After successful rejection, you might want to fetch updated products
      const updatedResponse = await axios.get(`/api/admin-profile/getAllProducts/superAdmin`);
      setData(updatedResponse.data);

    } catch (error) {
      console.error('Error rejecting products:', error);
    }
  };

  const handleOutStockSelected = () => {
    const newData = data.map((item) => {
      if (selectedItems[item.id]) {
        return { ...item, stock: 'Out Stock' };
      }
      return item;
    });
    setData(newData);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const newSelectedItems = {};
    data.forEach((item) => {
      newSelectedItems[item._id] = !selectAll; // Use item._id consistently
    });
    setSelectedItems(newSelectedItems);
  };

  const handleSelectItem = (itemId) => {
    const newSelectedItems = { ...selectedItems };
    newSelectedItems[itemId] = !newSelectedItems[itemId];
    setSelectedItems(newSelectedItems);
    setSlectedId(itemId)

  };

  const allSelected = Object.values(selectedItems).every((selected) => selected);

  const handleAddNewCategory = () => {
    setActiveComponent('CreateCategory');
  };

  const handleVerifyProduct = () => {
    setActiveComponent('ProductVerify');
  }

  // Calculate the total number of pages
  const totalPages = Math.ceil(data.length / productsPerPage);

  // Calculate the index of the first and last product to display on the current page
  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = Math.min(
    startIndex + productsPerPage,
    data.length
  );

  // Filter the products to display only the ones for the current page
  const displayedData = data.slice(startIndex, endIndex);

 // Function to handle page change
 const handlePageChange = (pageNumber) => {
  setCurrentPage(pageNumber);
  window.scrollTo(0, 0);
  scrollToActivePage(pageNumber);
};

const scrollToActivePage = (pageNumber) => {
  const paginationButton = document.getElementById(`page-${pageNumber}`);
  if (paginationButton) {
    paginationButton.scrollIntoView({ behavior: "smooth", inline: "center" });
  }
};

  return (
    <>
      <div className="p-4">
        <div className=" w-full  ">
          <div className="w-full block min-[400px]:hidden">
            <button
              onClick={handleAddNewCategory}
              className="bg-[#8A58DC] p-2 font-sm w-full text-white rounded-lg border-none font-semibold"
            >
              <FontAwesomeIcon icon={faPlus} /> Add New Category
            </button>
          </div>
          <div className="flex flex-row justify-between w-full ">
            <h2 className="text-lg md:text-xl lg:text-2xl pt-3 min-[400px]:pt-0">
              Product
            </h2>
            <div className="ml-auto hidden min-[400px]:block">
              <button
                onClick={handleAddNewCategory}
                className="bg-[#8A58DC] p-2 font-sm text-white rounded-lg border-none font-semibold"
              >
                <FontAwesomeIcon icon={faPlus} /> Add New Category
              </button>
            </div>
          </div>
        </div>
        {activeComponent === 'CreateCategory' ? (
          <CreateCategory setActiveComponent={setActiveComponent} />
        ) : activeComponent === 'ProductVerify' ? (
          <ProductVerify setActiveComponent={setActiveComponent} />
        ) : (
          // Show product table and pagination only when CreateCategory is not active
          <div className="mt-5 ">
            <div className="overflow-x-auto h-screen">
              <table className="w-full md:w-[100%] border rounded-lg ">
                <thead>
                  <tr className="flex font-poppins font-medium p-2 justify-between">
                    <th className="w-1/12"><ImageIcon className="" /></th>
                    <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">Product Name</th>
                    <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">Price</th>
                    <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">The Ekaiv Price</th>
                    <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">Business Name</th>
                    <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">Vendor</th>
                    <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">Status</th>
                    <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">Action</th>
                  </tr>
                </thead>
                <tbody className="bg-gray-100 ">
                  {displayedData.map((item) => (
                    <tr key={item._id} className="flex font-poppins font-medium p-2 justify-between">

                      <td className="w-1/12">
                        {item.images && item.images.length > 0 ? (
                          <img
                            src={item.images} // Assuming the first image is displayed
                            alt="product img"
                            className="w-24"
                          />
                        ) : (
                          <span>No Image</span>
                        )}
                      </td>
                      <td className="w-2/12 justify-center items-center ">
                        <div className="flex flex-col text-[#4779CC]">
                          <div className="text-center">{item.productName}</div>
                          <div>{item.name}</div>
                        </div>
                      </td>
                      <td className="w-2/12 ml-4">{item.discountPrice}</td>
                      <td className="w-2/12 ml-4" style={{
                        color:
                          item.status === 'Pending' ? 'orange' :
                            item.status === 'Rejected' ? 'red' :
                              item.status === 'Verified' && item.theEkaivDiscountPrice !== 0 ? 'black' : 'gray'
                      }}>
                        {item.status === 'Pending' ? 'Pending' :
                          item.status === 'Rejected' ? 'Rejected' :
                            item.status === 'Verified' && item.theEkaivDiscountPrice !== 0 ? item.theEkaivDiscountPrice :
                              ''}
                      </td>
                      <td className="w-2/12 text-[#4779CC] ml-8">{item?.businessName}</td>
                      <td className="w-2/12 ml-8">

                        <div className="mb-1 text-[#4779CC]">{item.vendorName}</div>

                      </td>


                      <td className={`w-2/12 ${item.status === 'Verified' ? 'text-green-500' : (item.status === 'Rejected' ? 'text-red-500' : 'text-orange-500')}`}>{item.status}</td>

                      <div className="w-full md:w-auto">
                        <Link to={`/product-verify/${item._id}`}>
                          <button className="w-full md:w-28 h-9 rounded-lg bg-[#3FBD6A] text-white font-poppins text-lg md:text-base lg:text-lg font-semibold tracking-wide"
                            // onClick={handleStockSelected}
                            onClick={handleVerifyProduct}
                          >
                            Edit
                          </button>
                        </Link>
                      </div>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>


            <div>
          <Pagination2
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          />
         </div>

          </div>

        )}
      </div>


    </>
  );
};

export default ManageProduct;
