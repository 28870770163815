import React from 'react';
import PropTypes from 'prop-types';

function TableComponent({ heads, data }) {
    return (
        <div className="table-container">
            <table className="table w-full table-auto border-collapse border border-gray-100">
                <thead>
                    <tr className="flex justify-between items-center w-full bg-gray-200 p-2">
                        {heads.map((head, index) => (
                            <th className="font-bold" key={index}>{head}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex} className='border-b border-gray-400'>
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex}>
                                    <div className="cell-content flex justify-between items-center p-2 w-full">{cell}</div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div >
    );
}

TableComponent.propTypes = {
    heads: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)).isRequired,
};

export default TableComponent;
