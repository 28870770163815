import React from 'react';

function CardComponent({ icon, title, value }) {
  return (
    <div className="w-full h-full">
      <div className="rounded-md shadow-md bg-white hover:shadow-lg h-[200px] flex items-center justify-center transition duration-300 ease-in-out cursor-pointer">
        <div className="p-4 flex justify-center items-center flex-col">
          <div className="flex items-center">
            {icon}
            <p className="text-xl sm:text-2xl font-bold text-[#737587] ml-2">{value}</p>
          </div>
          <p className="font-bold text-base sm:text-2xl text-center mt-2">{title}</p>
        </div>
      </div>
    </div>
  );
}

export default CardComponent;
