import React from 'react';

const ImagePreview = ({ imageUrl }) => {
  return (
    <div className="image-preview">
      {imageUrl ? (
        <img src={imageUrl} alt="Image not supported" className="preview-image" />
      ) : (
        <div className="placeholder">No Image Found</div>
      )}
    </div>
  );
};

export default ImagePreview;