const Success = {
    UPDATE_SUCCESS: "Updated Successfully",
    FORM_SUCCESS: 'Form submitted successfully',
    ITEM_ADDED_TO_CART: "Item added to cart successfully",
    ITEM_ADDED_TO_WISHLIST: "Item added to your wishlist",
    ITEM_REMOVED_FROM_WISHLIST: "Item removed from your wishlist",
    MAIL_SENT: "Mail sent successfully!",
    VENDOR_CREATED: "Vendor created successfully",
    CLIPBOARD: "Link copied to clipboard",
    CUSTOMIZATION_DETAILS_SUCCESS: "Customization details submitted successfully",
    CATEGORY_DELETE_SUCCESS: "Category deleted successfully!",
    CATEGORY_CREATED_SUCCESS: "Category created successfully!",
    PRICE_UPDATE_SUCCESS: "Vendor response and customized price updated successfully",
    PRICE_SUCCESS: "Customized price updated successfully",
    VENDOR_RESPONSE_SUCCESS: "Vendor response updated successfully",
    SAVE_SUCCESS: "Data saved successfully!",
    SUCCESS: "Product Verified sucessfully",
    PRODUCT_UPDATE_SUCCESS: "Product updated sucessfully",
    REJECT_SUCCESS: "Product Rejected Sucessfully",
    VENDOR_SUCCESS: "Vendor Verified sucessfully",
    VENDOR_REJECT: "Vendor Rejected successfully",
    OTP_SUCCESS: "OTP resent successfully!",
    OTP_NEW_PHONE_SUCCESS: 'OTP sent to the new mobile number or whatsApp successfully!',
    AVTAR_SUCCESS: "Vendor Avatar uploaded successfully!",
    OTP_SENT_SUCCESS: "OTP sent to existing mobile number or whatsApp successfully!",
    OTP_SENT_EMAIL_NEW_SUCCESS: "OTP sent to the new email successfully!",
    STATUS_SUCCESS: "Status changed successfully!",
    PRODUCT_SUCCESS: "Product created successfully!",
    OTP_SUCCESS: "OTP Verified successfully!",
    Inprocess: "Order status changed to 'In Process",
    InTransit: "Order status changed to 'In Transit",
    EditDiscard: "Changes discarded.",
    AddAddress: "Address added successfully",
    EditAddress: "Address edited Successfully",
    RemoveAddress: "Address removed successfully",
    COMMENT_SUCCESS : "Comment added successfully",
    DETAILS_UPDATED: "Details updated successfully",
    PAYMENT_SUCCESS: "'Payment successfully completed'"

}

export default { Success };