import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import AddressCard from './AddressCard';
import AddAddressForm from './AddAddressForm';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Button from '../common/Button';
import constant from "../../constants/ImageConstants"
import Loader from '../common/Loader';

const ROBOT_ADDRESS = constant.IMAGES_IMAGES.USER_PROFILE.ROBOT_ADDRESS;
const YourAddresses = () => {

  const { user } = useSelector((state) => state.auth);
  const [openAddressForm, openAddAddressForm] = useState(false);
  const [addressId, setAddressId] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Box sx={{ width: "100%", padding: "5px" }}>
        
          {loading ? (
            <div className="w-full mx-auto h-[200px] flex items-center justify-center">
              <Loader />
            </div>
          ) :(
          <>
          {(!(user && user.address.length > 0) && !openAddressForm) ? (
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "5px" }}>
            
            <img src={ROBOT_ADDRESS} onLoad={() => setImageLoaded(true)} width='30%' height='30%' />
            {!imageLoaded && (
              <div className="w-full mx-auto h-[200px] flex items-center justify-center">
                <Loader/>
              </div>
            )}
            
            <Typography
              sx={{
                fontSize: '28px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: 'normal',
                lineHeight: '25px',
                color: "#4C4C4C"
              }}>No Address found in your account!</Typography>
            <Typography
              sx={{
                fontSize: '26px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
                lineHeight: '25px',
                color: "#767575"
              }}>Add a Delivery Address</Typography>

          </Box>
        ) :
          (!openAddressForm && <AddressCard openAddAddressForm={openAddAddressForm} setAddressId={setAddressId} />)}
        {openAddressForm ? <AddAddressForm openAddAddressForm={openAddAddressForm} addressId={addressId} setAddressId={setAddressId} /> :
          (
            <div className='mt-4'>
              <Button style="primary"
                onClick={() => { openAddAddressForm(true) }}
              >
                Add Address
              </Button>
            </div>)}
          </>

        )}
        

      </Box>
    </>
  )
}

export default YourAddresses