import React, { useState, useRef, useEffect } from 'react'
import { toast } from 'react-hot-toast';
import commonService from '../../services/common/commonService';
import Loader from '../common/Loader';
import SuccessConstant from '../../constants/SuccessConstant';
import ErrorConstants from '../../constants/ErrorConstants';
import Button from '../common/Button';
import InputField from '../common/InputField';
import { API_URL } from '../../config/config';
import axios from 'axios';


function Learning() {
  //email.js

  const formlearn = useRef();
  const [phoneError, setPhoneError] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.phone) {
      toast.error('Please fill in all required fields.');
      return;
    }
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(formData.phone)) {

      toast.error(ErrorConstants.Error.PHONE_ERROR_MESSAGE);
      return;
    }
    try {
      // Call the function to send email and await the response
      const response = await commonService.sendTrainingAndLearningEmail(formData);

      // Handle success response
      toast.success(SuccessConstant.Success.FORM_SUCCESS);

      // Clear the form data after successful submission
      setFormData({
        name: '',
        phone: '',
      });
    } catch (error) {
      // Handle error
      toast.error(ErrorConstants.Error.FORM_ERROR_MESSAGE);
    }
  };

  const [formData, setFormData] = useState({
    name: '',
    phone: '',

  });

  const inputFieldsData = [
    { id: "name", name: "name", label: "Name", type: "text", placeholder: "Name", value: formData.name },
    { id: "phone", name: "phone", label: "Phone Number", type: "tel", placeholder: "Phone Number", value: formData.phone, error: phoneError },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'name' && value.length > 50) {
      // Do not update state if the name, location, or businessType exceeds 50 characters
      return;
    }
    // Ensure only digits are entered into the phone number field
    if (name === 'phone') {
      const numericValue = value.replace(/\D/g, '').slice(0, 10); // Remove non-digit characters
      setFormData({
        ...formData,
        [name]: numericValue,
      });


    } else {
      // Update formData with the new input value
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };



  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Implement logic to handle form submission with the formData state.
    // Example: Log form data to the console
  };

  const [ TrainingImage, setTrainingImage] = useState('')
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/assistance/getTraining`)
        setTrainingImage(response.data.TrainingImage.imageUrl.url)

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className=' bg-gray-100 p-2 sm:p-10'>

        <div className=' w-full flex flex-col justify-center items-center'>
          <h2 className="text-xl min-[510px]:text-2xl font-bold mb-4 text-gray-800">Training & Development</h2>
          <p className=' rounded-lg py-5 px-7 text-justify text-sm'>
            Enhance your entrepreneurial skills through our communication and learning programs. Whether you're looking to improve your language proficiency, master the art of effective communication, or learn the ropes of selling on The Ekaiv, our resources are designed to empower you with the knowledge and skills needed for success in the dynamic world of business.
          </p>
        </div>
        <div className=' grid md:grid-cols-2 grid-cols-1 gap-2 p-2'>
          <div className=' flex flex-col justify-center items-center' >
            <div className="p-5 w-[100%] md:w-[70%]">
              <form ref={formlearn} onSubmit={sendEmail} className="mt-4 flex flex-col gap-4">
                {inputFieldsData.map((field, index) => (
                  <InputField
                    key={index}
                    label={field.label}
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    value={field.value}
                    placeholder={field.placeholder}
                    onChange={handleInputChange}
                  />
                ))}
                <Button
                  type="submit"
                  style="primary"
                  disabled={buttonDisabled}
                >
                  Get Started
                </Button>
              </form>
            </div>
            {/* Add any other UI elements and functionality here */}
          </div>
          <div className='  flex justify-center items-center py-20 md:py-0 px-10 lg:px-20'>

            <img
              src={TrainingImage}
              alt="Banner"
              loading='lazy-loading'
              onLoad={() => setImageLoaded(true)}
            />
            {!imageLoaded && (
              <div className='flex items-center justify-center md:h-[610px] sm:h[400px] h-[210px]'>
                <Loader />
              </div>
            )}
          </div>



        </div>
      </div>

    </>
  )
}

export default Learning
