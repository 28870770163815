import React, { useState, useEffect, useMemo } from 'react';
import Loader from "../../component/common/Loader";
import VendorCard2 from './VendorCard2';
import Button from '../common/Button';

const VendorCard = ({ vendor }) => {

  const [hovered, setHovered] = useState(false);
  const ShopAvatarImage = vendor.ShopAvatar?.url;
  const businessName = vendor && vendor.BussinessDetails && vendor.BussinessDetails.Businessname;
  const state = vendor && vendor.BussinessDetails && vendor.BussinessDetails.BusinessAddress && vendor.BussinessDetails.BusinessAddress.state
  const typeOfBusiness = vendor && vendor.BussinessDetails && vendor.BussinessDetails.typeOfBusiness


  return (
    <div
      className="relative w-full shadow-md rounded-lg cursor-pointer hover:scale-105 hover:shadow-xl transition-transform duration-300"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="flex justify-center items-center overflow-hidden rounded-t-lg">
        {ShopAvatarImage ? (
          <img
            src={ShopAvatarImage}
            alt="ShopAvatar Image"
            className={`w-full object-cover h-[200px] shadow-md transition-opacity duration-300 ${hovered ? 'opacity-75' : 'opacity-100'}`}
          />
        ) : (
          <img
            src="https://khatricreations.com/pub/media/wysiwyg/Khatri_Sarees_banner_2_1.jpg"
            alt="Default Image"
            className={`w-full object-cover h-[200px] shadow-md  transition-opacity duration-300 ${hovered ? 'opacity-75' : 'opacity-100'}`}
          />
        )}
      </div>
      <div className={`absolute bottom-0 left-0 right-0 bg-gradient-to-b from-transparent to-[#fff] text-black p-4 opacity-0 transition-opacity duration-300 ${hovered ? 'opacity-100' : 'opacity-0'}`}>
        <h2 className="text-xl font-semibold mt-4">{businessName}</h2>
        <h2 className="text-sm text-gray-500 mt-4">{state?.toUpperCase()}</h2>
        <p className="text-[#8A58DC] mt-2 text-lg flex-wrap">
          {typeOfBusiness?.map((category, index) => (
            <React.Fragment key={index}>
              {index > 0 && ', '}
              {category.replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}
            </React.Fragment>
          ))}
        </p>
      </div>
    </div>
  );
};

const VendorsList = ({ vendors }) => {
  const [visibleVendors, setVisibleVendors] = useState(8);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!Array.isArray(vendors)) return;

    // Simulate API delay
    const fetchData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false); // Set loading to false after data is loaded
      } catch (error) {
        console.error('Error loading data:', error);
        setLoading(false); // Set loading to false in case of an error
      }
    };

    fetchData();
  }, [vendors]);

  const visibleVendorsList = useMemo(() => {
    return vendors?.slice(0, visibleVendors) || [];
  }, [vendors, visibleVendors]);


  const loadMore = async () => {


    try {
      // Simulate API delay
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setVisibleVendors((prevVisible) => prevVisible + 8);
      setLoading(false);
    } catch (error) {
      console.error('Error loading more data:', error);
      setLoading(false);
    }
  };

  return (
    <div className="">
      {!loading && vendors.length === 0 && (
        <div className='flex flex-col justify-center items-center'>
          <h1 className='text-[#8A58DC] overflow-hidden whitespace-nowrap text-center text-3xl p-5 font-bold'>Vendors Not Available.</h1>
          <img
            className='h-[450px]'
            src="https://img.freepik.com/free-vector/boycott-abstract-concept-vector-illustration-political-program-consumer-activism-collective-behavior-cancel-culture-moral-purchasing-solidarity-action-public-protest-abstract-metaphor_335657-4199.jpg?size=626&ext=jpg&ga=GA1.1.65071757.1704699075&semt=ais" 
            loading='lazy'
            alt="Ekaiv" />
        </div>

      )}

      {!loading && vendors.length > 0 && (
        <div className="flex flex-wrap w-full items-center justify-evenly gap-20">
          {visibleVendorsList.map((vendor) => (
            <div key={vendor.id} className="justify-center items-center flex">
              <VendorCard2 vendor={vendor} />
            </div>
          ))}
        </div>
      )}
      {loading && (
        <div className="flex flex-col gap-2 justify-center items-center mt-4">
          <Loader />
        </div>
      )}
      {!loading && visibleVendors < (vendors?.length || 0) && (

        <div className='  md:mt-20 w-full flex justify-center items-center mt-8'>
          <Button
            style="primary"
            onClick={loadMore}
          >
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

export default VendorsList;