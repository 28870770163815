import { Box, Menu, MenuItem, Typography } from "@mui/material";
import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import Fade from "@mui/material/Fade";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import adminService from "../../services/admin/adminService";

import Button from "../common/Button";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [openAllCategories, setOpenAllCategories] = useState(false);
  const [openSupport, setOpenSupport] = useState(false);
  const users = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [hoverLink, setHoverLink] = useState("");
  const [isHovered, setIsHovered] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const navRef = useRef(null);

  const handleCategoriesClick = (event) => {
    setActiveLink("All Categories");
    setAnchorEl(event.currentTarget);
  };
  const handleCategoriesClose = () => {
    setAnchorEl(null);
  };

  const [anchor, setAnchor] = React.useState(null);
  const open2 = Boolean(anchor);

  const handleSupportClick = (event) => {
    setActiveLink("Support");
    setAnchor(event.currentTarget);
  };

  const handleSupportClose = () => {
    setAnchor(null);
    setActiveLink("Support"); // Set activeLink to "Support" when the support dropdown is closed
  };

  const handleLinkHover = (link) => {
    setHoverLink(link);
  };

  const openMenuItems = () => {
    setOpenMenu(!openMenu);
  };

  let Links = useMemo(
    () => [
      { name: "Home", link: "/" },
      { name: "Catalogue", link: "/catalogue" },
      { name: "Products", link: "/products" },
      { name: "Assistance", link: "/assistance" },
    ],
    []
  );

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const navigate = useNavigate();

  //navigate the selected category to product page
  const [categoriesData, setCategoriesData] = useState([]);
  const [isClothingSubcategoriesOpen, setIsClothingSubcategoriesOpen] =
    useState(false);
  const [isKidsSubcategoriesOpen, setIsKidsSubcategoriesOpen] = useState(false);

  const handleCategorySelect = (
    categoryName,
    subcategoryName = "",
    subsubcategories
  ) => {
    // Construct the selected item based on whether a subcategory is provided
    const selectedItem = subcategoryName ? subcategoryName : categoryName;
    const selectedSubCategory = subsubcategories
      ? subsubcategories
      : selectedItem;
    if (categoryName === "clothing") {
      setIsClothingSubcategoriesOpen((prev) => !prev);
    } else if (categoryName === "kids") {
      setIsKidsSubcategoriesOpen((prev) => !prev);
    
    } else {
      if (selectedCategory === selectedSubCategory) {
        setSelectedCategory("");
      } else {
        setSelectedCategory(selectedSubCategory);
        handleCategoriesClose();
        navigate("/products", {
          state: {
            searchItem: selectedSubCategory,
          },
        });
      }
    }
    
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await adminService.fetchCategoriesData();
        setCategoriesData(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchData();
  }, []);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    },
    [setOpenMenu]
  );

  const debouncedHandleClickOutside = useCallback(
    debounce(handleClickOutside, 100),
    [handleClickOutside]
  );

  useEffect(() => {
    document.addEventListener("mousedown", debouncedHandleClickOutside);

    return () => {
      document.removeEventListener("mousedown", debouncedHandleClickOutside);
    };
  }, [debouncedHandleClickOutside]);

  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  return (
    <>
      <div ref={navRef}>
        <Box
          sx={{
            position: "sticky", // Add this line
            top: 0, // Add this line
            zIndex: 20, // Add this line
            width: "100%",
            height: "50px",
            padding: "10px 5px",
            fontSize: { lg: "1.1rem", md: "1rem", sm: "1rem" },
            color: "#ffffff",
          }}
          className={`flex shadow-gray-400 shadow-md items-center ${
            screenSize.width <= 900 ? "justify-end" : "justify-start"
          } bg-gradient-to-r from-[#F16080] to-[#5156E9]`}
        >
          {screenSize.width <= 900 && (
            <MenuIcon
              fontSize="large"
              onClick={openMenuItems}
              style={{
                cursor: "pointer",
                transitionDelay: "2s",
                position: "absolute",
                left: "10px",
              }}
            />
          )}
          <Box
            className={`flex flex-row gap-5 items-center w-[100%] justify-start`}
            style={{ display: screenSize.width <= 900 ? "none" : "flex" }}
          >
            <Box
              className={`font-light cursor-pointer pl-2`}
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleCategoriesClick}
            >
              <MenuIcon /> All Categories
            </Box>
            <div className="flex flex-1 items-center justify-center gap-[1rem]">
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCategoriesClose}
                TransitionComponent={Fade}
                sx={{
                  "&": { marginTop: "12px" },
                  borderRadius: "0 0 1px 1px !important",
                  maxHeight: isKidsSubcategoriesOpen ? 'none' : '500px', // Adjust maxHeight based on isKidsSubcategoriesOpen
    overflowY: isKidsSubcategoriesOpen ? 'visible' : 'auto', // Adjust overflowY based on isKidsSubcategoriesOpen
                }}
              >
                <>
                  {categoriesData?.length > 0 &&
                    categoriesData?.map((category) => {
                      // Capitalize the first letter of each word and replace hyphens with space
                      const categoryName = category.name
                        .split("-")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ");

                      const formattedCategoryName =
                        categoryName === "Home Deocr"
                          ? "Home Decor"
                          : categoryName;

                      return (
                        <div
                          className="max-w-[150px] w-[max-content] p-2"
                          key={category._id}
                        >
                          <li
                            className={`cursor-pointer text-start whitespace-nowrap relative group transition-all duration-300 pb-2 text-gray-800 ${
                              selectedCategory === category.name
                                ? "font-bold"
                                : ""
                            }`}
                            onMouseEnter={() => setIsHovered(category.name)}
                            onMouseLeave={() => setIsHovered("")}
                            onClick={() => handleCategorySelect(category.name)}
                          >
                            {formattedCategoryName}
                            {category.name === "clothing" && (
                              <span className="absolute  top-[43%] transform -translate-y-1/2">
                                {isClothingSubcategoriesOpen ? (
                                  <IoIosArrowUp />
                                ) : (
                                  <IoIosArrowDown />
                                )}
                              </span>
                            )}
                            <div
                              className={`absolute inset-x-0 bottom-0 h-0.5 bg-gray-800 transition-all duration-500 transform origin-bottom ${
                                isHovered === category.name
                                  ? "scale-x-100"
                                  : "scale-x-0"
                              }`}
                            />
                          </li>

                          {isClothingSubcategoriesOpen &&
                            category.subcategories &&
                            category.subcategories.length > 0 && (
                              <ul>
                                {category.subcategories.map((subcategory) => {
                                  const subCategoryName =
                                    subcategory.name.charAt(0).toUpperCase() +
                                    subcategory.name.slice(1);

                                  return (
                                    <div key={subcategory._id}>
                                      <li
                                        key={subcategory._id}
                                        onMouseEnter={() =>
                                          setIsHovered(subcategory.name)
                                        }
                                        onMouseLeave={() => setIsHovered("")}
                                        onClick={() =>
                                          handleCategorySelect(subcategory.name)
                                        }
                                        className={`cursor-pointer text-start whitespace-nowrap relative group transition-all duration-300 py-4 text-gray-800 ${
                                          selectedCategory === subcategory.name
                                            ? "font-bold"
                                            : ""
                                        }`}
                                      >
                                        {subCategoryName}
                                        {subcategory.name === "kids" && (
                                          <span className="absolute  top-[43%] transform -translate-y-1/2">
                                            {isKidsSubcategoriesOpen ? (
                                              <IoIosArrowUp />
                                            ) : (
                                              <IoIosArrowDown />
                                            )}
                                          </span>
                                        )}
                                        <div
                                          className={`absolute inset-x-0 bottom-0 h-0.5 bg-gray-800 transition-all duration-500 transform origin-bottom ${
                                            isHovered === subcategory.name
                                              ? "scale-x-100"
                                              : "scale-x-0"
                                          }`}
                                        />
                                      </li>
                                    </div>
                                  );
                                })}
                                {isKidsSubcategoriesOpen &&
                                  category &&
                                  category.subcategories &&
                                  category.subcategories.length > 0 && (
                                    <ul>
                                      {category.subcategories.map(
                                        (subcategory) => (
                                          <div key={subcategory._id}>
                                            <ul>
                                              {subcategory.subsubcategories &&
                                                subcategory.subsubcategories
                                                  .length > 0 &&
                                                subcategory.subsubcategories.map(
                                                  (subsubcategory) => (
                                                    <li
                                                      key={subsubcategory._id}
                                                      onMouseEnter={() =>
                                                        setIsHovered(
                                                          subsubcategory.name
                                                        )
                                                      }
                                                      onMouseLeave={() =>
                                                        setIsHovered("")
                                                      }
                                                      onClick={() =>
                                                        handleCategorySelect(
                                                          subsubcategory.name
                                                        )
                                                      }
                                                      className={`cursor-pointer text-start whitespace-nowrap relative group transition-all duration-300 py-4 text-gray-800zz ${
                                                        selectedCategory ===
                                                        subsubcategory.name
                                                          ? "font-bold"
                                                          : ""
                                                      }`}
                                                    >
                                                      {subsubcategory.name}
                                                    </li>
                                                  )
                                                )}
                                            </ul>
                                          </div>
                                        )
                                      )}
                                    </ul>
                                  )}
                              </ul>
                            )}
                        </div>
                      );
                    })}
                </>
              </Menu>

              {Links.map((navItem) => (
                <Link
                  to={navItem.link}
                  key={navItem.name}
                  onClick={() => handleLinkClick(navItem.link)}
                >
                  <Box
                    key={navItem.name}
                    className={`font-light transition-colors cursor-pointer duration-400 delay-75 hover:text-white relative inline-block ${
                      activeLink === navItem.link || hoverLink === navItem.link
                        ? "text-white"
                        : ""
                    }`}
                    onMouseEnter={() => handleLinkHover(navItem.link)}
                    onMouseLeave={() => handleLinkHover("")}
                  >
                    {navItem.name}
                    {(activeLink === navItem.link ||
                      hoverLink === navItem.link) && (
                      <div className="absolute bottom-0 left-0 w-full h-0.5 bg-white mt-1"></div>
                    )}
                  </Box>
                </Link>
              ))}

              <Box
                className={`font-light cursor-pointer hover:text-white relative`}
                id="support-button"
                aria-controls={open2 ? "support-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? "true" : undefined}
                onClick={handleSupportClick}
                onMouseEnter={() => handleLinkHover("Support")}
                onMouseLeave={() => handleLinkHover("")}
              >
                Support
                {open2 ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                {(hoverLink === "Support" || open2) && (
                  <div className="absolute bottom-0 left-0 w-full h-0.5 bg-[#fff]"></div>
                )}
              </Box>

              <Menu
                id="support-menu"
                MenuListProps={{
                  "aria-labelledby": "support-button",
                }}
                anchorEl={anchor}
                open={open2}
                onClose={handleSupportClose}
                TransitionComponent={Fade}
                sx={{
                  "&": { marginTop: "12px" },
                  borderRadius: "0 0 1px 1px !important",
                }}
              >
                <Link
                  to="/faq"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <MenuItem onClick={handleSupportClose}>FAQ's</MenuItem>
                </Link>

                <Link
                  to="/contact-us"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <MenuItem onClick={handleSupportClose}>Contact Us</MenuItem>
                </Link>
                <Link
                  to="/live-chat"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <MenuItem onClick={handleSupportClose}>Live Chat</MenuItem>
                </Link>
              </Menu>

              {users.user && users.user.role === "vendor" ? (
                <Link to="/vendor-profile">
                  <Button style="fourth">
                    Go to Seller Dashboard <ChevronRightIcon />
                  </Button>
                </Link>
              ) : users.user && users.user.role === "admin" ? (
                <Link to="/admin-profile">
                  <Button style="fourth">
                    Go to admin Dashboard <ChevronRightIcon />
                  </Button>
                </Link>
              ) : (
                <Link to="/become-seller">
                  <Button style="fourth">
                    Become a Seller <ChevronRightIcon />
                  </Button>
                </Link>
              )}
            </div>
          </Box>
        </Box>

        {/* SideNavigation */}
        {screenSize.width <= 900 && openMenu && (
          <div
            className={`w-[250px] fixed h-full  top-0 shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)] z-50  bg-[#fdfdfd] transform transition-transform duration-300 ease-in-out ${
              openMenu ? "translate-x-0" : "-translate-x-[400px]"
            }`}
          >
            <div
              className={`w-full h-full flex flex-col relative justify-start items-center pt-20`}
            >
              <CloseIcon
                onClick={openMenuItems}
                sx={{
                  position: "absolute",
                  cursor: "pointer",
                  right: "20px",
                  top: "20px",
                  color: "#222",
                }}
                fontSize="large"
              />
              <ul className="text-[#222] flex flex-col gap-5 font-light text-lg">
                <li onClick={() => setOpenAllCategories(!openAllCategories)}>
                  All Categories{" "}
                  {openAllCategories ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </li>
                <div
                  className={`${
                    openAllCategories
                      ? "flex flex-col gap-3 justify-start items-start"
                      : "hidden"
                  }`}
                >
                  {categoriesData?.length > 0 &&
                    categoriesData?.map((category) => {
                      // Capitalize the first letter of each word and replace hyphens with space
                      const categoryName = category.name
                        .split("-")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ");

                      const formattedCategoryName =
                        categoryName === "Home Deocr"
                          ? "Home Decor"
                          : categoryName;

                      return (
                        <div
                          className="max-w-[150px] w-[max-content] p-2"
                          key={category._id}
                        >
                          <li
                            className={`cursor-pointer text-start whitespace-nowrap relative group transition-all duration-300 pb-2 text-gray-800 ${
                              selectedCategory === category.name
                                ? "font-bold"
                                : ""
                            }`}
                            onMouseEnter={() => setIsHovered(category.name)}
                            onMouseLeave={() => setIsHovered("")}
                            onClick={() => handleCategorySelect(category.name)}
                          >
                            {formattedCategoryName}
                            {category.name === "clothing" && (
                              <span className="absolute  top-[43%] transform -translate-y-1/2">
                                {isClothingSubcategoriesOpen ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </span>
                            )}
                            <div
                              className={`absolute inset-x-0 bottom-0 h-0.5 bg-gray-800 transition-all duration-500 transform origin-bottom ${
                                isHovered === category.name
                                  ? "scale-x-100"
                                  : "scale-x-0"
                              }`}
                            />
                          </li>

                          {isClothingSubcategoriesOpen &&
                            category.subcategories &&
                            category.subcategories.length > 0 && (
                              <ul>
                                {category.subcategories.map((subcategory) => {
                                  const subCategoryName =
                                    subcategory.name.charAt(0).toUpperCase() +
                                    subcategory.name.slice(1);

                                  return (
                                    <div key={subcategory._id}>
                                      <li
                                        key={subcategory._id}
                                        onMouseEnter={() =>
                                          setIsHovered(subcategory.name)
                                        }
                                        onMouseLeave={() => setIsHovered("")}
                                        onClick={() =>
                                          handleCategorySelect(subcategory.name)
                                        }
                                        className={`cursor-pointer text-start whitespace-nowrap relative group transition-all duration-300 py-4 text-gray-800 ${
                                          selectedCategory === subcategory.name
                                            ? "font-bold"
                                            : ""
                                        }`}
                                      >
                                        {subCategoryName}
                                        {subcategory.name === "kids" && (
                                          <span className="absolute  top-[43%] transform -translate-y-1/2">
                                            {isKidsSubcategoriesOpen ? (
                                              <IoIosArrowUp />
                                            ) : (
                                              <IoIosArrowDown />
                                            )}
                                          </span>
                                        )}
                                        <div
                                          className={`absolute inset-x-0 bottom-0 h-0.5 bg-gray-800 transition-all duration-500 transform origin-bottom ${
                                            isHovered === subcategory.name
                                              ? "scale-x-100"
                                              : "scale-x-0"
                                          }`}
                                        />
                                      </li>
                                    </div>
                                  );
                                })}
                                {isKidsSubcategoriesOpen &&
                                  category &&
                                  category.subcategories &&
                                  category.subcategories.length > 0 && (
                                    <ul>
                                      {category.subcategories.map(
                                        (subcategory) => (
                                          <div key={subcategory._id}>
                                            <ul>
                                              {subcategory.subsubcategories &&
                                                subcategory.subsubcategories
                                                  .length > 0 &&
                                                subcategory.subsubcategories.map(
                                                  (subsubcategory) => (
                                                    <li
                                                      key={subsubcategory._id}
                                                      onMouseEnter={() =>
                                                        setIsHovered(
                                                          subsubcategory.name
                                                        )
                                                      }
                                                      onMouseLeave={() =>
                                                        setIsHovered("")
                                                      }
                                                      onClick={() =>
                                                        handleCategorySelect(
                                                          subsubcategory.name
                                                        )
                                                      }
                                                      className={`cursor-pointer text-start whitespace-nowrap relative group transition-all duration-300 py-4 text-gray-800 ${
                                                        selectedCategory ===
                                                        subsubcategory.name
                                                          ? "font-bold"
                                                          : ""
                                                      }`}
                                                    >
                                                      {subsubcategory.name}
                                                    </li>
                                                  )
                                                )}
                                            </ul>
                                          </div>
                                        )
                                      )}
                                    </ul>
                                  )}
                              </ul>
                            )}
                        </div>
                      );
                    })}
                </div>
                {Links.map((e, index) => {
                  return (
                    <Link to={e.link} key={index} onClick={openMenuItems}>
                      <li className="transition-colors duration-400 delay-75 hover:text-[#222]">
                        {e.name}
                      </li>
                    </Link>
                  );
                })}
                <li onClick={() => setOpenSupport(!openSupport)}>
                  Support{" "}
                  {openSupport ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                </li>
                <div
                  className={`${
                    openSupport
                      ? "flex flex-col gap-3 justify-center items-center"
                      : "hidden"
                  }`}
                >
                  <ul className="flex flex-col gap-3 pl-5 ">
                    <Link to="/faq" onClick={openMenuItems}>
                      <li className=" cursor-pointer transition-colors duration-400 delay-75 hover:text-[#222] ">
                        FAQ's
                      </li>
                    </Link>
                    <Link to="/contact-us" onClick={openMenuItems}>
                      <li className=" cursor-pointer transition-colors duration-400 delay-75 hover:text-[#222]">
                        Contact Us
                      </li>
                    </Link>
                    <Link to="/live-chat" onClick={openMenuItems}>
                      <li className=" cursor-pointer transition-colors duration-400 delay-75 hover:text-[#222]">
                        Live Chat
                      </li>
                    </Link>
                  </ul>
                </div>
                {users.user && users.user.role === "vendor" ? (
                  <Link to="/vendor-profile" onClick={openMenuItems}>
                    <Button>
                      Go to Seller Dashboard <ChevronRightIcon />
                    </Button>
                  </Link>
                ) : users.user && users.user.role === "admin" ? (
                  <Link to="/admin-profile" onClick={openMenuItems}>
                    <Button>
                      Go to admin Dashboard <ChevronRightIcon />
                    </Button>
                  </Link>
                ) : (
                  <Link to="/become-seller" onClick={openMenuItems}>
                    <Button>
                      Become a Seller <ChevronRightIcon />
                    </Button>
                  </Link>
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
