import axios from 'axios'
import {API_URL , config} from '../../../config/config';




const getOrder = async (data) => {
    try{
        const response = await axios.get(`${API_URL}/api/order/getAllOrders` , )
        if(response.data ) { 
            localStorage.setItem('order' , JSON.stringify(response.data.order));
        }
        return response.data
    }
    catch(error) {
    
            // Handle error appropriately (e.g., log it, show a user-friendly message)
            console.error('Error in getOrders:', error);
            throw error; //Rethrow the error to be handled at a higher level if needed
    }
}


const orderService = {
    getOrder,
}

export default orderService;