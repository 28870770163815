import React, { useState, useEffect } from 'react';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import { BiSort } from 'react-icons/bi';
import { API_URL, config } from '../../../config/config';
import { useSelector } from 'react-redux';
import axios from 'axios';
import CardComponent from '../../common/CardComponent';
import commonService from '../../../services/common/commonService';


export default function VendorSales() {
  const vendor = useSelector((state) => state.vendor);
  const loggedInVendorId = vendor && vendor.vendor && vendor.vendor._id;

  const [productSoldCount, setProductSoldCount] = useState(0);
  const [totalOrderCount, setTotalOrderCount] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [newOrdersCount, setNewOrdersCount] = useState(0);

  useEffect(() => {
    const fetchNewOrdersCount = async () => {
      try {
        const response = await commonService.fetchAllOrders();
        const orders = response;
        
        // Filter orders based on vendor ID
        const vendorOrders = orders.filter(order => 
          order.paymentStatus === 'completed' &&
          order.productsDetails.some(product => product.vendorId === loggedInVendorId)
        );
        setTotalOrderCount(vendorOrders.length);

        // Calculate product sold count (assuming status 'Completed')
        const soldOrders = vendorOrders.filter(order => order.orderStatus === 'Completed');
        setProductSoldCount(soldOrders.length);

        // Calculate new orders count including 'Completed', 'InTransit', and 'InProcess'
        const relevantStatuses = ['NewOrders', 'Completed', 'InTransit', 'InProcess'];
        const newOrders = vendorOrders.filter(order => relevantStatuses.includes(order.orderStatus));
        setNewOrdersCount(newOrders.length);

        // Calculate total earnings for orders with status 'Completed'
        const completedOrders = vendorOrders.filter(order => order.orderStatus === 'Completed');
        const totalEarnings = completedOrders.reduce((total, order) => total + order.totalAmount, 0);
        setTotalEarnings(totalEarnings);
        console.log("sdjhjasd", completedOrders)
      } catch (error) {
        console.error('Error fetching new orders:', error);
      }
    };

    fetchNewOrdersCount();
  }, []);

  const cardData = [
    { icon: <ManageAccountsIcon className='m-2' />, title: 'Product Sold', value: productSoldCount },
    { icon: <BiSort className="text-xl sm:text-2xl m-2" />, title: 'Total Orders', value: newOrdersCount },
    { icon: <PersonOutlineRoundedIcon className='m-2' />, title: 'Total Earnings', value: `Price: ₹ ${totalEarnings}` },
  ];

  return (
    <div className=''>
      <h1 className="mt-3 mb-3 text-2xl ml-6">Sales</h1>
      <div className="w-full flex flex-col sm:flex-row gap-y-5 gap-x-2 sm:p-5 py-5">
        {/* Mapping over cardData to render CardComponent */}
        {cardData.map((card, index) => (
          <CardComponent key={index} icon={card.icon} title={card.title} value={card.value} />
        ))}
      </div>
    </div>
  );
}
