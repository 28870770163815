import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import vendorService from "./vendorService";
import { config } from "../../../config/config";

const saveVendor = JSON.parse( localStorage.getItem('vendor'))

 
const initialState = {
    vendor : saveVendor ? saveVendor:null,
    isError : false,
    isLoading : false,
    isSuccess: false,
    message : "",
}

const vendorSlices = createSlice({
    name : "vendor",
    initialState, 
    reducers : {
        reset : (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
        },
        resetMessage : (state) => {
            state.message = ""
        } 
    },
    extraReducers : (builder) => {
        builder
        .addCase(getVendor.pending , (state )=>{
            state.isLoading = true
            state.isError = false
            state.isSuccess = false
        })
        .addCase(getVendor.fulfilled , (state , action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.vendor = action.payload.vendor
            state.message = action.payload.message
            state.isError = false
        })
        .addCase(getVendor.rejected , (state , action)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload
            
        })

        .addCase(updateVendorPersonal.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
          })
          .addCase(updateVendorPersonal.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            // Update the vendor data in the state if needed
            state.vendor = action.payload;
            state.message = action.payload.message;
            state.isError = false;
          })
          .addCase(updateVendorPersonal.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload;
    
          })

          .addCase(updateVendorBusiness.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
          })
          .addCase(updateVendorBusiness.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            // Update the vendor data in the state if needed
            state.vendor = action.payload;
            state.message = action.payload.message;
            state.isError = false;
          })
          .addCase(updateVendorBusiness.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload;
        
          })
          .addCase(updateVendorAccount.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
          })
          .addCase(updateVendorAccount.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            // Update the vendor data in the state if needed
            state.vendor = action.payload;
            state.message = action.payload.message;
            state.isError = false;
          })
          .addCase(updateVendorAccount.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload;
           })
           
      .addCase(deleteProduct.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        state.isError = false;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload.message;
      })

  
    }
})

export const getVendor = createAsyncThunk("/getVendor", async (thunkAPI) => {

    try {
        const res =  await vendorService.getVendor();

       
        return res;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})  


export const updateVendorPersonal=createAsyncThunk("/updateVendor/Personal" , async(newUserData, thunkAPI)=>{ // change API
    try {
    
     const data= await vendorService.updateVendorPersonal(newUserData);
   
     return data;
    } catch (error) {
     const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
     return thunkAPI.rejectWithValue(message) 
    }
})
export const updateVendorBusiness=createAsyncThunk("/updateVendor/Business" , async(newUserData, thunkAPI)=>{ // change API
    try {
    
     const data= await vendorService.updateVendorBusiness(newUserData);
   
     return data;
    } catch (error) {
     const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
     return thunkAPI.rejectWithValue(message) 
    }
})
export const updateVendorAccount=createAsyncThunk("/updateVendor/Account" , async(newUserData, thunkAPI)=>{ // change API
  try {
  
   const data= await vendorService.updateVendorAccount(newUserData);
  
   return data;
  } catch (error) {
   const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
   return thunkAPI.rejectWithValue(message) 
  }
})

export const deleteProduct = createAsyncThunk('/products/:productId', async (data , thunkAPI) => {
  try {
    const  res = await vendorService.deleteProduct(data);
    
    return res;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message);
  }
});





export const {reset, resetMessage} = vendorSlices.actions
export default vendorSlices.reducer
