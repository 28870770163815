import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL, config } from "../../../config/config";
import { useSelector } from "react-redux";
import Loader from "../../common/Loader";
import { Link } from "react-router-dom";
import SuccessConstant from "../../../constants/SuccessConstant";
import toast from "react-hot-toast";
import TableComponent from "../../common/TableComponent";
import Pagination2 from "../../common/Pagination2";
import commonService from "../../../services/common/commonService";
import VendorDashboardMobileCard from "../../common/VendorDashboardMobileCard";

const Table = () => {
  const productsPerPage = 20;
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const vendor = useSelector((state) => state.vendor);
  const loggedInVendorId = vendor && vendor.vendor && vendor.vendor._id;
  const PRODUCT_API_URL = `${API_URL}/api/products/getProduct`;
  const noOrdersMessage = (
    <div className="text-center text-gray-500 mt-10">
      No orders in process available.
    </div>
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await commonService.fetchAllOrders();
        const ordersWithProductDetails = await Promise.all(
          response.map(async (order) => {
            const productDetailsWithAdditionalInfo = await Promise.all(
              order.productsDetails.map(async (product) => {
                try {
                  const productResponse = await axios.get(
                    `${PRODUCT_API_URL}/${product.productId}`,
                    config
                  );

                  return { ...product, additionalInfo: productResponse.data };
                } catch (error) {
                  console.error("Error fetching product details:", error);
                  return { ...product, additionalInfo: null };
                }
              })
            );
            return {
              ...order,
              productsDetails: productDetailsWithAdditionalInfo,
            };
          })
        );
        setOrders(ordersWithProductDetails);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      // Make a request to your backend to update the order status
      await axios.put(
        `${API_URL}/api/order/updateOrderStatus/${orderId}`,
        {
          orderStatus: newStatus,
        },
        config
      );

      // Update the local state with the new order status
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order._id === orderId ? { ...order, orderStatus: newStatus } : order
        )
      );
      toast.success(SuccessConstant.Success.InTransit);
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-IN",
      options
    );
    return formattedDate;
  };

  const newOrders = orders.filter((order) => order.orderStatus === "InProcess");

  const scrollTo = () => {
    window.scrollTo(0, 0);
  };

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
    scrollToActivePage(pageNumber);
  };

  const scrollToActivePage = (pageNumber) => {
    const paginationButton = document.getElementById(`page-${pageNumber}`);
    if (paginationButton) {
      paginationButton.scrollIntoView({ behavior: "smooth", inline: "center" });
    }
  };
  // Calculate the total number of pages
  const totalPages = Math.ceil(newOrders.length / productsPerPage);

  // Calculate the index of the first and last product to display on the current page
  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = Math.min(startIndex + productsPerPage, newOrders.length);

  const vendorNewOrders = newOrders.filter((order) =>
    order.productsDetails.some(
      (product) => product.vendorId === loggedInVendorId
    )
  );

  return (
    <>
      <div className="hidden sm:block p-5 overflow-x-auto">
        {loading ? (
          <div className="flex justify-center">
            <Loader />
          </div>
        ) : vendorNewOrders.length === 0 ? (
          noOrdersMessage
        ) : (
          <div>
            <TableComponent
              heads={[
                "Customer",
                "Order Details",
                "Order Status",
                "Date/Time",
                "Action",
              ]}
              data={newOrders
                ?.slice(startIndex, endIndex)
                ?.map((item, index) => {
                  const uniqueProductIds = new Set(
                    item.productsDetails
                      .filter(
                        (product) => product.vendorId === loggedInVendorId
                      )
                      .map((product) => product.productId)
                  );

                  return Array.from(uniqueProductIds).map(
                    (productId, productIndex) => {
                      const productDetail = item.productsDetails.find(
                        (product) => product.productId === productId
                      );

                      if (productDetail) {
                        const image =
                          productDetail.additionalInfo?.product?.images?.[0];

                        return (
                          <React.Fragment key={`${index}-${productIndex}`}>
                            <td>{item?.shippingAddress?.fullname}</td>
                            <td>
                              <div className="flex flex-col lg:flex-row gap-2 items-center">
                                <div key={productIndex}>
                                  <Link
                                    to={{
                                      pathname: `/SpecificProductsPage/${productDetail.additionalInfo?.product?._id}`,
                                      state: {
                                        productId:
                                          productDetail.additionalInfo?.product
                                            ?._id,
                                      },
                                    }}
                                    target="_blank"
                                    onClick={scrollTo}
                                  >
                                    {image && (
                                      <img
                                        src={image}
                                        alt="Product"
                                        className="w-16 h-16 md:w-24 md:h-24 object-fill"
                                      />
                                    )}
                                  </Link>
                                  <div className="flex flex-col items-start">
                                    <div>
                                      Quantity: {productDetail.quantity}
                                    </div>
                                    <div>
                                      Price:{" "}
                                      {productDetail.price *
                                        productDetail.quantity}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                <button className="bg-orange-100 w-28 h-10 rounded-2xl text-orange-600">
                                  {item.orderStatus}
                                </button>
                              </div>
                            </td>
                            <td className="px-4 py-2">
                              {formatDate(item.createdAt)}
                            </td>
                            <td className="px-4 py-2">
                              <div className="flex flex-row gap-2">
                                <button
                                  onClick={() =>
                                    handleStatusChange(item._id, "InTransit")
                                  }
                                  className="bg-orange-400 text-white w-24 h-10 rounded-[6px]"
                                >
                                  In Transit
                                </button>
                              </div>
                            </td>
                          </React.Fragment>
                        );
                      }

                      return null;
                    }
                  );
                })}
            />
            {/* Custom pagination */}

            <div>
              <Pagination2
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
      <div className="block sm:hidden p-5 mb-[100px]">
        {loading ? (
          <div className="flex justify-center">
            <Loader />
          </div>
        ) : vendorNewOrders.length === 0 ? (
          noOrdersMessage
        ) : (
          <>
            {newOrders?.map((item, index) => {
              const uniqueProductIds = new Set(
                item.productsDetails
                  .filter((product) => product.vendorId === loggedInVendorId)
                  .map((product) => product.productId)
              );

              return Array.from(uniqueProductIds).map(
                (productId, productIndex) => {
                  // Find the product details for the current product ID
                  const productDetail = item.productsDetails.find(
                    (product) => product.productId === productId
                  );

                  if (productDetail) {
                    // Access the first image for the current productDetail
                    const image =
                      productDetail.additionalInfo?.product?.images?.[0];

                    return (
                      <div
                        key={`${index}-${productIndex}`}
                        className="w-full mb-4"
                      >
                        <VendorDashboardMobileCard
                          order={item}
                          formatDate={formatDate}
                          scrollTo={scrollTo}
                          productDetail={productDetail}
                          showProcessButton={true}
                          handleStatusChange={handleStatusChange}
                          changeStatus="InTransit"
                        />
                      </div>
                    );
                  }
                  return null;
                }
              );
            })}
          </>
        )}
      </div>
      {/* mobile view */}
    </>
  );
};

export default Table;
