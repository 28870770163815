import React, { useState } from 'react'
import Searchbar from '../component/Catalogue/Searchbar'
import VendorCard from '../component/Catalogue/VendorCard'
import BrandingTag from '../component/Home/BrandingTag'
import Instagram from '../component/Home/Instagram'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function CataloguePage() {
  const vendorId = useParams();
  const [vendors, setVendors] = useState([])
  return (
    <>
      <div className='bg-white pl-2 sm:pl-10 pr-2 sm:pr-10 pt-7'>
        <helmet>
          <title>Catalogue</title>
        </helmet>
        <Searchbar setVendors={setVendors} />
        <VendorCard vendors={vendors} />
        <BrandingTag />
        <Instagram />
      </div>
    </>
  )
}

export default CataloguePage