import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL, config } from '../../../config/config';
import EditBlog from './EditBlog';
import Button from '../../common/Button';
import AddBlogModal from './AddBlogModal';
import { toast } from 'react-hot-toast';
import Loader from '../../common/Loader';

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with zero if necessary
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month (zero-indexed) and pad with zero if necessary
  const year = date.getFullYear(); // Get full year

  return `${day}/${month}/${year}`;
}


function OurBlogs() {
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [activeComponent, setActiveComponent] = useState("OurBlogs");
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [isAddBlogModalOpen, setIsAddBlogModalOpen] = useState(false);
  const [selectedBlogType, setSelectedBlogType] = useState('all');
  const [selectedBlogStatus, setSelectedBlogStatus] = useState('all');

  const getBlogs = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${API_URL}/api/admin-profile/getallblogs`, config);
      setBlogs(response.data.blog);
      console.log("dssdf", response.data.blog);

    } catch (error) {
      toast.error('Error fetching blogs');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);


  const handleEditBlog = (blog) => {
    try {
      setActiveComponent("EditBlog");
      setSelectedBlog(blog);
    } catch (error) {
      console.error("Error navigating to edit blog page:", error);
    }
  };

  const handleAddBlogModalOpen = () => {
    setIsAddBlogModalOpen(true);
  };

  const handleAddBlogModalClose = () => {
    setIsAddBlogModalOpen(false);
    getBlogs();
  };

  const handleBlogAdded = (newBlog) => {
    setBlogs([...blogs, newBlog]);
    getBlogs();
  };

  const handleBackToBlogs = () => {
    setActiveComponent("OurBlogs");
    getBlogs();
  };

  const handleBlogTypeChange = (type) => {
    setSelectedBlogType(type);
    setSelectedBlogStatus('all');
  };

  const handleBlogStatusChange = (status) => {
    setSelectedBlogStatus(status);
  };

  const filteredBlogs = blogs.filter((blog) => {
    if (selectedBlogType !== 'all' && blog.authorType !== selectedBlogType) return false;
    if (selectedBlogStatus !== 'all' && blog.status !== selectedBlogStatus) return false;
    if (selectedBlogType === 'admin' && blog.authorType !== 'admin') return false;
    return true;
  });


  return (
    <>
      <div className='w-full h-full'>
        <div className='p-5 w-full flex justify-between items-center'>
          <div className='flex flex-row gap-10'>
            <Button style='primary'
              className={selectedBlogType === 'all' ? 'bg-[#F16080]' : ''}
              onClick={() => handleBlogTypeChange('all')}
            >
              All Blogs
            </Button>
            <Button
              style='primary'
              className={selectedBlogType === 'admin' ? 'bg-[#F16080]' : ''}
              onClick={() => handleBlogTypeChange('admin')}
            >
              Admin Blogs
            </Button>
            <Button
              style='primary'
              className={selectedBlogType === 'vendor' ? 'bg-[#F16080]' : ''}
              onClick={() => handleBlogTypeChange('vendor')}
            >
              Vendor Blogs
            </Button>
          </div>
          {selectedBlogType !== 'vendor' && (
            <Button style='primary' onClick={handleAddBlogModalOpen}>ADD BLOGS</Button>
          )}
        </div>
        <hr className='gray-300' />
        {selectedBlogType === 'vendor' && (
          <div className='flex flex-row gap-10 p-5'>
            <Button
              style='primary'
              className={selectedBlogStatus === 'all' ? 'bg-[#F16080] w-full' : 'w-full'}
              onClick={() => handleBlogStatusChange('all')}
            >
              All
            </Button>
            <Button
              style='primary'
              className={selectedBlogStatus === 'verified' ? 'bg-[#F16080] w-full' : 'w-full'}
              onClick={() => handleBlogStatusChange('verified')}
            >
              Published
            </Button>
            <Button
              style='primary'
              className={selectedBlogStatus === 'pending' ? 'bg-[#F16080] w-full' : 'w-full'}
              onClick={() => handleBlogStatusChange('pending')}
            >
              Pending
            </Button>
            <Button
              style='primary'
              className={selectedBlogStatus === 'rejected' ? 'bg-[#F16080] w-full' : 'w-full'}
              onClick={() => handleBlogStatusChange('rejected')}
            >
              Rejected
            </Button>
          </div>
        )}

        {loading ? (
          <div className='flex justify-center items-center h-[500px]'>
            <Loader />
          </div>
        ) : (
          <>
            {activeComponent === "OurBlogs" && (
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Title
                    </th>
                    <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Image
                    </th>
                    <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Date
                    </th>
                    <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Status
                    </th>
                    <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {filteredBlogs && filteredBlogs?.map((blog, index) => (
                    blog && blog?.title ? (
                      <tr key={index}>
                        <td className='px-6 py-4 w-1/2'>
                          <span className='text-md font-semibold text-gray-900'>{blog.title}</span>
                        </td>
                        <td className='px-6 py-4'>
                          <img src={blog.blogImage.url} alt={blog.title} className='h-12 w-12 rounded-full' />
                        </td>
                        <td className='px-6 py-4'>
                          <span className='px-2 p-2 inline-flex leading-5 font-semibold rounded-full bg-green-100 text-green-800'>
                            {formatDate(blog.createdAt)}
                          </span>
                        </td>
                        <td className='px-6 py-4'>
                          <span className={`px-2 p-2 inline-flex leading-5 font-semibold rounded-full ${blog.status === 'verified' ? 'bg-green-100 text-green-800' :
                            blog.status === 'pending' ? 'bg-yellow-100 text-yellow-500' :
                              blog.status === 'rejected' ? 'bg-red-100 text-red-800' : ''
                            }`}>
                            {blog.status}
                          </span>
                        </td>
                        <td className='px-6 py-4'>
                          <button onClick={() => handleEditBlog(blog)} className='inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'>
                            Edit
                          </button>
                        </td>
                      </tr>
                    ) : null
                  ))}
                </tbody>
              </table>
            )}



            {activeComponent === "EditBlog" && (
              <EditBlog blog={selectedBlog} onClose={handleBackToBlogs} getBlogs={getBlogs} />
            )}

            {isAddBlogModalOpen && (
              <AddBlogModal onClose={handleAddBlogModalClose} onBlogAdded={handleBlogAdded} />
            )}
          </>
        )}

      </div>
    </>

  );
}

export default OurBlogs;
