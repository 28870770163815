import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { Tooltip } from '@mui/material'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { API_URL, config } from '../../../config/config';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import SuccessConstant from '../../../constants/SuccessConstant';
import ErrorConstants from '../../../constants/ErrorConstants';

const ProductVerify = () => {

    const params = useParams();
    const navigate = useNavigate();
    const [notVerifiedReasons, setNotVerifiedReasons] = useState("");
    const [products, setProducts] = useState(null)
    const [customization, setCustomization] = useState('')
    const [images, setImages] = useState([]);
    const [productName, setProductName] = useState('');
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [material, setMaterial] = useState('');
    const [internalMaterial, setInternalMaterial] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [dimensions, setDimensions] = useState('');
    const [originalPrice, setOriginalPrice] = useState('');
    const [discountPrice, setDiscountPrice] = useState('');
    const [modelSize, setModelSize] = useState('');
    const [sizeChartImage, setSizeChartImage] = useState(null);
    const [stock, setStock] = useState('');
    const [processingTime, setProcessingTime] = useState('');
    const [shelfLife, setShelfLife] = useState('');
    const [imageUpload, setImageUpload] = useState(false);
    const [VideoUpload, setVideoUpload] = useState(false);

    const [theEkaivPrice, setTheEkaivPrice] = useState('');
    const [theEkaivDiscountPrice, setTheEkaivDiscountPrice] = useState('');
    const[weight, setWeight] = useState('');

    const handleOriginalPriceChange = (event) => {
        // Allow only digits and limit to 10 digits
        const value = event.target.value.replace(/\D/g, '').slice(0, 10);
        setTheEkaivPrice(value);
    };

    const handleDiscountedPriceChange = (event) => {
        // Allow only digits and limit to 10 digits
        const value = event.target.value.replace(/\D/g, '').slice(0, 10);
        setTheEkaivDiscountPrice(value);
    };


    useEffect(() => {
        const fetchProductDetails = async () => {


            try {
                if (!params) {
                    console.error('Product or productId is undefined');
                    return;
                }

                const response = await axios.get(
                    `${API_URL}/api/products/getProduct/${params.itemId}`
                );

                const productDetails = response.data;
                setProducts(productDetails.product);


            } catch (error) {
                if (error.response) {
                    toast.error(` ${(error.response && error.response.data && error.response.data.message) || error.message || error.toString()}`);
                }
                console.error('Error fetching product details:', error);
            }
        };

        fetchProductDetails();
    }, [params]);


    const handleVideoChange = (event) => {
        const videoFile = event.target.files[0];

        // Check if the file size is within the allowed limit (50 MB)
        const maxSize = 50 * 1024 * 1024; // 50 MB in bytes
        if (videoFile && videoFile.size > maxSize) {
            alert('File size exceeds the maximum limit (50 MB). Please choose a smaller file.');
            return;
        }

        setSelectedVideo(videoFile);
    };




    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file, index) => {
            if (file instanceof Blob) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    setImages((prevState) => [
                        ...prevState,
                        { id: index, src: e.target.result },
                    ]);
                };
                reader.readAsDataURL(file);
            }
        });
    }, []);


    const handleSizeChange = (event) => {
        const size = event.target.value;
        setSelectedSizes((prevSelectedSizes) => {
            if (prevSelectedSizes.includes(size)) {
                return prevSelectedSizes.filter((s) => s !== size);
            } else {
                return [...prevSelectedSizes, size];
            }
        });
    };

    useEffect(() => {
        if (products) {
            setProductName(products.productName);
            setCustomization(products.customization);
            setDescription(products.description);
            setMaterial(products.material);
            setSelectedCategory(products.selectedCategory);
            setProcessingTime(products.processingTime);
            setDimensions(products.dimensions);
            setOriginalPrice(products.originalPrice);
            setDiscountPrice(products.discountPrice)
            setImages(products.images[0])
            // setSelectedVideo(products.video)
            setStock(products.stock);
            setShelfLife(products.shelfLife);
            setInternalMaterial(products.internalMaterial);
            setTheEkaivPrice(products.theEkaivPrice)
            setTheEkaivDiscountPrice(products.theEkaivDiscountPrice)
            setWeight(products.weight); 
        }
    }, [params, products])


    const handleVerify = async () => {
        try {
            const ekaivPrices = {
                theEkaivPrice,
                theEkaivDiscountPrice
            }
            const response = await axios.put(
                `${API_URL}/api/admin-profile/products/${products._id}/verify`, ekaivPrices, config
            );
            toast.success(SuccessConstant.Success.SUCCESS)
            window.scrollTo(0, 0);
            navigate(-1);
            // Handle verification response as needed
        } catch (error) {
            if (error.response) {
                toast.error(` ${(error.response && error.response.data && error.response.data.message) || error.message || error.toString()}`);
            }
            console.error('Error verifying product:', error);
        }

    };

    const handleReject = async () => {
        if (!notVerifiedReasons) {
            // If the "Not Verified Reasons" field is not filled, show a toast message and return
            toast.error(ErrorConstants.Error.VENDOR_REJECTED);
            return;
        }


        try {
            const response = await axios.put(
                `${API_URL}/api/admin-profile/products/${products._id}/reject`, {
                reason: notVerifiedReasons,
            }, config
            );
            toast.success(SuccessConstant.Success.REJECT_SUCCESS);
            navigate(-1);

            // Handle rejectEion response as needed
        } catch (error) {
            if (error.response) {
                toast.error(` ${(error.response && error.response.data && error.response.data.message) || error.message || error.toString()}`);
            }
            console.error('Error rejecting product:', error);
        }

        window.scrollTo(0, 0);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const submitData = {
            productName,
            selectedCategory,
            type,
            description,
            customization,
            material,
            internalMaterial,
            selectedSizes: selectedSizes.join(','),
            dimensions,
            originalPrice,
            discountPrice,
            modelSize,
            processingTime,
            selectedVideo,
            sizeChartImage,
            stock,
            weight
        };

        try {
            const response = await axios.post(
                `${API_URL}/api/admin-profile/products/${products._id}/verify`,
                submitData
            );
            window.history.back();

            // Handle submission response as needed
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };

    return (

        <div className='container mx-auto p-4 mb-2'>
            <form onSubmit={handleSubmit} className='max-w-full mx-auto bg-[#F5f6f9] shadow-md rounded-lg px-8 pt-6 pb-8 mb-4 '>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    {/* Left side */}
                    <div className='left w-full flex-wrap flex flex-col px-2 mb-4'>
                        <div className='flex gap-2 '>
                            <label htmlFor='productName' className="text-sm font-semibold">Product Name</label>
                            <div>
                                <Tooltip title={<h1 style={{ fontSize: 16 }}>Do not exceed 180 characters when entering the product name.</h1>} >
                                    <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                </Tooltip>
                            </div>
                        </div>
                        <div className='flex flex-row gap-2'>
                            <input
                                type="checkbox"
                                checked={productName}
                            />
                            <span className="mt-2 px-3 py-2 border bg-white rounded-md focus:outline-none w-full">{productName}</span>
                            {productName && (
                                <label className="text-green-400 flex justify-center items-center">
                                    Verified
                                </label>
                            )}

                        </div>

                        <div className='flex flex-col mt-4'>
                            <label htmlFor='category' className="text-sm font-semibold">Choose a category</label>
                            <div className='flex flex-row gap-2'>
                                <input
                                    type="checkbox"
                                    checked={selectedCategory}
                                />
                                <span className="mt-2 px-3 py-2 border bg-white rounded-md focus:outline-none w-full">{selectedCategory}</span>
                                {selectedCategory && (
                                    <label className="text-green-400 flex justify-center items-center">
                                        Verified
                                    </label>
                                )}
                            </div>
                        </div>

                        <div className='flex flex-col mt-4'>
                            <label htmlFor='category' className="text-sm font-semibold">Is product Customizable</label>
                            <div className='flex flex-row gap-2'>
                                <input
                                    type="checkbox"
                                    checked={customization}
                                />
                                <p className="mt-2 px-3 py-2 border bg-white rounded-md focus:outline-none w-full">{customization}</p>
                                {customization && (
                                    <label className="text-green-400 flex justify-center items-center">
                                        Verified
                                    </label>
                                )}
                            </div>
                        </div>

                        <div className='flex flex-col mt-4'>
                            <div className='flex gap-2'>
                                <label htmlFor='description' className="text-sm font-semibold">Product Specification</label>
                                <div>
                                    <Tooltip title={<h1 style={{ fontSize: 16 }}>Do not exceed 1000 characters when entering the product description.</h1>} >
                                        <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className='gap-2 flex items-center'>
                                <input
                                    type="checkbox"
                                    checked={description}
                                />
                                <p className="mt-2 px-3 py-2 border bg-white rounded-md focus:outline-none w-full">{description}</p>
                                {description && (
                                    <label className="text-green-400 ml-2">
                                        Verified
                                    </label>
                                )}
                            </div>

                        </div>
                        <div className='flex flex-col mt-4'>
                            <div className='flex gap-2'>
                                <label htmlFor='Weight' className="text-sm font-semibold">Product Weight</label>
                                
                            </div>
                            <div className='gap-2 flex items-center'>
                                <input
                                    type="checkbox"
                                    checked={weight}
                                />
                                <p className="mt-2 px-3 py-2 border bg-white rounded-md focus:outline-none w-full">{weight}</p>
                                {weight && (
                                    <label className="text-green-400 ml-2">
                                        Verified
                                    </label>
                                )}
                            </div>

                        </div>

                        <div className='flex flex-col mt-4'>
                            <label htmlFor='material' className="text-sm font-semibold">
                                {selectedCategory === 'homemade-food' ? 'Ingredients' :
                                    ['Men', 'Women', 'kids', 'Girl', 'Boy'].includes(selectedCategory) ? 'Fabric' : 'Material'}
                            </label>
                            <div className='flex flex-row gap-2'>
                                <input
                                    type="checkbox"
                                    checked={material}
                                />
                                <p className="mt-2 px-3 py-2 border bg-white rounded-md focus:outline-none w-full">
                                    {material} </p>
                                {material && (
                                    <label className="text-green-400 ml-2 flex justify-center items-center">
                                        Verified
                                    </label>
                                )}
                            </div>
                        </div>

                        {['Men', 'Women', 'kids', 'Girl', 'Boy'].includes(selectedCategory) ? (
                            <div className='flex flex-col mt-2'>
                                <label htmlFor='internalMaterial' className="text-sm font-semibold">Inner Fabric</label>
                                <div className='flex flex-row gap-2'>
                                    <input
                                        type="checkbox"
                                        checked={internalMaterial}
                                    />
                                    <p className="mt-2 px-3 py-2 border bg-white rounded-md focus:outline-none w-full">
                                        {internalMaterial} </p>
                                    {internalMaterial && (
                                        <label className="text-green-400 ml-2 flex justify-center items-center">
                                            Verified
                                        </label>
                                    )}
                                </div>
                            </div>
                        ) : null}


                        <div className='flex flex-col mt-4'>
                            <div className='flex gap-2'>
                                <label htmlFor='processing-time' className="text-sm font-semibold">Processing Time</label>
                                <div>
                                    <Tooltip title={<h1 style={{ fontSize: 16, }}>Enter the number of days required for processing the order.</h1>} >
                                        <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                    </Tooltip>
                                </div>

                            </div>
                            <div className='flex flex-row gap-2'>
                                <input
                                    type="checkbox"
                                    checked={processingTime}
                                />

                                <p className="mt-2 px-3 py-2 border bg-white rounded-md focus:outline-none w-full">
                                    {processingTime} </p>
                                {processingTime && (
                                    <label className="text-green-400 ml-2 flex justify-center items-center">
                                        Verified
                                    </label>
                                )}
                            </div>
                        </div>

                    </div>

                    {/* Right side */}
                    <div className='right flex flex-col w-full flex-wrap px-2 mb-4 mt-2'>
                        <div className='flex gap-2'>
                            <label className="text-sm font-semibold">Product Images</label>
                            <div>
                                <Tooltip title={<h1 style={{ fontSize: 16, }}>You need to add minimum 1 and maximum 5 images. Pay attention to the quality of the pictures you add, comply with the background color standards. Pictures must be in certain dimensions. Ensure that the product showcases all the details.</h1>} >
                                    <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                </Tooltip>
                            </div>
                        </div>

                        <div className="flex flex-col flex-wrap mt-2 w-full lg:w-full md:w-1/3">
                            <div className='flex flex-row item items-center gap-2 mt-2'>
                                <input
                                    type="checkbox"
                                    checked={imageUpload}
                                    onChange={() => setImageUpload(!imageUpload)}
                                />

                                {products?.images?.map((image, index) => (
                                    <div key={index}>
                                        <Link to={image} target="_blank" rel="noopener noreferrer">
                                            <img src={image} alt={`Image ${index}`} className='w-16 h-16' />
                                        </Link>
                                    </div>
                                ))}
                                {imageUpload && (
                                    <label className="text-green-400 ml-2 flex">
                                        Verified
                                    </label>
                                )}
                            </div>
                        </div>

                        <div className="w-full lg:w-full md:w-1/3 px-2 mb-4 mt-4">
                            <h3 className="text-sm font-semibold">
                                {['Men', 'Women'].includes(selectedCategory) && 'Selected Sizes:'}
                                {['Kids', 'Boy', 'Girl'].includes(selectedCategory) && 'Selected Years:'}
                            </h3>
                            {(['Men', 'Women', 'Kids', 'Boy', 'Girl'].includes(selectedCategory)) && (
                                <div className='flex flex-row items-center gap-2 mt-2'>
                                    <input
                                        type="checkbox"
                                        checked={selectedCategory !== ''}
                                    />
                                    {['Men', 'Women'].includes(selectedCategory) && (
                                        <div className="mt-2">
                                            <ul className='flex flex-row gap-2'>
                                                {products.selectedSizes.map((size, index) => (
                                                    <li key={index}>{size}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                    {['Kids', 'Boy', 'Girl'].includes(selectedCategory) && (
                                        <div className="mt-2">
                                            <ul className='flex flex-row gap-2'>
                                                {products.selectedSizes.map((size, index) => (
                                                    <li key={index}>{size}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                    <label className="text-green-400 ml-2 flex">
                                        Verified
                                    </label>
                                </div>
                            )}
                        </div>



                        <div className="w-full md:w-1/2 lg:w-full px-2 mb-4 mt-2">
                            {(['Men', 'Women', 'Kids', 'Boy', 'Girl'].includes(selectedCategory)) ? (
                                <>
                                    <label className="text-sm font-semibold" htmlFor='size-chart'>Size Chart Image</label>
                                    <div className='flex flex-row gap-2 mt-2 justify-start items-center'>
                                        <input
                                            type="checkbox"
                                            checked={products.sizeChartImage}
                                        />
                                        <img src={products.sizeChartImage}
                                            className="w-20 h-20"
                                        />
                                        {sizeChartImage && (
                                            <label className="text-green-400 ml-2 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}

                                    </div>

                                    <label htmlFor='model-size' className="text-sm font-semibold mt-4">Model Size</label>
                                    <div className='flex flex-row gap-2 mt-2 justify-start items-center'>
                                        <input
                                            type="checkbox"
                                            checked={products.modelSize}
                                        />
                                        <p className="mt-2 px-3 py-2 border bg-white rounded-md focus:outline-none w-full">{products.modelSize} </p>
                                        {products.modelSize && (
                                            <label className="text-green-400 ml-2 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}

                                    </div>
                                </>
                            ) : (
                                selectedCategory !== 'homemade-food' ? (
                                    <div className="mb-2 mt-4">
                                        <label htmlFor='dimensions' className="text-sm font-semibold">Dimensions</label>
                                        <div className='flex flex-row gap-2 mt-2 w-full'>
                                            <input
                                                type="checkbox"
                                                checked={dimensions}
                                            />
                                            <p className="mt-2 px-3 py-2 border bg-white rounded-md focus:outline-none w-full">
                                                {dimensions} </p>
                                            {dimensions && (
                                                <label className="text-green-400 ml-2 flex justify-center items-center">
                                                    Verified
                                                </label>
                                            )}
                                        </div>
                                    </div>

                                ) : null

                            )}
                        </div>


                        <div className="flex lg:flex-row max-lg:flex-col gap-4 mt-2">
                            <div className='w-full'>
                                <label htmlFor='original-price' className="text-sm font-semibold">Original Price</label>
                                <div className='flex flex-row gap-2 mt-2'>
                                    <input type="checkbox" checked={originalPrice} />
                                    <p className="mt-2 px-3 py-2 border bg-white rounded-md focus:outline-none w-full">
                                        {originalPrice} </p>
                                    {originalPrice && (
                                        <label className="text-green-400 ml-2 flex justify-center items-center">
                                            Verified
                                        </label>
                                    )}
                                </div>
                            </div>
                            <div className='w-full'>
                                <label htmlFor='discount-price' className="text-sm font-semibold mt-2">Discounted Price</label>
                                <div className='flex flex-row gap-2 mt-2'>
                                    <input
                                        type="checkbox"
                                        checked={discountPrice}
                                    />
                                    <p className="mt-2 px-3 py-2 border bg-white rounded-md focus:outline-none w-full">
                                        {discountPrice} </p>
                                    {discountPrice && (
                                        <label className="text-green-400 ml-2 flex justify-center items-center">
                                            Verified
                                        </label>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex lg:flex-row max-lg:flex-col gap-4 mt-4 mb-4">
                            {/* Ekaiv prizes */}
                            <div>
                                <label htmlFor='ekaivorigina-price' className="text-sm font-semibold mt-2">Ekaiv Original Price</label>
                                <input
                                    type='text'
                                    placeholder='Add Ekaiv original price of the product'
                                    value={theEkaivPrice}
                                    onChange={handleOriginalPriceChange}
                                    className="mt-2 px-3 py-2 border bg-white rounded-md focus:outline-none w-full"
                                />
                            </div>
                            <div>
                                <label htmlFor='ekaivdiscount-price' className="text-sm font-semibold mt-2">Ekaiv Discounted Price</label>
                                <input
                                    type='text'
                                    placeholder='Add Ekaiv discount price of the product'
                                    value={theEkaivDiscountPrice}
                                    onChange={handleDiscountedPriceChange}
                                    className="mt-2 px-3 py-2 w-full border rounded-md focus:outline-none focus:border-[#8A58DC]"
                                />
                            </div>
                        </div>

                        {selectedCategory !== 'homemade-food' ? (
                            <div className='flex flex-col mt-4'>
                                <label htmlFor='expiry-date' className="text-sm font-semibold">Stock</label>
                                <div className='flex flex-row gap-2 mt-2'>
                                    <input
                                        type="checkbox"
                                        checked={stock}
                                    />
                                    <p className="mt-2 px-3 py-2 border bg-white rounded-md focus:outline-none w-full"> {stock} </p>
                                    {stock && (
                                        <label className="text-green-400 ml-2 flex justify-center items-center">
                                            Verified
                                        </label>
                                    )}
                                </div>
                            </div>
                        ) : <></>}



                        {selectedCategory === 'homemade-food' && (
                            <div className='flex flex-col mt-2'>
                                <label htmlFor='expiry-date' className="text-sm font-semibold">Self Life</label>
                                <div className='flex flex-row gap-2 mt-2'>
                                    <input
                                        type="checkbox"
                                        checked={shelfLife}
                                    />
                                    <p className="mt-2 px-3 py-2 border bg-white rounded-md focus:outline-none w-full">{shelfLife}</p>
                                    {shelfLife && (
                                        <label className="text-green-400 ml-2 flex justify-center items-center">
                                            Verified
                                        </label>
                                    )}
                                </div>
                            </div>
                        )}


                    </div>
                </div>
                <div className="w-full flex-col justify-center items-center">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="myTextArea">
                        Not Verified Reasons :
                    </label>
                    <textarea
                        className="shadow appearance-none w-full py-2 px-3 border rounded-xl focus:outline-none focus:border-[#8A58DC] text-gray-700 leading-tight focus:shadow-outline"
                        id="myTextArea"
                        rows="4"
                        placeholder="Enter your text here"
                        value={notVerifiedReasons}
                        onChange={(e) => setNotVerifiedReasons(e.target.value)}
                    ></textarea>
                </div>


                <div className="w-full mt-4 flex gap-5">
                    <button type='submit' className="bg-custom-purple hover:bg-purple-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={handleVerify}>Verify</button>
                    <button
                        type='button'
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={handleReject} >
                        Reject
                    </button>
                </div>

            </form>
        </div>
    );
}

export default ProductVerify;

